import {
  TimelineItem,
  TimelineItemProps,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';
import { experimentalStyled as styled } from '@mui/material/styles';

export interface MTimelineItemProps extends TimelineItemProps {
  color?: 'grey' | 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}

export const TimelineItemStyle = styled(TimelineItem)`
  cursor: pointer;
  &:last-child {
    .time-line-connector {
      display: none;
    }
  }
`;

const MTimelineItem = (props: MTimelineItemProps) => (
  <TimelineItemStyle {...props}>
    <TimelineOppositeContent style={{ display: 'none' }} />
    <TimelineSeparator>
      <TimelineDot color={props.color} />
      <TimelineConnector className="time-line-connector" />
    </TimelineSeparator>
    <TimelineContent style={{ width: '100%' }}>{props.children}</TimelineContent>
  </TimelineItemStyle>
);

export default MTimelineItem;
