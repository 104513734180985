// eslint-disable-next-line max-classes-per-file
import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import isArray from 'lodash/isArray';

import { BaseSearchModel } from 'models/BaseSearchModel';

import { TicketFormModel } from '../../TicketForm/models';
import { Ticket, TicketSearchCriteria, BoardSearchCriteria } from '../types';

export class TicketListModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && TicketFormModel.toClass(item.value))
  ticketList: Array<Ticket> | undefined;

  @Expose({ name: 'totalElements' })
  totalElements: number | undefined;
}

export class TicketSearchModel extends BaseSearchModel {
  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: TicketSearchCriteria | undefined;
}
export class BoardSearchModel extends BaseSearchModel {
  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;

  @Expose({ name: 'searchCriteria' })
  @Transform((item) => item && BoardSearchCriteriaModel.toPlain(item.value), {
    toPlainOnly: true
  })
  searchCriteria: BoardSearchCriteria | undefined;
}

export class BoardSearchCriteriaModel extends BaseModel {
  @Expose({ name: 'projCode' })
  @Transform(({ value }) => (!isArray(value) ? [value] : value))
  projCode: string | undefined;

  @Expose({ name: 'assignee' })
  assignee: Array<string> | undefined;

  @Expose({ name: 'statusType' })
  statusType: Array<string> | undefined;

  @Expose({ name: 'period' })
  period: string | undefined;

  @Expose({ name: 'updatedStartDate' })
  @Transform(({ value }) => new Date(value).getTime())
  updatedStartDate: any | undefined;

  @Expose({ name: 'updatedEndDate' })
  @Transform(({ value }) => new Date(value).getTime())
  updatedEndDate: any | undefined;

  @Expose({ name: 'assigneeId' })
  assigneeId: Array<string> | undefined;

  @Expose({ name: 'appVerId' })
  appVerId: Array<string> | undefined;

  @Expose({ name: 'type' })
  type: Array<string> | undefined;

  @Expose({ name: 'priority' })
  priority: Array<string> | undefined;

  @Expose({ name: 'serviceType' })
  serviceType: Array<string> | undefined;

  @Expose({ name: 'serviceDetail' })
  serviceDetail: Array<string> | undefined;

  @Expose({ name: 'createdStartDate' })
  @Transform(({ value }) => new Date(value).getTime())
  createdStartDate: any | undefined;

  @Expose({ name: 'createdEndDate' })
  @Transform(({ value }) => new Date(value).getTime())
  createdEndDate: any | undefined;
}
export class TicketFileModel extends BaseModel {
  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'fileName' })
  fileName: string | undefined;

  @Expose({ name: 'fileUrl' })
  fileUrl: string | undefined;
}

export class TicketExcelDownloadParamModel extends TicketSearchModel {
  @Expose({ name: 'menuName' })
  menuName: string | undefined;

  @Expose({ name: 'columnNames' })
  columnNames: Array<string> | undefined;

  @Expose({ name: 'labelNames' })
  labelNames: Array<string> | undefined;
}
