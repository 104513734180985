import { forwardRef, useCallback } from 'react';

import MInput, { MInputProps } from 'components/@material-extend/MInput';
import { ESTIMATION_TIME_REGEX } from 'constants/common';
import isEmpty from 'lodash/isEmpty';
import { correctDurationString } from 'utils/formatDuration';

interface EstimationInputProps extends Omit<MInputProps, 'onChange'> {
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  name: string;
  defaultValue?: string;
}

const EstimationInput = (props: EstimationInputProps, ref: React.Ref<unknown>) => {
  const { value, onChange, name, defaultValue = '', onKeyDown, ...rest } = props;

  const handleValidateValue = useCallback(
    (inputValue: any) => {
      if (isEmpty(inputValue)) {
        onChange(name, defaultValue);
        return;
      }
      if (ESTIMATION_TIME_REGEX.test(inputValue.trim())) {
        const convertedValue = correctDurationString(inputValue, defaultValue);
        onChange(name, convertedValue);
      }
    },
    [defaultValue, name, onChange]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;
    onChange(name, value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;
    handleValidateValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleValidateValue(value);
    }
    onKeyDown && onKeyDown(event);
  };

  return (
    <MInput
      {...rest}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      inputRef={ref}
    />
  );
};

export default forwardRef(EstimationInput);
