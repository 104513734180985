import {
  GET_APPLICATION_BY_PROJ_CODE,
  GET_PROJECT_URL_BY_CORP_CODE,
  GET_VERSION_BY_APP_CODE,
  TICKET_URL,
  GET_PROJECT_CICD_BY_PROJ_CODE,
  LOAD_SERVICE_CLASSFICATION,
  GET_FILE_SERVICE_TARGET,
  DELETE_TICKET_DETAIL,
  RESOTE_INTEGRATION
} from 'constants/apiUrl';
import apiClient, { multipartConfig } from 'apis/apiClient';

export const createTicket = (params: FormData) =>
  apiClient
    .post(TICKET_URL, params, multipartConfig)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const editTicket = (params: FormData) =>
  apiClient
    .put(TICKET_URL, params, multipartConfig)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchProjectsByCorpCode = () =>
  apiClient
    .post(GET_PROJECT_URL_BY_CORP_CODE)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchApplicationsByProjCode = (params: any) =>
  apiClient
    .post(GET_APPLICATION_BY_PROJ_CODE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchVersionsByAppCode = (params: any) =>
  apiClient
    .post(GET_VERSION_BY_APP_CODE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getProjectCicdByProjCode = (params: any) =>
  apiClient
    .post(GET_PROJECT_CICD_BY_PROJ_CODE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const loadServiceClassfication = (params: any) =>
  apiClient
    .post(LOAD_SERVICE_CLASSFICATION, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getFileServiceTarget = (params: any) =>
  apiClient
    .post(GET_FILE_SERVICE_TARGET, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const deleteTicket = (params: any) =>
  apiClient
    .post(DELETE_TICKET_DETAIL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const restoreIntegration = (params: any) =>
  apiClient
    .put(RESOTE_INTEGRATION, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
