import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function DocIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6193 3.6001H9.92918C9.6695 3.59988 9.42036 3.70283 9.23658 3.8863C9.0528 4.06977 8.94944 4.31874 8.94922 4.57842V8.93939L18.0416 11.609L26.6001 8.93939V4.57842C26.5999 4.31859 26.4964 4.06951 26.3124 3.88601C26.1285 3.70252 25.8791 3.59966 25.6193 3.6001Z"
          fill="#41A5EE"
        />
        <path
          d="M26.6001 8.93945H8.94922V14.2787L18.0416 15.8805L26.6001 14.2787V8.93945Z"
          fill="#2B7CD3"
        />
        <path
          d="M8.94922 14.2786V19.6179L17.5069 20.6857L26.6001 19.6179V14.2786H8.94922Z"
          fill="#185ABD"
        />
        <path
          d="M9.92918 24.9572H25.6185C25.8784 24.9579 26.128 24.8551 26.3121 24.6716C26.4963 24.4881 26.5999 24.2389 26.6001 23.9789V19.6179H8.94922V23.9789C8.94944 24.2386 9.0528 24.4875 9.23658 24.671C9.42036 24.8545 9.6695 24.9574 9.92918 24.9572Z"
          fill="#103F91"
        />
        <path
          opacity="0.1"
          d="M15.4566 7.87158H8.94922V21.2198H15.4566C15.9962 21.2171 16.4333 20.7811 16.4374 20.2415V8.8499C16.4333 8.31031 15.9962 7.87426 15.4566 7.87158Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M14.9218 8.40552H8.94922V21.7537H14.9218C15.4614 21.751 15.8986 21.315 15.9026 20.7754V9.38384C15.8986 8.84425 15.4614 8.4082 14.9218 8.40552V8.40552Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M14.9218 8.40552H8.94922V20.6859H14.9218C15.4614 20.6832 15.8986 20.2471 15.9026 19.7076V9.38384C15.8986 8.84425 15.4614 8.4082 14.9218 8.40552V8.40552Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M14.3871 8.40552H8.94922V20.6859H14.3871C14.9267 20.6832 15.3638 20.2471 15.3679 19.7076V9.38384C15.3638 8.84425 14.9267 8.4082 14.3871 8.40552V8.40552Z"
          fill="black"
        />
        <path
          d="M4.58088 8.40552H14.3871C14.928 8.40506 15.367 8.84294 15.3679 9.38384V19.1736C15.367 19.7145 14.928 20.1524 14.3871 20.1519H4.58088C4.32106 20.1524 4.07171 20.0495 3.88775 19.866C3.7038 19.6825 3.60032 19.4335 3.6001 19.1736V9.38384C3.60032 9.12401 3.7038 8.87493 3.88775 8.69143C4.07171 8.50794 4.32106 8.40508 4.58088 8.40552Z"
          fill="url(#paint0_linear_word)"
        />
        <path
          d="M7.625 15.9117C7.64389 16.0628 7.65703 16.1943 7.66278 16.3068H7.68578C7.694 16.2 7.71207 16.0711 7.73918 15.9207C7.76628 15.7704 7.7901 15.6431 7.81228 15.5388L8.84318 11.0973H10.1772L11.245 15.4722C11.3072 15.7429 11.3517 16.0172 11.3781 16.2937H11.3962C11.4158 16.0246 11.4528 15.7571 11.5071 15.4928L12.3605 11.0916H13.5738L12.0755 17.456H10.6569L9.64078 13.2453C9.61121 13.1238 9.57781 12.9655 9.54057 12.7706C9.50333 12.5756 9.48033 12.4332 9.47157 12.3434H9.45432C9.44282 12.4469 9.41982 12.6005 9.38532 12.8042C9.35082 13.0088 9.32371 13.1591 9.30318 13.2577L8.34785 17.4584H6.90543L5.39893 11.0973H6.63107L7.5601 15.5478C7.58783 15.6679 7.60949 15.7894 7.625 15.9117Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_word"
            x1="-1.00541"
            y1="11.465"
            x2="6.6442"
            y2="24.7611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2368C4" />
            <stop offset="0.5" stopColor="#1A5DBE" />
            <stop offset="1" stopColor="#1146AC" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
