import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';
import { Application } from '../types';

export class ProjectModel extends BaseModel {
  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'projName' })
  projName: string | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'integrateSolution' })
  integrateSolution: string | undefined;
}

export class ApplicationModel extends BaseModel {
  @Expose({ name: 'projCode', toPlainOnly: true })
  projCode: string | undefined;

  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;
}

export class VersionModel extends BaseModel {
  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'version' })
  version: string | undefined;
}

export class ProjectApplications extends BaseModel {
  @Expose({ name: 'application' })
  application: Application | undefined;
}
