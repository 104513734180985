import { PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { debounce, put, select } from 'redux-saga/effects';
import {
  UNAUTHORIZED_STATUS,
  BAD_REQUEST_STATUS,
  INTERNAL_SERVER_ERROR_STATUS,
  API_STATUS_ECONNABORTED,
  MISSING_LICENSE,
  FORBIDDEN,
  KEY_ERROR_USER_LOGOUT,
  FORCE_LOGOUT,
  LOGOUT_DUPLICATE
} from 'constants/common';
import {
  SIGN_IN_URI,
  NOTFOUND_URI,
  SERVICE_UNAVAILABLE_URI,
  TIMEOUT_URI,
  FORBIDDEN_URI,
  TICKET_DETAIL
} from 'constants/routes';
import { authActions, authSliceName, initialState } from 'features/auth/slices';
import { LOGIN } from 'constants/apiUrl';
import toastHelper from 'helpers/toast';
import getDefaultLang from 'utils/getDefaultLang';
import { getCorpInTicketLink } from 'utils/helpers';
import { ticketDetailActions } from 'features/helpDesk/ticket/TicketDetail/slices';

const ERROR_HANDLER = 'ERROR_HANDLER';

const IS_NOT_DEV = process.env.NODE_ENV !== 'development';

const DEBOUND_TIME = 1000;

export const errorHandler = (payload: unknown) => ({
  type: ERROR_HANDLER,
  payload
});

const handleClientError = (error: any) => {
  const isAxiosError = Boolean(error.isAxiosError);
  switch (true) {
    case error.code === API_STATUS_ECONNABORTED:
      window.location.href = TIMEOUT_URI;
      break;
    case isAxiosError && window.location.pathname === SIGN_IN_URI:
      toastHelper.error('error.network');
      break;
    case isAxiosError:
      // if (IS_NOT_DEV) window.location.href = SERVICE_UNAVAILABLE_URI;
      toastHelper.error('error.internalServerError');
      break;

    default:
      toastHelper.error(error);
  }
};

const isNotFound = (errorMessage: string) => errorMessage.includes('error.common.notfound');

// Just show a toast message for now
function* handler(action: PayloadAction<any>): Generator<any, any> {
  const error = action.payload;
  if (!error) return;
  const isServerError = Boolean(error.response);
  if (!isServerError) {
    handleClientError(error);
    return;
  }

  const pathToGetErrorMessage = get(error, 'response.data.data.validationErrors', null)
    ? 'validationErrors[0].errors[0].message'
    : 'errors[0].message';

  const responseError = get(
    error,
    `response.data.data.${pathToGetErrorMessage}`,
    'Something went wrong!'
  );

  const responseErrorKey = get(error, `response.data.data.errors[0].messageKey`);
  const responseStatusError = get(error, ['response', 'status'], 400);
  const urlLogin = get(error, ['response', 'config', 'url'], '');

  // GET   ROLE USER LOGIn TO SHOW TOAST IMPORT
  const roleUserLogin = get(error, `response.data.data.errors[0].messageKey`, null);

  const langStorage = getDefaultLang(localStorage.getItem('i18nextLng'));
  switch (responseStatusError) {
    case UNAUTHORIZED_STATUS: {
      const formStatus = (state: any) =>
        get(state, [authSliceName, 'formStatus'], initialState.formStatus);
      const selectedFormStatus = yield select(formStatus);
      if (
        urlLogin !== LOGIN &&
        window.location.pathname === SIGN_IN_URI &&
        selectedFormStatus === initialState.formStatus
      ) {
        // not show toast message when logout
        return;
      }

      toastHelper.error(responseError);
      break;
    }
    case BAD_REQUEST_STATUS: {
      if (isNotFound(responseError) && IS_NOT_DEV) {
        const { pathname, search } = window.location;
        if (pathname === TICKET_DETAIL && responseError !== 'error.common.notfound.incorp.id') {
          const corpTicket = getCorpInTicketLink(search);
          const corpData = (state: any) => get(state, [authSliceName, 'userInfo', 'corpList']);
          const corpList: any = yield select(corpData) || [];
          const corpIndex = corpList.findIndex((item: any) => item.corpCode === corpTicket);
          if (corpIndex > -1) {
            yield put(
              authActions.changeCurrentCorp({
                corpCode: corpTicket
              })
            );
            yield put(
              ticketDetailActions.fetchAllTicketDetail({
                ticketCode: search.split('=')[1],
                corpCode: corpTicket
              })
            );
            return;
          }
          window.location.href = NOTFOUND_URI;
        }
      }
      if (responseErrorKey === KEY_ERROR_USER_LOGOUT) {
        // todo
        yield put(authActions.autoLogout({}));
        window.location.href = SIGN_IN_URI;
        sessionStorage.setItem(FORCE_LOGOUT, LOGOUT_DUPLICATE);
        return;
      }
      const mesGitlapErr =
        langStorage === 'en'
          ? 'The contact value entered already exists. Please enter another value.'
          : '입력한 연락처 값이 이미 존재합니다. 다른 값을 입력해 주세요.';
      const mesaage =
        responseError === 'message.gitlab.user.contact.exist' ? mesGitlapErr : responseError;
      toastHelper.error(mesaage);
      break;
    }
    case INTERNAL_SERVER_ERROR_STATUS: {
      if (IS_NOT_DEV) window.location.href = SERVICE_UNAVAILABLE_URI;
      toastHelper.error('error.internalServerError');
      break;
    }
    case MISSING_LICENSE: {
      yield put(authActions.setShowImportLicense(true));
      yield put(authActions.setRoleUserLogin(roleUserLogin));
      break;
    }
    case FORBIDDEN: {
      window.location.href = FORBIDDEN_URI;
      break;
    }
    default: {
      toastHelper.error(responseError);
    }
  }
}
export default function* errorHandlerSaga() {
  yield debounce(DEBOUND_TIME, ERROR_HANDLER, handler);
}
