import { Box } from '@mui/material';
import SvgIconStyle from 'components/SvgIconStyle';
import { CHANGE_TYPE_NOTIFICATION } from './constants';
import { TYPE_NOTI } from './type';

export const getIconByType = (type: TYPE_NOTI) => {
  const icons = (() => {
    switch (type) {
      case CHANGE_TYPE_NOTIFICATION.STATUS_UPDATE:
        return 'ic_status.png';
      case CHANGE_TYPE_NOTIFICATION.ASSIGN_TO_ME:
        return 'ic_assign.png';
      case CHANGE_TYPE_NOTIFICATION.UPDATE_FIELD:
        return 'ic_updated.png';
      case CHANGE_TYPE_NOTIFICATION.CREATE_TICKET:
        return 'ic_created.png';
      case CHANGE_TYPE_NOTIFICATION.UNASSIGNED_USER:
        return 'ic_unassign.png';
      case CHANGE_TYPE_NOTIFICATION.LOG_WORK:
        return 'ic_logwork.png';
      case CHANGE_TYPE_NOTIFICATION.COMMENT:
        return 'ic_comment.png';
      case CHANGE_TYPE_NOTIFICATION.TAG:
        return 'ic_tag.png';
      default:
        return 'ic_created.png';
    }
  })();

  return <Box height={24} src={`/static/icons/${icons}`} component="img" />;
};

export const getIcon = (name: string, color = 'inherit') => (
  <SvgIconStyle color={color} src={`/static/icons/${name}.svg`} />
);
