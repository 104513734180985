import React from 'react';

// quill
import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';
import TableColumnTool from 'quill-better-table/src/modules/table-column-tool';

import { getEventComposedPath, cellId, isInTableCell, rowId } from '../helpers';
import { RangeStatic } from '../interfaces';

import {
  TableCol,
  TableColGroup,
  TableCellLine,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableViewWrapper
} from './TableBlots';
import TableSelection from './TableSelection';
import TableOperationMenu from './TableOperationMenu';

const Delta = Quill.import('delta');

class BetterTable extends QuillBetterTable {
  static register = () => {
    Quill.register(TableCol, true);
    Quill.register(TableColGroup, true);
    Quill.register(TableCellLine, true);
    Quill.register(TableCell, true);
    Quill.register(TableRow, true);
    Quill.register(TableBody, true);
    Quill.register(TableContainer, true);
    Quill.register(TableViewWrapper, true);
    Quill.register(TableViewWrapper, true);
  };

  constructor(quill: Quill, options: any) {
    super(quill, options);

    // handle right click on quill-better-table
    this.quill.root.addEventListener(
      'contextmenu',
      (evt: React.MouseEvent<any>) => {
        if (!this.table) return;
        evt.preventDefault();

        // bugfix: evt.path is undefined in Safari, FF, Micro Edge
        const path = getEventComposedPath(evt);
        if (!path || path.length <= 0) return;

        const tableNode = path.filter(
          (node: any) =>
            node.tagName &&
            node.tagName.toUpperCase() === TableContainer.tagName.toUpperCase() &&
            node.classList.contains(TableContainer.className)
        )[0];

        const rowNode = path.filter(
          (node: any) =>
            node.tagName &&
            node.tagName.toUpperCase() === TableRow.tagName.toUpperCase() &&
            node.getAttribute('data-row')
        )[0];

        const cellNode = path.filter(
          (node: any) =>
            node.tagName &&
            node.tagName.toUpperCase() === TableCell.tagName.toUpperCase() &&
            node.getAttribute('data-row')
        )[0];

        if (!cellNode) return;

        const isTargetCellSelected = this.tableSelection?.selectedTds
          ?.map((tableCell: TableCell) => tableCell.domNode)
          .includes(cellNode);

        if (this.tableSelection?.selectedTds?.length <= 0 || !isTargetCellSelected) {
          this.tableSelection?.setSelection(
            cellNode.getBoundingClientRect(),
            cellNode.getBoundingClientRect()
          );
        }

        if (this.tableOperationMenu) this.tableOperationMenu = this.tableOperationMenu.destroy();

        if (tableNode) {
          this.tableOperationMenu = new TableOperationMenu(
            {
              table: tableNode,
              row: rowNode,
              cell: cellNode,
              left: evt.pageX,
              top: evt.pageY
            },
            quill,
            options.operationMenu
          );
        }
      },
      false
    );
  }

  insertTable(rows: number, columns: number, currentRange?: RangeStatic) {
    const range = currentRange || this.quill.getSelection();

    if (range == null) return;

    const currentBlot = this.quill.getLeaf(range.index)[0];
    let delta = new Delta().retain(range.index);

    // Can not insert table into a table cell.
    if (isInTableCell(currentBlot)) return;

    delta.insert('\n');

    // Insert table column
    delta = new Array(columns).fill('\n').reduce((memo, text) => {
      memo.insert(text, { [TableCol.blotName]: true });
      return memo;
    }, delta);

    // Insert table cell line with empty line
    delta = new Array(rows).fill(0).reduce((memo) => {
      const tableRowId = rowId();
      return new Array(columns).fill('\n').reduce((memo, text) => {
        memo.insert(text, { [TableCellLine.blotName]: { row: tableRowId, cell: cellId() } });
        return memo;
      }, memo);
    }, delta);
    delta.insert('\n');

    // @ts-ignore
    this.quill.updateContents(delta, Quill.sources.USER);
    // @ts-ignore
    this.quill.setSelection(range.index + columns + 1, Quill.sources.API);
  }

  showTableTools(table: any, quill: any, options: any) {
    this.table = table;
    this.columnTool = new TableColumnTool(table, quill, options);
    this.tableSelection = new TableSelection(table, quill, options);
  }
}

export default BetterTable;
