import React, { ReactNode } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { experimentalStyled as styled } from '@mui/material/styles';

import { Icon } from '@iconify/react';
import arrowDown from '@iconify/icons-eva/chevron-down-fill';
import arrowRight from '@iconify/icons-eva/chevron-right-fill';

export const TreeItemStyled = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  [`& .${treeItemClasses.group}`]: {
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2)
    }
  }
}));

type MTreeProps = {
  expands: Array<string>;
  renderTreeItem: ReactNode;
  collapseIcon?: ReactNode;
  expandIcon?: ReactNode;
  showEndIcon?: boolean;
  onNodeToggle?: (e: React.SyntheticEvent, nodeIds: Array<string>) => void;
  onNodeSelect?: (e: React.SyntheticEvent, nodeId: string) => void;
};
const MTree = ({
  expands,
  renderTreeItem,
  onNodeToggle,
  onNodeSelect,
  showEndIcon = true,
  collapseIcon = <Icon icon={arrowDown} width={24} height={24} />,
  expandIcon = <Icon icon={arrowRight} width={24} height={24} />
}: MTreeProps) => (
  <TreeView
    defaultCollapseIcon={collapseIcon}
    defaultExpandIcon={expandIcon}
    defaultEndIcon={showEndIcon ? <div style={{ width: 24 }} /> : null}
    sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    expanded={expands}
    onNodeToggle={onNodeToggle}
    onNodeSelect={onNodeSelect}
  >
    {renderTreeItem}
  </TreeView>
);

export default MTree;
