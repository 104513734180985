import { Icon as Iconify } from '@iconify/react';
import { experimentalStyled as styled, Theme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';

import { DRAWER_WIDTH, DRAWER_COLLAPSE_WIDTH } from './constants';

export const RootStyle = styled('div')(
  ({ theme, styleProps }: { theme: Theme; styleProps: { isExpandMenu: boolean } }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: styleProps.isExpandMenu ? DRAWER_WIDTH : DRAWER_COLLAPSE_WIDTH
    }
  })
);

export const ButtonCollapseStyle = styled(Box)(
  ({ theme, styleProps }: { theme: Theme; styleProps: { isExpandMenu: boolean } }) => ({
    [theme.breakpoints.up('lg')]: {
      top: theme.typography.pxToRem(30),
      borderRadius: `0 ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(10)} 0`,
      position: 'fixed',
      left: styleProps.isExpandMenu ? theme.typography.pxToRem(280) : theme.typography.pxToRem(100),
      width: theme.typography.pxToRem(32),
      height: theme.typography.pxToRem(32),
      boxShadow: theme.customShadows.z8
    }
  })
);

export const IconStyle = styled(Iconify)(({ theme }) => ({
  width: theme.typography.pxToRem(25),
  height: theme.typography.pxToRem(25),
  marginTop: theme.typography.pxToRem(3),
  marginLeft: theme.typography.pxToRem(3),
  color: theme.palette.primary.main,
  cursor: 'pointer'
}));

export const CorpNameStyle = styled(Typography)(
  ({ theme, styleProps }: { theme: Theme; styleProps: { isExpandMenu: boolean } }) => ({
    display: styleProps.isExpandMenu ? 'block' : 'none',
    maxWidth: theme.typography.pxToRem(130),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600
  })
);

export const CorpNameStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600
}));

export const ItemCorpNameStyled = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden ',
  textOverflow: 'ellipsis ',
  fontWeight: 400,
  fontSize: '0.875rem'
}));

export const WrapperListCorp = styled(Box)(({ theme }) => ({
  maxHeight: '80vh',
  overflow: 'auto'
}));

export const WrapperListStatusList = styled(Box)(({ theme }) => ({
  border: `1px solid #cccc`,
  borderRadius: '4px',
  padding: theme.spacing(1),
  minHeight: '330px'
}));

export const NotFoundData = styled('div')(({ theme }) => ({
  minHeight: '330px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const LabelVersion = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.spacing(2),
  marginBottom: 5,
  marginTop: -5
}));

export const WrapperLogoCorpName = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

export const LogoCorp = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(32),
  height: theme.typography.pxToRem(32),
  overflow: 'hidden',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1)
}));

export const ListItemHeaderStyled = styled('div')(({ theme }) => ({
  overflowX: 'auto',
  overflowY: 'hidden',
  height: 80,
  scrollbarWidth: 'thin'
}));

export const ContentHeaderItemStyled = styled(Stack)(({ theme }) => ({
  minWidth: 540,
  height: '100%',
  whiteSpace: 'nowrap'
}));

export const WrapperIconAccount = styled('div')(({ theme }) => ({
  '& > div': {
    transform: 'none !important'
  }
}));
