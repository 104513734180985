import { Expose } from 'class-transformer';
import BaseModel from './BaseModel';

export type LocalizationKey = 'ko' | 'en';

export interface ILocaleString {
  en: string;
  ko: string;
}

export interface IHistoryMessage {
  en: Array<string>;
  ko: Array<string>;
}

// Modeling the mulitlingle fields which are string type
export class LocaleStringModel extends BaseModel {
  @Expose({ name: 'en' })
  en: LocalizationKey | undefined;

  @Expose({ name: 'ko' })
  ko: LocalizationKey | undefined;
}
