import 'material-react-toastify/dist/ReactToastify.css';
import { ToastContainer as MToastContainer } from 'material-react-toastify';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& .Toastify__toast-container--top-right': {
      [theme.breakpoints.down('sm')]: {
        width: theme.typography.pxToRem(250),
        marginLeft: theme.typography.pxToRem(100),
        top: theme.typography.pxToRem(15)
      },
      [theme.breakpoints.up('md')]: {
        width: theme.typography.pxToRem(300)
      }
    }
  }
}));

const ToastContainer = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <MToastContainer />
    </div>
  );
};
export default ToastContainer;
