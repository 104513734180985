import { Expose } from 'class-transformer';

import BaseModel from './BaseModel';

export class SearchCriteriaModel extends BaseModel {}

export class BaseSearchModel extends BaseModel {
  @Expose({ name: 'direction' })
  direction: string | undefined;

  @Expose({ name: 'itemPerPage' })
  itemPerPage: number | undefined;

  @Expose({ name: 'pageNum' })
  pageNum: number | undefined;

  @Expose({ name: 'sortBy' })
  sortBy: string | undefined;

  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteriaModel | undefined;
}
