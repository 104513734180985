import { ReactNode } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';

import { authSliceName } from 'features/auth/slices';

type AuthButtonProps = {
  btnId: string;
  children: ReactNode;
};

const AuthButtonTicket = ({ btnId, children }: AuthButtonProps) => {
  const menuButtonList = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'buttonTickets'], []),
    shallowEqual
  );

  return menuButtonList && menuButtonList.indexOf(btnId) !== -1 ? <>{children}</> : null;
};

export default AuthButtonTicket;
