import { Expose } from 'class-transformer';

import BaseModel from 'models/BaseModel';
import { FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';
import { TransformDateModel } from 'utils/transformer';
import { ViewFile } from 'interfaces/ViewFile';
import MultipartModel from 'models/MultipartModel';

export class TicketGetParamsModel extends BaseModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'appVersionId' })
  appVersionId: string | undefined;
}

export class TicketChangeStatusParamsModel extends TicketGetParamsModel {
  @Expose({ name: 'statusType' })
  statusType: string | undefined;

  @Expose({ name: 'approvalOpinion' })
  approvalOpinion: string | undefined;

  @Expose({ name: 'approval' })
  approval: string | undefined;

  @Expose({ name: 'withdrawYn' })
  withdrawYn: string | undefined;

  @Expose({ name: 'approvalDate' })
  approvalDate: number | undefined;

  @Expose({ name: 'disableCancelYn' })
  disableCancelYn: string | undefined;
}

export class TicketLogWorkModel extends MultipartModel {
  @Expose({ name: 'timeSpent' })
  timeSpent: string | undefined;

  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'workDesc' })
  workDescription: string | undefined;

  @Expose({ name: 'startedDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  date: number | undefined;

  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'ticketId' })
  ticketId: number | undefined;

  @Expose({ name: 'ticketLogWorkFiles' })
  ticketLogWorkFiles: Array<ViewFile> | undefined;

  @Expose({ name: 'logworkStatus' })
  logworkStatus: string | undefined;

  @Expose({ name: 'originalLogworkStatus' })
  originalLogworkStatus: string | undefined;
}

export class CheckIntegrationActiveModel extends TicketGetParamsModel {
  @Expose({ name: 'projCode' })
  projCode: string | undefined;
}
