import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class ProjectSearchParamsModel extends BaseModel {
  @Expose({ name: 'direction' })
  direction: string | undefined;

  @Expose({ name: 'itemPerPage' })
  itemPerPage: number | undefined;

  @Expose({ name: 'pageNum' })
  pageNum: number | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteriaModel | undefined;

  @Expose({ name: 'sortBy' })
  sortBy: string | undefined;

  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;
}

export class SearchCriteriaModel extends BaseModel {
  @Expose({ name: 'searchType' })
  searchType: string | undefined;

  @Expose({ name: 'searchValue' })
  searchValue: string | undefined;

  @Expose({ name: 'type' })
  type: string | undefined;

  @Expose({ name: 'status' })
  status: string | undefined;
}

export class SearchProjectByIDModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;
}
export class ProjectExcelDownloadParamModel extends ProjectSearchParamsModel {
  @Expose({ name: 'columns' })
  columns: Array<string> | undefined;

  @Expose({ name: 'labelNames' })
  labelNames: Array<string> | undefined;
}
