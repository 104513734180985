import numeral from 'numeral';
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';

// ----------------------------------------------------------------------

export function formatDataSize(number: string | number, format: string = '0b') {
  return numeral(number).format(format);
}

export function formatPercent(number: string | number, format: string = '0,0.0%') {
  return numeral(number).format(format);
}

export function formatNumber(number: string | number) {
  return numeral(number).format();
}

export const stringToNumber = (value: string): string | number => {
  const converted = toNumber(value);

  return isNumber(converted) ? converted : value;
};
