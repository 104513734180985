import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, call } from 'redux-saga/effects';
import { errorHandler } from 'store/errorHandlerSaga';
import * as commonCodeApis from './apis';
import { CommonCode } from './models';

const commonCodeSliceName = 'commonCode';

export const initialState = {
  data: {},
  subCode: {},
  isLoading: false,
  serviceClassfication: [],
  serviceClassficationDetail: []
};

const commonCodeSlice = createSlice({
  name: commonCodeSliceName,
  initialState,
  reducers: {
    fetchCommonCodeProcessing: (state) => {
      state.isLoading = true;
    },
    fetchCommonCodeSuccess: (state, action) => {
      state.isLoading = false;
      // Update the new common codes into the fetched list
      state.data = { ...state.data, ...action.payload };
    },
    fetchCommonCodeFailure: (state) => {
      state.isLoading = false;
    },
    clearCommonCodes: (state) => {
      state.data = initialState.data;
      state.subCode = initialState.subCode;
    },
    fetchSubCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.subCode = { ...state.subCode, ...action.payload };
    },
    fetchServiceClassficationSuccess: (state, action) => {
      state.isLoading = false;
      state.serviceClassfication = action.payload;
    },
    fechServiceClassficationDetailSuccess: (state, action) => {
      state.isLoading = false;
      state.serviceClassficationDetail = action.payload;
    },
    removeCommonSubCodeByKey: (state, { payload }) => {
      state.subCode = { ...state.subCode, [payload]: [] };
    }
  }
});

const { actions: reducerActions, reducer: commonCodeReducer } = commonCodeSlice;

const commonCodeSliceSaga = createSliceSaga({
  name: commonCodeSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *fetchCommonCode(action): any {
      const codes = action.payload;
      try {
        yield put(reducerActions.fetchCommonCodeProcessing());

        const {
          data: { data: responseData }
        } = yield call(commonCodeApis.fetchCommonCodeList, codes);

        const transformedData: any = {};
        Object.keys(responseData).forEach((key) => {
          transformedData[key] = CommonCode.toClass(responseData[key]);
        });

        yield put(reducerActions.fetchCommonCodeSuccess(transformedData));
      } catch (error) {
        yield put(reducerActions.fetchCommonCodeFailure());
        yield put(errorHandler(error));
      }
    },
    *fetchSubCommonCodeByGroupCode(action): any {
      const codes = action.payload;
      try {
        yield put(reducerActions.fetchCommonCodeProcessing());

        const {
          data: { data: responseData }
        } = yield call(commonCodeApis.fetchSubCodeByGroupCode, codes);

        const transformedData: any = {};
        Object.keys(responseData).forEach((key) => {
          transformedData[key] = CommonCode.toClass(responseData[key]);
        });

        yield put(reducerActions.fetchSubCodeSuccess(transformedData));
      } catch (error) {
        yield put(reducerActions.fetchCommonCodeFailure());
        yield put(errorHandler(error));
      }
    },
    *fetchServiceClassfication(action): any {
      try {
        yield put(reducerActions.fetchCommonCodeProcessing());
        const { data } = yield call(commonCodeApis.loadServiceClassfication, action.payload);
        if (data.data) {
          const listService = data.data.map((item: any) => CommonCode.toClass(item));
          yield put(reducerActions.fetchServiceClassficationSuccess(listService));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchServiceClassficationDetail(action): any {
      try {
        const {
          data: { data: responseData }
        } = yield call(commonCodeApis.fetchSubCodeByGroupCode, { codes: action.payload });
        if (responseData) {
          const dataTransformer: any = {};
          Object.keys(responseData).forEach((keyService: string) => {
            const listCodeDetail = get(responseData, [keyService], []);
            const transform = listCodeDetail.length
              ? listCodeDetail.map((item: any) => CommonCode.toClass(item))
              : [];
            dataTransformer[keyService] = transform;
          });
          yield put(reducerActions.fechServiceClassficationDetailSuccess(dataTransformer));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  }
});

const { actions: sagaActions, saga: commonCodeSaga } = commonCodeSliceSaga;

const commonCodeActions = {
  ...reducerActions,
  ...sagaActions
};

export { commonCodeSliceName, commonCodeActions, commonCodeReducer, commonCodeSaga };
