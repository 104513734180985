import { createSlice } from '@reduxjs/toolkit';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';
import { call, put } from 'redux-saga/effects';
import { errorHandler } from 'store/errorHandlerSaga';
import get from 'lodash/get';

import { fetchProjectsByCorpCode } from 'features/helpDesk/ticket/TicketForm/apis';
import { ProjectModel } from 'features/helpDesk/ticket/TicketManagement/models';

import { EmailMeWhenSettingModel } from './EmailMeWhenSettingModel';
import {
  addListStatus,
  getEmailMeWhenSettings,
  getStatusByProjectCode,
  updateEmailMeWhenSettings
} from './apis';

const emailConfigSliceName = 'emailConfigSlice';

const initialState = {
  emailMeWhenSettings: null,
  isSubmittingSettingForm: false,
  listProject: [],
  treeStatus: null,
  loadingFetchStatus: false
};

const emailSlice = createSlice({
  name: emailConfigSliceName,
  initialState,
  reducers: {
    getEmailMeWhenSettingsSuccess: (state, action) => {
      state.emailMeWhenSettings = action.payload;
    },
    modifySubmittingSettingFormStatus: (state, action) => {
      state.isSubmittingSettingForm = action.payload;
    },
    fetchProjectSuccess: (state, action) => {
      state.listProject = action.payload;
    },
    fetchTreeStatusSuccess: (state, action) => {
      state.treeStatus = action.payload;
      state.loadingFetchStatus = false;
    },
    modifyLoadingFetchStatus: (state, action) => {
      state.loadingFetchStatus = action.payload;
    },
    clearForm: (state) => {
      state.treeStatus = null;
      state = { ...initialState };
    }
  }
});

const { actions: reducerActions, reducer: emailReducer } = emailSlice;

const ticketSliceSaga = createSliceSaga({
  name: emailConfigSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *getEmailMeWhenSettings(): any {
      try {
        const { data: emailSettingsRes } = yield call(getEmailMeWhenSettings);
        if (emailSettingsRes) {
          yield put(
            reducerActions.getEmailMeWhenSettingsSuccess(
              EmailMeWhenSettingModel.toClass(emailSettingsRes.data)
            )
          );
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyLoadingFetchStatus(false));
      }
    },
    *updateEmailMeWhenSettings(action): any {
      try {
        const { data: emailSettingsRes } = yield call(
          updateEmailMeWhenSettings,
          EmailMeWhenSettingModel.toPlain(action.payload.params)
        );
        if (emailSettingsRes) {
          yield put(sagaActions.getEmailMeWhenSettings());
          action.payload.callback();
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifySubmittingSettingFormStatus(false));
      }
    },
    *fetchProjects(): any {
      try {
        const { data: projectRes } = yield call(fetchProjectsByCorpCode);
        if (projectRes) {
          const projectData = ProjectModel.toClass(projectRes.data);
          yield put(reducerActions.fetchProjectSuccess(projectData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchstatusByProject(action): any {
      try {
        yield put(reducerActions.modifyLoadingFetchStatus(true));
        const { data } = yield call(getStatusByProjectCode, action.payload);
        if (data) {
          const subCodeList: Array<any> = [];
          Object.keys(data.data).forEach((key) => {
            subCodeList.push(get(data, ['data', key]));
          });
          yield put(reducerActions.fetchTreeStatusSuccess({ subCodeList, code: 'root' }));
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyLoadingFetchStatus(false));
      }
    },
    *addListStatus(action): any {
      try {
        yield call(addListStatus, action.payload);
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  }
});

const { saga: emailSaga, actions: sagaActions } = ticketSliceSaga;

const emailActions = {
  ...reducerActions,
  ...sagaActions
};

export { emailConfigSliceName, emailActions, emailReducer, emailSaga, initialState };
