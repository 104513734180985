export const TITLE_FIELD_MAX_LENGTH = 150;
export const LONG_INPUT_MAX_LENGTH = 5000;
export const LONG_INPUT_MAX_LENGTH_DECIMAL = '5,000';
export const TEXT_MAX_LENGTH_10 = 10;
export const TEXT_MAX_LENGTH_8 = 8;
export const TEXT_MAX_LENGTH_16 = 16;
export const TEXT_MAX_LENGTH_15 = 15;
export const TEXT_MAX_LENGTH_20 = 20;
export const TEXT_MAX_LENGTH_30 = 30;
export const TEXT_MAX_LENGTH_25 = 25;
export const TEXT_MAX_LENGTH_50 = 50;
export const TEXT_MAX_LENGTH_100 = 100;
export const TEXT_MAX_LENGTH_120 = 120;
export const TEXT_MAX_LENGTH_150 = 150;
export const TEXT_MAX_LENGTH_200 = 200;
export const TEXT_MAX_LENGTH_250 = 250;
export const TEXT_MAX_LENGTH_255 = 255;
export const TEXT_MAX_LENGTH_300 = 300;
export const TEXT_MAX_LENGTH_500 = 500;
export const TEXT_MAX_LENGTH_5K = 5000;
export const TEXT_MAX_LENGTH_5K_DECIMAL = '5,000';
export const TEXT_MAX_LENGTH_10K = 10000;
export const TEXT_MAX_LENGTH_10K_DECIMAL = '10,000';
export const TEXT_MAX_LENGTH_20K = 20000;
export const TEXT_MAX_LENGTH_20K_DECIMAL = '20,000';
export const TEXT_MAX_LENGTH_50K = 50000;
export const TEXT_MAX_LENGTH_50K_DECIMAL = '50,000';
export const TEXT_MAX_LENGTH_20B = 20000000;
export const TEXT_MAX_LENGTH_20B_DECIMAL = '20,000,000';
export const TEXT_MAX_LENGTH_10B = 20000000;
export const TEXT_MAX_LENGTH_10B_DECIMAL = '10,000,000';

export const TEXT_MIN_LENGTH_2 = 2;
export const TEXT_MIN_LENGTH_1 = 1;
export const TEXT_MIN_LENGTH_5 = 5;

export const MAX_NOTIFICATION_DISPLAY = 99;
export const NOTIFICATION_ITEM_PER_PAGE = 5;
