import isEmpty from 'lodash/isEmpty';

const CONVERSION_RATES = {
  MINS_TO_HOUR: 60,
  HOURS_TO_DAY: 8,
  DAYS_TO_WEEK: 5,
  WEEKS_TO_MONTH: 4
};

const NORMAL_CONVERSION_RATES = {
  MINS_TO_HOUR: 60,
  HOURS_TO_DAY: 24,
  DAYS_TO_WEEK: 7,
  WEEKS_TO_MONTH: 4
};

// Duration of each time type in minute unit
const MIN = 1;
const HOUR = CONVERSION_RATES.MINS_TO_HOUR * MIN;
const DAY = CONVERSION_RATES.HOURS_TO_DAY * HOUR;
const WEEK = CONVERSION_RATES.DAYS_TO_WEEK * DAY;
const MONTH = CONVERSION_RATES.WEEKS_TO_MONTH * WEEK;

const N_HOUR = NORMAL_CONVERSION_RATES.MINS_TO_HOUR * MIN;
const N_DAY = NORMAL_CONVERSION_RATES.HOURS_TO_DAY * N_HOUR;
const N_WEEK = NORMAL_CONVERSION_RATES.DAYS_TO_WEEK * N_DAY;
const N_MONTH = NORMAL_CONVERSION_RATES.WEEKS_TO_MONTH * N_WEEK;

const DURATION_FORMATTER = [
  { time: MONTH, format: 'mo' },
  { time: WEEK, format: 'w' },
  { time: DAY, format: 'd' },
  { time: HOUR, format: 'h' },
  { time: MIN, format: 'm' }
];

export const NORMAL_DURATION_FORMATER = [
  { time: N_MONTH, format: 'mo' },
  { time: N_WEEK, format: 'w' },
  { time: N_DAY, format: 'd' },
  { time: N_HOUR, format: 'h' },
  { time: MIN, format: 'm' }
];

export const durationToMinute = (duration: string): number =>
  duration.split(/\s/).reduce((acc, cur) => {
    const [, num, word]: string[] = cur.split(/(\d+)/);
    const foundDuration = DURATION_FORMATTER.find((d) => d.format === word);
    return acc + Number(num) * (foundDuration ? foundDuration.time : 1);
  }, 0);

export const minuteToDuration = (
  minute: number,
  durationFormatter = DURATION_FORMATTER
): string => {
  const { result } = durationFormatter.reduce(
    (acc, { time, format }) =>
      acc.leftMinute / time >= 1
        ? {
            result: `${acc.result} ${Math.floor(acc.leftMinute / time)}${format}`,
            leftMinute: acc.leftMinute % time
          }
        : acc,
    { result: '', leftMinute: minute }
  );

  return result;
};

export const correctDurationString = (duration: string, defaultValue: string = ''): string => {
  // Remove extra whitespace between words and at the beginning and end
  const trimmedDuration = duration.replace(/\s+/g, ' ').trim();
  const result = minuteToDuration(durationToMinute(trimmedDuration)).trim();
  return isEmpty(result) ? defaultValue : result;
};
