import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled, Theme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, useTheme } from '@mui/material';
//
import { MHidden } from 'components/@material-extend';
import AccountPopover from './AccountPopover';
import CorpPopover from './CorpPopover';
import NewTicketDialog from './NewTicketDialog';
import Notifications from './Notifications';
import ConcurrentUser from './ConcurrentUser';
import { ContentHeaderItemStyled, ListItemHeaderStyled } from './styles';
// import Notifications from './Notifications';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(
  ({ theme, styleProps }: { theme: Theme; styleProps: { isExpandMenu: boolean } }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
      width: styleProps.isExpandMenu ? `calc(100% - ${DRAWER_WIDTH + 1}px)` : '100%'
    }
  })
);

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
  isExpandMenu: boolean;
};

export default function DashboardNavbar({ onOpenSidebar, isExpandMenu }: DashboardNavbarProps) {
  const theme = useTheme<Theme>();

  return (
    <RootStyle theme={theme} styleProps={{ isExpandMenu }}>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box sx={{ flexGrow: 1 }} />
        <ListItemHeaderStyled>
          <ContentHeaderItemStyled
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <ConcurrentUser />
            <NewTicketDialog />

            <CorpPopover />
            <Notifications />
            <AccountPopover />
          </ContentHeaderItemStyled>
        </ListItemHeaderStyled>
      </ToolbarStyle>
    </RootStyle>
  );
}
