import { Select, SelectProps, useTheme, Theme } from '@mui/material';

export default function MSelect(props: SelectProps) {
  const theme = useTheme<Theme>();
  const StyleMenuSelect = {
    PaperProps: {
      style: {
        maxHeight: theme.typography.pxToRem(189)
      }
    }
  };
  return <Select {...props} MenuProps={StyleMenuSelect} />;
}
