import { fork } from 'redux-saga/effects';
import { authSaga } from 'features/auth/slices';
import errorHandlerSaga from './errorHandlerSaga';

// TODO: hotfix for now
// Need to find out why redux-toolkit-saga can not load its slices
export default function* rootSaga() {
  yield fork(errorHandlerSaga);
  yield fork(authSaga);
}
