export type STATUS_ICON_TYPE =
  | 'AVAILABLE'
  | 'BUSY'
  | 'DO_NOT_DISTURB'
  | 'BE_RIGHT_BACK'
  | 'APPEAR_AWAY'
  | 'APPEAR_OFFLINE';

type USER_STATUS_TYPE = {
  [key in STATUS_ICON_TYPE]: STATUS_ICON_TYPE;
};
export const USER_STATUS: USER_STATUS_TYPE = {
  AVAILABLE: 'AVAILABLE',
  BUSY: 'BUSY',
  DO_NOT_DISTURB: 'DO_NOT_DISTURB',
  BE_RIGHT_BACK: 'BE_RIGHT_BACK',
  APPEAR_AWAY: 'APPEAR_AWAY',
  APPEAR_OFFLINE: 'APPEAR_OFFLINE'
};

export const TIME_RESET = {
  THIRTY_MINUTES: 'THIRTY_MINUTES',
  ONE_HOUR: 'ONE_HOUR',
  TWO_HOURS: 'TWO_HOURS',
  TODAY: 'TODAY',
  THIS_WEEK: 'THIS_WEEK',
  CUSTOM: 'CUSTOM'
};
