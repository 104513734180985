import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import get from 'lodash/get';
// material
import { MenuItem, Tooltip } from '@mui/material';
import { clearSearchInTicket, removeViewTabTikcet } from 'utils/authHelper';

// components
import MenuPopover from 'components/MenuPopover';
import { TEXT_MAX_LENGTH_20 } from 'constants/lengths';
import { MIconButton } from 'components/@material-extend';
import { ReactComponent as CorpPopoverIcon } from 'img/icons/corpPopover.svg';
import { authActions, authSliceName } from 'features/auth/slices';

import { ItemCorpNameStyled, WrapperListCorp } from './styles';

// ----------------------------------------------------------------------
const TEST_ID = 'corpHeader';

type Corp = {
  corpName: string;
  corpCode: string;
};

export default function CorpPopover() {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const corpList = useSelector((state) => get(state, [authSliceName, 'userInfo', 'corpList'], []));
  const currentCorpCode = useSelector((state) =>
    get(state, [authSliceName, 'currentCorpCode'], '')
  );

  const handleChangeCorp = (corpCode: string) => {
    dispatch(authActions.changeCurrentCorp({ corpCode, navigate }));
    setOpen(false);
    clearSearchInTicket();
    removeViewTabTikcet();
  };

  const showListCorp = () => {
    setOpen(true);
    setLoading(false);
  };

  const handleGetListCorp = () => {
    setLoading(true);
    dispatch(
      authActions.getCorpList({
        showLoading: false,
        callback: showListCorp
      })
    );
  };

  return (
    <>
      <MIconButton
        test-id={TEST_ID}
        ref={anchorRef}
        onClick={handleGetListCorp}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          cursor: isLoading ? 'progress' : 'pointer',
          ...(open && { bgcolor: 'action.selected' })
        }}
      >
        <CorpPopoverIcon />
      </MIconButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <WrapperListCorp sx={{ py: 1 }}>
          {corpList.map((corp: Corp) => (
            <MenuItem
              key={corp.corpCode}
              selected={corp.corpCode === currentCorpCode}
              onClick={() => handleChangeCorp(corp.corpCode)}
              sx={{ py: 1, px: 2.5 }}
            >
              {corp.corpName?.length > TEXT_MAX_LENGTH_20 ? (
                <Tooltip title={corp.corpName}>
                  <ItemCorpNameStyled>{corp.corpName}</ItemCorpNameStyled>
                </Tooltip>
              ) : (
                <ItemCorpNameStyled>{corp.corpName}</ItemCorpNameStyled>
              )}
            </MenuItem>
          ))}
        </WrapperListCorp>
      </MenuPopover>
    </>
  );
}
