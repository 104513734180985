import { Expose, Transform } from 'class-transformer';

import BaseModel from 'models/BaseModel';
import { TransformDateModel } from 'utils/transformer';
import { FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';

export class TicketCommentParamsModel extends BaseModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'commentType' })
  type: string | undefined;

  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;
}
export class TicketCommentModel extends BaseModel {
  @Expose({ name: 'ticketId' })
  ticketId?: string | number | undefined;

  @Expose({ name: 'id' })
  commentId: string | number | undefined;

  @Expose({ name: 'content' })
  content: string | undefined;

  @Expose({ name: 'type' })
  type?: string | undefined;

  @Expose({ name: 'typeName' })
  typeName?: string | undefined;

  @Expose({ name: 'createdDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  createdDate?: string | undefined;

  @Expose({ name: 'createdBy' })
  createdBy?: string | undefined;

  @Expose({ name: 'createdName' })
  createdName?: string | undefined;

  @Expose({ name: 'createdUserAvtUrl' })
  createdUserAvtUrl?: string | undefined;

  @Expose({ name: 'updatedDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  updatedDate?: string | undefined;

  @Expose({ name: 'tags' })
  tags?: Array<string> | undefined;

  @Expose({ name: 'commentStatus' })
  commentStatus?: string | undefined;
}

export class TicketCommentListModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && TicketCommentModel.toClass(item.value))
  ticketCommentList: Array<TicketCommentModel> | undefined;

  @Expose({ name: 'numberOfElements' })
  totalElements: number | undefined;
}

export class TicketCommentDeleteModel extends BaseModel {
  @Expose({ name: 'id' })
  commentId: string | number | undefined;
}
