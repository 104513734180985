export const dashboard = {
  title: 'Dashboard',
  welcomeMessage: {
    greeting: 'Hello, {{name}}',
    welcomeTo: 'Welcome to MetaDesk!',
    howCanHelp: 'How can we help you?',
    help: '? Help',
    useManual: 'User Instruction Manual',
    downloadUserManual: 'MetaDesk',
    forEngLang: 'For English language:',
    forKoLang: 'For Korean language:',
    pleaseRefer: 'Please refer to user manual.',
    emptyFiles: 'Document does not exist. Please contact the administrator.'
  },
  myRecentTickets: 'My Recent Tickets',
  metrics: {
    progressingRate: {
      title: 'Ticket Progressing Rate',
      service: 'Service ',
      serviceTooltip: `The number of Service tickets in every status, except 'CLOSED', among all Service tickets.`,
      incident: 'Incidents',
      incidentTooltip: `The number of Incident tickets in every status, except 'CLOSED', among all Incident tickets.`,
      serviceReqDeploy: 'Service Request (Deploy)',
      serviceReqDeployTooltip: `The number of Service Request (Deploy) tickets in every status, except 'CLOSED', among all Service Request (Deploy) tickets.`,
      serviceDeploy: 'Service (Deploy)',
      serviceDeployTooltip: `The number of Service (Deploy) tickets in every status, except 'CLOSED', among all Service (Deploy) tickets.`,
      serviceRequset: 'Service Request',
      serviceRequsetTooltip: `The number of Service Request tickets in every status, except 'CLOSED', among all Service Request tickets.`,
      tooltipProgessRate: `The number of {{label}} tickets in every status, except 'CLOSED', among all {{label}} tickets.`
    },
    ticketCreated: {
      title: 'Ticket Created',
      tooltip: 'The total number of tickets created in the last 30 days.'
    },
    unassigned: {
      title: 'Unassigned',
      tooltip:
        'The number of tickets currently not assigned, not cancelled, not resolved and not closed.'
    },
    notClosedTickets: {
      title: 'Not Closed Tickets',
      tooltip:
        'The number of currently not closed tickets. This includes tickets in every status, except Cancelled, Resolved and Closed.'
    },
    inProgressTickets: {
      title: 'In Progress Tickets',
      tooltip: `The number of tickets in every status, except "Open", "Cancel", "Closed" and "Approval request" (if any)`
    },
    ticketTargets: {
      last30Days: 'last 30 days',
      vsPrev30Days: 'vs previous 30 days',
      vsYesterday: 'vs yesterday'
    },
    waitingForProgress: {
      title: 'Waiting for Progress',
      tooltip: `The number of tickets with the status 'Open'.`
    },
    closeTicket: {
      title: 'Closed Tickets',
      tooltip: `The number of tickets with the statuses "Cancel", "Closed" within last 30 days.`
    }
  },
  announcement: 'Announcement',
  dashboardStatus: {
    title: 'Document Status'
  },
  notFound: 'There are no results for the query.',
  notFoundAnnouncement: 'There are no new announcements.',
  settings: 'Settings',
  profile: 'Profile',
  customDashboard: 'Custom Dashboard',
  emailMeWhen: 'Email Alarm Setting',
  email: 'Email',
  kakao: 'Kakao',
  projectMember: 'Project Member',
  project: 'Project',
  setting: {
    unassigned: 'Unassigned',
    ticketProgRate: 'Ticket Progressing Rate',
    closeTicket: 'Closed Tickets',
    ticketCreated: 'Ticket Created',
    announcement: 'Announcement',
    calendar: 'Calendar',
    contractStatus: 'Contract Status',
    documentStatus: 'Document Status',
    inProgTicket: 'In Progress Tickets',
    myRecentTicket: 'My Recent Tickets',
    waitingForProgress: 'Waiting for Progress'
  },
  emailSetting: {
    ticketCreated: 'Ticket Created',
    ticketUpdated: 'Ticket Updated',
    ticketAssigned: 'Ticket Assigned',
    ticketStatus: 'Ticket Status',
    ticketComment: 'Ticket Comment',
    accountRegistrationRequest: 'Account Registration Request',
    announcement: 'Announcement',
    checkOrRemoveAll: 'Check/remove all'
  },
  contractStatus: {
    title: 'Contract Status',
    created: 'Contract {{contractID}} has been created.',
    willExpire: 'Contract {{contractID}} will be terminated after {{dateCount}} day(s).',
    expired: 'Contract {{contractID}} will be expired today',
    completed: 'Contract {{contractID}} has been changed to completed.'
  },
  notifications: {
    title: 'Notifications',
    myNotifications: 'My notifications',
    seeUnread: 'See Unread',
    unread: 'Unread',
    seeAll: 'See All',
    all: 'All',
    totalUnreadMessage: 'You have {{total}} unread messages'
  },
  schedule: {
    title: 'Schedule {{year}}-{{month}}'
  },
  userStatus: {
    available: 'Available',
    busy: 'Busy',
    doNotDisturb: 'Do not disturb',
    beRightBack: 'Be right back',
    appearAway: 'Appear away',
    appearOffline: 'Appear offline',
    duration: 'Duration',
    resetStatus: 'Reset Status',
    status: 'Status',
    resetAfter: 'Reset status after',
    timeReset: {
      thirtyMinutes: '30 Minutes',
      oneHour: '1 Hour',
      twoHour: '2 Hours',
      today: 'Today',
      thisWeek: 'This week',
      custom: 'Custom',
      timeReuire: 'Time required field.'
    }
  }
};
