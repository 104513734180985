import { Transform } from 'class-transformer';
import dayjs from 'dayjs';
import isString from 'lodash/isString';

import { parseValue } from './helpers';

export function TransformDateModel(format: string) {
  const toClass = Transform(
    ({ value }) => (value && dayjs(value).isValid() ? dayjs(value).format(format) : value),
    {
      toClassOnly: true
    }
  );
  const toPlain = Transform((v) => v && v.value && dayjs(v.value).valueOf(), { toPlainOnly: true });

  return (target: any, key: string) => {
    toClass(target, key);
    toPlain(target, key);
  };
}

export function TransformArrayModel(model: any) {
  const toClass = Transform((item) => item && model.toClass(item.value), {
    toClassOnly: true
  });
  const toPlain = Transform((item) => item && model.toPlain(item.value), { toPlainOnly: true });

  return (target: any, key: string) => {
    toClass(target, key);
    toPlain(target, key);
  };
}
export function TransformJSONModel(shouldParse: boolean = true) {
  const toClass = Transform(({ value }) => (isString(value) ? value : JSON.stringify(value)), {
    toClassOnly: true
  });
  const toPlain = Transform(({ value }) => (shouldParse ? parseValue(value) : value), {
    toPlainOnly: true
  });

  return (target: any, key: string) => {
    toClass(target, key);
    toPlain(target, key);
  };
}
