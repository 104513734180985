import dayjs from 'dayjs';

import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import { BASIC_DATE_FORMAT } from 'constants/common';

export class ContractModel extends BaseModel {
  @Expose({ name: 'contractCode' })
  contractCode: string | undefined;

  @Expose({ name: 'contractName', toClassOnly: true })
  contractName: string | undefined;

  @Expose({ name: 'orgName', toClassOnly: true })
  orgName: string | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'startDate', toClassOnly: true })
  @Transform((v) => v.value && dayjs(v.value).format(BASIC_DATE_FORMAT))
  contractStartDate: string | undefined;

  @Expose({ name: 'endDate', toClassOnly: true })
  @Transform((v) => v.value && dayjs(v.value).format(BASIC_DATE_FORMAT))
  contractEndDate: string | undefined;
}

export class ContractSearchModel extends BaseModel {
  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteriaContractModel | undefined;
}

export class SearchCriteriaContractModel extends BaseModel {
  @Expose({ name: 'contractType' })
  contractType: string | undefined;

  @Expose({ name: 'searchType' })
  searchType: string | undefined;

  @Expose({ name: 'searchValue' })
  searchValue: string | undefined;

  @Expose({ name: 'statusType' })
  statusType: string | undefined;
}
