import Quill from 'quill';

const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

class SmartBreak extends Break {
  static value() {
    return '\n';
  }

  insertInto(parent: any, ref: any) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';

export default SmartBreak;
