import { ButtonProps } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import useLocales from 'hooks/useLocales';
import MButton from '../MButton';

// For automation test
const TEST_ID = 'inviteUserBtn';

const InviteUserButton = (props: ButtonProps) => {
  const { t } = useLocales();
  return (
    <MButton
      test-id={TEST_ID}
      {...props}
      startIcon={<SendIcon />}
      variant="contained"
      color="primary"
    >
      {t('common.button.inviteUser')}
    </MButton>
  );
};

export default InviteUserButton;
