import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class InviteUsersModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'roleCode' })
  roleCode: string | undefined;

  @Expose({ name: 'emails' })
  emails: string[] | undefined;
}
