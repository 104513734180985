import { Expose, Transform } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class ProjectCicdItemsModel extends BaseModel {
  @Expose({ name: 'projCicdCode' })
  projCicdCode: string | undefined;

  @Expose({ name: 'projCicdName' })
  projCicdName: string | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;
}

export class ProjectCicdListModel extends BaseModel {
  @Expose({ name: 'projects' })
  @Transform((item: any) => item && ProjectCicdItemsModel.toClass(item.value))
  projects: Array<any> | undefined;

  @Expose({ name: 'solution' })
  solution: string | undefined;
}

export class GetProjectCicdModel extends BaseModel {
  @Expose({ name: 'projCode' })
  projCode: string | undefined;
}
