import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';
import { MenuListModel } from './MenuListModel';
import { ButtonPermissionModel } from './ButtonPermissionModel';
import { CorpListModel } from './CorpListModel';
import { CorpUserInfoModel } from './CorpUserInfoModel';
import { TicketSettingModel } from './TicketSettingModel';

export class UserInfoModel extends BaseModel {
  @Expose({ name: 'corpUserDto' })
  corpUserInfo: CorpUserInfoModel | undefined;

  @Expose({ name: 'menuList' })
  menuList: Array<MenuListModel> | undefined;

  @Expose({ name: 'buttonPermission' })
  buttonPermission: Array<ButtonPermissionModel> | undefined;

  @Expose({ name: 'buttonTickets' })
  buttonTickets: Array<string> | undefined;

  @Expose({ name: 'corpList' })
  corpList: Array<CorpListModel> | undefined;

  @Expose({ name: 'ticketSetting' })
  ticketSetting: Array<TicketSettingModel> | undefined;

  @Expose({ name: 'emailYn' })
  emailYn: string | undefined;

  @Expose({ name: 'kakaoYn' })
  kakaoYn: string | undefined;

  @Expose({ name: 'logPeriod' })
  logPeriod: string | undefined;
}
