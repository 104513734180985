import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function PngIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 237.504 303.188"
      >
        <g transform="translate(-1449 -316)">
          <path
            d="M219.821,0H32.842V303.188h237.5V50.525Z"
            transform="translate(1416.158 316)"
            fill="#e8e8e8"
          />
          <path
            d="M227.64,25.263H32.842V0H219.821Z"
            transform="translate(1416.158 316)"
            fill="#38a3ff"
          />
          <path
            d="M156.575,241.152q0,8.042-4.742,12.421t-13.472,4.381h-3.643v15.917H121.755V225.892h16.606q9.09,0,13.652,3.971T156.575,241.152Zm-21.857,6.235h2.363a6.446,6.446,0,0,0,4.643-1.641,5.953,5.953,0,0,0,1.725-4.529q0-4.857-5.383-4.857h-3.348v11.027Z"
            transform="translate(1379.158 316)"
            fill="#a4a9ad"
          />
          <path
            d="M183.484,245.779h20.708v25.86a56.937,56.937,0,0,1-18.542,2.888q-11.093,0-17.147-6.432t-6.055-18.345q0-11.617,6.629-18.083t18.575-6.465a41.181,41.181,0,0,1,8.549.853,36.2,36.2,0,0,1,7.007,2.166l-4.1,10.174a25.365,25.365,0,0,0-11.388-2.56q-5.678,0-8.778,3.692t-3.1,10.551q0,6.728,2.806,10.256t8.09,3.528a23.738,23.738,0,0,0,5.316-.558v-7.515h-8.565v-10.01Z"
            transform="translate(1430.158 316)"
            fill="#a4a9ad"
          />
          <path
            d="M219.821,50.525h50.525L219.821,0Z"
            transform="translate(1416.158 316)"
            fill="#d1d3d3"
          />
          <path
            d="M208.032,273.871h-17l-17.525-33.8h-.295q.622,7.974.623,12.176v21.627H162.351V225.892h16.934l17.459,33.343h.2q-.461-7.253-.459-11.65V225.893h11.552v47.978Z"
            transform="translate(1379.158 316)"
            fill="#a4a9ad"
          />
          <path
            d="M292.439,258a4.6,4.6,0,0,0,4.6-4.6V235a4.6,4.6,0,0,0-4.6-4.6h-18.4a4.6,4.6,0,0,0-4.6,4.6v18.4h-18.4V235a4.6,4.6,0,0,0-4.6-4.6h-18.4a4.6,4.6,0,0,0-4.6,4.6v18.4h-18.4V235a4.6,4.6,0,0,0-4.6-4.6h-18.4a4.6,4.6,0,0,0-4.6,4.6v18.4a4.6,4.6,0,0,0,4.6,4.6h18.4v18.4h-18.4a4.6,4.6,0,0,0-4.6,4.6v18.4a4.6,4.6,0,0,0,4.6,4.6h18.4v18.4h-18.4a4.6,4.6,0,0,0-4.6,4.6v18.4a4.6,4.6,0,0,0,4.6,4.6h18.4a4.6,4.6,0,0,0,4.6-4.6V327h18.4v18.4a4.6,4.6,0,0,0,4.6,4.6h18.4a4.6,4.6,0,0,0,4.6-4.6V327h18.4v18.4a4.6,4.6,0,0,0,4.6,4.6h18.4a4.6,4.6,0,0,0,4.6-4.6V327a4.6,4.6,0,0,0-4.6-4.6h-18.4V304h18.4a4.6,4.6,0,0,0,4.6-4.6V281a4.6,4.6,0,0,0-4.6-4.6h-18.4V258Zm-46,0v18.4h-18.4V258Zm-41.4,41.4V281h18.4v18.4Zm23,23V304h18.4v18.4Zm23-23V281h18.4v18.4Z"
            transform="translate(1330.708 148)"
            fill="#c6cacf"
          />
        </g>
      </svg>
    </Box>
  );
}
