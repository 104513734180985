import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  TextField,
  FormHelperText
} from '@mui/material';

type MMultiSelectProps = {
  values?: { label: string; value: string }[];
  options: { label: string; value: string }[];
  onChange?: (value: any[]) => void;
  usingCheckbox?: boolean;
  labelText: string;
  placeholderText?: string;
  helperText?: string;
  error?: boolean;
};

function MMultiSelect({
  values = [],
  options = [],
  onChange,
  usingCheckbox,
  labelText,
  placeholderText,
  helperText,
  error = false
}: MMultiSelectProps) {
  const handleChange = (_e: React.SyntheticEvent<Element, Event>, newValues: any[]) => {
    !!onChange && onChange(newValues);
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: AutocompleteRenderOptionState
  ) => (
    <li {...props}>
      {usingCheckbox && <Checkbox checked={selected} />}
      {option.label}
    </li>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={labelText} placeholder={placeholderText} error={error} />
  );

  const isOptionEqualToValue = <T extends {}>(option: T, value: T): boolean =>
    !!(JSON.stringify(option) === JSON.stringify(value));

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={options}
        value={values}
        filterSelectedOptions={!usingCheckbox}
        disableCloseOnSelect={usingCheckbox}
        onChange={handleChange}
        renderOption={renderOption}
        renderInput={renderInput}
        isOptionEqualToValue={isOptionEqualToValue}
      />
      {error && (
        <FormHelperText error sx={{ px: 2 }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}

export default MMultiSelect;
