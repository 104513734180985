import { useRef, useState, useEffect, useCallback } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import get from 'lodash/get';

import { MIconButton } from 'components/@material-extend';
import MenuPopover from 'components/MenuPopover';
import useLocales from 'hooks/useLocales';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'hooks/useInjector';
import { ACTIVE_YN } from 'constants/commonCodes';

import {
  notificationSliceName,
  notificationsSaga,
  notificationsReducer,
  notificationActions
} from 'features/notifications/slides';
import MessageNotification from 'features/notifications/components/NotificationMessage';
import { NotificationType } from 'features/notifications/type';
import { WrapperIconReadAll } from 'features/notifications/styles';
import { SEARCH_NOTIFICATION_DEFAULT, NOTIFICATION_TAB } from 'features/notifications/constants';
import { NOTIFICATIONS, TICKET_DETAIL } from 'constants/routes';
import { getIconByType, getIcon } from 'features/notifications/helper';
import { MAX_NOTIFICATION_DISPLAY, NOTIFICATION_ITEM_PER_PAGE } from 'constants/lengths';
import { convertObjectToParamUrl } from 'utils/urlHelper';
import { TIME_GET_NUMBER_NOTIFICATION } from 'constants/common';

const NumNotificationStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.typography.pxToRem(-3),
  right: theme.typography.pxToRem(-3),
  width: theme.typography.pxToRem(25),
  height: theme.typography.pxToRem(25),
  borderRadius: '50%',
  backgroundColor: theme.palette.error.main,
  fontSize: theme.typography.pxToRem(12),
  color: '#ffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const WrapperTime = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const WrapperFooter = styled(FlexBox)(({ theme }) => ({
  padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(30)}`
}));

const WrapperHeader = styled(FlexBox)(({ theme }) => ({
  padding: `${theme.typography.pxToRem(20)}`
}));

const TypographyStyled = styled(Typography)(() => ({
  cursor: 'pointer'
}));

const TypographyTimeStyled = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.grey[500]}`
}));

const TEST_ID = 'notifications';

export default function Notifications() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { notificationsHeader, numberUnread } = useSelector(
    (state) => get(state, [notificationSliceName], {}),
    shallowEqual
  );

  const currentCorpCode = useSelector(
    (state) => get(state, ['user', 'currentCorpCode']),
    shallowEqual
  );

  const notificationList = get(notificationsHeader, 'notificationList', []);
  const notificationRef = useRef(null);
  const { t } = useLocales();
  const [open, setOpen] = useState(false);
  useInjectReducer({
    key: notificationSliceName,
    reducer: notificationsReducer
  });

  useInjectSaga({ key: notificationSliceName, saga: notificationsSaga });

  useEffect(() => {
    if (open) {
      dispatch(
        notificationActions.fetchNotificationHeader({
          ...SEARCH_NOTIFICATION_DEFAULT,
          itemPerPage: NOTIFICATION_ITEM_PER_PAGE
        })
      );
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (currentCorpCode) {
      dispatch(notificationActions.getUnreadNumber());
    }
  }, [dispatch, currentCorpCode]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(notificationActions.getUnreadNumber());
    }, TIME_GET_NUMBER_NOTIFICATION);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch]);

  const onClosePopover = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handelReadNotifications = useCallback(
    (notification: NotificationType) => {
      const ticketDetailParamUrl = convertObjectToParamUrl({
        ticketCode: notification.ticketCode
      });
      if (notification.readYn === ACTIVE_YN.No) {
        dispatch(notificationActions.updateNotification([notification.noId]));
      }
      onClosePopover();
      navigate(`/${TICKET_DETAIL}?${ticketDetailParamUrl}`);
    },
    [dispatch, navigate, onClosePopover]
  );

  const handelNavigateNotificationPage = useCallback(
    (tab: string) => {
      navigate(`${NOTIFICATIONS}?tab=${tab}`);
      onClosePopover();
    },
    [navigate, onClosePopover]
  );

  const handelReadAll = useCallback(() => {
    onClosePopover();
    dispatch(notificationActions.updateReadAllNotification());
  }, [dispatch, onClosePopover]);

  return (
    <>
      <MIconButton
        test-type={TEST_ID}
        ref={notificationRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44
        }}
      >
        {getIcon('ic_notification')}
        {numberUnread ? (
          <>
            <NumNotificationStyled>
              {numberUnread > MAX_NOTIFICATION_DISPLAY
                ? `${MAX_NOTIFICATION_DISPLAY}+`
                : numberUnread}
            </NumNotificationStyled>
          </>
        ) : null}
      </MIconButton>

      <MenuPopover
        sx={{ width: 'auto' }}
        open={open}
        onClose={onClosePopover}
        anchorEl={notificationRef.current}
      >
        <Box>
          <WrapperHeader>
            <Box>
              <Typography variant="h6"> {t('dashboard.notifications.title')}</Typography>

              <Typography color="textSecondary">
                {t('dashboard.notifications.totalUnreadMessage', {
                  total: numberUnread
                })}
              </Typography>
            </Box>
            {numberUnread ? (
              <WrapperIconReadAll onClick={handelReadAll}>
                {getIcon('ic_double_check', 'success')}
              </WrapperIconReadAll>
            ) : null}
          </WrapperHeader>

          {notificationList.map((notification: NotificationType, index: number) => (
            <MenuItem
              sx={{ mb: 0.5 }}
              key={index}
              selected={notification.readYn === ACTIVE_YN.No}
              onClick={() => handelReadNotifications(notification)}
            >
              <FlexBox>
                {getIconByType(notification.changeType)}
                <Box sx={{ ml: 1 }}>
                  <MessageNotification
                    message={notification.message}
                    type={notification.changeType}
                    readYn={notification.readYn === ACTIVE_YN.Yes}
                  />
                  <WrapperTime>
                    {getIcon('ic_clock', 'disabled')}
                    <TypographyTimeStyled sx={{ ml: 1 }}>
                      {notification.createdDate}
                    </TypographyTimeStyled>
                  </WrapperTime>
                </Box>
              </FlexBox>
            </MenuItem>
          ))}

          <WrapperFooter>
            <TypographyStyled
              onClick={() => handelNavigateNotificationPage(NOTIFICATION_TAB.UNREAD)}
              color="primary"
              variant="subtitle1"
            >
              {t('dashboard.notifications.seeUnread')}
            </TypographyStyled>
            <TypographyStyled
              onClick={() => handelNavigateNotificationPage(NOTIFICATION_TAB.ALL)}
              color="primary"
              variant="subtitle1"
            >
              {t('dashboard.notifications.seeAll')}
            </TypographyStyled>
          </WrapperFooter>
        </Box>
      </MenuPopover>
    </>
  );
}
