import { TIME_RESET, USER_STATUS } from 'constants/userStatus';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';

import { HourRestType, StatusLabelType, TimeResetType } from './type';

export const getListStatusSelectbox = (t: any): StatusLabelType[] => [
  {
    lable: t('dashboard.userStatus.available'),
    value: USER_STATUS.AVAILABLE
  },
  {
    lable: t('dashboard.userStatus.busy'),
    value: USER_STATUS.BUSY
  },
  {
    lable: t('dashboard.userStatus.doNotDisturb'),
    value: USER_STATUS.DO_NOT_DISTURB
  },
  {
    lable: t('dashboard.userStatus.beRightBack'),
    value: USER_STATUS.BE_RIGHT_BACK
  },
  {
    lable: t('dashboard.userStatus.appearAway'),
    value: USER_STATUS.APPEAR_AWAY
  },
  {
    lable: t('dashboard.userStatus.appearOffline'),
    value: USER_STATUS.APPEAR_OFFLINE
  }
];

export const getTimeDurationSelectbox = (t: any): TimeResetType[] => [
  {
    lable: t('dashboard.userStatus.timeReset.thirtyMinutes'),
    value: TIME_RESET.THIRTY_MINUTES
  },
  {
    lable: t('dashboard.userStatus.timeReset.oneHour'),
    value: TIME_RESET.ONE_HOUR
  },
  {
    lable: t('dashboard.userStatus.timeReset.twoHour'),
    value: TIME_RESET.TWO_HOURS
  },
  {
    lable: t('dashboard.userStatus.timeReset.today'),
    value: TIME_RESET.TODAY
  },
  {
    lable: t('dashboard.userStatus.timeReset.thisWeek'),
    value: TIME_RESET.THIS_WEEK
  }
  // {
  //   lable: t('dashboard.userStatus.timeReset.custom'),
  //   value: TIME_RESET.CUSTOM
  // }
];

export const getHoursSelectBox = (): HourRestType[] => [
  { lable: '0:00 AM', hour: 0, minitues: 0, value: 0 },
  { lable: '0:30 AM', hour: 0, minitues: 30, value: 0.5 },
  { lable: '1:00 AM', hour: 1, minitues: 0, value: 1 },
  { lable: '1:30 AM', hour: 1, minitues: 30, value: 1.5 },
  { lable: '2:00 AM', hour: 2, minitues: 0, value: 2 },
  { lable: '2:30 AM', hour: 2, minitues: 30, value: 2.5 },
  { lable: '3:00 AM', hour: 3, minitues: 0, value: 3 },
  { lable: '3:30 AM', hour: 3, minitues: 30, value: 3.5 },
  { lable: '4:00 AM', hour: 4, minitues: 0, value: 4 },
  { lable: '4:30 AM', hour: 4, minitues: 30, value: 4.5 },
  { lable: '5:00 AM', hour: 5, minitues: 0, value: 5 },
  { lable: '5:30 AM', hour: 5, minitues: 30, value: 5.5 },
  { lable: '6:00 AM', hour: 6, minitues: 0, value: 6 },
  { lable: '6:30 AM', hour: 6, minitues: 30, value: 6 },
  { lable: '7:00 AM', hour: 7, minitues: 0, value: 7 },
  { lable: '7:30 AM', hour: 7, minitues: 30, value: 7.5 },
  { lable: '8:00 AM', hour: 8, minitues: 0, value: 8 },
  { lable: '8:30 AM', hour: 8, minitues: 30, value: 8.5 },
  { lable: '9:00 AM', hour: 9, minitues: 0, value: 9 },
  { lable: '9:30 AM', hour: 9, minitues: 30, value: 9.5 },
  { lable: '10:00 AM', hour: 10, minitues: 0, value: 10 },
  { lable: '10:30 AM', hour: 10, minitues: 30, value: 10.5 },
  { lable: '11:00 AM', hour: 11, minitues: 0, value: 11 },
  { lable: '11:30 AM', hour: 11, minitues: 30, value: 11.5 },

  { lable: '12:00 PM', hour: 12, minitues: 0, value: 12 },
  { lable: '12:30 PM', hour: 12, minitues: 30, value: 12.5 },
  { lable: '1:00 PM', hour: 13, minitues: 0, value: 13 },
  { lable: '1:30 PM', hour: 13, minitues: 30, value: 13.5 },
  { lable: '2:00 PM', hour: 14, minitues: 0, value: 14 },
  { lable: '2:30 PM', hour: 14, minitues: 30, value: 14.5 },
  { lable: '3:00 PM', hour: 15, minitues: 0, value: 15 },
  { lable: '3:30 PM', hour: 15, minitues: 30, value: 15.5 },
  { lable: '4:00 PM', hour: 16, minitues: 0, value: 16 },
  { lable: '4:30 PM', hour: 16, minitues: 30, value: 16.5 },
  { lable: '5:00 PM', hour: 17, minitues: 0, value: 17 },
  { lable: '5:30 PM', hour: 17, minitues: 30, value: 17.5 },
  { lable: '6:00 PM', hour: 18, minitues: 0, value: 18 },
  { lable: '6:30 PM', hour: 18, minitues: 30, value: 18.5 },
  { lable: '7:00 PM', hour: 19, minitues: 0, value: 19 },
  { lable: '7:30 PM', hour: 19, minitues: 30, value: 19.5 },
  { lable: '8:00 PM', hour: 20, minitues: 0, value: 20 },
  { lable: '8:30 PM', hour: 20, minitues: 30, value: 20.5 },
  { lable: '9:00 PM', hour: 21, minitues: 0, value: 21 },
  { lable: '9:30 PM', hour: 21, minitues: 30, value: 21.5 },
  { lable: '10:00 PM', hour: 22, minitues: 0, value: 22 },
  { lable: '10:30 PM', hour: 22, minitues: 30, value: 22.5 },
  { lable: '11:00 PM', hour: 23, minitues: 0, value: 23 },
  { lable: '11:30 PM', hour: 23, minitues: 30, value: 23.5 }
];

export const fomatTimeStatus = (typeReset: string, dateReset?: any, timeReset?: any): number => {
  let result = dayjs(new Date());

  switch (typeReset) {
    case TIME_RESET.THIRTY_MINUTES: {
      result = result.add(30, 'minutes');
      break;
    }
    case TIME_RESET.ONE_HOUR: {
      result = result.add(1, 'hour');
      break;
    }
    case TIME_RESET.TWO_HOURS: {
      result = result.add(2, 'hour');
      break;
    }
    case TIME_RESET.TODAY: {
      result = result.endOf('date');
      break;
    }
    case TIME_RESET.THIS_WEEK: {
      result = result.endOf('week').add(1, 'date').endOf('date');
      break;
    }
    case TIME_RESET.CUSTOM: {
      const startOfDate = dayjs(dateReset).startOf('date');
      result = startOfDate.add(timeReset, 'hour');
      break;
    }
  }

  return new Date(result.format(FULL_DATE_FORMAT_INCLUDE_SECOND)).getTime();
};
