const common = {
  goToHome: 'Go To Home',
  goToDashboard: 'Go to Dashboard',
  defaultTabTitle: 'MetaDesk',
  button: {
    new: 'New',
    create: 'Create',
    save: 'Save',
    discard: 'Discard',
    cancel: 'Cancel',
    ok: 'OK',
    search: 'Search',
    checkDuplication: 'Check',
    yes: 'Yes',
    no: 'No',
    resetPassword: 'Reset password',
    delete: 'Delete',
    add: 'Add',
    newTicket: 'New Ticket',
    back: 'Back',
    apply: 'Apply',
    active: 'Active',
    inactive: 'Inactive',
    less: 'Less',
    more: 'More',
    downloadExcel: 'Download Excel',
    downloadPDF: 'Download PDF',
    downloadWord: 'Download Word',
    privateTicket: 'Set Ticket Private',
    layoutTicket: 'Set Ticket Layout',
    button: 'Button',
    remove: 'Remove',
    inviteUser: 'Invite User',
    send: 'Send',
    generate: 'Generate',
    sendEmail: 'Send email',
    permission: 'Permission',
    approve: 'Approve',
    reject: 'Reject',
    sendDeny: 'Send',
    approveRequest: ' Approval Request',
    withdraw: 'Withdraw',
    backList: 'List',
    action: 'Action',
    chooseFile: 'Choose File'
  },
  error: {
    required: '{{ field }} is required field.',
    invalid: 'Please enter a valid {{ field }}',
    invalidOrder: 'The order number already exists. Please choose a unique order number'
  },
  form: {
    validate: {
      lengthRange: '{{ field }} must contain {{ min }}-{{ max }} characters.',
      maximum: '{{ field }} must contain less than {{ length }} characters.',
      minimum: '{{ field }} must contain more than {{ length }} characters.',
      validTime: 'Please enter valid time value.',
      validDate: 'Please enter a valid date.',
      compareDate: '{{ startField }} must be earlier than {{ endField }}.',
      periodExceed30days: 'The search period must not exceed 30 days.',
      periodExceed6months: 'The search period must not exceed 6 months.',
      periodExceedDynamicmonths: 'The search period must not exceed {{ month }} months.',
      periodExceedYears: 'The search period must not exceed {{ years }} years.',
      excludeCharacters: 'File names can\'t contain any of these characters: "{{ characters }}".',
      maxTotalFileSize: 'Total file size has exceeded {{ maxSize }}.',
      allowExtensions: 'MetaDesk supports the following multimedia formats: {{ extensions }}.',
      maxFileSize: 'Please upload files not exceeding {{ maxSize }}/file.',
      atLeastOne: 'At least one {{field}} must be selected.',
      fileSizeAndType: 'Please upload {{fileType}} files not exceeding {{fileSize}}/file.',
      validNumber: 'Please input whole numbers greater than {{number}}.',
      changePassword:
        'Password must be 8~16 char. and can contain upper-, lowercase, numbers, and special characters (!, $, %, &, @).',
      userName: `The username must contain 5~16 char, including lowercase English letters, numbers, '_', '-', and '.' (However, it cannot end in '.', '-', '.git', or '.atom')`,
      inputValue: 'Please input value'
    },
    field: {
      label: {
        number: 'No.',
        username: 'Username',
        email: 'Email',
        name: 'Name',
        organization: 'Organization',
        department: 'Department',
        title: 'Title',
        phoneNumber: 'Business phone',
        role: 'Role',
        status: 'Status',
        creator: 'Creator',
        creationDate: 'Creation Date',
        updater: 'Updater',
        updateDate: 'Update Date',
        orgName: 'Organization Name',
        orgID: 'Organization ID',
        type: 'Type',
        project: 'Project',
        projectID: 'Project No.',
        projectName: 'Project Name',
        projectType: 'Project Type',
        contractNo: 'Contract No.',
        contractName: 'Contract Name',
        users: 'Users',
        announcementType: 'Announcement Type',
        publish: 'Publish',
        publishDate: 'Publish date',
        ticketType: 'Ticket type',
        workflowName: 'Workflow name',
        desc: 'Description',
        text: 'Text',
        integrationYn: 'MetaCICD Integration'
      }
    },
    select: {
      placeholder: 'Select',
      all: 'All',
      selectUnselectAll: 'Select/Unselect all'
    },
    searchInput: {
      placeholder: 'Please input value'
    },
    discardWarning: 'Are you sure you want to discard?',
    deleteWarning: 'Are you sure you want to delete?',
    saveWarning: 'Do you want to save changes?',
    changeWarning: 'You have unsaved changes. Do you want to cancel the changes?',
    noChangeWarning: 'No changes.',
    unassigned: 'Unassigned',
    datePicker: {
      selectDate: 'Select date'
    }
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
    available: 'Available',
    yes: 'Yes',
    no: 'No',
    published: 'Published',
    unpublished: 'Unpublished',
    urgent: 'Yes',
    normal: 'No',
    verified: 'Verified',
    verify: 'Verify',
    pending: 'Pending'
  },
  table: {
    headerTitle: 'Results',
    export: {
      noData: 'No data to export.'
    }
  },
  notFoundData: 'No data found!',
  waitVerify: 'is waiting for verification.',
  loading: 'Loading...',
  editorSearch: {
    post: {
      notFound: 'Found no matching posts for {{searchTerm}}. Create new one'
    },
    guideline: {
      hashtag: 'These posts below having the keyword you are searching for'
    }
  }
};

const error = {
  404: {
    title: 'Sorry, page not found!',
    description:
      'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.'
  },
  403: {
    title: 'Forbidden',
    description1: 'Sorry, but you don’t have permission to access this address.',
    description2: 'If the error persists, please contact the system admin.'
  },
  network: 'Please check your network.',
  internalServerError: 'Internal server error.',
  timeOut: {
    title: 'Timeout',
    description: 'The connection has timed out.'
  },
  500: {
    title: 'Service Unavailable',
    description: 'Sorry for the inconvenience.',
    subDescription: 'If the error persists, please contact the system admin.'
  },
  maintenance: {
    title: 'Upgrading system...',
    description: 'We are constantly improving the system for better service!'
  }
};

const table = {
  no: 'No.',
  rowsPerPage: 'Rows per page',
  totalResults: 'Total Results',
  items: 'items',
  notFound: 'There are no results.',
  noMatchResults: 'Please check the terms of the search.',
  column: {
    findColumn: 'Find column',
    showAll: 'Show all',
    resetAll: 'Reset'
  },
  toolbar: {
    excelDownload: 'Excel download',
    columnList: 'Column list'
  },
  dataFilter: {
    reset: 'Reset',
    filter: 'Filter'
  }
};

const file = {
  attachment: 'Attachment',
  uploadPhoto: 'Upload photo',
  updatePhoto: 'Update photo',
  selectFiles: 'Select files',
  browse: 'browse',
  thoroughMachine: 'thorough your machine.',
  dropOrClick: 'Drop files here or click <1>{{ browse }}</1> <2>{{ thoroughMachine }}<2/>',
  limitation:
    'File size is limited to {{ maxFileSize }}/file and {{ maxRequestSize }}/request,  with a maximum of {{maxFiles}} files allowed.',
  downloadSuccess: 'Downloaded successfully.',
  maxFiles: 'The total number of upload files exceeds 20.'
};

const editor = {
  counterLabel: '{{ count }} char (including HTML tags)',
  enterLink: 'Enter link',
  visitLink: 'Visit URL',
  saveLink: 'Save',
  editLink: 'Edit',
  removeLink: 'Remove',
  font: 'Font',
  heading: 'Heading',
  normal: 'Normal',
  fontFamily: {
    batang: 'Batang',
    malgunGothic: 'Malgun Gothic'
  },
  // table controllers
  insertColumnRight: 'Insert 1 column right',
  insertColumnLeft: 'Insert 1 column left',
  insertRowUp: 'Insert 1 row above',
  insertRowDown: 'Insert 1 row below',
  mergeCells: 'Merge cells',
  unmergeCells: 'Unmerge cells',
  deleteColumn: 'Delete column',
  deleteRow: 'Delete row',
  deleteTable: 'Delete table',
  row: 'Row',
  col: 'Column',
  tooltip: {
    font: 'Font',
    header: 'Header',
    size: 'Size',
    color: 'Color',
    background: 'Background',
    align: 'Align',
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    strike: 'Strike',
    codeBlock: 'Code-block',
    blockquote: 'Blockquote',
    link: 'Link',
    image: 'Image',
    video: 'Video',
    formula: 'Formula',
    clean: 'Clean',
    list: {
      bullet: 'Bulleted list',
      number: 'Numbered list'
    },
    indent: {
      decrease: 'Decrease indent',
      increase: 'Increase indent'
    },
    script: {
      subscript: 'Subscripts',
      superscript: 'Superscript'
    },
    direction: 'Direction',
    undo: 'Undo',
    redo: 'Redo',
    betterTable: 'Better-table'
  }
};

const lang = {
  ko: 'Korean',
  en: 'English'
};

const success = {
  updated: 'Changes have been successfully saved.',
  updateStatusTicket: 'Ticket status is updated.'
};

export { common, error, table, file, editor, lang, success };
