import { Expose } from 'class-transformer';
import { BaseUserListModel } from 'features/system/UserManagement/models';

export class UserListModel extends BaseUserListModel {
  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'projName' })
  projName: string | undefined;

  @Expose({ name: 'avatarUrl' })
  avatarUrl: string | undefined;
}
