import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Grid(theme: Theme) {
  return {
    MuiGrid: {
      styleOverrides: {}
    }
  };
}
