import * as Yup from 'yup';
import dayjs from 'dayjs';

import {
  TEXT_MAX_LENGTH_15,
  TITLE_FIELD_MAX_LENGTH,
  TEXT_MAX_LENGTH_20B,
  TEXT_MAX_LENGTH_20B_DECIMAL
} from 'constants/lengths';
import { ESTIMATION_TIME_REGEX } from 'constants/common';

import { checkTicketNeedApprove } from './helpers';

export const getTicketSchema = (
  t: any,
  currentTicket: any,
  isCustomer: boolean,
  labelTicketType: string,
  labelServiceType: string
) =>
  Yup.object().shape({
    projCode: Yup.string().required(
      t('common.error.required', { field: t('helpDesk.ticketManagement.form.project') })
    ),
    serviceType: Yup.string().required(
      t('common.error.required', {
        field: labelServiceType || t('helpDesk.ticketManagement.form.serviceType')
      })
    ),
    serviceDetail: Yup.string().required(
      t('common.error.required', { field: t('helpDesk.ticketManagement.form.serviceDetails') })
    ),
    ticketType: Yup.string().required(
      t('common.error.required', {
        field: labelTicketType || t('helpDesk.ticketManagement.form.ticketType')
      })
    ),
    summary: Yup.string()
      .trim()
      .required(t('common.error.required', { field: t('helpDesk.ticketManagement.form.title') }))
      .max(
        TITLE_FIELD_MAX_LENGTH,
        t('common.form.validate.maximum', {
          field: t('helpDesk.ticketManagement.form.title'),
          length: TITLE_FIELD_MAX_LENGTH
        })
      ),
    description: Yup.string()
      .trim()
      .required(
        t('common.error.required', { field: t('helpDesk.ticketManagement.form.description') })
      )
      .max(
        TEXT_MAX_LENGTH_20B,
        t('common.form.validate.maximum', {
          field: t('helpDesk.ticketManagement.form.description'),
          length: TEXT_MAX_LENGTH_20B_DECIMAL
        })
      ),
    estimation: Yup.string()
      .trim()
      .matches(ESTIMATION_TIME_REGEX, {
        excludeEmptyString: true,
        message: t('common.form.validate.validTime')
      }),
    affectedVersion: Yup.string().max(
      TEXT_MAX_LENGTH_15,
      t('common.form.validate.maximum', {
        field: t('helpDesk.ticketManagement.form.affectedVersion'),
        length: TEXT_MAX_LENGTH_15
      })
    ),
    techSolution0: Yup.string().max(
      TEXT_MAX_LENGTH_20B,
      t('common.form.validate.maximum', {
        field: t('helpDesk.ticketManagement.form.technicalSolution'),
        length: TEXT_MAX_LENGTH_20B_DECIMAL
      })
    ),
    techSolution1: Yup.string()
      .nullable()
      .max(
        TEXT_MAX_LENGTH_20B,
        t('common.form.validate.maximum', {
          field: t('helpDesk.ticketManagement.form.technicalSolution'),
          length: TEXT_MAX_LENGTH_20B_DECIMAL
        })
      ),
    techSolution2: Yup.string()
      .nullable()
      .max(
        TEXT_MAX_LENGTH_20B,
        t('common.form.validate.maximum', {
          field: t('helpDesk.ticketManagement.form.technicalSolution'),
          length: TEXT_MAX_LENGTH_20B_DECIMAL
        })
      ),
    techSolution3: Yup.string()
      .nullable()
      .max(
        TEXT_MAX_LENGTH_20B,
        t('common.form.validate.maximum', {
          field: t('helpDesk.ticketManagement.form.technicalSolution'),
          length: TEXT_MAX_LENGTH_20B_DECIMAL
        })
      ),
    techSolution4: Yup.string()
      .nullable()
      .max(
        TEXT_MAX_LENGTH_20B,
        t('common.form.validate.maximum', {
          field: t('helpDesk.ticketManagement.form.technicalSolution'),
          length: TEXT_MAX_LENGTH_20B_DECIMAL
        })
      ),
    targetCompDate: Yup.date()
      .typeError(t('system.contractManagement.errorMess.dateType'))
      .nullable()
      .test('compareDate', t('helpDesk.ticketManagement.hint.validateCompletionDate'), (value) => {
        if (currentTicket) return !dayjs(value).isBefore(currentTicket.createdDate, 'day');
        return !dayjs(value).isBefore(dayjs().startOf('day'), 'day');
      }),
    issueDetected: Yup.date()
      .typeError(t('system.contractManagement.errorMess.dateType'))
      .nullable(),
    approval: Yup.string()
      .nullable()
      .test(
        'approval',
        t('common.error.required', {
          field: t('helpDesk.ticketManagement.form.approveRejectPerson')
        }),
        (approval: any, schema) => {
          const { ticketType, serviceType } = schema.parent;
          const isTicketApprove = checkTicketNeedApprove(ticketType, serviceType);
          if (isTicketApprove) {
            if (approval === 'null') return false;
            return Boolean(approval);
          }
          return true;
        }
      ),
    assignee: Yup.string()
      .nullable()
      .test(
        'assignee',
        t('common.error.required', {
          field: t('helpDesk.ticketManagement.form.assignee')
        }),
        (assignee: any) => (isCustomer ? true : Boolean(assignee))
      )
  });
