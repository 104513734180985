import { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { authSliceName } from 'features/auth/slices';

import {
  ConfigStatusWrapper,
  CurrentStatusWrapper,
  FixedStyled,
  WrapperPostionList
} from './styled';
import ListStatus from './ListStatus';
import { userStatusActions } from './slices';
import StatusIcon from './StatusIcon';
import { StatusSelectedType } from './type';

const ConfigStatus = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const userProfile = useSelector((state) =>
    get(state, [authSliceName, 'userInfo', 'corpUserInfo'], {})
  );

  const { userStatus, statusDuration } = userProfile || {};

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (value: StatusSelectedType) => {
    dispatch(
      userStatusActions.updateUserStatus({
        userStatus: value.status,
        timestamp: value.timestamp,
        username: userProfile.username
      })
    );
    handleClose();
  };

  return (
    <ConfigStatusWrapper ref={anchorRef}>
      <CurrentStatusWrapper onClick={handleOpen}>
        <Typography color="primary">
          <StatusIcon statusKey={userStatus} renderProps={(status) => status.lable} />
        </Typography>
        <ExpandMoreIcon />
      </CurrentStatusWrapper>

      {open ? <FixedStyled onClick={handleClose} /> : null}
      <WrapperPostionList className={open ? 'show' : ''}>
        <ListStatus onChangeStatus={handleChangeStatus} statusDuration={statusDuration} />
      </WrapperPostionList>
    </ConfigStatusWrapper>
  );
};

export default ConfigStatus;
