import { useMemo, useEffect, useState } from 'react';
import mime from 'mime-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Link, ListItem, ListItemIcon, ListItemText, Theme, useTheme } from '@mui/material';
import { MIconButton } from '@material-extend';

import { getNameOfFile, getExtensionOfFile, isFile, truncateFileName } from 'utils/helpers';
import { getIconFromMimeType } from 'helpers/fileIcons';

interface AttachedTextItemProps {
  fileId?: string | number;
  name?: string;
  extension?: string;
  url?: string;
  file?: File;
  onRemove?: (file: any) => void;
  onFileNameClick?: (file: any) => void;
  initial?: any;
  animate?: any;
  exit?: any;
  canRemove?: boolean;
  needTruncate?: boolean;
}

const TextListItemStyle = styled(ListItem)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.spacing(1),
  border: `solid 1px ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative'
}));

const AttachedTextItem = ({
  // File type
  file,
  // object type
  fileId = '',
  name = '',
  extension = '',
  onRemove,
  onFileNameClick,
  url,
  canRemove,
  needTruncate = false,
  ...props
}: AttachedTextItemProps) => {
  const theme = useTheme<Theme>();
  const [fileName, setFileName] = useState<string>(name);
  const [fileExtension, setFileExtension] = useState<string>(extension);

  const handleRemove: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove && onRemove(file || { id: fileId, fileName: name, fileUrl: url });
  };

  useEffect(() => {
    // If file
    if (file && isFile(file)) {
      const _fileName = getNameOfFile(file.name);
      const _fileExtension = getExtensionOfFile(file.name);
      setFileName(_fileName);
      setFileExtension(_fileExtension);
    }
  }, [file]);

  const AttachedIcon = useMemo(() => getIconFromMimeType(mime.lookup(fileExtension) || ''), [
    fileExtension
  ]);

  const handleFileNameClick = () => {
    onFileNameClick && onFileNameClick(file || { id: fileId, fileName: name, fileUrl: url });
    file && FileSaver.saveAs(file);
  };

  return (
    <TextListItemStyle
      theme={theme}
      {...props}
      secondaryAction={
        canRemove && (
          <MIconButton edge="end" size="small" onClick={handleRemove}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      }
    >
      <ListItemIcon>
        <AttachedIcon width={24} height={24} />
      </ListItemIcon>
      <Link
        target="_blank"
        href={!isEmpty(url) ? url : undefined}
        onClick={handleFileNameClick}
        underline="hover"
        color="text.secondary"
        sx={{ width: '100%' }}
      >
        <ListItemText
          primary={`${needTruncate ? truncateFileName(fileName) : fileName}.${fileExtension}`}
          primaryTypographyProps={{
            variant: 'caption',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pr: 8
          }}
          sx={{ width: '100%' }}
        />
      </Link>
    </TextListItemStyle>
  );
};
export default AttachedTextItem;
