// scroll bar
import 'simplebar/src/simplebar.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// highlightjs
import 'highlight.js/styles/a11y-light.css';

import createStore from 'store';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MLocalizationProvider } from '@material-extend';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';

import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <SettingsProvider>
          <MLocalizationProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </MLocalizationProvider>
        </SettingsProvider>
      </HelmetProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
