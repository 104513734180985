import { AnimatePresence } from 'framer-motion';
// material
import { Dialog, DialogProps } from '@mui/material';

// ----------------------------------------------------------------------

interface DialogAnimateProps extends DialogProps {
  animate?: object;
  onClose?: VoidFunction;
}

export default function DialogAnimate({
  open = false,
  animate,
  onClose,
  children,
  ...other
}: DialogAnimateProps) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
