import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {
  FileWordIcon,
  FileExcelIcon,
  FilePdfIcon,
  FilePowerPointIcon,
  FileVideoIcon,
  FileTxtIcon,
  FileJpgIcon,
  FilePngIcon,
  FileGifIcon,
  FileBmpIcon,
  ZipIcon,
  JarIcon
} from 'assets';
import { MIME_TYPES, JAR_MIME_TYPE, ZIP_MIME_TYPES } from 'constants/common';

export const getIconFromMimeType = (mimeType?: string) => {
  if (!mimeType) {
    return InsertDriveFileIcon;
  }

  // ZIP type mime may appear as `application/zip-compressed`/`application/zip`,... (listed in ZIP_MIME_TYPES)
  if (ZIP_MIME_TYPES.indexOf(mimeType) !== -1) return ZipIcon;

  switch (mimeType) {
    case MIME_TYPES.DOC:
    case MIME_TYPES.DOCX:
      return FileWordIcon;
    case MIME_TYPES.XLS:
    case MIME_TYPES.XLSX:
      return FileExcelIcon;
    case MIME_TYPES.PPT:
    case MIME_TYPES.PPTX:
      return FilePowerPointIcon;
    case MIME_TYPES.PDF:
      return FilePdfIcon;
    case MIME_TYPES.MP4:
      return FileVideoIcon;
    case MIME_TYPES.TXT:
      return FileTxtIcon;
    case JAR_MIME_TYPE:
      return JarIcon;
    case MIME_TYPES.JPEG:
      return FileJpgIcon;
    case MIME_TYPES.PNG:
      return FilePngIcon;
    case MIME_TYPES.GIF:
      return FileGifIcon;
    case MIME_TYPES.BMP:
      return FileBmpIcon;
    default:
      return InsertDriveFileIcon;
  }
};

export const getFileExtension = (fileName: string) =>
  fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1);
