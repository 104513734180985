import NewButton from 'components/@material-extend/buttons/NewButton';
import { ButtonAuth } from 'interfaces/ButtonAuth';
import AuthButtonTicket from './AuthButtonTicket';
import { NEW_BUTTON_ID } from '../../constants';

const AuthNewTicketButton = (props: ButtonAuth) => (
  <AuthButtonTicket btnId={NEW_BUTTON_ID}>
    <NewButton {...props} />
  </AuthButtonTicket>
);

export default AuthNewTicketButton;
