import { GET_LIST_CCU_HISTORY } from 'constants/apiUrl';
import apiClient from 'apis/apiClient';

export const fetchCcuHistoryData = (params: any) =>
  apiClient
    .post(GET_LIST_CCU_HISTORY, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
