export const signUp = {
  title: {
    signUp: 'Sign up',
    haveAccount: 'Already have an account?',
    login: 'Login',
    subTitle: 'Send a request for user account creation'
  },
  field: {
    corporation: 'Corporation',
    confirmPwd: 'Confirm Password'
  },
  message: {
    successSignUp: 'Sign up successfully.Please check your email to get the password.',
    successRegister: 'Thanks for registering!  Your account need the approval before login.',
    needVerifyPhone:
      'Please verify your phone number via Email/Kakao message to complete the Sign up',
    matchPwd: 'The password confirmation does not match.',
    validatePwd:
      'Please enter 8 to 30 English upper/lower case letters, numbers, and special characters',
    checkDuplicateUserError:
      'This is concurrence account registration, please use the existing password to log in after approval.',
    pleaseCheckDuplicate: 'Please check duplicate user'
  }
};
