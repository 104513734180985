import { createContext, useEffect, useState, useMemo, useRef } from 'react';
import SocketService from 'components/SocketService';
import { union } from 'lodash';

type SocketRegister = {
  name: string;
  url: string;
  onChange: VoidFunction;
};

type SocketInitial = {
  subscribe: (sub: SocketRegister) => void;
  unsubscribe: (name: string) => void;
  handleChangeChannel: (channel: string[]) => void;
};

const initial = {
  subscribe: () => {},
  unsubscribe: () => {},
  handleChangeChannel: () => {}
};

export const SocketContext = createContext<SocketInitial>(initial);

interface Props {
  children: React.ReactNode;
}

export const MESSAGE_TYPE = {
  SCHEDULE: 'SCHEDULE',
  METRIC: 'METRIC',
  TICKET: 'MYRECENT-TICKET',
  CONTRACT: 'CONTRACT',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  DOCUMENT: 'DOCUMENT',
  USER_KICKOUT: 'KCIKOUT'
};

const SocketProvider: React.FC<Props> = ({ children }) => {
  const [canSubcription, setCanSubcription] = useState(false);
  const subscriptions = useRef<any[]>([]);
  const [channels, setChannel] = useState<any[]>([]);

  const subscribe = (newSub: SocketRegister) => {
    if (!subscriptions.current.find((sub: SocketRegister) => sub.name === newSub.name)) {
      subscriptions.current = [...subscriptions.current, newSub];
    }
  };

  const unsubscribe = (name: string) => {
    subscriptions.current = [
      ...subscriptions.current.filter((sub: SocketRegister) => sub.name !== name)
    ];
  };

  const handleSocketChange = (topics: string[]) => {
    topics.forEach((topic: string) => {
      const sub = subscriptions.current.find((sub: SocketRegister) => sub.name === topic);
      if (sub) sub.onChange();
    });
  };

  const handleChangeChannel = (listChannel: string[]) => {
    setChannel((pre) => union([...pre, ...listChannel]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urls = useMemo(() => subscriptions.current.map((sub: SocketRegister) => sub.url), [
    subscriptions.current
  ]);

  useEffect(() => {
    // because the number of channels is dynamic
    // we will open connect after all channels rendered
    if (
      channels.length &&
      subscriptions.current.length &&
      channels.length === subscriptions.current.length &&
      !canSubcription
    ) {
      setCanSubcription(true);
    }
  }, [channels, canSubcription]);
  return (
    <SocketContext.Provider value={{ subscribe, unsubscribe, handleChangeChannel }}>
      {children}
      {canSubcription && <SocketService urls={urls} onChange={handleSocketChange} />}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
