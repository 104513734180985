import { useMemo } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useLocales from 'hooks/useLocales';
import { STATUS_ICON_TYPE, USER_STATUS } from 'constants/userStatus';
import { StatusIconRender } from './type';
import { IconStyled } from './styled';

type StatusIconType = {
  statusKey: STATUS_ICON_TYPE;
  renderProps: (iconStatus: StatusIconRender) => React.ReactNode;
};
const StatusIcon = ({ statusKey, renderProps }: StatusIconType) => {
  const { t } = useLocales();

  const renderIcon: StatusIconRender | null = useMemo(() => {
    switch (statusKey) {
      case USER_STATUS.AVAILABLE:
        return {
          icon: (
            <IconStyled>
              <CheckCircleIcon color="success" />
            </IconStyled>
          ),
          lable: t('dashboard.userStatus.available'),
          value: USER_STATUS.AVAILABLE
        };
      case USER_STATUS.BUSY:
        return {
          icon: (
            <IconStyled>
              <CircleIcon color="error" />
            </IconStyled>
          ),
          lable: t('dashboard.userStatus.busy'),
          value: USER_STATUS.BUSY
        };
      case USER_STATUS.DO_NOT_DISTURB:
        return {
          icon: (
            <IconStyled>
              <DoNotDisturbOnIcon color="error" />
            </IconStyled>
          ),
          lable: t('dashboard.userStatus.doNotDisturb'),
          value: USER_STATUS.DO_NOT_DISTURB
        };
      case USER_STATUS.BE_RIGHT_BACK:
        return {
          icon: (
            <IconStyled>
              <WatchLaterIcon color="warning" />
            </IconStyled>
          ),
          lable: t('dashboard.userStatus.beRightBack'),
          value: USER_STATUS.BE_RIGHT_BACK
        };
      case USER_STATUS.APPEAR_AWAY:
        return {
          icon: (
            <IconStyled>
              <WatchLaterIcon color="warning" />
            </IconStyled>
          ),
          lable: t('dashboard.userStatus.appearAway'),
          value: USER_STATUS.APPEAR_AWAY
        };
      case USER_STATUS.APPEAR_OFFLINE:
        return {
          icon: (
            <IconStyled>
              <HighlightOffIcon sx={{ bgcolor: 'white', borderRadius: '50%' }} />
            </IconStyled>
          ),
          lable: t('dashboard.userStatus.appearOffline'),
          value: USER_STATUS.APPEAR_OFFLINE
        };
      default:
        return null;
    }
  }, [statusKey, t]);

  return <>{renderIcon ? renderProps(renderIcon) : null}</>;
};

export default StatusIcon;
