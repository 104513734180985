import { ACTIVE_YN } from 'constants/commonCodes';
import { OptionConfig, StatusSubmit, StatusTicetType } from './types';

export const checkStatusTreeData = (treeData: StatusTicetType) => {
  const recursive = (itemEmail: any) => {
    if (itemEmail.subCodeList.length) {
      let sendMailYn = ACTIVE_YN.No;
      let sendKakaoYn = ACTIVE_YN.No;
      itemEmail.subCodeList.forEach((subMenu: any) => {
        recursive(subMenu);
        if (subMenu.sendMailYn === ACTIVE_YN.Yes) {
          sendMailYn = ACTIVE_YN.Yes;
        }
        if (subMenu.sendKakaoYn === ACTIVE_YN.Yes) {
          sendKakaoYn = ACTIVE_YN.Yes;
        }
      });
      itemEmail.sendMailYn = sendMailYn;
      itemEmail.sendKakaoYn = sendKakaoYn;
    }
  };
  recursive(treeData);
};

export const checkAllValueTreeData = (
  treeData: StatusTicetType,
  newValue: string,
  option: OptionConfig
) => {
  const recursive = (itemEmail: StatusTicetType) => {
    itemEmail[option] = newValue;
    if (itemEmail.subCodeList.length) {
      itemEmail.subCodeList.forEach((subMenu: any) => {
        recursive(subMenu);
      });
    }
  };
  recursive(treeData);
};

export const checkIsCheckAllData = (
  treeData: StatusTicetType
): { sendMailYn: boolean; sendKakaoYn: boolean } => {
  let sendMailYn = true;
  let sendKakaoYn = true;

  const recursive = (itemEmail: StatusTicetType) => {
    if (itemEmail.code !== 'root') {
      if (itemEmail.sendKakaoYn === ACTIVE_YN.No) {
        sendKakaoYn = false;
      }
      if (itemEmail.sendMailYn === ACTIVE_YN.No) {
        sendMailYn = false;
      }
    }

    if (itemEmail.subCodeList.length) {
      itemEmail.subCodeList.forEach((subMenu: any) => {
        recursive(subMenu);
      });
    }
  };
  recursive(treeData);

  return {
    sendMailYn,
    sendKakaoYn
  };
};

export const findNodeCallback = (
  treeData: StatusTicetType,
  nodeSelect: StatusTicetType,
  callback: Function
) => {
  const { code, groupCode } = nodeSelect;
  const recursive = (node: StatusTicetType) => {
    if (node.code === code && node.groupCode === groupCode) {
      callback(node);
      return;
    }
    const { subCodeList } = node;

    subCodeList.forEach((item: StatusTicetType) => {
      recursive(item);
    });
  };
  recursive(treeData);
};

export const updateSendEmailKakaoStatus = (
  nodeUpdate: StatusTicetType,
  value: string,
  option: OptionConfig
) => {
  if (nodeUpdate.subCodeList.length) {
    const recursive = (node: StatusTicetType) => {
      node[option] = value;
      const { subCodeList } = node;

      subCodeList.forEach((item) => {
        recursive(item);
      });
    };
    recursive(nodeUpdate);
  } else {
    nodeUpdate[option] = value;
  }
};

export const getListEmailKakaoSubmit = (
  treeData: StatusTicetType,
  projCode: string
): Array<StatusSubmit> => {
  const listData: Array<StatusSubmit> = [];

  const recursive = (node: StatusTicetType) => {
    const { code, ticketType, statusSendEmailId, subCodeList, sendMailYn, sendKakaoYn } = node;
    if (code !== 'root') {
      const statusItem: StatusSubmit = {
        projCode,
        id: statusSendEmailId,
        ticketType,
        sendMailYn,
        sendKakaoYn,
        statusCode: code
      };
      listData.push(statusItem);
    }

    for (const val of subCodeList) {
      recursive(val);
    }
  };

  if (treeData) {
    recursive(treeData);
  }
  return listData;
};

export const checkIndeterminateNode = (
  nodes: StatusTicetType
): { indeterminateKakao: boolean; indeterminateEmail: boolean } => {
  let indeterminateKakao = false;
  let indeterminateEmail = false;

  const recursive = (nodes: StatusTicetType) => {
    const { length } = nodes.subCodeList || 0;
    if (length > 1) {
      for (let i = 0; i < length; i++) {
        for (let j = i + 1; j < length; j++) {
          if (nodes.subCodeList[i].sendKakaoYn !== nodes.subCodeList[j].sendKakaoYn) {
            indeterminateKakao = true;
          }
          if (nodes.subCodeList[i].sendMailYn !== nodes.subCodeList[j].sendMailYn) {
            indeterminateEmail = true;
          }
        }
      }
    }
    for (const val of nodes.subCodeList) {
      recursive(val);
    }
  };
  recursive(nodes);

  return {
    indeterminateEmail,
    indeterminateKakao
  };
};
