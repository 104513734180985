// form
import {
  useFormContext,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn
} from 'react-hook-form';

// ----------------------------------------------------------------------

export type RHFControllerProps = {
  name: string;
  children: ({
    field,
    fieldState,
    formState
  }: {
    field: ControllerRenderProps<any, any>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
  }) => React.ReactElement;
};

function RHFController({ name, children }: RHFControllerProps) {
  const { control } = useFormContext();

  return <Controller name={name} control={control} render={children} />;
}

export default RHFController;
