// form
import { useFormContext, Controller } from 'react-hook-form';

import Toggle, { ToggleProps } from 'components/Toggle';

// ----------------------------------------------------------------------
export type RHFToggleProps = Omit<ToggleProps, 'value'> & {
  name: string;
};

function RHFToggle({ name, ...other }: RHFToggleProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...otherFieldProps } }) => (
        <Toggle {...otherFieldProps} {...other} />
      )}
    />
  );
}

export default RHFToggle;
