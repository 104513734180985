// material
import { Box, BoxProps } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import LogoImg from 'img/logo.png';
import FullLogoImg from 'img/full_logo.png';
import { APP_VERSION } from 'constants/appConfig';
import { LabelVersionLogin } from 'features/auth/LoginPage/styles';
// ----------------------------------------------------------------------
const StyledBox = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',

  '& img': {
    width: '175px'
  },

  '& img.full': {
    width: '240px'
  }
}));

type LogoProps = {
  sx?: BoxProps;
  noLogoText?: boolean;
  isVersionLogin?: boolean;
};
export default function Logo({ sx, noLogoText = false, isVersionLogin = false }: LogoProps) {
  return (
    <StyledBox sx={{ ...sx }}>
      <img
        src={noLogoText ? LogoImg : FullLogoImg}
        className={noLogoText ? '' : 'full'}
        alt="MetaDesk logo"
      />
      {isVersionLogin ? (
        <LabelVersionLogin>
          <p>{APP_VERSION} </p>
        </LabelVersionLogin>
      ) : null}
    </StyledBox>
  );
}
