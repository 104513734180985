// material

import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function FolderItemIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="87"
        height="87"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M84.1663 29.9998H50.833L42.4997 21.6665H21.6663C17.083 21.6665 13.333 25.4165 13.333 29.9998V79.9998C13.333 84.5832 17.083 88.3332 21.6663 88.3332H86.2497C89.7913 88.3332 92.4997 85.6248 92.4997 82.0832V38.3332C92.4997 33.7498 88.7497 29.9998 84.1663 29.9998Z"
          fill="#0089D0"
        />
        <path
          d="M92.917 42.5H36.8753C32.917 42.5 29.3753 45.4167 28.7503 49.375L21.667 88.3333H87.7086C91.667 88.3333 95.2086 85.4167 95.8336 81.4583L101.042 52.2917C102.084 47.2917 98.1253 42.5 92.917 42.5Z"
          fill="#74CAFF"
        />
      </svg>
    </Box>
  );
}
