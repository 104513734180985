import { Typography, Switch } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

import { ACTIVE_YN } from 'constants/commonCodes';

const WrapperToggleStyled = styled('div')`
  display: flex;
  align-items: center;
`;

const TypographyStyled = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold
}));

export type ToggleProps = {
  label: string;
  value: string;
  valueLabel?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  isRadioBtnFrontOfText?: boolean;
};

const Toggle = ({ label, value, valueLabel, disabled = false, onChange }: ToggleProps) => {
  const handelChangeState = (event: any) => {
    onChange && onChange(event.target.checked ? ACTIVE_YN.Yes : ACTIVE_YN.No);
  };
  return (
    <WrapperToggleStyled>
      <TypographyStyled variant="subtitle2">{label}</TypographyStyled>

      {valueLabel && <Typography variant="body1">{valueLabel}</Typography>}
      <Switch disabled={disabled} onChange={handelChangeState} checked={ACTIVE_YN.Yes === value} />
    </WrapperToggleStyled>
  );
};

export default Toggle;
