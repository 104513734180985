import { Expose } from 'class-transformer';

import BaseModel from 'models/BaseModel';
import { TransformDateModel } from 'utils/transformer';
import { FULL_DATE_FORMAT } from 'constants/common';

export class TicketBranchDetailModel extends BaseModel {
  @Expose({ name: 'branchName' })
  branchName: string | undefined;

  @Expose({ name: 'branchUrl' })
  branchUrl: string | undefined;

  @Expose({ name: 'createdBy' })
  createdBy: string | undefined;

  @Expose({ name: 'creator' })
  creator: string | undefined;

  @Expose({ name: 'creatorId' })
  creatorId: string | undefined;

  @Expose({ name: 'status' })
  status: string | undefined;

  @Expose({ name: 'repoName' })
  repoName: string | undefined;

  @Expose({ name: 'updatedDate' })
  @TransformDateModel(FULL_DATE_FORMAT)
  updatedDate?: string | undefined;

  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'updaterId' })
  updaterId: string | undefined;

  @Expose({ name: 'updater' })
  updater: string | undefined;

  @Expose({ name: 'checked' })
  checked: boolean | undefined;
}

export class GetTicketBranchHistoryModel extends BaseModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'apprType' })
  apprType: string | undefined;
}
