import get from 'lodash/get';
import {
  assigneeInputSliceName,
  initialState as initialStateAssignee
} from 'containers/AssigneeInput/slices';
import { authSliceName } from 'features/auth/slices';

import { ticketFormSliceName, initialState } from './slices';

export const getServiceClassfication = (state: any) =>
  get(state, [ticketFormSliceName, 'serviceClassfication'], initialState.serviceClassfication);

export const getServiceClassficationDetail = (state: any) =>
  get(
    state,
    [ticketFormSliceName, 'serviceClassficationDetail'],
    initialState.serviceClassficationDetail
  );

export const getProjCodeOfAssigneeList = (state: any) =>
  get(
    state,
    [assigneeInputSliceName, 'currentProjectCode'],
    initialStateAssignee.currentProjectCode
  );

export const getAssigneeList = (state: any) =>
  get(state, [assigneeInputSliceName, 'assigneeList'], initialStateAssignee.assigneeList);

export const getIsSubmittingForm = (state: any) =>
  get(state, [ticketFormSliceName, 'isSubmittingForm'], initialState.isSubmittingForm);

export const getProjectCicdList = (state: any) =>
  get(state, [ticketFormSliceName, 'projectCicdList'], initialState.projectCicdList);

export const getUserInfo = (state: any) =>
  get(state, [authSliceName, 'userInfo', 'corpUserInfo'], null);

export const getTicketTypeList = (state: any) =>
  get(state, [ticketFormSliceName, 'ticketTypeList'], null);

export const getAssigneeCutomer = (state: any) =>
  get(state, [ticketFormSliceName, 'listAssignCustomer'], null);
