import { ButtonProps } from '@mui/material';
import ResetPassButton from 'components/@material-extend/buttons/ResetPassButton';
import AuthButton from './AuthButton';

import { EDIT_BUTTON_ID } from '../../constants';

const AuthResetPassButton = (props: ButtonProps) => (
  <AuthButton btnId={EDIT_BUTTON_ID}>
    <ResetPassButton {...props} />
  </AuthButton>
);

export default AuthResetPassButton;
