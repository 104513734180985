import {
  TICKET_DETAIL_URL,
  TICKET_HISTORY_URL,
  TICKET_LOG_WORK_URL,
  TICKET_CHANGE_STATUS_URL,
  TICKET_EXPORT_DETAIL,
  SEARCH_PROJECT_USER_URL,
  TICKET_COMMENT_URL,
  SEARCH_TICKET_COMMENT_URL,
  DELETE_TICKET_COMMENT_URL,
  TICKET_LOG_WORK_SEARCH_URL,
  TICKET_LOG_WORK_DELETE_URL,
  GET_WORKFLOW_DETAIL,
  TICKET_RELATES_LIST_URL,
  TICKET_RELATES_URL,
  TICKET_RELATES_DELETE_URL,
  TICKET_MERGE_REQUEST,
  TICKET_BRANCH,
  TICKET_COMMIT,
  TICKET_DEPLOYMENT_STATUS,
  TICKET_DEPLOYMENT_HISTORY,
  CHECK_INTEGRATION_ACTIVE,
  CHECK_GITLAB_CICD_ACTIVE,
  UPDATE_DEPLOYMENT_SCHEDULE,
  CHECK_REGULAR_GROUP,
  TICKET_CICD_EXPORT,
  DELETE_BRANCH_MR
} from 'constants/apiUrl';
import apiClient, { downloadClient } from 'apis/apiClient';

export const fetchTicketDetail = (params: any) =>
  apiClient
    .post(TICKET_DETAIL_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchTicketHistory = (params: any) =>
  apiClient
    .post(TICKET_HISTORY_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const logWork = (params: any) =>
  apiClient
    .post(TICKET_LOG_WORK_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const changeStatus = (params: any) =>
  apiClient
    .put(TICKET_CHANGE_STATUS_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const exportTicket = (params: any) =>
  downloadClient
    .post(TICKET_EXPORT_DETAIL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchUsersByProject = (params: any) =>
  apiClient
    .post(SEARCH_PROJECT_USER_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchTicketComments = (params: any) =>
  apiClient
    .post(SEARCH_TICKET_COMMENT_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const createTicketComment = (params: any) =>
  apiClient
    .post(TICKET_COMMENT_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const editTicketComment = (params: any) =>
  apiClient
    .put(TICKET_COMMENT_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const deleteTicketComment = (params: any) =>
  apiClient
    .post(DELETE_TICKET_COMMENT_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchTicketLogWork = (params: any) =>
  apiClient
    .post(TICKET_LOG_WORK_SEARCH_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const deleteTicketLogWork = (params: any) =>
  apiClient
    .post(TICKET_LOG_WORK_DELETE_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const updateTicketLogWork = (params: any) =>
  apiClient
    .put(TICKET_LOG_WORK_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getWorkflowDetail = (params: string) =>
  apiClient
    .post(GET_WORKFLOW_DETAIL, {
      type: params
    })
    .then((res) => res?.data?.data);

export const fetchTicketRelates = (params: any) =>
  apiClient
    .post(TICKET_RELATES_LIST_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const addTicketRelates = (params: any) =>
  apiClient
    .post(TICKET_RELATES_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const deleteTicketRelate = (params: any) =>
  apiClient
    .post(TICKET_RELATES_DELETE_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getTicketMergeRequest = (params: any) =>
  apiClient
    .post(TICKET_MERGE_REQUEST, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getTicketBranch = (params: any) =>
  apiClient
    .post(TICKET_BRANCH, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getTicketCommit = (params: any) =>
  apiClient
    .post(TICKET_COMMIT, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getTicketDeploymentStatus = (params: any) =>
  apiClient
    .post(TICKET_DEPLOYMENT_STATUS, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getTicketDeploymentHistory = (params: any) =>
  apiClient
    .post(TICKET_DEPLOYMENT_HISTORY, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const checkIntegrationActive = (params: any) =>
  apiClient
    .post(CHECK_INTEGRATION_ACTIVE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const checkGitlabCicdActive = (params: any) =>
  apiClient
    .post(CHECK_GITLAB_CICD_ACTIVE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const updateDeploymentSchedule = (params: any) =>
  apiClient
    .put(UPDATE_DEPLOYMENT_SCHEDULE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const checkRegularGroup = (params: any) =>
  apiClient
    .post(CHECK_REGULAR_GROUP, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const exportTicketCicd = (params: any) =>
  downloadClient
    .post(TICKET_CICD_EXPORT, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const deleteBranchMR = (params: any) =>
  apiClient
    .post(DELETE_BRANCH_MR, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
