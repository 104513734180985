import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useLocales from 'hooks/useLocales';

// hook form
import { useFormContext } from 'react-hook-form';
import { RHFSelect } from 'components/hook-form';

// material
import { SelectChangeEvent } from '@mui/material';

import { FormGridItem } from 'components/layouts';
import { MMenuItem } from 'components/@material-extend';
import { useAlert } from 'hooks/useAlert';

import { Application, ApplicationVersion } from 'features/helpDesk/ticket/TicketManagement/types';
import { ticketFormActions, ticketFormSliceName, initialState } from '../../slices';

type ProjectAppVersionFormProps = {
  onAfterProjectChange: (value: any) => void;
  isEdit: boolean;
  disableProject: boolean;
  openFromRegister?: boolean;
};

const ProjectAppVersionForm = ({
  onAfterProjectChange,
  isEdit,
  disableProject,
  openFromRegister
}: ProjectAppVersionFormProps) => {
  const { t } = useLocales();
  const dispatch: any = useDispatch();
  const [shouldUseFirstAppCode, setUseFirstAppCode] = useState<boolean>(false);
  const [shouldUseFirstVersion, setUseFirstVersion] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const { setValue, watch } = useFormContext();

  const watchedProjCode = watch('projCode');
  const watchedAppCode = watch('appCode');

  const projectList = useSelector(
    (state) => get(state, [ticketFormSliceName, 'projectList'], initialState.projectList),
    shallowEqual
  );

  const listProjectFilter = useMemo(() => {
    if (isEdit) {
      const currentProject = projectList.find((item: any) => item.projCode === watchedProjCode);
      if (currentProject && currentProject.integrateSolution) {
        const listProjectFileter = projectList.filter(
          (item: any) => item.integrateSolution === currentProject.integrateSolution
        );
        return listProjectFileter;
      }
      return projectList;
    }
    return projectList;
  }, [projectList, watchedProjCode, isEdit]);

  const applicationList = useSelector(
    (state) => get(state, [ticketFormSliceName, 'applicationList'], initialState.applicationList),
    shallowEqual
  );

  const versionList = useSelector(
    (state) => get(state, [ticketFormSliceName, 'versionList'], initialState.versionList),
    shallowEqual
  );

  useEffect(() => {
    if (listProjectFilter && listProjectFilter.length === 1) {
      const projCode = get(listProjectFilter, [0, 'projCode']);
      setValue('projCode', projCode);
      setUseFirstAppCode(true);
      setUseFirstVersion(true);
    }
  }, [listProjectFilter, setValue]);

  useEffect(() => {
    if (!isEmpty(watchedProjCode)) {
      dispatch(
        ticketFormActions.fetchApplications({
          projCodes: [watchedProjCode]
        })
      );
    } else {
      setValue('appCode', '');
      dispatch(ticketFormActions.clearApplications());
    }
  }, [dispatch, setValue, watchedProjCode]);

  useEffect(() => {
    if (!isEmpty(watchedAppCode)) {
      dispatch(
        ticketFormActions.fetchVersions({
          projCode: watchedProjCode,
          appCode: watchedAppCode
        })
      );
    } else {
      setValue('appVersionId', '');
      dispatch(ticketFormActions.clearVersions());
    }
  }, [dispatch, setValue, watchedAppCode, watchedProjCode]);

  useEffect(() => {
    if (!isEmpty(applicationList) && shouldUseFirstAppCode) {
      // Get first application
      const firstCode = get(applicationList, [0, 'appCode'], '');
      setValue('appCode', firstCode);
    }
  }, [applicationList, setValue, shouldUseFirstAppCode]);

  useEffect(() => {
    if (!isEmpty(versionList) && shouldUseFirstVersion) {
      // Get first version
      const firstCode = get(versionList, [0, 'id'], '');
      setValue('appVersionId', firstCode);
    }
  }, [versionList, setValue, shouldUseFirstVersion]);

  useEffect(() => () => dispatch(ticketFormActions.clearProjects()), [dispatch]);

  const handleChangeProject = (event: SelectChangeEvent) => {
    const { value, name } = event.target;
    setValue(name, value, { shouldValidate: true });
    setUseFirstAppCode(true);
    setUseFirstVersion(true);
    setValue('appCode', '');
    setValue('appVersionId', '');
    onAfterProjectChange && onAfterProjectChange(value);
  };

  const onChangeProjectCode = (event: SelectChangeEvent) => {
    if (isEdit) {
      showAlert({
        message: 'helpDesk.ticketManagement.warningChangeProject',
        onYes: () => handleChangeProject(event)
      });
    } else {
      handleChangeProject(event);
    }
  };

  const onChangeAppCode = (event: SelectChangeEvent) => {
    const { value, name } = event.target;
    setValue(name, value);
    setUseFirstAppCode(false);
    setUseFirstVersion(true);
    setValue('appVersionId', '');
  };

  const onChangeVersionId = (event: SelectChangeEvent) => {
    const { value, name } = event.target;
    setValue(name, value);
    setUseFirstVersion(false);
  };

  useEffect(() => {
    if (openFromRegister) {
      setUseFirstVersion(true);
      setUseFirstAppCode(true);
    }
  }, [openFromRegister]);

  return (
    <>
      <FormGridItem spacing={4}>
        {/* Project - 프로젝트 */}
        <RHFSelect
          name="projCode"
          label={t('helpDesk.ticketManagement.form.project')}
          onChange={onChangeProjectCode}
          disabled={disableProject}
        >
          {listProjectFilter.map((item: any) => (
            <MMenuItem key={item.projCode} value={item.projCode}>
              {item.projName}
            </MMenuItem>
          ))}
        </RHFSelect>
      </FormGridItem>
      <FormGridItem spacing={4}>
        {/* Application - 애플리케이션 */}
        <RHFSelect
          name="appCode"
          label={t('helpDesk.ticketManagement.form.application')}
          disabled={isEmpty(watchedProjCode)}
          onChange={onChangeAppCode}
        >
          {applicationList.map(({ appCode, name }: Application) => (
            <MMenuItem key={appCode} value={appCode}>
              {name}
            </MMenuItem>
          ))}
        </RHFSelect>
      </FormGridItem>
      <FormGridItem spacing={4}>
        {/* Version - 버전 */}
        <RHFSelect
          name="appVersionId"
          label={t('helpDesk.ticketManagement.form.version')}
          disabled={isEmpty(watchedAppCode)}
          onChange={onChangeVersionId}
        >
          {versionList.map(({ id, version }: ApplicationVersion) => (
            <MMenuItem key={id} value={id}>
              {version}
            </MMenuItem>
          ))}
        </RHFSelect>
      </FormGridItem>
    </>
  );
};

export default ProjectAppVersionForm;
