// material
import { useTheme, Theme } from '@mui/material';
import { TimelineDot, TimelineDotProps } from '@mui/lab';

// ----------------------------------------------------------------------

interface MTimelineDotProps extends Omit<TimelineDotProps, 'color'> {
  color?: 'grey' | 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}

export default function MTimelineDot({
  color = 'grey',
  variant = 'filled',
  sx,
  ...other
}: MTimelineDotProps) {
  const theme = useTheme<Theme>();

  if (color === 'grey' || color === 'inherit' || color === 'primary' || color === 'secondary') {
    return <TimelineDot color={color} variant={variant} sx={sx} {...other} />;
  }

  return (
    <TimelineDot
      variant={variant}
      sx={{
        ...(variant === 'filled' && {
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main
        }),
        ...(variant === 'outlined' && {
          borderColor: theme.palette[color].main
        }),
        ...sx
      }}
      {...other}
    />
  );
}
