import find from 'lodash/find';
import get from 'lodash/get';
import keys from 'lodash/keys';

import {
  TICKET_MANAGEMENT,
  SCHEDULE_MANAGEMENT,
  DOCUMENT_MANAGEMENT,
  CONTRACT_MANAGEMENT,
  VIEW_ANNOUNCEMENT
} from 'constants/routes';
import { ACTIVE_YN } from 'constants/commonCodes';
import { MESSAGE_TYPE } from 'contexts/SocketProvider';

export type SettingName =
  | 'ticketProgRate'
  | 'closeTicket'
  | 'ticketCreated'
  | 'announcement'
  | 'calendar'
  | 'contractStatus'
  | 'documentStatus'
  | 'inProgTicket'
  | 'waitingForProgress'
  | 'myRecentTicket';

export type DashboardSettings = {
  [K in SettingName]: 'Y' | 'N';
};

export type DashboardPermission = {
  [K in SettingName]: boolean;
};

type AllMenuCodes = {
  menuPath: string;
  menuCode: string;
};

interface CheckPermissionByMenuCode {
  settingName: SettingName;
  allMenuCodes: AllMenuCodes;
  userMenuCodes: string[];
}

interface GetPermissionByMenuPath {
  dashboardSettings: DashboardSettings;
  allMenuCodes: AllMenuCodes;
  userMenuCodes: string[];
}

interface CheckPermissionByDashboardSetting extends GetPermissionByMenuPath {
  settingName: SettingName;
}
type DashboardSettingList = {
  [K in SettingName]: string;
};
export const DASHBOARD_SETTING_LIST: DashboardSettingList = {
  ticketProgRate: TICKET_MANAGEMENT,
  ticketCreated: TICKET_MANAGEMENT,
  waitingForProgress: TICKET_MANAGEMENT,
  inProgTicket: TICKET_MANAGEMENT,
  closeTicket: TICKET_MANAGEMENT,
  calendar: SCHEDULE_MANAGEMENT,
  myRecentTicket: TICKET_MANAGEMENT,
  announcement: VIEW_ANNOUNCEMENT,
  contractStatus: CONTRACT_MANAGEMENT,
  documentStatus: DOCUMENT_MANAGEMENT
};

export const MAP_SETTING_AND_MESSAGE_TYPE: DashboardSettingList = {
  ticketProgRate: MESSAGE_TYPE.METRIC,
  ticketCreated: MESSAGE_TYPE.METRIC,
  waitingForProgress: MESSAGE_TYPE.METRIC,
  inProgTicket: MESSAGE_TYPE.METRIC,
  closeTicket: MESSAGE_TYPE.METRIC,
  calendar: MESSAGE_TYPE.SCHEDULE,
  myRecentTicket: MESSAGE_TYPE.TICKET,
  announcement: MESSAGE_TYPE.ANNOUNCEMENT,
  contractStatus: MESSAGE_TYPE.CONTRACT,
  documentStatus: MESSAGE_TYPE.DOCUMENT
};

export const SETTING_NAME_LIST = keys(DASHBOARD_SETTING_LIST) as SettingName[];

export const DEFAULT_DASHBOARD_PERMISSION: DashboardPermission = SETTING_NAME_LIST.reduce(
  (acc, settingName: SettingName) => ({ ...acc, [settingName]: false }),
  {} as DashboardPermission
);

export const checkPermissionByMenuCode = ({
  settingName,
  allMenuCodes,
  userMenuCodes
}: CheckPermissionByMenuCode) => {
  const menuCodeChecking = get(
    find(allMenuCodes, (item: any) => item.menuPath === DASHBOARD_SETTING_LIST[settingName]),
    'menuCode'
  );
  if (!menuCodeChecking) return false;
  return userMenuCodes.includes(menuCodeChecking);
};

export const checkPermissionByDashboardSetting = ({
  settingName,
  dashboardSettings,
  allMenuCodes,
  userMenuCodes
}: CheckPermissionByDashboardSetting) => {
  const dashboardSettingValue = dashboardSettings[settingName];
  if (!dashboardSettingValue) return false;
  const result = checkPermissionByMenuCode({
    settingName,
    allMenuCodes,
    userMenuCodes
  });
  return result && dashboardSettingValue === ACTIVE_YN.Yes;
};

export const getPermissionByMenuPath = (params: GetPermissionByMenuPath): DashboardPermission =>
  SETTING_NAME_LIST.reduce(
    (res, settingName: SettingName) => ({
      ...res,
      [settingName]: checkPermissionByDashboardSetting({ settingName, ...params })
    }),
    {} as DashboardPermission
  );
