import { UPLOAD_IMAGE_URL } from 'constants/apiUrl';
import apiClient from 'apis/apiClient';

export const uploadImageToEditor = (params: any) =>
  apiClient
    .post(UPLOAD_IMAGE_URL, params)
    .then((res) => res?.data)
    .catch((error) => {
      throw error;
    });
