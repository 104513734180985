import { ButtonProps } from '@mui/material';
import useLocales from 'hooks/useLocales';
import MButton from '../MButton';

// For automation test
const TEST_ID = 'discardBtn';

const DiscardButton = (props: ButtonProps) => {
  const { t } = useLocales();
  return (
    <MButton test-id={TEST_ID} {...props}>
      {t('common.button.discard')}
    </MButton>
  );
};

export default DiscardButton;
