import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';
import every from 'lodash/every';
import isEqualWith from 'lodash/isEqualWith';
import mime from 'mime-types';
import { ILocaleString } from 'models/LocaleModel';
import uniq from 'lodash/uniq';

import { BASE_API_URL } from 'constants/appConfig';

export const isFile = (data: any) => data instanceof File;
export const isFileArray = (data: any) => {
  if (Array.isArray(data)) {
    return data.length > 0 && data.every((item) => isFile(item));
  }
  return false;
};

const EDITOR_CONTENT_FIELDS = ['description', 'content'];

const convertDataByKey = (key: string, value: any) => {
  // Remove language formatter select input
  if (EDITOR_CONTENT_FIELDS.includes(key)) {
    // TODO: change to a generic Regex
    return value.replaceAll(
      '<select class="ql-ui" contenteditable="false"><option value="plain">Plain</option><option value="bash">Bash</option><option value="cpp">C++</option><option value="cs">C#</option><option value="css">CSS</option><option value="diff">Diff</option><option value="xml">HTML/XML</option><option value="java">Java</option><option value="javascript">Javascript</option><option value="markdown">Markdown</option><option value="php">PHP</option><option value="python">Python</option><option value="ruby">Ruby</option><option value="sql">SQL</option></select>',
      ''
    );
  }
  return value;
};

export const convertCodeBlockData = (data: any) => {
  const res = {} as any;
  for (const key in data) {
    if (Reflect.has(data, key)) {
      const value = data[key];
      res[key] = convertDataByKey(key, value);
    }
  }
  return res;
};

export const convertToFormData = (data: any): FormData => {
  const formData = new FormData();
  for (const key in data) {
    if (Reflect.has(data, key)) {
      const items = data[key];
      if (Array.isArray(items)) {
        // Stringify array containing plain objects
        if (every(items, isPlainObject) || every(items, isString)) {
          formData.append(key, JSON.stringify(items));
        } else {
          // In case of non-plain object, such as `File` object
          items.forEach((item: any, index: number) => {
            formData.append(`${key}[${index}]`, item);
          });
        }
      } else {
        formData.append(key, convertDataByKey(key, items));
      }
    }
  }
  return formData;
};

export const getTotalSizeOfFiles = (files: File[]): number =>
  files.reduce((acc: number, file: File) => acc + file.size, 0);

export const getSubCodeList = (commonCodes: any) => {
  if (isEmpty(commonCodes)) return {};

  const result: any = {};

  Object.keys(commonCodes).forEach((code) => {
    result[code] = commonCodes[code].subCodeList;
  });

  return result;
};

export const getExtensionOfFile = (filename: string) => filename.split('.').pop() || '';
export const getNameOfFile = (filename?: string) =>
  filename?.split('.').slice(0, -1).join('.') || '';
export const isImageFile = (mime: string) => mime.startsWith('image/');

export const getExtensionsByAccept = (accept?: string | string[]) => {
  if (!accept || isEmpty(accept)) return '';
  const listAccept = (isString(accept) ? accept.split(',') : accept).map((ext) => {
    if (mime.extension(ext)) return `.${mime.extension(ext)}`;
    return ext;
  });
  return uniq(listAccept).join(', ');
};

export function parseValue(storedValue: string) {
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    // storedValue is a string and can not be parsed as an object
    return storedValue;
  }
}

export const calcRateOfIncrease = (value: number = 0, prevVal: number = 0) =>
  value / (prevVal || 1) - (prevVal ? 1 : 0);

export const checkEqualCommonCodeValue = <
  T extends {
    value: string;
    title: ILocaleString;
  }
>(
  first: T,
  second: T
) =>
  isEqualWith(
    first,
    second,
    (firstParam: T, secondParam: T) => firstParam.value === secondParam.value
  );

const MAX_FILE_NAME = 30;
const FILE_NAME_REMINDER = 10;

export function truncateFileName(fileName: string) {
  if (fileName.length <= MAX_FILE_NAME) return fileName;

  return `${fileName.substring(0, MAX_FILE_NAME - FILE_NAME_REMINDER)}...${fileName.substring(
    fileName.length - FILE_NAME_REMINDER,
    fileName.length
  )}`;
}

export const getWeekOrder = (date: any) => {
  const daysInMonth = dayjs(date).daysInMonth();
  const startWeek = Number(dayjs(date).startOf('week').format('DD'));
  const endWeek = Number(dayjs(date).endOf('week').format('DD'));
  const result = Math.round(Number(dayjs(date).endOf('week').format('DD')) / 7);
  if (startWeek < endWeek) return result;
  if (daysInMonth - startWeek >= 5) return 4;
  return daysInMonth - startWeek >= 3 ? 5 : 1;
};

// when copy image paste to editor api upload img will call => image duplicate in editor, function remove img copy and retain images uploaded to the server BASE_API_URL
export const filterImageQuillEditor = (value: any) => {
  const baseUrl = new URL(BASE_API_URL);
  const domainUrl = baseUrl.hostname;

  const tempElement = document.createElement('div');
  tempElement.innerHTML = value;

  const imgElements = tempElement.getElementsByTagName('img');
  for (let i = imgElements.length - 1; i >= 0; i--) {
    const imgSrc = imgElements[i].getAttribute('src') || '';
    const srcDomain = new URL(imgSrc).hostname;
    if (srcDomain !== domainUrl) {
      imgElements[i].remove();
    }
  }

  const modifiedHTMLString = tempElement.innerHTML;
  return modifiedHTMLString;
};

export const formatMilisecondToSeconds = (duration: number | any): string => {
  let result = '';
  const h = duration / 1000 / 60 / 60;
  const hForamt = Math.floor(h);
  if (hForamt > 0) {
    result = `${hForamt}h`;
  }

  const m = (h - hForamt) * 60;
  const mFormat = Math.floor(m);
  if (mFormat > 0) {
    result = `${result} ${mFormat}min`;
  }

  const s = (m - mFormat) * 60;
  const sFormat = Math.floor(s);

  if (sFormat > 0) {
    result = `${result} ${sFormat}sec`;
  } else {
    result = `0sec ${duration}ms`;
  }

  return result;
};

export const getCorpInTicketLink = (searchParams: string) => {
  if (searchParams) {
    return searchParams.substring(searchParams.indexOf('=') + 1, searchParams.indexOf('-'));
  }
  return '';
};
