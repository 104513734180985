import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

export const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.typography.pxToRem(16),
  marginBottom: theme.typography.pxToRem(5),
  '&.selected': {
    backgroundColor: theme.palette.grey[200]
  }
}));

export const WrapperContent = styled('div')(() => ({
  display: 'flex'
}));

export const WrapperHeader = styled(FlexBox)(({ theme }) => ({
  padding: theme.typography.pxToRem(24)
}));

export const CardStyle = styled(Card)(({ theme }) => ({
  marginTop: theme.typography.pxToRem(20),
  '& td': {
    paddingTop: 0,
    paddingBottom: 0
  },
  '& td:hover': {
    backgroundColor: 'white'
  }
}));

export const WrapperIcon = styled('div')(({ theme }) => ({
  marginRight: theme.typography.pxToRem(10)
}));

export const WrapperIconReadAll = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  '& span': {
    display: 'block'
  }
}));
