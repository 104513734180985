import { Expose, Transform } from 'class-transformer';

import BaseModel from 'models/BaseModel';
import { ViewFile } from 'interfaces/ViewFile';
import { TransformDateModel } from 'utils/transformer';
import { TicketFormModel } from 'features/helpDesk/ticket/TicketForm/models';
import { FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';
import { ProjectCICDType } from '../types';

export class TicketLogWorkListModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && TicketLogWorkItemModel.toClass(item.value))
  list: Array<any> | undefined;

  @Expose({ name: 'totalElements' })
  totalElements: number | undefined;
}

export class TicketLogWorkItemModel extends BaseModel {
  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'createdUserAvtUrl' })
  avatarUrl: string | undefined;

  @Expose({ name: 'updatedName' })
  createdBy: string | undefined;

  @Expose({ name: 'startedDate', toClassOnly: true })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  startedDate: number | undefined;

  @Expose({ name: 'timeSpent' })
  timeSpent: string | undefined;

  @Expose({ name: 'workDesc' })
  workDesc: string | undefined;

  @Expose({ name: 'createdBy' })
  username: string | undefined;

  @Expose({ name: 'logworkStatus' })
  logworkStatus: string | undefined;

  @Expose({ name: 'ticketLogWorkFiles' })
  ticketLogWorkFiles: Array<ViewFile> | undefined;

  @Expose({ name: 'originalLogworkStatus' })
  originalLogworkStatus: string | undefined;
}

export class TicketTimeSpentModel extends BaseModel {
  @Expose({ name: 'estimated' })
  estimated: string | undefined;

  @Expose({ name: 'remaining' })
  remaining: string | undefined;

  @Expose({ name: 'logged' })
  logged: string | undefined;
}

export class TicketModel extends TicketFormModel {
  @Expose({ name: 'timeSpent' })
  timeSpent: TicketTimeSpentModel | undefined;

  @Expose({ name: 'id' })
  ticketId: number | undefined;

  @Expose({ name: 'mergeRequestCount' })
  mergeRequestCount: number | undefined;

  @Expose({ name: 'branchCount' })
  branchCount: number | undefined;

  @Expose({ name: 'commitCount' })
  commitCount: number | undefined;

  @Expose({ name: 'projCicdNames' })
  projCicdNames: string[] | undefined;

  @Expose({ name: 'integrateSolution' })
  integrateSolution: string | undefined;

  @Expose({ name: 'approval' })
  approval: string | undefined;

  @Expose({ name: 'editYn' })
  editYn: string | undefined;

  @Expose({ name: 'disableCicdFieldYn' })
  disableCicdFieldYn: string | undefined;

  @Expose({ name: 'beforeStatus' })
  beforeStatus: string | undefined;

  @Expose({ name: 'integrateActive' })
  integrateActive: string | undefined;

  @Expose({ name: 'disabledStatusYn' })
  disabledStatusYn: string | undefined;

  @Expose({ name: 'projectCicds' })
  projectCicds: Array<ProjectCICDType> | undefined;

  @Expose({ name: 'creatorUserStatus' })
  creatorUserStatus: string | undefined;

  @Expose({ name: 'assigneeUserStatus' })
  assigneeUserStatus: string | undefined;

  @Expose({ name: 'regularGroup' })
  regularGroup: string | undefined;

  @Expose({ name: 'picDisplayMap' })
  picDisplayMap: any;
}
