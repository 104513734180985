import { useState, useEffect, useMemo } from 'react';
import { SxProps } from '@mui/system';
import mime from 'mime-types';
import isEmpty from 'lodash/isEmpty';
import FileSaver from 'file-saver';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon as Iconify } from '@iconify/react';

import { alpha, Theme, experimentalStyled as styled } from '@mui/material/styles';
import { Box, Tooltip, Typography, useTheme, Link } from '@mui/material';

import { getIconFromMimeType, getFileExtension } from 'helpers/fileIcons';
import { MIconButton } from 'components/@material-extend';
import { getNameOfFile, isImageFile, isFile } from 'utils/helpers';

import { THUMBNAIL_SIZE } from './constants';

export type ThumbnailSizeType = 'sm' | 'md' | 'lg';

interface AttachedItemProps {
  fileId?: string | number;
  name?: string;
  extension?: string;
  url?: string;
  file?: File;
  onFileNameClick?: (file: any) => void;
  onRemove?: (file: any) => void;
  sx?: SxProps<Theme>;
  size?: ThumbnailSizeType;
  canRemove?: boolean;
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];
type RequiredAttachedItemProps = RequireAtLeastOne<AttachedItemProps, 'url' | 'file'>;

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

const IconWrapperStyle = styled(Link)(
  ({
    theme,
    styleProps
  }: {
    theme: Theme;
    styleProps: { hasPreview: Boolean; size: { width: number; height: number } };
  }) => ({
    width: styleProps.size.width,
    height: styleProps.size.height,
    borderRadius: theme.typography.pxToRem(8),
    padding: styleProps.hasPreview ? 0 : theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[500_16],
    mixBlendMode: 'normal',
    position: 'relative',
    '> .imagePreview': {
      borderRadius: theme.typography.pxToRem(8),
      width: styleProps.size.width,
      height: styleProps.size.height
    },
    '> .cancelIconContainer': {
      position: 'absolute',
      top: theme.typography.pxToRem(2),
      right: theme.typography.pxToRem(3),
      display: 'none'
    },
    '> .cancelIconContainer > button': {
      padding: 0
    },
    '.cancelIcon': {
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(20)
    },
    '&:hover': {
      cursor: 'pointer',
      '& .placeholder': {
        zIndex: 9
      },
      '& > .cancelIconContainer': {
        display: 'inline-flex'
      }
    }
  })
);

const FileNameWrapperStyle = styled(Link)(
  ({ theme, styleProps }: { theme: Theme; styleProps: { size: { width: number } } }) => ({
    background: 'none',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    width: styleProps.size.width,
    color: theme.palette.common.black,
    textDecoration: 'none',
    '.ellipsis': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  })
);

const MIconButtonStyle = styled(MIconButton)(({ theme }) => ({
  padding: theme.typography.pxToRem(2),
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.grey[900], 0.72),
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[900], 0.48)
  }
}));

export default function AttachedItem({
  // File type
  file,
  // object type
  fileId = '',
  name = '',
  extension = '',
  url,
  sx,
  onFileNameClick,
  onRemove,
  size = 'lg',
  canRemove
}: RequiredAttachedItemProps) {
  const theme = useTheme<Theme>();
  const [fileName, setFileName] = useState<string>(name);
  const [fileExtension, setFileExtension] = useState<string>(extension);
  const [imagePreview, setImagePreview] = useState<string>('');

  const handleRemove: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove && onRemove(file || { id: fileId, fileName: name, fileUrl: url });
  };

  const handleFileNameClick = () => {
    onFileNameClick && onFileNameClick(file || { id: fileId, fileName: name, fileUrl: url });
    file && FileSaver.saveAs(file);
  };

  useEffect(() => {
    // If file
    if (file && isFile(file)) {
      const _fileName = getNameOfFile(file.name);
      const _fileExtension = mime.extension(file.type) || '';
      setFileName(_fileName);
      setFileExtension(_fileExtension);

      // check if image => show preview
      if (isImageFile(file.type)) {
        const preview = URL.createObjectURL(file);
        setImagePreview(preview);
      } else {
        setImagePreview('');
      }
    } else {
      const mimeType = mime.lookup(extension) || '';
      setImagePreview((isImageFile(mimeType) && url) || '');
    }
  }, [extension, file, url]);

  const Icon = useMemo(() => {
    if (file && file instanceof File) {
      if (isEmpty(file?.type) || file?.type === 'application/x-zip-compressed')
        return getIconFromMimeType(`.${getFileExtension(file?.name).toLowerCase()}`);
      return getIconFromMimeType(file?.type);
    }
    return getIconFromMimeType(mime.lookup(fileExtension) || '');
  }, [file, fileExtension]);

  return (
    <RootStyle theme={theme} className="wrapperIconFile">
      <IconWrapperStyle
        theme={theme}
        styleProps={{ hasPreview: !isEmpty(imagePreview), size: THUMBNAIL_SIZE[size] }}
        sx={sx}
        target="_blank"
        href={!isEmpty(url) ? url : undefined}
        onClick={handleFileNameClick}
      >
        {!isEmpty(imagePreview) ? (
          <Box
            component="img"
            alt="thumbnail"
            className="imagePreview"
            src={imagePreview}
            sx={{ zIndex: 2, objectFit: 'cover' }}
          />
        ) : (
          Icon && <Icon sx={{ height: '100%', width: '100%' }} />
        )}
        {canRemove && (
          <MIconButtonStyle buttonClassName="cancelIconContainer" onClick={handleRemove}>
            <Iconify className="cancelIcon" icon={closeFill} />
          </MIconButtonStyle>
        )}
      </IconWrapperStyle>

      <Tooltip title={`${fileName}.${fileExtension}`}>
        <FileNameWrapperStyle
          theme={theme}
          href={!isEmpty(url) ? url : undefined}
          onClick={handleFileNameClick}
          styleProps={{ size: THUMBNAIL_SIZE[size] }}
        >
          <Typography variant="caption" component="span" className="ellipsis">
            {fileName}
          </Typography>
          {!isEmpty(fileExtension) && (
            <Typography variant="caption" component="span">
              {`.${fileExtension}`}
            </Typography>
          )}
        </FileNameWrapperStyle>
      </Tooltip>
    </RootStyle>
  );
}
