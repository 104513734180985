import { IconButton, IconButtonProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// For automation test
const TEST_ID = 'deleteBtn';

const DeleteButton = (props: IconButtonProps) => (
  <IconButton test-id={TEST_ID} aria-label="delete" {...props}>
    <DeleteIcon color="error" fontSize="inherit" />
  </IconButton>
);

export default DeleteButton;
