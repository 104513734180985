import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function DocIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0416 13.7446L8.94922 12.1428V23.9788C8.94944 24.2384 9.0528 24.4874 9.23658 24.6709C9.42036 24.8544 9.6695 24.9573 9.92918 24.9571H25.6184C25.8784 24.9577 26.128 24.855 26.3121 24.6715C26.4962 24.4879 26.5998 24.2387 26.6 23.9788V19.6178L18.0416 13.7446Z"
          fill="#185C37"
        />
        <path
          d="M18.0416 3.6001H9.92918C9.6695 3.59988 9.42036 3.70283 9.23658 3.8863C9.0528 4.06977 8.94944 4.31873 8.94922 4.57842V8.93937L18.0416 14.2786L22.856 15.8804L26.6 14.2786V8.93937L18.0416 3.6001Z"
          fill="#21A366"
        />
        <path d="M8.94922 8.93945H18.0416V14.2787H8.94922V8.93945Z" fill="#107C41" />
        <path
          opacity="0.1"
          d="M15.4566 7.87158H8.94922V21.2198H15.4566C15.9962 21.2171 16.4333 20.781 16.4373 20.2415V8.8499C16.4333 8.31031 15.9962 7.87426 15.4566 7.87158Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M14.9218 8.40552H8.94922V21.7537H14.9218C15.4614 21.751 15.8986 21.315 15.9026 20.7754V9.38384C15.8986 8.84425 15.4614 8.4082 14.9218 8.40552V8.40552Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M14.9218 8.40552H8.94922V20.6859H14.9218C15.4614 20.6832 15.8986 20.2471 15.9026 19.7075V9.38384C15.8986 8.84425 15.4614 8.4082 14.9218 8.40552V8.40552Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M14.3871 8.40552H8.94922V20.6859H14.3871C14.9267 20.6832 15.3638 20.2471 15.3678 19.7075V9.38384C15.3638 8.84425 14.9267 8.4082 14.3871 8.40552V8.40552Z"
          fill="black"
        />
        <path
          d="M4.58088 8.40552H14.3871C14.928 8.40506 15.367 8.84294 15.3679 9.38384V19.1736C15.367 19.7145 14.928 20.1524 14.3871 20.1519H4.58088C4.32105 20.1524 4.07171 20.0495 3.88775 19.866C3.7038 19.6825 3.60032 19.4334 3.6001 19.1736V9.38384C3.60032 9.12401 3.7038 8.87493 3.88775 8.69143C4.07171 8.50794 4.32105 8.40508 4.58088 8.40552Z"
          fill="url(#paint0_linear_excel)"
        />
        <path
          d="M6.63965 17.4599L8.70225 14.2695L6.81297 11.0972H8.33015L9.36104 13.1286C9.45632 13.3208 9.52532 13.4637 9.55654 13.559H9.5705C9.63786 13.4046 9.70932 13.2559 9.78407 13.1105L10.8864 11.1005H12.2829L10.3451 14.2547L12.3321 17.4624H10.8462L9.65511 15.2355C9.5998 15.1398 9.55281 15.0395 9.51464 14.9357H9.49493C9.4602 15.0368 9.41389 15.1336 9.35693 15.224L8.13054 17.4599H6.63965Z"
          fill="white"
        />
        <path
          d="M25.6192 3.6001H18.0415V8.93937H26.6V4.57842C26.5997 4.31859 26.4963 4.06951 26.3123 3.88601C26.1283 3.70252 25.879 3.59966 25.6192 3.6001Z"
          fill="#33C481"
        />
        <path d="M18.0415 14.2786H26.6V19.6178H18.0415V14.2786Z" fill="#107C41" />
        <defs>
          <linearGradient
            id="paint0_linear_excel"
            x1="-1.0054"
            y1="11.4649"
            x2="6.64419"
            y2="24.7611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#18884F" />
            <stop offset="0.5" stopColor="#117E43" />
            <stop offset="1" stopColor="#0B6631" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
