import {
  GET_UNREAD_NUMBER,
  SEARCH_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_READ_ALL_NOTIFICATION
} from 'constants/apiUrl';
import apiClient from 'apis/apiClient';

export const fetchNotifications = (params: any) =>
  apiClient
    .post(SEARCH_NOTIFICATION, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const updateStatusNotification = (params: any) =>
  apiClient
    .put(UPDATE_NOTIFICATION, {
      noId: params
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const updateReadAllNotifications = () =>
  apiClient
    .put(UPDATE_READ_ALL_NOTIFICATION)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const getUnreadNumber = () =>
  apiClient
    .post(GET_UNREAD_NUMBER)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
