import get from 'lodash/get';

import { createSlice } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import toastHelper from 'helpers/toast';
import { errorHandler } from 'store/errorHandlerSaga';
import { convertToFormData } from 'utils/helpers';
import { CommonCode } from 'features/commonCode/models';
import { fetchSubCodeByGroupCode, fetchCommonCodeList } from 'features/commonCode/apis';
import { fetchUsersByProject } from 'containers/AssigneeInput/apis';

import {
  createTicket,
  editTicket,
  fetchApplicationsByProjCode,
  fetchProjectsByCorpCode,
  fetchVersionsByAppCode,
  getProjectCicdByProjCode,
  loadServiceClassfication
} from './apis';
import { ApplicationModel, ProjectModel, VersionModel } from '../TicketManagement/models';

import { TicketFormModel, ProjectCicdListModel, GetProjectCicdModel } from './models';
import { checkTicketNeedApprove, showOverlapProjectErrorMessage } from './helpers';
import { fetchTicketHistory } from '../TicketDetail/apis';
import { ticketDetailSliceName } from '../TicketDetail/slices';

const initialState = {
  data: {},
  projectList: [],
  applicationList: [],
  versionList: [],
  projectCicdList: {},
  actionsCreateTicketSucceed: false,
  actionsUpdateTicketSucceed: false,
  recentTicketCode: null,
  isSubmittingForm: false,
  serviceClassfication: [],
  serviceClassficationDetail: [],
  ticketTypeList: {},
  listAssignCustomer: []
};

const ticketFormSliceName = 'ticketForm';

const ticketFormSlice = createSlice({
  name: ticketFormSliceName,
  initialState,
  reducers: {
    modifyUpdateTicketStatus: (state, { payload }) => {
      state.actionsUpdateTicketSucceed = Boolean(payload);
      state.recentTicketCode = payload || null;
    },
    modifyCreateTicketStatus: (state, { payload }) => {
      state.actionsCreateTicketSucceed = payload;
    },
    fetchProjectsSuccess: (state, { payload }) => {
      state.projectList = payload;
    },
    fetchApplicationsSuccess: (state, { payload }) => {
      state.applicationList = payload;
    },
    fetchVersionsSuccess: (state, { payload }) => {
      state.versionList = payload;
    },
    clearProjects: (state) => {
      state.projectList = initialState.projectList;
      state.applicationList = initialState.applicationList;
      state.versionList = initialState.versionList;
    },
    clearApplications: (state) => {
      state.applicationList = initialState.applicationList;
    },
    clearVersions: (state) => {
      state.versionList = initialState.versionList;
    },
    modifyFormLoading: (state, { payload }) => {
      state.isSubmittingForm = payload;
    },
    fetchProjectCicdSuccess: (state, { payload }) => {
      state.projectCicdList = payload;
    },
    clearProjectCicdData: (state) => {
      state.projectCicdList = initialState.projectCicdList;
    },
    fetchServiceClassficationSuccess: (state, { payload }) => {
      state.serviceClassfication = payload;
    },
    fetchServiceClassficationDetailSuccess: (state, { payload }) => {
      state.serviceClassficationDetail = payload;
    },
    fetchTicketTypeListSuccess: (state, { payload }) => {
      state.ticketTypeList = payload;
    },
    fetchAsigneeCustomerSuccess: (state, { payload }) => {
      state.listAssignCustomer = payload;
    },
    clearDataDefault: (state) => {
      state.ticketTypeList = {};
      state.serviceClassfication = [];
      state.serviceClassficationDetail = [];
    }
  }
});

const { actions: reducerActions, reducer: ticketFormReducer } = ticketFormSlice;

const ticketFormSliceSaga = createSliceSaga({
  name: ticketFormSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *createTicket(action): any {
      try {
        yield put(reducerActions.modifyFormLoading(true));
        const { value, callback } = action.payload;
        const isApproveText = checkTicketNeedApprove(value?.ticketType, value?.serviceType)
          ? 'createApprove'
          : 'create';
        const submitParams = TicketFormModel.toPlain(value);
        const { data } = yield call(createTicket, convertToFormData(submitParams));
        const { id, title } = get(data, ['data']);
        if (id && callback) {
          callback(id, title);
        }
        yield put(reducerActions.modifyCreateTicketStatus(true));
        toastHelper.success(`helpDesk.ticketManagement.succeedMess.${isApproveText}`);
      } catch (error) {
        yield put(reducerActions.modifyCreateTicketStatus(false));
        const projects = get(error, 'response.data.data.data.projects', []);
        if (projects.length) {
          const tickets = get(error, 'response.data.data.data.tickets', []);
          showOverlapProjectErrorMessage({ projects, tickets });
        } else {
          yield put(errorHandler(error));
        }
      } finally {
        yield put(reducerActions.modifyFormLoading(false));
      }
    },
    *editTicket(action): any {
      try {
        yield put(reducerActions.modifyFormLoading(true));
        const { value, callback } = action.payload;

        const submitParams = TicketFormModel.toPlain(value);

        const { data } = yield call(editTicket, convertToFormData(submitParams));

        toastHelper.success('helpDesk.ticketManagement.succeedMess.edit');
        const ticketCode = get(data, ['data', 'ticketCode'], '');
        callback && callback(ticketCode);
        yield put(reducerActions.modifyUpdateTicketStatus(ticketCode));
        yield put(reducerActions.clearProjectCicdData());
      } catch (error) {
        yield put(reducerActions.modifyUpdateTicketStatus(false));

        const projects = get(error, 'response.data.data.data.projects', []);
        if (projects.length) {
          const tickets = get(error, 'response.data.data.data.tickets', []);
          showOverlapProjectErrorMessage({ projects, tickets });
        } else {
          yield put(errorHandler(error));
        }
      } finally {
        yield put(reducerActions.modifyFormLoading(false));
      }
    },
    *checkTicketHistory(action): any {
      try {
        yield put(reducerActions.modifyFormLoading(true));
        const { value, callback } = action.payload;

        const { data: resHistory } = yield call(fetchTicketHistory, value);

        const listNewHistory = get(resHistory, ['data']) || [];

        const listCurrentHistory = yield select((state) =>
          get(state, [ticketDetailSliceName, 'history'])
        );
        callback(listNewHistory.length === listCurrentHistory.length);
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(reducerActions.modifyFormLoading(false));
      }
    },
    *fetchProjects(): any {
      try {
        const { data: projectRes } = yield call(fetchProjectsByCorpCode);
        if (projectRes) {
          const projectData = ProjectModel.toClass(projectRes.data);
          yield put(reducerActions.fetchProjectsSuccess(projectData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchApplications(action): any {
      try {
        const { data: applicationRes } = yield call(fetchApplicationsByProjCode, action.payload);
        if (applicationRes) {
          const appData = ApplicationModel.toClass(applicationRes.data);
          yield put(reducerActions.fetchApplicationsSuccess(appData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchVersions(action): any {
      try {
        const { data: versionRes } = yield call(
          fetchVersionsByAppCode,
          ApplicationModel.toPlain(action.payload)
        );
        if (versionRes) {
          const versionData = VersionModel.toClass(versionRes.data);
          yield put(reducerActions.fetchVersionsSuccess(versionData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *getProjectCicdByProjCode(action): any {
      try {
        const {
          payload: { projCode }
        } = action;
        const { data: projectCicdRes } = yield call(
          getProjectCicdByProjCode,
          GetProjectCicdModel.toPlain({ projCode })
        );
        const projectCicdTransferData = ProjectCicdListModel.toClass(projectCicdRes.data);
        yield put(reducerActions.fetchProjectCicdSuccess(projectCicdTransferData));
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchServiceClassfication(action): any {
      try {
        const { data } = yield call(loadServiceClassfication, action.payload);
        if (data.data) {
          const listService = data.data.map((item: any) => CommonCode.toClass(item));
          yield put(reducerActions.fetchServiceClassficationSuccess(listService));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchServiceClassficationDetail(action): any {
      try {
        const key = action.payload;
        const {
          data: { data: responseData }
        } = yield call(fetchSubCodeByGroupCode, { codes: [key] });
        if (responseData) {
          const listCodeDetail = get(responseData, [key], []);
          const transformedData = listCodeDetail.length
            ? listCodeDetail.map((item: any) => CommonCode.toClass(item))
            : [];
          yield put(reducerActions.fetchServiceClassficationDetailSuccess(transformedData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchTicketTypeList(action): any {
      try {
        const {
          data: { data: responseData }
        } = yield call(fetchCommonCodeList, action.payload);

        const transformedData: any = {};
        Object.keys(responseData).forEach((key) => {
          transformedData[key] = CommonCode.toClass(responseData[key]);
        });

        yield put(reducerActions.fetchTicketTypeListSuccess(transformedData));
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchAssignUserRoleCustomer(action): any {
      try {
        const { data: usersList } = yield call(fetchUsersByProject, action.payload);
        if (usersList?.data) {
          yield put(reducerActions.fetchAsigneeCustomerSuccess(usersList?.data));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  }
});

const { saga: ticketFormSaga, actions: sagaActions } = ticketFormSliceSaga;

const ticketFormActions = {
  ...reducerActions,
  ...sagaActions
};
export { ticketFormSliceName, ticketFormActions, ticketFormReducer, ticketFormSaga, initialState };
