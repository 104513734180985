import { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import isString from 'lodash/isString';

export const RootStyle = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%'
}));

export const AccordionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[600],
  cursor: 'pointer',
  border: 'none',
  background: theme.palette.background.paper
}));

export const TitleContent = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));

export const ContentRootStyle = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2)
}));

type MAccordionProps = {
  title: string | JSX.Element;
  children: JSX.Element;
  rightContent?: string | JSX.Element;
  isKanban?: boolean;
  expandedUser?: boolean;
};

const MAccordion = ({ title, children, rightContent, isKanban, expandedUser }: MAccordionProps) => {
  const [showContent, setShowContent] = useState(true);

  const toggleShowContent = (event: any) => {
    event.preventDefault();
    setShowContent(!showContent);
  };

  useEffect(() => {
    if (isKanban) {
      setShowContent(Boolean(expandedUser));
    }
  }, [expandedUser, isKanban]);

  return (
    <RootStyle>
      <AccordionHeader>
        <TitleContainer component="button" onClick={toggleShowContent}>
          {showContent ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          <TitleContent>
            {isString(title) ? <Typography variant="subtitle2">{title}</Typography> : title}
          </TitleContent>
        </TitleContainer>
        {rightContent && <>{rightContent}</>}
      </AccordionHeader>

      {showContent && <ContentRootStyle>{children}</ContentRootStyle>}
    </RootStyle>
  );
};

export default MAccordion;
