import { IconButtonProps } from '@mui/material';

import DeleteButton from 'components/@material-extend/buttons/DeleteButton';
import AuthButton from './AuthButton';
import { DELETE_BUTTON_ID } from '../../constants';

const AuthDeleteButton = (props: IconButtonProps) => (
  <AuthButton btnId={DELETE_BUTTON_ID}>
    <DeleteButton {...props} />
  </AuthButton>
);

export default AuthDeleteButton;
