import { useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import Quill from 'quill';
import { ImageResize } from 'quill-image-resize-module-ts';
import { useInjectReducer, useInjectSaga } from 'hooks/useInjector';

// editor
import QuillEditor from 'components/editor/quill';
import { QuillEditorProps } from 'components/editor/quill/interfaces';
import { DEFAULT_FORMATS } from 'components/editor/quill/constants';
import { MIME_TYPES } from 'constants/common';

import { quillActions, quillSliceName, quillReducer, quillSaga, initialState } from './slices';

const Delta = Quill.import('delta');
Quill.register('modules/imageResize', ImageResize);

const QuillEditorWithImage = (
  props: QuillEditorProps & {
    type: string; // This defines the sub folder containing image files, based on screen name
    accept?: string; // List of image mime types allowed to upload
  }
) => {
  useInjectReducer({
    key: quillSliceName,
    reducer: quillReducer
  });
  useInjectSaga({ key: quillSliceName, saga: quillSaga });

  const dispatch: any = useDispatch();
  const {
    formats = DEFAULT_FORMATS,
    modules,
    loading,
    type,
    accept = `${MIME_TYPES.JPEG},${MIME_TYPES.PNG},${MIME_TYPES.GIF}`,
    ...others
  } = props;

  const isLoading = useSelector((state) =>
    get(state, [quillSliceName, 'isLoading'], initialState.isLoading)
  );

  const getStateSetFilterImage = useSelector(
    (state: any) => get(state, [quillSliceName, 'filterImage'], initialState.filterImage),
    shallowEqual
  );
  const moduleConfigs = useMemo(
    () => ({
      ...modules,
      clipboard: {
        matchVisual: false,
        matchers: [
          [
            'IMG',
            // Prevent pasting image by insert blank data
            (node: any, delta: typeof Delta) => {
              const isBase64 = delta?.ops?.[0]?.insert?.image?.includes('base64');
              return isBase64 ? new Delta().insert('') : delta;
            }
          ]
        ]
      },
      imageUploader: {
        accept,
        upload: (file: File) =>
          new Promise((resolve, reject) => {
            dispatch(
              quillActions.uploadImageToEditor({
                params: {
                  file,
                  type
                },
                callback: resolve,
                errorCallback: reject
              })
            );
          })
      },
      imageResize: {
        modules: ['Resize', 'DisplaySize'],
        overlayStyles: {
          border: 'none'
        },
        displayStyles: false
      }
    }),
    [accept, dispatch, modules, type]
  );
  const setFilterImage = () => {
    dispatch(quillActions.setFilterImage(false));
  };
  return (
    <QuillEditor
      loading={isLoading || loading}
      formats={[...formats, 'image']}
      modules={moduleConfigs}
      stateSetFilterImage={getStateSetFilterImage}
      setFilterImage={setFilterImage}
      {...others}
    />
  );
};

export default QuillEditorWithImage;
