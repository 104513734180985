const common = {
  goToHome: '홈으로',
  goToDashboard: '대시보드로 이동',
  defaultTabTitle: 'MetaDesk',
  button: {
    new: '신규',
    create: '등록',
    save: '저장',
    discard: '취소',
    cancel: '취소',
    ok: '확인',
    search: '조회',
    checkDuplication: '중복확인',
    yes: '예',
    no: '아니오',
    resetPassword: '비밀번호 초기화',
    delete: '삭제',
    add: '추가',
    newTicket: '티켓 등록',
    back: '이전',
    apply: '적용',
    active: '사용중',
    inactive: '미사용',
    less: '접기',
    more: '펼치기',
    downloadExcel: '엑셀 다운로드',
    downloadPDF: 'PDF 다운로드',
    downloadWord: '워드 다운로드',
    privateTicket: '개인 티켓 설정',
    layoutTicket: '티켓 레이아웃 설정',
    button: '버튼',
    remove: '삭제',
    inviteUser: '사용자 초대',
    send: '사용자 초대',
    generate: '생성',
    sendEmail: '이메일 전송',
    permission: '권한',
    approve: '승인',
    reject: '반려',
    sendDeny: '전송',
    approveRequest: ' 승인요청',
    withdraw: '철회',
    backList: '목록',
    action: '버튼 권한',
    chooseFile: '파일 선택'
  },
  error: {
    required: '{{ field }}은/는 필수 항목입니다. ',
    invalid: '올바른 {{ field }}을/를 입력하세요.',
    invalidOrder: '정렬 순서가 이미 존재합니다. 다른 정렬 순서를 다시 선택하세요'
  },
  form: {
    validate: {
      lengthRange: '{{ field }}은/는 {{ min }}-{{ max }}자이어야 합니다.',
      maximum: '{{field}}은/는 {{length}}자 이하여야 합니다.',
      minimum: '{{field}} 은/는 {{length}}자 이상 포함되어 야 합니다.',
      validTime: '유효한 시간 형식의 값을 입력하세요.',
      validDate: '올바른 형식의 날짜를 입력해 주세요.',
      compareDate: '{{ startField }}은 {{ endField }} 보다 작아야합니다.',
      periodExceed30days: '최대 30일까지 조회 가능합니다.',
      periodExceed6months: '최대 6개월까지 조회 가능합니다.',
      periodExceedDynamicmonths: '최대 {{month}}개월까지 조회 가능합니다.',
      periodExceedYears: '최대 {{ years }}년까지 조회 가능합니다.',
      excludeCharacters: '파일명은 "{{ characters }}" 포함하면 안 됩니다.',
      maxTotalFileSize: '{{ maxSize }} 초과하였습니다.',
      allowExtensions: '{{ extensions }} 파일 형식만 허용됩니다.',
      maxFileSize: '{{ maxSize }} 이하 파일을 올려주세요.',
      atLeastOne: '최소 1개 이상의 {{field}}이/가 지정되어야 합니다.',
      fileSizeAndType: '{{fileSize}} 이하 {{fileType}} 파일을 올려주세요.',
      validNumber: '{{number}}보다 더 큰 정수를 입력해주세요.',
      changePassword: '8~16자 영문 대소문자, 숫자, 특수문자 (!, $, %, &, @)를 사용하세요.',
      userName: `사용자명은 영문 소문자, 숫자, '_', '-', '.'를 포함하여 5~16자여야 합니다 (단, '.', '-', '.git', '.atom'으로 끝날 수 없습니다.)`,
      inputValue: '데이터를 입력하십시오.'
    },
    field: {
      label: {
        number: '순번',
        username: '사용자명',
        email: '이메일',
        name: '이름',
        organization: '조직',
        department: '부서',
        title: '직급',
        phoneNumber: '연락처',
        role: '권한',
        status: '상태',
        creator: '등록자',
        creationDate: '등록일시',
        updater: '수정자',
        updateDate: '수정일시',
        orgName: '조직명',
        orgID: '조직ID',
        type: '유형',
        project: '프로젝트',
        projectID: '프로젝트 번호',
        projectName: '프로젝트명',
        projectType: '프로젝트 유형',
        contractNo: '계약 번호',
        contractName: '계약명',
        users: '사용자',
        announcementType: '공지사항 유형',
        publish: '게시',
        publishDate: '게시일',
        ticketType: '티켓 유형',
        workflowName: '워크플로우명',
        desc: '설명',
        text: '텍스트',
        integrationYn: '통합여부'
      }
    },
    select: {
      placeholder: '선택',
      all: '전체',
      selectUnselectAll: '전체 선택/해제'
    },
    searchInput: {
      placeholder: '입력'
    },
    discardWarning: '취소하시겠습니까?',
    deleteWarning: '삭제하시겠습니까?',
    saveWarning: '변경 내용을 저장하시겠습니까?',
    changeWarning: '저장하지 않은 변경 내용이 있습니다. 변경을 취소하시겠습니까?',
    noChangeWarning: '변경된 내용이 없습니다',
    unassigned: ' 미할당',
    datePicker: {
      selectDate: '날짜 선택'
    }
  },
  status: {
    active: '사용중',
    inactive: '미사용',
    available: '중복확인',
    yes: '예',
    no: '아니오',
    published: '게시',
    unpublished: '미게시',
    urgent: '긴급',
    normal: '일반',
    verified: '인증 완료',
    verify: '인증',
    pending: '대기'
  },
  table: {
    headerTitle: '조회 결과',
    export: {
      noData: '출력할 데이터가 없습니다.'
    }
  },
  waitVerify: '인증 대기중.',
  notFoundData: '데이터가 존재하지 않습니다.',
  loading: '로딩 중...',
  editorSearch: {
    post: {
      notFound: '{{searchTerm}}와/과 맞는 결과가 없습니다. 새 정보를 등록하십시오.'
    },
    guideline: {
      hashtag: '아래 댓글은 검색 키워드가 포함되어 있습니다.'
    }
  }
};

const error = {
  404: {
    title: '페이지를 찾을 수 없습니다!',
    description:
      '죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 입력하신 URL이 올바른지 확인해 주세요.'
  },
  403: {
    title: '접속 권한 없음',
    description1: '이 페이지를 볼 수 있는 권한을 가지고 있지 않습니다.',
    description2: '시스템 관리자에게 권한에 대해 문의 하십시오.'
  },
  network: '네트워크 상태를 확인해주세요.',
  internalServerError: '내부 서버 오류.',
  timeOut: {
    title: 'Timeout',
    description: '요청 제한 시간이 초과되었습니다.'
  },
  500: {
    title: '서비스를 사용할 수 없습니다',
    description: '이용에 불편을 드리는 점 양해 부탁드립니다.',
    subDescription: '문제가 계속되면 시스템 관리자에게 문의하십시오.'
  },
  maintenance: {
    title: '시스템 업그레이드',
    description:
      '더 나은 사용자 환경을 제공하기 위해 시스템을 주기적으로 검토하고 지속적으로 개선합니다!'
  }
};

const table = {
  no: '순번',
  rowsPerPage: '페이지당 표시개수',
  totalResults: '전체',
  items: '건',
  notFound: '조회결과가 없습니다.',
  noMatchResults: '조회조건을 확인하시기 바랍니다.',
  column: {
    findColumn: '컬럼 찾기',
    showAll: '전체 보기',
    resetAll: '초기화'
  },
  toolbar: {
    excelDownload: '엑셀 다운로드',
    columnList: '컬럼 목록'
  },
  dataFilter: {
    reset: '초기화',
    filter: '적용'
  }
};

const file = {
  attachment: '첨부 파일',
  uploadPhoto: '이미지 올리기',
  updatePhoto: '이미지 변경',
  selectFiles: '파일 첨부',
  browse: '선택해주세요.',
  thoroughMachine: '컴퓨터에서 파일을',
  dropOrClick: '업로드 대상 파일을 드래그하거나 <2>{{ thoroughMachine }}<2/> <1>{{ browse }}</1>',
  limitation:
    '파일은 {{ maxFileSize }}, 등록 건수당 {{ maxRequestSize }}까지 업로드 가능하며 최대 {{maxFiles}}개 파일이 허용됩니다.',
  downloadSuccess: '파일이 다운로드되었습니다.',
  maxFiles: '업로드 파일의 총 개수가 20개를 초과합니다.'
};

const editor = {
  counterLabel: '{{ count }}자   (HTML 태그 포함)',
  enterLink: '링크입력',
  visitLink: 'URL주소',
  saveLink: '저장',
  editLink: '수정',
  removeLink: '제거',
  font: '폰트',
  heading: '제목',
  normal: '일반',
  fontFamily: {
    batang: '바탕',
    malgunGothic: '맑은 고딕'
  },
  // table controllers
  insertColumnRight: '오른쪽 열 1개 삽입',
  insertColumnLeft: '왼쪽 열 1개 삽입',
  insertRowUp: '위쪽 행 1개 삽입',
  insertRowDown: '아래쪽 행 1개 삽입',
  mergeCells: '셀 병합',
  unmergeCells: '셀 병합 취소',
  deleteColumn: '열 삭제',
  deleteRow: '행 삭제',
  deleteTable: '테이블 삭제',
  row: '행',
  col: '열',
  tooltip: {
    font: '폰트',
    header: '헤더',
    size: '크기',
    color: '색상',
    background: '배경',
    align: '정렬',
    bold: '굵게',
    italic: '이탤릭체',
    underline: '밑줄',
    strike: '스트라이크',
    codeBlock: '코드 블록',
    blockquote: '큰따옴표',
    link: '링크',
    image: '이미지',
    video: '비디오',
    formula: '수식',
    clean: '지움',
    list: {
      bullet: '글머리 기호 목록',
      number: '번호 매기기 목록'
    },
    indent: {
      decrease: '들여쓰기 감소',
      increase: '들여쓰기 증가'
    },
    script: {
      subscript: '아래 첨자',
      superscript: '위 첨자'
    },
    direction: '방향',
    undo: '실행 취소',
    redo: '다시 실행',
    betterTable: '더 나은 테이블'
  }
};

const lang = {
  ko: '한국어',
  en: '영어'
};

const success = {
  updated: '변경 내용이 저장되었습니다.',
  updateStatusTicket: '티켓 상태가 변경되었습니다'
};

export { common, error, table, file, editor, lang, success };
