import { ButtonProps } from '@mui/material';
import EditIconButton from 'components/@material-extend/buttons/EditIconButton';
import AuthButton from './AuthButton';

import { EDIT_BUTTON_ID } from '../../constants';

const AuthEditIconButton = (props: ButtonProps) => (
  <AuthButton btnId={EDIT_BUTTON_ID}>
    <EditIconButton {...props} />
  </AuthButton>
);

export default AuthEditIconButton;
