import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box } from '@mui/material';

const MLoadingButton = ({
  startIcon,
  loadingPosition = 'start',
  loading,
  ...others
}: LoadingButtonProps) => (
  <LoadingButton
    loadingPosition={startIcon ? loadingPosition : undefined}
    startIcon={startIcon || loading ? <Box sx={{ width: 16 }} /> : <span />}
    loading={loading}
    {...others}
  />
);

export default MLoadingButton;
