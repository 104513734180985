// material
import { Stack, Typography, CheckboxProps } from '@mui/material';

import { MCheckbox } from 'components/@material-extend';

export interface LabeledCheckboxProps extends CheckboxProps {
  label?: string;
}

const LabeledCheckbox = (props: LabeledCheckboxProps) => {
  const { label, checked, sx, ...others } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        ...sx,
        height: '100%'
      }}
    >
      <Typography component="span" fontWeight="bold">
        {label}
        <MCheckbox color="primary" checked={checked} {...others} />
      </Typography>
    </Stack>
  );
};

export default LabeledCheckbox;
