import { Expose } from 'class-transformer';
import { BASIC_DATE_FORMAT } from 'constants/common';

import BaseModel from 'models/BaseModel';
import { TransformDateModel } from 'utils/transformer';

export class TicketRelatesListModel extends BaseModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'type' })
  type: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'ticketId' })
  ticketId: number | undefined;

  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'allowDelYn' })
  allowDelYn: string | undefined;

  @Expose({ name: 'statusName' })
  statusName: string | undefined;

  @Expose({ name: 'assignName' })
  assignName: string | undefined;

  @Expose({ name: 'projCicdName' })
  projCicdName: string | undefined;

  @Expose({ name: 'typeName' })
  typeName: string | undefined;

  @Expose({ name: 'targetCompDate' })
  @TransformDateModel(BASIC_DATE_FORMAT)
  targetCompDate: number | undefined;
}

export class AddTicketRelateModel extends BaseModel {
  @Expose({ name: 'ticketId' })
  ticketId: number | undefined;

  @Expose({ name: 'ticketRelationIds' })
  ticketRelationIds: number[] | undefined;
}

export class DeleteTicketRelateModel extends BaseModel {
  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'ticketId' })
  ticketId: number | undefined;
}
