import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import { FULL_DATE_FORMAT } from 'constants/common';
import dayjs from 'dayjs';

export class ProjectApplicationModel extends BaseModel {
  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'appVersionId' })
  appVersionId: string | undefined;

  @Expose({ name: 'application' })
  @Transform((v) => v && ApplicationObjModel.toClass(v.value))
  application: any | undefined;
}

export class ApplicationObjModel extends BaseModel {
  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'applicationVersion' })
  @Transform((v) => v && ApplicationVersionObjModel.toClass(v.value))
  applicationVersion: any | undefined;
}

export class ApplicationVersionObjModel extends BaseModel {
  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'releaseDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  releaseDate: string | undefined;

  @Expose({ name: 'serverDetail' })
  serverDetail: string | undefined;
}
