import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { LANGS } from 'constants/common';
import i18next from 'i18next';
// utils
import getDefaultLang from '../utils/getDefaultLang';

// ----------------------------------------------------------------------

export type LocalizationKey = 'ko' | 'en';

export default function useLocales() {
  const { t, i18n } = useTranslation();
  const langStorage = 'ko' || getDefaultLang(localStorage.getItem('i18nextLng'));

  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  useEffect(() => {
    if (i18next.language === 'en-US') {
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    dayjs.locale(newlang); // Update dayjs locale
  };

  return {
    t,
    onChangeLang: handleChangeLanguage,
    currentLang,
    allLang: LANGS
  };
}
