import { Box, Typography, DialogTitle, Stack } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

import { DialogAnimate } from 'components/animate';
import { YesButton, NoButton, DiscardButton } from 'components/@material-extend';

export const ButtonActionsStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3)
}));

export const DialogRootStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3)
}));

type CommonDialogProps = {
  title?: any;
  children: any;
  isOpenDialog: boolean;
  onCloseDialog?: VoidFunction;
  onYes?: (event: any) => void;
  onNo?: (event: any) => void;
  onDiscard?: (event: any) => void;
};

const AlertDialog = ({
  title,
  isOpenDialog,
  onCloseDialog,
  children,
  onNo,
  onYes,
  onDiscard
}: CommonDialogProps) => (
  <DialogAnimate
    open={isOpenDialog}
    onClose={onCloseDialog}
    maxWidth="xs"
    onClick={(e: any) => {
      e.stopPropagation();
    }}
  >
    <DialogTitle>
      <Typography color="primary">{title}</Typography>
    </DialogTitle>
    <DialogRootStyle>
      {children}
      <ButtonActionsStyle>
        <Stack direction={{ xs: 'row' }} spacing={{ xs: 2 }}>
          {onDiscard && <DiscardButton onClick={onDiscard} />}
          {onNo && <NoButton onClick={onNo} />}
          {onYes && <YesButton onClick={onYes} />}
        </Stack>
      </ButtonActionsStyle>
    </DialogRootStyle>
  </DialogAnimate>
);

export default AlertDialog;
