import React from 'react';
import get from 'lodash/get';
import htmlParse from 'html-react-parser';
import { experimentalStyled as styled } from '@mui/material/styles';

import { IHistoryMessage } from 'models/LocaleModel';
import useLocales from 'hooks/useLocales';
import { CHANGE_TYPE_NOTIFICATION, SERVICE_TYPE } from 'features/notifications/constants';

type MessageNotificationProps = {
  message: IHistoryMessage;
  type: string;
  readYn: boolean;
};

const Message = styled('p')(() => ({
  '&.unSelect': {
    'strong,b': {
      fontWeight: 400
    }
  }
}));

const MessageNotification = ({ message, type, readYn }: MessageNotificationProps) => {
  const {
    t,
    currentLang: { value }
  } = useLocales();
  const messageArray = get(message, [value], []);
  return (
    <Message className={`${readYn ? 'unSelect' : ''}`}>
      {messageArray.map((msg: string, index: number) => (
        <React.Fragment key={index}>
          {type === CHANGE_TYPE_NOTIFICATION.UPDATE_FIELD && msg === SERVICE_TYPE
            ? t('helpDesk.ticketManagement.form.technicalSolution')
            : htmlParse(msg)}
        </React.Fragment>
      ))}
    </Message>
  );
};

export default MessageNotification;
