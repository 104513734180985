import { createSlice } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import get from 'lodash/get';

import { errorHandler } from 'store/errorHandlerSaga';
import { loadingActions } from 'components/loading/slices';

import {
  fetchNotifications,
  getUnreadNumber,
  updateReadAllNotifications,
  updateStatusNotification
} from './apis';
import { NotificationDataModel } from './models';

const initialState = {
  dataAll: {},
  dataUnread: {},
  notificationsHeader: {},
  notificationsHeaderLoading: false,
  numberUnread: 0,
  actionsSucceed: false
};

const notificationSliceName = 'notifications';

const notificationsSlice = createSlice({
  name: notificationSliceName,
  initialState,
  reducers: {
    fetchAllNotificationsSuccess: (state, { payload }) => {
      state.dataAll = payload;
    },
    fetchNotificationsUnreadSuccess: (state, { payload }) => {
      state.dataUnread = payload;
    },
    fetchNotificationsHeaderSuccess: (state, { payload }) => {
      state.notificationsHeader = payload;
    },
    modifyNotificationsHeaderLoading: (state, { payload }) => {
      state.notificationsHeaderLoading = payload;
    },
    setNumberUnread: (state, { payload }) => {
      state.numberUnread = payload;
    },
    setActionSucceed: (state, { payload }) => {
      state.actionsSucceed = payload;
    }
  }
});

const { actions: reducerActions, reducer: notificationsReducer } = notificationsSlice;

const notificationsSliceSaga = createSliceSaga({
  name: notificationSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *fetchAllNotifications(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { data: resNotification } = yield call(fetchNotifications, action.payload);
        const notifications = NotificationDataModel.toClass(resNotification.data);
        yield put(reducerActions.fetchAllNotificationsSuccess(notifications));
        yield put(reducerActions.setActionSucceed(false));
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchNotificationUnread(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { data: resNotification } = yield call(fetchNotifications, action.payload);
        const notifications = NotificationDataModel.toClass(resNotification.data);
        yield put(reducerActions.fetchNotificationsUnreadSuccess(notifications));
        yield put(reducerActions.setActionSucceed(false));
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchNotificationHeader(action): any {
      try {
        yield put(reducerActions.modifyNotificationsHeaderLoading(true));
        const { data: resNotification } = yield call(fetchNotifications, action.payload);
        const notifications = NotificationDataModel.toClass(resNotification.data);
        yield put(reducerActions.fetchNotificationsHeaderSuccess(notifications));
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(reducerActions.modifyNotificationsHeaderLoading(false));
      }
    },
    *updateNotification(action): any {
      try {
        yield call(updateStatusNotification, action.payload);
        const numberUnread = yield select((state) =>
          get(state, [notificationSliceName, 'numberUnread'])
        );
        yield put(reducerActions.setNumberUnread(numberUnread - 1));
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *updateReadAllNotification(): any {
      try {
        yield put(loadingActions.showLoading());
        const { data } = yield call(updateReadAllNotifications);
        if (data) {
          yield put(reducerActions.setNumberUnread(0));
          yield put(reducerActions.setActionSucceed(true));
        }
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *getUnreadNumber(): any {
      try {
        const { data } = yield call(getUnreadNumber);
        yield put(reducerActions.setNumberUnread(data.data));
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  }
});

const { saga: notificationsSaga, actions: sagaActions } = notificationsSliceSaga;

const notificationActions = {
  ...reducerActions,
  ...sagaActions
};
export {
  notificationSliceName,
  notificationActions,
  notificationsReducer,
  notificationsSaga,
  initialState
};
