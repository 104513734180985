import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class RoleModel extends BaseModel {
  @Expose({ name: 'roleCode' })
  roleCode: string | undefined;

  @Expose({ name: 'roleName' })
  roleName: string | undefined;

  @Expose({ name: 'parentRole' })
  parentRole: string | undefined;
}
