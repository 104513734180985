import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class AssignedUserModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'username' })
  username: string | undefined;
}
