import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as auth from 'utils/authHelper';

import {
  getAccessToken,
  getCurrentCorpCode,
  getUserLoggedAt,
  getRefreshToken
} from 'utils/authHelper';
import {
  CHANGE_PASSWORD_URL,
  DASHBOARD_URI,
  FORBIDDEN_URI,
  NOT_PRIVATE_ROUTES,
  SELECT_CORP_URI,
  SIGN_IN_URI
} from 'constants/routes';
import { useAuth } from 'features/auth/useAuth';
import { authSliceName, authActions, initialState } from 'features/auth/slices';
import LoadingScreen from 'components/LoadingScreen';
import dayjs from 'dayjs';

const PrivateRoute = ({ comp: Component }: { comp: any }) => {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const { isAuthenticated, isExpired, needChangePassword } = useAuth();
  const [tokenChecked, setTokenChecked] = useState(false);
  const [waitForChecking, setWaitForChecking] = useState(true);
  const accessToken = getAccessToken();
  const corpCode = getCurrentCorpCode();

  const currentMenuCode = useSelector((state) =>
    get(state, [authSliceName, 'currentMenuCode'], initialState.currentMenuCode)
  );

  const navigateCorp = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'navigateCorp'], []),
    shallowEqual
  );
  const corpUserInfo = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'corpUserInfo'], []),
    shallowEqual
  );

  const userMenuCodes: Array<string> = useSelector((state) => {
    const permissions = get(state, [authSliceName, 'userInfo', 'buttonPermission'], []);
    return permissions.map((menu: any) => get(menu, ['menuCode'], ''));
  }, shallowEqual);

  const allMenuCodes = useSelector(
    (state) => get(state, [authSliceName, 'menuCodes'], []),
    shallowEqual
  );

  const foundMenu = useMemo(
    () =>
      get(
        allMenuCodes.find((item: any) => item.menuPath === pathname),
        'menuCode'
      ),
    [pathname, allMenuCodes]
  );
  const logUserOut = useCallback(() => {
    const currentAccessToken = getAccessToken();

    if (currentAccessToken) {
      dispatch(authActions.autoLogout({ navigate }));
    }
    navigate(SIGN_IN_URI, { state: location });
    auth.clearUserCredential();
  }, [navigate, location, dispatch]);

  const healthCheckToken = useCallback(() => {
    setWaitForChecking(true);
    const currentAccessToken = getAccessToken();
    const currentCorpCode = getCurrentCorpCode();
    if (currentAccessToken) {
      if (currentCorpCode) {
        dispatch(
          authActions.healthCheckToken({
            corpCode: currentCorpCode,
            callback: () => {
              setTokenChecked(true);
            }
          })
        );
      }
    } else {
      logUserOut();
    }
  }, [dispatch, logUserOut]);

  useEffect(() => {
    if (userMenuCodes.length && allMenuCodes.length) {
      const currentMenuCode =
        pathname === DASHBOARD_URI
          ? ''
          : get(
              allMenuCodes.find((item: any) => item.menuPath === pathname),
              'menuCode',
              ''
            );
      dispatch(authActions.updateCurrentMenuCode(currentMenuCode));
    }
  }, [allMenuCodes, userMenuCodes, pathname, dispatch]);

  useEffect(() => {
    if (
      (currentMenuCode === '' || currentMenuCode) &&
      tokenChecked &&
      waitForChecking &&
      !isEmpty(accessToken)
    ) {
      setWaitForChecking(false);
    }
  }, [currentMenuCode, tokenChecked, waitForChecking, accessToken]);

  useEffect(() => {
    const userLoggedAt = Number(getUserLoggedAt());
    const corpCode = getCurrentCorpCode();
    const refreshToken = getRefreshToken();
    switch (true) {
      case !isAuthenticated || isExpired: {
        logUserOut();
        break;
      }
      case needChangePassword: {
        navigate(CHANGE_PASSWORD_URL);
        break;
      }
      case dayjs(userLoggedAt).add(25, 'm').valueOf() <= dayjs().valueOf(): {
        dispatch(
          authActions.refreshToken({
            corpCode,
            refreshToken,
            callback: healthCheckToken
          })
        );
        break;
      }
      default: {
        healthCheckToken();
      }
    }
  }, [
    isAuthenticated,
    isExpired,
    needChangePassword,
    dispatch,
    healthCheckToken,
    logUserOut,
    navigate
  ]);

  useEffect(() => {
    if (
      accessToken &&
      !corpCode &&
      ((navigateCorp && navigateCorp.length > 1) || isEmpty(corpUserInfo))
    ) {
      navigate(SELECT_CORP_URI);
    } else if (corpCode) {
      dispatch(authActions.fetchVerifyPhone(corpCode));
    }
  }, [accessToken, navigate, dispatch, corpCode, navigateCorp, corpUserInfo]);

  if (waitForChecking) return <LoadingScreen isFullScreen />;

  if (NOT_PRIVATE_ROUTES.includes(pathname)) return Component;

  if (userMenuCodes.length && !userMenuCodes.includes(foundMenu))
    return <Navigate to={FORBIDDEN_URI} />;

  return Component;
};
export default PrivateRoute;
