import { ACTIVE_YN } from 'constants/commonCodes';
import { OptionNotificationsType } from './types';

export const initialOption: OptionNotificationsType = {
  ticketCreated: {
    sendKakaoYn: ACTIVE_YN.No,
    sendMailYn: ACTIVE_YN.No
  },

  ticketAssigned: {
    sendKakaoYn: ACTIVE_YN.No,
    sendMailYn: ACTIVE_YN.No
  },

  ticketUpdated: {
    sendKakaoYn: ACTIVE_YN.No,
    sendMailYn: ACTIVE_YN.No
  },

  ticketStatus: {
    sendKakaoYn: ACTIVE_YN.No,
    sendMailYn: ACTIVE_YN.No
  },

  ticketComment: {
    sendKakaoYn: ACTIVE_YN.No,
    sendMailYn: ACTIVE_YN.No
  },

  accountRegistrationRequest: {
    sendKakaoYn: ACTIVE_YN.No,
    sendMailYn: ACTIVE_YN.No
  }
};
export const SEND_KAKAO = 'sendKakaoYn';
export const SEND_EMAIL = 'sendMailYn';
