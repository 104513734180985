import { Typography } from '@mui/material';

type AppVersionProps = {
  isBreakline?: boolean;
};
const AppVersion = ({ isBreakline = false }: AppVersionProps) =>
  isBreakline ? (
    <>
      <Typography align="center">
        COPYRIGHT © MetanetDigital <br /> ALL RIGHTS RESERVED
      </Typography>
    </>
  ) : (
    <Typography align="center">COPYRIGHT © MetanetDigital ALL RIGHTS RESERVED</Typography>
  );

export default AppVersion;
