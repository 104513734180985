import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import get from 'lodash/get';
// material
import { Theme } from '@mui/material/styles';
import { Box, Drawer, useTheme, Divider, Tooltip } from '@mui/material';
// components
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import DynamicMenu from 'containers/DynamicMenu';
import { MHidden } from 'components/@material-extend';
import AppVersion from 'components/AppVersion';
import { APP_VERSION } from 'constants/appConfig';
import { clearSearchInTicket } from 'utils/authHelper';

import { authSliceName } from 'features/auth/slices';
// ----------------------------------------------------------------------

import outlineFormatIndentDecrease from '@iconify/icons-ic/outline-format-indent-decrease';
import outlineFormatIndentIncrease from '@iconify/icons-ic/outline-format-indent-increase';

import {
  RootStyle,
  ButtonCollapseStyle,
  IconStyle,
  CorpNameStyle,
  LabelVersion,
  CorpNameStyled,
  LogoCorp,
  WrapperLogoCorpName
} from './styles';

import { DRAWER_WIDTH, DRAWER_COLLAPSE_WIDTH } from './constants';
// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  isExpandMenu: boolean;

  onCloseSidebar: VoidFunction;
  onCollapseExpandMenu: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isExpandMenu,
  onCollapseExpandMenu
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const theme = useTheme<Theme>();

  const corpList = useSelector((state) => get(state, [authSliceName, 'userInfo', 'corpList'], {}));

  const currentCorpCode = useSelector((state) =>
    get(state, [authSliceName, 'currentCorpCode'], {})
  );

  const currentMenu = useSelector((state) => get(state, [authSliceName, 'currentMenuCode'], ''));

  const currentCorp = useMemo(
    () => corpList.find((item: any) => item.corpCode === currentCorpCode),
    [corpList, currentCorpCode]
  );

  const { corpName, logoUrl } = currentCorp || {};

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // CLEAR SEARCH VALUE TICKET WHEN CHANGE MENU
  useEffect(() => {
    if (currentMenu !== 'TICK') {
      clearSearchInTicket();
    }
  }, [currentMenu]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: isExpandMenu ? 2.5 : 3.5, py: 3, pb: 0 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', textDecoration: 'unset' }}>
          <Logo noLogoText={!isExpandMenu} sx={{ maxWidth: 175 }} />
        </Box>
        <LabelVersion>{APP_VERSION}</LabelVersion>
        {corpName && (
          <Divider sx={{ mb: 3 }} variant="middle">
            <>
              {corpName?.length >= 15 ? (
                <Tooltip title={corpName}>
                  <WrapperLogoCorpName>
                    {logoUrl ? (
                      <LogoCorp>
                        <img src={logoUrl} alt="" />
                      </LogoCorp>
                    ) : null}
                    <CorpNameStyle theme={theme} styleProps={{ isExpandMenu }} variant="subtitle2">
                      {corpName}
                    </CorpNameStyle>
                  </WrapperLogoCorpName>
                </Tooltip>
              ) : (
                <WrapperLogoCorpName>
                  {logoUrl ? (
                    <LogoCorp>
                      <img src={logoUrl} alt="" />
                    </LogoCorp>
                  ) : null}
                  <CorpNameStyled>{corpName}</CorpNameStyled>
                </WrapperLogoCorpName>
              )}
            </>
          </Divider>
        )}
      </Box>
      {/* Sidebar Menu */}
      <DynamicMenu isExpandMenu={isExpandMenu} />
      <Divider />
      <Box sx={{ px: isExpandMenu ? 2.5 : 3.5, py: 3, pb: 2 }}>
        <AppVersion isBreakline />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle theme={theme} styleProps={{ isExpandMenu }}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isExpandMenu ? DRAWER_WIDTH : DRAWER_COLLAPSE_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
          <ButtonCollapseStyle
            onClick={onCollapseExpandMenu}
            styleProps={{ isExpandMenu }}
            theme={theme}
          >
            <IconStyle
              icon={isExpandMenu ? outlineFormatIndentDecrease : outlineFormatIndentIncrease}
            />
          </ButtonCollapseStyle>
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
