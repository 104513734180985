import { createSlice } from '@reduxjs/toolkit';
import { SELECT_CORP_URI, DASHBOARD_URI, SIGN_IN_URI, CHANGE_PASSWORD_URL } from 'constants/routes';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import isFunction from 'lodash/isFunction';
import { call, put, select, delay } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import {
  setExpiredAt,
  setAccessToken,
  setRefreshToken,
  setRememberFeature,
  clearUserCredential,
  setCurrentCorpCode,
  getCurrentCorpCode,
  getRefreshToken,
  getExpiredAt,
  setAfterLogin,
  getAferLogin,
  removeAfterLogin,
  setNeedChangePass,
  getCurrentUser,
  setUserName
} from 'utils/authHelper';
import toastHelper from 'helpers/toast';
import { ACTIVE_YN } from 'constants/commonCodes';
import { errorHandler } from 'store/errorHandlerSaga';
import { loadingActions } from 'components/loading/slices';
import { fetchMenuPath } from 'features/system/MenuManagement/apis';
import { MenuPathModel, GetMenuPathModel } from 'features/system/MenuManagement/models';
import {
  DynamicContentModel,
  DynamicContentDetailModel
} from 'features/system/DynamicContentManagement/models';
import * as dynamicContentApis from 'features/system/DynamicContentManagement/apis';
import { getVerifyType } from 'features/Register/apis';
import { checkHaveVerify } from 'features/Register/helpers';

import * as authApis from './apis';

import {
  LoginModel,
  AuthorizedUserModel,
  ChangePwdModel,
  UserInfoModel,
  CorpListModel,
  ForgotPwdModel,
  DashboardSettingModel
} from './models';
import { FORM_TABS } from './constants';

const authSliceName = 'user';

export const AUTH_STATE = {
  currentMenuCode: null
};

const initialState = {
  isLoading: false,
  error: null,
  isConnectSocket: true,
  userInfo: {
    corpList: [],
    menuList: [],
    corpUserInfo: {},
    buttonPermission: [],
    token: {},
    navigateChangePwd: false,
    navigateCorp: []
  },
  currentCorpCode: getCurrentCorpCode(), // Get current cord code in local storage at the initial state
  formStatus: FORM_TABS.loginForm,
  tokenChecked: false,
  menuCodes: [],
  dashboardSettings: [],
  isSubmittingSettingForm: false,
  currentMenuCode: null,
  isShowImportLicense: false,
  roleUserLogin: null,
  selectCorpSetting: null,
  token: '', // save token to renew header WS,
  verifyPhone: false,
  overWriteYn: null
};

const authSlice = createSlice({
  name: authSliceName,
  initialState,
  reducers: {
    formStatus: (state, action) => {
      state.formStatus = action.payload;
    },
    loginProcessing: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
    loginFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    setConnectSocket: (state, action) => {
      state.isConnectSocket = action.payload;
    },
    changePwdProcessing: (state) => {
      state.isLoading = true;
    },
    changePwdSuccess: (state) => {
      state.isLoading = false;
    },
    changePwdFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    getUserInfoProcessing: (state) => {
      state.isLoading = true;
    },
    getUserInfoSuccess: (state, action) => {
      state.isLoading = false;
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
    getUserInfoFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    getCorpListProcessing: (state) => {
      state.isLoading = true;
    },
    getCorpListSuccess: (state, action) => {
      state.isLoading = false;
      state.userInfo.corpList = action.payload;
    },
    getCorpListFailure: (state) => {
      state.isLoading = true;
    },
    selectCurrentCorp: (state, action) => {
      state.currentCorpCode = action.payload;
    },
    updateMenuCodes: (state, action) => {
      state.menuCodes = action.payload;
    },
    modifyCurrentMenuCode: (state, action) => {
      state.currentMenuCode = action.payload;
    },
    getDashboardSettingsSuccess: (state, action) => {
      state.dashboardSettings = action.payload;
    },
    modifySubmittingSettingFormStatus: (state, action) => {
      state.isSubmittingSettingForm = action.payload;
    },
    setShowImportLicense: (state, action) => {
      state.isShowImportLicense = action.payload;
    },
    setRoleUserLogin: (state, action) => {
      state.roleUserLogin = action.payload;
    },
    setSelectCorpSetting: (state, action) => {
      state.selectCorpSetting = action.payload;
    },
    setTokenStore: (state, action) => {
      state.token = action.payload;
    },
    setVerifyPhone: (state, action) => {
      state.verifyPhone = action.payload;
    },
    setOverwriteYn: (state, action) => {
      state.overWriteYn = action.payload;
    },
    setNavigateChangePassword: (state, action) => {
      state.userInfo.navigateChangePwd = action.payload;
    },
    clearData: () => initialState
  }
});
const { actions: reducerActions, reducer: authReducer } = authSlice;

const authSliceSaga = createSliceSaga({
  name: authSliceName,
  caseSagas: {
    *login(action): any {
      try {
        // if user was logged out because the token has been expired
        // only storage data were cleared
        // it will not trigger clearing auth state
        // so we must always clear data before calling login api
        yield put(reducerActions.clearData());

        yield put(loadingActions.showLoading());
        yield put(reducerActions.loginProcessing());

        const { redirectState } = action.payload;

        const loginParam = LoginModel.toPlain(action.payload.loginParams);
        const { data } = yield call(authApis.login, loginParam);

        const overWriteYn = get(data, ['data', 'overWriteYn']);

        if (overWriteYn === ACTIVE_YN.Yes) {
          yield put(reducerActions.setOverwriteYn(overWriteYn));
          return;
        }

        if (data) {
          setUserName(loginParam.username);
          const { navigate, isRemember, loginParams } = action.payload;
          setRememberFeature(isRemember, loginParams.userName);
          const user = AuthorizedUserModel.toClass(data.data);
          const tokenInfo = {
            accessToken: get(user, ['token', 'accessToken']),
            refreshToken: get(user, ['token', 'refreshToken'])
          };
          // set token to localStorage
          setAccessToken(tokenInfo.accessToken);
          setAfterLogin(1);
          yield put(reducerActions.setTokenStore(tokenInfo.accessToken));

          if (tokenInfo.refreshToken) {
            setRefreshToken(tokenInfo.refreshToken);
          }

          yield put(reducerActions.loginSuccess(user));
          // history

          const navigateChangePwd = get(user, 'navigateChangePwd');
          const navigateCorp = get(user, 'navigateCorp');

          // Navigate to Change pwd page
          if (navigateChangePwd) {
            // yield put(reducerActions.formStatus(FORM_TABS.changePwd));
            setNeedChangePass(navigateChangePwd);
            navigate(CHANGE_PASSWORD_URL, { state: redirectState });
          }
          // If user belong to only 1 Corp
          // Get the user info and navigate to homepage
          else if (navigateCorp.length === 1) {
            yield put(
              sagaActions.changeCurrentCorp({ corpCode: navigateCorp[0], navigate, redirectState })
            );
          }
          // Otherwise navigate to select Corp page
          else {
            navigate(SELECT_CORP_URI, { state: redirectState });
          }
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.loginFailure(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *changePwd(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield put(reducerActions.changePwdProcessing());
        const { changePwdParams, navigate, redirectState } = action.payload;
        const userInfo = yield select((state) => get(state, [authSliceName, 'userInfo'], {}));

        const changePwdParam = ChangePwdModel.toPlain(changePwdParams);
        yield call(authApis.changePwd, changePwdParam);
        // If changing pwd success navigate to Homepage or Select Corp
        // depend on the number of Corporations which this user belong to
        toastHelper.success('login.successMess.changePwd');
        yield put(reducerActions.setNavigateChangePassword(false));
        setNeedChangePass(false);
        if (userInfo.navigateCorp && userInfo.navigateCorp.length === 1) {
          yield put(
            sagaActions.changeCurrentCorp({
              corpCode: userInfo.navigateCorp[0],
              navigate,
              redirectState
            })
          );
        } else {
          navigate(SELECT_CORP_URI, { state: redirectState });
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.changePwdFailure(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *forgotPwd(action): any {
      try {
        yield put(loadingActions.showLoading());
        const forgotPwdParam = ForgotPwdModel.toPlain(action.payload);
        yield call(authApis.forgotPwd, forgotPwdParam);
        toastHelper.success('login.successMess.forgotPwd');
        yield put(reducerActions.formStatus(FORM_TABS.loginForm));
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *getUserInfo(action): any {
      try {
        yield put(reducerActions.getUserInfoProcessing());
        const currentCorp = getCurrentCorpCode();
        // Choose the first corp in the list as default
        if (!currentCorp) {
          const { data: responseData } = yield call(authApis.getCorpList);
          const corpList: any = CorpListModel.toClass(responseData.data);
          yield put(reducerActions.getCorpListSuccess(corpList));
          const defaultCorpCode = get(corpList, '[0].corpCode', '');
          // Store default corp
          setCurrentCorpCode(defaultCorpCode);
          // Change corpCode in app state
          yield put(reducerActions.selectCurrentCorp(defaultCorpCode));
        }

        const expiredAt = getExpiredAt();

        const { data: responseData } = yield call(authApis.getUserInfo);
        const userInfoModel = UserInfoModel.toClass(responseData.data);
        yield put(reducerActions.getUserInfoSuccess(userInfoModel));
        yield put(reducerActions.setConnectSocket(true));

        if (!expiredAt) {
          const timeout = get(userInfoModel, ['corpUserInfo', 'timeOut']);
          setExpiredAt(timeout);
        }

        // const { data: menuPath } = yield call(fetchMenuPath);
        // if (menuPath) {
        //   yield put(reducerActions.updateMenuCodes(menuPath.data));
        // }

        const { redirectState, navigate } = action.payload;
        if (isFunction(navigate)) {
          redirectState
            ? navigate(redirectState.pathname + redirectState.search)
            : // Navigate to Dashboard
              navigate(DASHBOARD_URI);
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.getUserInfoFailure(error));
      }
    },
    *logout(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield call(authApis.logout);
        const { navigate } = action.payload;
        yield put(reducerActions.clearData());
        yield delay(2000);
        clearUserCredential();
        navigate(SIGN_IN_URI);
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *postUserLogout(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { navigate, value } = action.payload;
        yield call(authApis.postLogout, value);
        yield put(reducerActions.clearData());
        yield delay(2000);
        clearUserCredential();
        navigate(SIGN_IN_URI);
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *autoLogout(action: any): any {
      try {
        const { navigate } = action.payload;
        yield call(authApis.logout);
        yield put(reducerActions.clearData());
        yield delay(1000);
        clearUserCredential();
        if (navigate) {
          navigate(SIGN_IN_URI);
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *getSelectCorpSetting(action): any {
      try {
        const { data: responseData } = yield call(
          dynamicContentApis.fetchDynamicContent,
          DynamicContentModel.toPlain(action.payload)
        );
        if (responseData) {
          yield put(
            reducerActions.setSelectCorpSetting(
              DynamicContentDetailModel.toClass(responseData.data)
            )
          );
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *getCorpList(action: { payload: { showLoading: boolean; callback?: Function } }): any {
      try {
        const { showLoading, callback } = action.payload;
        if (showLoading) {
          yield put(loadingActions.showLoading());
        }

        const { data: responseData } = yield call(authApis.getCorpList);
        const corpList = CorpListModel.toClass(responseData.data);
        yield put(reducerActions.getCorpListSuccess(corpList));

        if (typeof callback === 'function') callback();
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *modifyCorpCode(action): any {
      try {
        const { navigate, corpCode, redirectState } = action.payload;
        yield put(reducerActions.selectCurrentCorp(corpCode));
        yield put(sagaActions.getUserInfo({ navigate, redirectState }));
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *changeCurrentCorp(action): any {
      const currentCorpCode = yield select((state) =>
        get(state, [authSliceName, 'currentCorpCode'], null)
      );
      try {
        yield put(loadingActions.showLoading());

        const { corpCode, isPassUser } = action.payload;
        setCurrentCorpCode(corpCode);

        const refreshToken = getRefreshToken();
        if (refreshToken && corpCode) {
          const afterLogin = getAferLogin();
          const value: any = {
            corpCode,
            refreshToken
          };

          if (isPassUser) {
            value.username = getCurrentUser();
          }

          if (afterLogin === '1') {
            value.afterLogin = true;
          }

          const { data: healthCheckTokenRes } = yield call(authApis.healthCheckToken, value);
          removeAfterLogin();
          if (healthCheckTokenRes.data) {
            const { accessToken } = healthCheckTokenRes.data;
            if (accessToken) {
              setAccessToken(accessToken);
              yield put(reducerActions.setTokenStore(accessToken));
            }
            yield put(sagaActions.modifyCorpCode(action.payload));
          } else {
            yield put(sagaActions.modifyCorpCode(action.payload));
          }
        } else {
          yield put(sagaActions.modifyCorpCode(action.payload));
        }
      } catch (error) {
        // reset to the previous corp code if error
        if (currentCorpCode) setCurrentCorpCode(currentCorpCode);
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *healthCheckToken(action): any {
      try {
        const {
          payload: { callback, corpCode }
        } = action;

        const { data: healthCheckTokenRes } = yield call(authApis.healthCheckToken, { corpCode });

        if (healthCheckTokenRes.data) {
          const { accessToken } = healthCheckTokenRes.data;
          if (accessToken) {
            setAccessToken(accessToken);
            yield put(reducerActions.setTokenStore(accessToken));
          }
          yield put(sagaActions.getUserInfo({}));
          yield put(sagaActions.getMenuCodes({ activeYn: ACTIVE_YN.Yes }));
          callback && callback();
        } else {
          yield put(sagaActions.logout({}));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *refreshToken(action): any {
      try {
        const { corpCode, refreshToken, callback } = action.payload;
        const { data: refreshTokenRes } = yield call(authApis.refreshToken, {
          corpCode,
          refreshToken
        });

        if (refreshTokenRes.data) {
          const { accessToken } = refreshTokenRes.data;
          if (accessToken) {
            yield put(reducerActions.setTokenStore(accessToken));
            setAccessToken(accessToken);
          }
          yield put(sagaActions.getUserInfo({}));
          yield put(sagaActions.getMenuCodes({ activeYn: ACTIVE_YN.Yes }));
          callback && callback();
        }
      } catch (error) {
        // do nothing
      }
    },
    *getDashboardSettings(): any {
      try {
        const { data: dashBoardSettingsRes } = yield call(authApis.getDashboardSettings);
        if (dashBoardSettingsRes) {
          yield put(
            reducerActions.getDashboardSettingsSuccess(
              DashboardSettingModel.toClass(dashBoardSettingsRes.data)
            )
          );
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *updateDashboardSettings(action): any {
      try {
        yield put(reducerActions.modifySubmittingSettingFormStatus(true));
        const { data: dashBoardSettingsRes } = yield call(
          authApis.updateDashboardSettings,
          DashboardSettingModel.toPlain(action.payload.params)
        );
        if (dashBoardSettingsRes) {
          yield put(reducerActions.modifySubmittingSettingFormStatus(false));
          yield put(sagaActions.getDashboardSettings());
          action.payload.callback();
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifySubmittingSettingFormStatus(false));
      }
    },
    *getMenuCodes(action): any {
      try {
        const { data: menuPath } = yield call(
          fetchMenuPath,
          GetMenuPathModel.toPlain(action.payload)
        );
        if (menuPath) {
          yield put(reducerActions.updateMenuCodes(MenuPathModel.toClass(menuPath.data)));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *updateCurrentMenuCode(action): any {
      try {
        yield put(reducerActions.modifyCurrentMenuCode(action.payload));
        set(AUTH_STATE, 'currentMenuCode', action.payload);
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *fetchVerifyPhone(action): any {
      try {
        const corpCode = action.payload;

        const { data } = yield call(getVerifyType, {
          corpCode,
          codes: ['CNT_AU_MHT']
        });
        if (!isEmpty(data?.data)) {
          const subCodeList = get(data, ['data', 'CNT_AU_MHT', 'subCodeList']) || [];
          const haveVetify = checkHaveVerify(subCodeList);
          yield put(reducerActions.setVerifyPhone(haveVetify));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  },
  sagaType: SagaType.TakeLatest
});
const { saga: authSaga, actions: sagaActions } = authSliceSaga;

const authActions = { ...reducerActions, ...sagaActions };

export { initialState, authSliceName, authActions, authReducer, authSaga, authSliceSaga };
