import { Expose, Transform } from 'class-transformer';
import BaseModel from 'models/BaseModel';
import { TicketFormModel } from '../../TicketForm/models';

export class BoardTicketModel extends BaseModel {
  @Expose({ name: 'data' })
  @Transform(({ value }) => {
    const objectEntries = Object.entries(value);
    const newValue = objectEntries.map((item: any) => {
      const convertValue = BoardTicketValueModel.toClass(item[1]);
      return {
        projectCode: item[0],
        data: convertValue
      };
    });
    return newValue;
  })
  data: Array<any> | undefined;
}

export class BoardTicketValueModel extends BaseModel {
  @Expose({ name: 'types' })
  @Transform(({ value }) => {
    const newValue = value.map((item: any) => BoardTicketTypesModel.toClass(item));
    return newValue;
  })
  types: Array<any> | undefined;

  @Expose({ name: 'tickets' })
  @Transform(({ value }) => {
    const newValue = value.map((item: any) => TicketFormModel.toClass(item));
    return newValue;
  })
  tickets: Array<any> | undefined;
}

export class BoardTicketTypesModel extends BaseModel {
  @Expose({ name: 'groupCode' })
  groupCode: string | undefined;

  @Expose({ name: 'code' })
  code: string | undefined;

  @Expose({ name: 'name' })
  name: any | undefined;
}
