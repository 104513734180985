import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';

// dayjs locales
import 'dayjs/locale/ko';
import 'dayjs/locale/en';

//
import en from './en';
import kr from './kr';

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: { kr, en },
      fallbackLng: {
        ko: ['kr', 'en'],
        default: ['ko']
      },
      lng: 'ko',
      debug: false,
      interpolation: {
        escapeValue: false
      },
      detection: { order: ['localStorage', 'navigator'] }
    },
    () => {
      // Init dayjs locale
      dayjs.locale(i18n.language);
    }
  );

export default i18n;
