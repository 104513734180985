import { useContext } from 'react';

import { AppContext } from 'contexts/AppProvider';

type ShowAlertProps = {
  message: string;
  warningOnly?: boolean;
  onYes?: VoidFunction;
  onNo?: VoidFunction;
};

export const useAlert = () => {
  const { setAlertDialogProps, hideDialog } = useContext(AppContext);

  const showAlert = ({ message, warningOnly, onYes, onNo }: ShowAlertProps) => {
    setAlertDialogProps({
      message,
      isOpenDialog: true,
      onYes: () => {
        hideDialog();
        onYes && onYes();
      },
      onNo: warningOnly
        ? null
        : () => {
            hideDialog();
            onNo && onNo();
          }
    });
  };

  return { showAlert };
};
