import apiClient from 'apis/apiClient';
import {
  VIEW_TREE_URL,
  GET_MENU_PATH,
  GET_ALL_MENU_PATH,
  DELETE_MENU,
  MENU_URL
} from 'constants/apiUrl';

export const fetchTreeData = () =>
  apiClient
    .post(VIEW_TREE_URL)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchMenuPath = (params?: any) =>
  apiClient
    .post(GET_MENU_PATH, params || {})
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchAllMenuPathForSelect = (params?: any) =>
  apiClient
    .post(GET_ALL_MENU_PATH, params || {})
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const deleteMenu = (params: any) =>
  apiClient
    .post(DELETE_MENU, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const createMenu = (params: any) =>
  apiClient
    .post(MENU_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const editMenu = (params: any) =>
  apiClient
    .put(MENU_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
