import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import get from 'lodash/get';

import { errorHandler } from 'store/errorHandlerSaga';
import {
  UserBriefInformationModel,
  UserFullInformationModel,
  UserInformationSearchModel
} from './models';
import { fetchUserInformation } from './apis';

const initialState = {
  isLoading: false,
  // multi users
  multiUserInfo: {},
  // single users
  singleUserInfo: {}
};

const userInformationSliceName = 'userInformation';

const userInformationSlice = createSlice({
  name: userInformationSliceName,
  initialState,
  reducers: {
    showUserInfoLoading: (state) => {
      state.isLoading = true;
    },
    stopUserInfoLoading: (state) => {
      state.isLoading = false;
    },
    fetchUserInformationSuccess: (state, { payload }) => {
      state.multiUserInfo = { ...state.multiUserInfo, ...payload };
    },
    fetchUserFullInformationSuccess: (state, { payload }) => {
      state.singleUserInfo = payload;
    },
    clearFullData: (state) => {
      state.singleUserInfo = initialState.singleUserInfo;
    },
    clearData: () => initialState
  }
});

const { actions: reducerActions, reducer: userInformationReducer } = userInformationSlice;

const userInformationSliceSaga = createSliceSaga({
  name: userInformationSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *fetchUserInformation(action): any {
      try {
        yield put(reducerActions.showUserInfoLoading());

        const plainedParams = UserInformationSearchModel.toPlain(action.payload);
        const username = get(plainedParams, ['username']);

        const { data: userRes } = yield call(fetchUserInformation, plainedParams);
        if (userRes) {
          const userData = UserBriefInformationModel.toClass(userRes.data);
          yield put(reducerActions.fetchUserInformationSuccess({ [username]: userData }));
        }
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(reducerActions.stopUserInfoLoading());
      }
    },
    *fetchUserFullInformation(action): any {
      try {
        yield put(reducerActions.showUserInfoLoading());

        const plainedParams = UserInformationSearchModel.toPlain(action.payload);

        const { data: userRes } = yield call(fetchUserInformation, plainedParams);
        if (userRes) {
          const userData = UserFullInformationModel.toClass(userRes.data);
          yield put(reducerActions.fetchUserFullInformationSuccess(userData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(reducerActions.stopUserInfoLoading());
      }
    }
  }
});

const { saga: userInformationSaga, actions: sagaActions } = userInformationSliceSaga;

const userInformationActions = {
  ...reducerActions,
  ...sagaActions
};
export {
  userInformationSliceName,
  userInformationActions,
  userInformationReducer,
  userInformationSaga,
  initialState
};
