import { useCallback, useContext, useEffect } from 'react';

import { MESSAGE_TYPE, SocketContext } from 'contexts/SocketProvider';
import { SOCKET_DUPLICATE_SESSION } from 'constants/apiUrl';

import { authActions } from 'features/auth/slices';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { FORCE_LOGOUT, LOGOUT_DUPLICATE } from 'constants/common';
import { SIGN_IN_URI } from 'constants/routes';
import { getAccessToken, getCurrentCorpCode, getCurrentUser } from 'utils/authHelper';

const ShowDuplicateLogin = () => {
  const { subscribe, unsubscribe, handleChangeChannel } = useContext(SocketContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    sessionStorage.setItem(FORCE_LOGOUT, LOGOUT_DUPLICATE);
    dispatch(
      authActions.postUserLogout({
        navigate,
        value: {
          username: getCurrentUser(),
          key: '',
          token: getAccessToken(),
          corpCode: getCurrentCorpCode()
        }
      })
    );
    navigate(SIGN_IN_URI);
  }, [navigate, dispatch]);

  useEffect(() => {
    handleChangeChannel([MESSAGE_TYPE.USER_KICKOUT]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    subscribe({
      name: MESSAGE_TYPE.USER_KICKOUT,
      url: SOCKET_DUPLICATE_SESSION,
      onChange: handleLogout
    });
  }, [handleLogout, subscribe]);

  useEffect(
    () => () => {
      unsubscribe(MESSAGE_TYPE.USER_KICKOUT);
    },
    [unsubscribe]
  );

  return <></>;
};

export default ShowDuplicateLogin;
