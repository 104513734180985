// eslint-disable-next-line max-classes-per-file
import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import { ccuHistory, SearchCriteria } from '../types';

export class CUserAndHisModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && CUserAndHisList.toClass(item.value))
  ccuHistory: Array<ccuHistory> | undefined;

  @Expose({ name: 'totalElements' })
  totalElements: number | undefined;
}

export class CUserAndHisSearchModel extends BaseModel {
  @Expose({ name: 'direction' })
  direction: string | undefined;

  @Expose({ name: 'itemPerPage' })
  itemPerPage: number | undefined;

  @Expose({ name: 'pageNum' })
  pageNum: number | undefined;

  @Expose({ name: 'sortBy' })
  sortBy: string | undefined;

  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteria | undefined;
}

export class CUserAndHisList extends BaseModel {
  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'deltaRate' })
  deltaRate: number | undefined;

  @Expose({ name: 'deltaCount' })
  deltaCount: number | undefined;

  @Expose({ name: 'userCount' })
  userCount: number | undefined;

  @Expose({ name: 'createdDate' })
  createdDate: string | undefined;

  @Expose({ name: 'updatedDate' })
  updatedDate: string | undefined;

  @Expose({ name: 'remark' })
  remark: string | undefined;
}
