import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { errorHandler } from 'store/errorHandlerSaga';
import { authActions } from 'features/auth/slices';

import { updateStatus } from './apis';

const initialState = {
  actionsSucceed: false
};

const userStatusSliceName = 'userStatusSliceName';

const userStatusSlice = createSlice({
  name: userStatusSliceName,
  initialState,
  reducers: {
    modifyInternalSharingStatus: (state, { payload }) => {
      state.actionsSucceed = payload;
    }
  }
});

const { actions: reducerActions, reducer: userStatusReducer } = userStatusSlice;

const userStatusSliceSaga = createSliceSaga({
  name: userStatusSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *updateUserStatus(action): any {
      try {
        yield call(updateStatus, action.payload);
        yield put(reducerActions.modifyInternalSharingStatus(true));
        yield put(authActions.getUserInfo({}));
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  }
});

const { actions: sagaActions, saga: userStatusSaga } = userStatusSliceSaga;

const userStatusActions = {
  ...reducerActions,
  ...sagaActions
};

export { userStatusSliceName, userStatusActions, userStatusReducer, userStatusSaga, initialState };
