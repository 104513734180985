import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class StatusModel extends BaseModel {
  @Expose({ name: 'code' })
  value: string | undefined;

  @Expose({ name: 'name' })
  title: any | undefined;

  @Expose({ name: 'groupCode' })
  type: string | undefined;
}

export class GetCommonCodeByMultiProjectModel extends BaseModel {
  @Expose({ name: 'projCodes' })
  projCodes: string | undefined;
}

export class IntegratedProjectModel extends BaseModel {
  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'projCicdName' })
  projCicdName: string | undefined;

  @Expose({ name: 'projCicdCode' })
  projCicdCode: string | undefined;
}
