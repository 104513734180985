import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import useDeepCompareEffect from 'use-deep-compare-effect';

// material
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormCard } from 'components/layouts';

// hook form
import { useFormContext } from 'react-hook-form';
import { RHFController } from 'components/hook-form';
import UploadFile from 'components/upload/UploadFile';
import useLocales from 'hooks/useLocales';

import TechnicalSolutionItem from '../TechnicalSolutionItem';
import {
  TECH_SOLUTION_FIELD_PREFIX,
  MAX_NUMBER_OF_TECHNICAL_SOLUTIONS,
  TECH_SOLUTION_FIELD_LIST,
  ATTACHMENT_ACCEPTED_TYPES,
  TECT_SOLUTION_FILES
} from '../../constants';

const TechnicalSolutionForm = () => {
  const { t } = useLocales();

  const [counter, setCounter] = useState(0);
  const { setValue, getValues, watch } = useFormContext();

  const currentTechSolutions = getValues(TECH_SOLUTION_FIELD_LIST);

  const currentTechFiles = getValues(TECT_SOLUTION_FILES);

  const listWatchFile = TECT_SOLUTION_FILES.map((item) => watch(item));

  useDeepCompareEffect(() => {
    const nonEmptyFields = currentTechSolutions.filter((item: string) => !isEmpty(item));

    if (nonEmptyFields.length - 1 > counter) {
      setCounter(nonEmptyFields.length - 1);
    }
  }, [counter, currentTechSolutions]);

  const onAddMore = () => {
    counter < MAX_NUMBER_OF_TECHNICAL_SOLUTIONS && setCounter(counter + 1);
  };

  const handleRemoveField = (currentIndex: string | number) => {
    // The following fields will be pushed to the front
    const currentValues = [...currentTechSolutions];
    for (let i = Number(currentIndex); i < MAX_NUMBER_OF_TECHNICAL_SOLUTIONS; i++) {
      // The item at `i` will be replaced by the next item or empty string
      setValue(`${TECH_SOLUTION_FIELD_PREFIX}${i}`, currentValues[i + 1] || '');

      setValue(`tech${i + 1}Files`, listWatchFile[i + 1]);
    }

    // Then update counter
    counter > 0 && setCounter(counter - 1);
  };

  const handleRemoveTechFile = (fileRemove: any, index: number) => {
    const filteredItems = currentTechFiles[index].filter(({ id }: any) => id !== fileRemove.id);
    setValue(`tech${index + 1}Files`, filteredItems);
  };

  return (
    <Box sx={{ p: 2, pr: 0, width: '100%' }}>
      {[...Array(MAX_NUMBER_OF_TECHNICAL_SOLUTIONS)].map((_, index: number) => {
        // Do not show index for the first techSolution field if there is just one
        const firstFieldIndex = counter > 0 ? 0 : '';

        return (
          counter >= index && (
            <FormCard>
              <TechnicalSolutionItem
                key={index}
                isPrimary={index === 0}
                index={index !== 0 ? index : firstFieldIndex}
                name={`${TECH_SOLUTION_FIELD_PREFIX}${index}`}
                onRemove={handleRemoveField}
                onChangeValue={setValue}
              />
              <RHFController name={`t${index + 1}Files`}>
                {({ field: { ref, ...otherFieldProps } }: any) => (
                  <UploadFile
                    accept={ATTACHMENT_ACCEPTED_TYPES}
                    {...otherFieldProps}
                    onChange={setValue}
                    initialFiles={listWatchFile[index]}
                    onRemoveInitialFile={(fileValue) => handleRemoveTechFile(fileValue, index)}
                  />
                )}
              </RHFController>
            </FormCard>
          )
        );
      })}

      <Box sx={{ mt: 2 }}>
        <Button
          onClick={onAddMore}
          disabled={counter >= MAX_NUMBER_OF_TECHNICAL_SOLUTIONS - 1}
          color="primary"
          variant="contained"
        >
          <AddIcon />
          {t('helpDesk.ticketManagement.form.moreTechSol')}
        </Button>
      </Box>
    </Box>
  );
};

export default TechnicalSolutionForm;
