import { StringMap } from './interfaces';

export const FONT_FAMILY_OPTIONS = [
  'Arial',
  'Malgun Gothic',
  'Consolas',
  'Batang',
  'Georgia',
  'Verdana',
  'Public Sans'
];

export const DEFAULT_FONT_SIZE = '14px';
export const FONT_SIZE_OPTIONS = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px'
];

export const DEFAULT_FORMATS = [
  'font',
  'size',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'list',
  'indent',
  'script',
  'blockquote',
  'bullet',
  'code',
  'align',
  'link',
  'better-table',
  'image',
  'video'
];

export const DEFAULT_COMMENT_FORMATS = [
  'font',
  'size',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'list',
  'indent',
  'script',
  'blockquote',
  'bullet',
  'code',
  'align',
  'link',
  'better-table',
  'mention'
];

export const DEFAULT_TOOLBAR_OPTIONS = {
  id: 'quill',
  fontFamilies: FONT_FAMILY_OPTIONS,
  fontSizes: FONT_SIZE_OPTIONS
};

export const INITIAL_TABLE_ROW_COL = {
  row: 1,
  col: 1
};

export const TABLE_MODULE_NAME = 'better-table';
export const COL_ATTRIBUTES: string[] = ['width'];
export const COL_DEFAULT: StringMap = {
  width: 100
};
export const CELL_IDENTITY_KEYS: string[] = ['row', 'cell'];
export const CELL_ATTRIBUTES: string[] = ['rowspan', 'colspan'];
export const CELL_DEFAULT: StringMap = {
  rowspan: 1,
  colspan: 1
};
export const ERROR_LIMIT: number = 5;
export const DATA_INFO_ATTRIBUTE = 'data-info';
export const DATA_KEYWORD_ATTRIBUTE = 'data-keyword';
export const DATA_LANGUAGE = 'data-language';
export const PROPS_CHILDREN = 'children';
export const PROPS_CLASS_NAME = 'className';
export const CODE_BLOCK_CLASS_NAME = 'ql-code-block-container';
