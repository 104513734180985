import { useState, useCallback, useMemo, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { Trans } from 'react-i18next';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

// material
import { alpha, Theme, experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';

// hooks
import useLocales from 'hooks/useLocales';

import { isFilenameValid } from 'utils/validationHelper';
import { getExtensionsByAccept, getTotalSizeOfFiles, isFile } from 'utils/helpers';
import { formatDataSize } from 'utils/formatNumber';
import { ViewFile } from 'interfaces/ViewFile';
import {
  MAX_UPLOAD_SIZE_10MB,
  MAX_UPLOAD_SIZE_20MB,
  MAX_UPLOAD_SIZE_10MB_STRING_FORMAT,
  MAX_UPLOAD_SIZE_20MB_STRING_FORMAT,
  SPECIAL_CHARACTERS,
  MAX_FILES
} from 'constants/common';

import AttachedList, { FileInfo } from './AttachedList';
import { ThumbnailSizeType } from './AttachedItem';

const isExceedingMaxSize = ({
  currentFile,
  otherFiles,
  maxTotalSize
}: {
  currentFile?: File;
  otherFiles: File[];
  maxTotalSize: number;
}): boolean => {
  const totalSize = getTotalSizeOfFiles(otherFiles) + (currentFile?.size || 0);
  return totalSize > maxTotalSize;
};

// ----------------------------------------------------------------------
// Styles
const RootStyle = styled('div')(({ theme }: { theme: Theme }) => ({
  width: '100%'
}));

const DropZoneStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  width: '100%',
  height: 90,
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: theme.typography.pxToRem(8),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
}));

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': { opacity: 0.72 }
}));

const TypographyWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 0,
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  textAlign: 'center',
  '.titleTypography': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    lineHeight: theme.typography.pxToRem(30)
  },
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    flexDirection: 'column',
    marginLeft: theme.typography.pxToRem(40),
    '.titleTypography': {
      fontSize: theme.typography.pxToRem(16)
    }
  }
}));

export const LinkStyle = styled('span')(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main
}));

// ----------------------------------------------------------------------

export type FileValidator = {
  code: string;
  message: string;
  /** The condition for file to be valid after dropping */
  validator: (file: File) => boolean;
};

type FileLimitationMessage = {
  maxFileSize: string | number;
  maxRequestSize: string | number;
  maxFiles: string | number;
};

interface UploadFileProps extends DropzoneOptions {
  name?: string;
  error?: boolean;
  sx?: SxProps<Theme>;
  onUpload?: (allFiles: File[]) => void;
  onDownload?: (file: any) => void;
  /** Acts as setValue in useHookForm */
  onChange?: (field: string, value: File[], shouldValidate?: boolean) => void;
  thumbnailSize?: ThumbnailSizeType;
  initialFiles?: ViewFile[]; // Files for editing, Download & Remove only
  onRemoveInitialFile?: (viewFile: any) => void;
  validators?: FileValidator[];
  maxTotalSize?: number | boolean;

  /** Custom error messages */
  rejectionMessage?: string | string[];
  /** Error message shown when uploaded file exceeds max size config */
  maxSizeRejectionMessage?: string;
  /** Error message shown when uploaded file does not have accepted type */
  acceptRejectionMessage?: string;
  maxTotalSizeRejectionMessage?: string;
  limitationMessage?: FileLimitationMessage;
  isDiscard?: boolean;
  setIsDiscard?: any;
  styleDropZoneCustom?: any;
}

function UploadFile({
  error,
  rejectionMessage,
  sx,
  multiple = true,
  thumbnailSize = 'lg',
  name,
  onDrop,
  onUpload,
  onDownload,
  onChange,
  initialFiles = [],
  onRemoveInitialFile,
  validators,
  accept = '',
  maxSizeRejectionMessage = '',
  maxSize = MAX_UPLOAD_SIZE_10MB,
  maxTotalSize = MAX_UPLOAD_SIZE_20MB,
  maxTotalSizeRejectionMessage = '',
  acceptRejectionMessage = '',
  limitationMessage = {
    maxFileSize: MAX_UPLOAD_SIZE_10MB_STRING_FORMAT,
    maxRequestSize: MAX_UPLOAD_SIZE_20MB_STRING_FORMAT,
    maxFiles: MAX_FILES
  },
  isDiscard, // check discard when upload component in form not in popup - page user guide setting
  setIsDiscard,
  styleDropZoneCustom,
  ...others
}: UploadFileProps) {
  const theme = useTheme<Theme>();

  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const [currentFileInfo, setCurrentFileInfo] = useState<FileInfo[]>([]);

  useEffect(() => {
    if (isDiscard) {
      setCurrentFileInfo([]);
      setIsDiscard(false);
    }
  }, [isDiscard, setIsDiscard]);

  const { t } = useLocales();

  const handleChange = useCallback(
    (fileInfo: FileInfo[]) => {
      const files = fileInfo.map(({ file }) => file);
      if (onUpload) onUpload(files);
      if (onChange && name) onChange(name, files);
    },
    [name, onChange, onUpload]
  );

  const handleDrop = useCallback(
    (acceptedFiles, fileRejections, event) => {
      const newFiles = acceptedFiles.map((file: File) => ({
        file,
        id: `${file.name}-${new Date().getTime()}`
      }));
      const info = [...currentFileInfo, ...newFiles];
      setCurrentFileInfo(info);

      if (onDrop) onDrop(acceptedFiles, fileRejections, event);
      handleChange(info);
    },
    [currentFileInfo, handleChange, onDrop]
  );

  const handleCustomValidate = (file: File) => {
    if (validators) {
      for (const val of validators) {
        const { code, message, validator }: FileValidator = val;
        if (!validator(file)) {
          return {
            code,
            message
          };
        }
      }
    }
    if (!isFilenameValid(file)) {
      return {
        code: 'invalid-filename',
        message: t('common.form.validate.excludeCharacters', {
          characters: SPECIAL_CHARACTERS
        })
      };
    }

    return null;
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    maxSize,
    accept,
    onDrop: handleDrop,
    validator: handleCustomValidate,
    ...others
  });
  /**
   * Check if all recently uploaded files
   * exceeds the max total size or not
   */
  const isRecentFilesTooLarge = useMemo(() => {
    const files = currentFileInfo.map(({ file }) => file);
    return (
      isNumber(maxTotalSize) &&
      isExceedingMaxSize({
        otherFiles: files,
        maxTotalSize
      })
    );
  }, [maxTotalSize, currentFileInfo]);

  const handleShowFirstErrorMessage = () => {
    if (isEmpty(fileRejections)) return '';

    const lastErrorMessage = fileRejections[0]?.errors[0];

    // Customized Message
    switch (lastErrorMessage.code) {
      case 'invalid-filename':
        return t('common.form.validate.excludeCharacters', {
          characters: SPECIAL_CHARACTERS
        });
      case 'file-too-large':
        return !isEmpty(maxSizeRejectionMessage)
          ? maxSizeRejectionMessage
          : t('common.form.validate.maxFileSize', {
              maxSize: maxSize && formatDataSize(maxSize)
            });
      case 'file-invalid-type':
        return !isEmpty(acceptRejectionMessage)
          ? acceptRejectionMessage
          : t('common.form.validate.allowExtensions', {
              extensions: getExtensionsByAccept(accept)
            });
    }
    return lastErrorMessage?.message;
  };
  const ShowRejectionMessage = () => {
    const maxTotalSizeRejection =
      isRecentFilesTooLarge &&
      (!isEmpty(maxTotalSizeRejectionMessage)
        ? maxTotalSizeRejectionMessage
        : t('common.form.validate.maxTotalFileSize', {
            maxSize: limitationMessage.maxRequestSize
          }));

    if (maxTotalSizeRejection) {
      return (
        <Paper
          variant="outlined"
          sx={{
            py: 2,
            px: 2,
            my: 2,
            textAlign: 'center',
            borderColor: 'error.light',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
          }}
        >
          <Typography variant="caption" component="p">
            {maxTotalSizeRejection}
          </Typography>
        </Paper>
      );
    }
    if (currentFileInfo.length + initialFiles.length > MAX_FILES) {
      return (
        <Paper
          variant="outlined"
          sx={{
            py: 2,
            px: 2,
            my: 2,
            textAlign: 'center',
            borderColor: 'error.light',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
          }}
        >
          <Typography variant="caption" component="p">
            {t('file.maxFiles')}
          </Typography>
        </Paper>
      );
    }

    return !isEmpty(fileRejections) ? (
      <Paper
        variant="outlined"
        sx={{
          py: 2,
          px: 2,
          my: 2,
          textAlign: 'center',
          borderColor: 'error.light',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
        }}
      >
        <Typography variant="caption" component="p">
          {!isEmpty(rejectionMessage) ? rejectionMessage : handleShowFirstErrorMessage()}
        </Typography>
      </Paper>
    ) : null;
  };

  const handleRemove = (targetFile: any) => {
    if (isFile(targetFile)) {
      const filteredItems = currentFileInfo.filter(({ file, id }: FileInfo) => file !== targetFile);
      setCurrentFileInfo(filteredItems);
      handleChange(filteredItems);
    } else {
      !!onRemoveInitialFile && onRemoveInitialFile(targetFile);
    }
  };

  return (
    <>
      <RootStyle theme={theme} sx={sx}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            }),
            height: styleDropZoneCustom && styleDropZoneCustom.height
          }}
        >
          <input {...getInputProps()} />

          <PlaceholderStyle className="placeholder">
            <TypographyWrapperStyle className="messageUploadForm">
              <Typography variant="caption" component="div">
                <Trans
                  i18nKey="file.dropOrClick"
                  values={{
                    browse: t('file.browse'),
                    thoroughMachine: t('file.thoroughMachine')
                  }}
                  components={{ 1: <LinkStyle />, 2: matchMd ? <span /> : <span /> }}
                />
              </Typography>
              <Typography variant="caption" component="p" sx={{ ml: 0.5 }}>
                {t('file.limitation', limitationMessage)}
              </Typography>
            </TypographyWrapperStyle>
            <TypographyWrapperStyle sx={{ padding: 1 }}>
              <Typography component="div">
                {t('common.form.validate.allowExtensions', {
                  extensions: getExtensionsByAccept(accept)
                })}
              </Typography>
            </TypographyWrapperStyle>
          </PlaceholderStyle>
        </DropZoneStyle>

        <ShowRejectionMessage />

        <AttachedList
          initialFiles={initialFiles}
          currentFileInfo={currentFileInfo}
          onRemove={handleRemove}
          onDownload={onDownload}
          thumbnailSize={thumbnailSize}
          canRemove
        />
      </RootStyle>
    </>
  );
}

export default UploadFile;
