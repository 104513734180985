import { Expose } from 'class-transformer';
import { VersionModel } from './ProjectModel';

export class ApplicationVersionModel extends VersionModel {
  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'originalFileName' })
  originalFileName: string | undefined;
}
