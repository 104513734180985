import Quill from 'quill';
import forOwn from 'lodash/forOwn';
import merge from 'lodash/merge';
import i18next from 'i18next';

import QuillTableOperationMenu from 'quill-better-table/src/modules/table-operation-menu';

import {
  css,
  getColToolCellIndexByBoundary,
  getColToolCellIndexesByBoundary,
  getRelativeRect
} from '../helpers';
import { TableCell, TableRow } from './TableBlots';
import { StringMap } from '../interfaces';
import { ERROR_LIMIT, TABLE_MODULE_NAME } from '../constants';

const t = i18next.t.bind(i18next);

const MENU_MIN_HEIGHT = 150;
const MENU_WIDTH = 200;
const MENU_ITEMS_DEFAULT: StringMap = {
  insertColumnRight: {
    text: t('editor.insertColumnRight'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      const colIndex = getColToolCellIndexByBoundary(
        // @ts-ignore
        this.columnToolCells,
        // @ts-ignore
        this.boundary,
        (cellRect, boundary) => Math.abs(cellRect.x + cellRect.width - boundary.x1) <= ERROR_LIMIT,
        // @ts-ignore
        this.quill.root.parentNode
      );

      const newColumn = tableContainer?.insertColumn(
        // @ts-ignore
        this.boundary,
        colIndex,
        true,
        // @ts-ignore
        this.quill.root.parentNode
      );

      if (!newColumn) return;

      // @ts-ignore
      this.tableColumnTool.updateToolCells();
      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.quill.setSelection(this.quill.getIndex(newColumn[0]), 0, Quill.sources.SILENT);
      // @ts-ignore
      this.tableSelection?.setSelection(
        newColumn[0].domNode.getBoundingClientRect(),
        newColumn[0].domNode.getBoundingClientRect()
      );
    }
  },

  insertColumnLeft: {
    text: t('editor.insertColumnLeft'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      const colIndex = getColToolCellIndexByBoundary(
        // @ts-ignore
        this.columnToolCells,
        // @ts-ignore
        this.boundary,
        (cellRect, boundary) => Math.abs(cellRect.x - boundary.x) <= ERROR_LIMIT,
        // @ts-ignore
        this.quill.root.parentNode
      );

      const newColumn = tableContainer?.insertColumn(
        // @ts-ignore
        this.boundary,
        colIndex,
        false,
        // @ts-ignore
        this.quill.root.parentNode
      );

      if (!newColumn) return;

      // @ts-ignore
      this.tableColumnTool.updateToolCells();
      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.quill.setSelection(this.quill.getIndex(newColumn[0]), 0, Quill.sources.SILENT);
      // @ts-ignore
      this.tableSelection?.setSelection(
        newColumn[0].domNode.getBoundingClientRect(),
        newColumn[0].domNode.getBoundingClientRect()
      );
    }
  },

  insertRowUp: {
    text: t('editor.insertRowUp'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      const affectedCells = tableContainer?.insertRow(
        // @ts-ignore
        this.boundary,
        false,
        // @ts-ignore
        this.quill.root.parentNode
      );

      if (!affectedCells) return;

      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.quill.setSelection(this.quill.getIndex(affectedCells[0]), 0, Quill.sources.SILENT);
      // @ts-ignore
      this.tableSelection?.setSelection(
        affectedCells[0].domNode.getBoundingClientRect(),
        affectedCells[0].domNode.getBoundingClientRect()
      );
    }
  },

  insertRowDown: {
    text: t('editor.insertRowDown'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      const affectedCells = tableContainer?.insertRow(
        // @ts-ignore
        this.boundary,
        true,
        // @ts-ignore
        this.quill.root.parentNode
      );

      if (!affectedCells) return;

      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.quill.setSelection(this.quill.getIndex(affectedCells[0]), 0, Quill.sources.SILENT);
      // @ts-ignore
      this.tableSelection?.setSelection(
        affectedCells[0].domNode.getBoundingClientRect(),
        affectedCells[0].domNode.getBoundingClientRect()
      );
    }
  },

  mergeCells: {
    text: t('editor.mergeCells'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      // compute merged Cell rowspan, equal to length of selected rows
      const rowspan = tableContainer?.rows().reduce((sum: number, row: TableRow) => {
        const rowRect = getRelativeRect(
          row.domNode.getBoundingClientRect(),
          // @ts-ignore
          this.quill.root.parentNode
        );
        if (
          rowRect.y > this.boundary.y - ERROR_LIMIT &&
          rowRect.y + rowRect.height < this.boundary.y + this.boundary.height + ERROR_LIMIT
        ) {
          sum += 1;
        }
        return sum;
      }, 0);

      // compute merged cell colspan, equal to length of selected cols
      const colspan = this.columnToolCells.reduce((sum: number, cell: TableCell) => {
        // @ts-ignore
        const cellRect = getRelativeRect(cell.getBoundingClientRect(), this.quill.root.parentNode);
        if (
          cellRect.x > this.boundary.x - ERROR_LIMIT &&
          cellRect.x + cellRect.width < this.boundary.x + this.boundary.width + ERROR_LIMIT
        ) {
          sum += 1;
        }
        return sum;
      }, 0);

      const mergedCell = tableContainer?.mergeCells(
        // @ts-ignore
        this.boundary,
        // @ts-ignore
        this.selectedTds,
        rowspan,
        colspan,
        // @ts-ignore
        this.quill.root.parentNode
      );

      if (!mergedCell) return;
      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.tableSelection?.setSelection(
        mergedCell.domNode.getBoundingClientRect(),
        mergedCell.domNode.getBoundingClientRect()
      );
    }
  },

  unmergeCells: {
    text: t('editor.unmergeCells'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      // @ts-ignore
      tableContainer?.unmergeCells(this.selectedTds, this.quill.root.parentNode);
      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.tableSelection?.clearSelection();
    }
  },

  deleteColumn: {
    text: t('editor.deleteColumn'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      const colIndexes = getColToolCellIndexesByBoundary(
        // @ts-ignore
        this.columnToolCells,
        // @ts-ignore
        this.boundary,
        (cellRect, boundary) =>
          cellRect.x + ERROR_LIMIT > boundary.x &&
          cellRect.x + cellRect.width - ERROR_LIMIT < boundary.x1,
        // @ts-ignore
        this.quill.root.parentNode
      );

      const isDeleteTable = tableContainer?.deleteColumns(
        // @ts-ignore
        this.boundary,
        colIndexes,
        // @ts-ignore
        this.quill.root.parentNode
      );

      if (!isDeleteTable) {
        // @ts-ignore
        this.tableColumnTool.updateToolCells();
        // @ts-ignore
        this.quill.update(Quill.sources.USER);
        // @ts-ignore
        this.tableSelection?.clearSelection();
      }
    }
  },

  deleteRow: {
    text: t('editor.deleteRow'),
    handler() {
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      // @ts-ignore
      tableContainer?.deleteRow(this.boundary, this.quill.root.parentNode);
      // @ts-ignore
      this.quill.update(Quill.sources.USER);
      // @ts-ignore
      this.tableSelection?.clearSelection();
    }
  },

  deleteTable: {
    text: t('editor.deleteTable'),
    handler() {
      // @ts-ignore
      const betterTableModule = this.quill.getModule(TABLE_MODULE_NAME);
      // @ts-ignore
      const tableContainer = Quill.find(this.table);
      betterTableModule.hideTableTools();
      tableContainer?.remove();
      // @ts-ignore
      this.quill.update(Quill.sources.USER);
    }
  }
};

type TableOperationMenuParam = {
  table: any;
  row: TableRow;
  cell: TableCell;
  left: number;
  top: number;
};

class TableOperationMenu extends QuillTableOperationMenu {
  constructor(params: TableOperationMenuParam, quill: Quill, options: any) {
    super(params, quill, options);
    this.menuItems = merge(MENU_ITEMS_DEFAULT, options.items);
  }

  menuItemCreator({ text, iconSrc, handler }: any) {
    const node = document.createElement('div');
    node.classList.add('qlbt-operation-menu-item');

    const iconSpan = document.createElement('span');
    iconSpan.classList.add('qlbt-operation-menu-icon');
    iconSpan.innerHTML = iconSrc;

    const textSpan = document.createElement('span');
    textSpan.classList.add('qlbt-operation-menu-text');
    textSpan.innerText = text;

    node.appendChild(iconSpan);
    node.appendChild(textSpan);
    node.addEventListener('click', handler.bind(this), false);
    return node;
  }

  menuInitial({ table, left, top }: TableOperationMenuParam) {
    this.domNode = document.createElement('div');
    this.domNode.classList.add('qlbt-operation-menu');
    css(this.domNode, {
      position: 'absolute',
      left: `${left}px`,
      top: `${top - 100}px`,
      'min-height': `${MENU_MIN_HEIGHT}px`,
      width: `${MENU_WIDTH}px`
    });

    forOwn(this.menuItems, (value: any, key: string) => {
      if (value) {
        this.domNode.appendChild(this.menuItemCreator(merge(MENU_ITEMS_DEFAULT[key], value)));

        if (['insertRowDown', 'unmergeCells'].indexOf(key) > -1) {
          this.domNode.appendChild(dividingCreator());
        }
      }
    });

    // if colors option is false, disabled bg color
    if (this.options.color && this.options.color !== false) {
      this.domNode.appendChild(dividingCreator());
      this.domNode.appendChild(subTitleCreator(this.colorSubTitle));
      this.domNode.appendChild(this.colorsItemCreator(this.cellColors));
    }

    // create dividing line
    function dividingCreator() {
      const dividing = document.createElement('div');
      dividing.classList.add('qlbt-operation-menu-dividing');
      return dividing;
    }

    // create subtitle for menu
    function subTitleCreator(title: string) {
      const subTitle = document.createElement('div');
      subTitle.classList.add('qlbt-operation-menu-subtitle');
      subTitle.innerText = title;
      return subTitle;
    }
  }
}

export default TableOperationMenu;
