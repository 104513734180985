import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class DashboardSettingModel extends BaseModel {
  @Expose({ name: 'ticketProgRate' })
  ticketProgRate: string | undefined;

  @Expose({ name: 'ticketCreated' })
  ticketCreated: string | undefined;

  @Expose({ name: 'documentStatus' })
  documentStatus: string | undefined;

  @Expose({ name: 'contractStatus' })
  contractStatus: string | undefined;

  @Expose({ name: 'myRecentTicket' })
  myRecentTicket: string | undefined;

  @Expose({ name: 'announcement' })
  announcement: string | undefined;

  @Expose({ name: 'calendar' })
  calendar: string | undefined;

  @Expose({ name: 'inProgTicket' })
  inProgTicket: string | undefined;

  @Expose({ name: 'closeTicket' })
  closeTicket: string | undefined;

  @Expose({ name: 'unassigned' })
  unassigned: string | undefined;

  @Expose({ name: 'waitingForProgress' })
  waitingForProgress: string | undefined;
}
