import { Expose } from 'class-transformer';

import BaseModel from 'models/BaseModel';

export class UserBriefInformationModel extends BaseModel {
  @Expose({ name: 'email' })
  email: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'title' })
  title: string | undefined;

  @Expose({ name: 'phoneNo' })
  phoneNo: string | undefined;

  @Expose({ name: 'avatarUrl' })
  avatarUrl: string | undefined;

  @Expose({ name: 'orgName' })
  orgName: string | undefined;

  @Expose({ name: 'userStatus' })
  userStatus: string | undefined;
}

export class UserFullInformationModel extends UserBriefInformationModel {
  @Expose({ name: 'username' })
  username: string | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'deptName' })
  deptName: string | undefined;

  @Expose({ name: 'roleCode' })
  roleCode: string | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'roleName' })
  roleName: string | undefined;

  @Expose({ name: 'userStatus' })
  userStatus: any | undefined;
}
