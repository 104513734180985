import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class OrganizationModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'orgName' })
  orgName: string | undefined;
}
