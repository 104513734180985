import { ExportIcon } from 'assets';
import { IconButton, IconButtonProps } from '@mui/material';

// For automation test
const EXPORT_BTN = 'exportBtn';

const ExportButton = (props: IconButtonProps) => (
  <IconButton test-id={EXPORT_BTN} aria-label="export" {...props}>
    <ExportIcon />
  </IconButton>
);

export default ExportButton;
