import { Key } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { authSliceName } from 'features/auth/slices';
import useLocales from 'hooks/useLocales';
import { List, Box } from '@mui/material';
import { LocalizationKey, ILocaleString } from 'models/LocaleModel';
import { DASHBOARD_URI } from 'constants/routes';
import { ListSubheaderStyle, configIcons, getIcon } from './styles';
import NavItem, { NavItemProps } from './components/NavItem';

interface MenuItem {
  menuName: string;
  name: ILocaleString;
  menuCode: Key;
  path: string | null;
  subMenuList: MenuItem[];
  icon: string;
}

// Mapping the current lang and icon
const mapMenuItem = (menuList: MenuItem[], currentLangValue: LocalizationKey) => {
  const recursive = (menus: MenuItem[]) =>
    menus.map((m: MenuItem) => {
      const navItem: NavItemProps = {
        ...m,
        menuName: m.name[currentLangValue],
        icon: m.icon ? getIcon(m.icon) : null,
        subMenuList: m.subMenuList ? recursive(m.subMenuList) : []
      };
      return navItem;
    });

  return recursive(menuList);
};
type NavSectionProps = {
  isExpandMenu: boolean;
};
export default function NavSection({ isExpandMenu }: NavSectionProps) {
  const { t, currentLang } = useLocales();
  const menuList: MenuItem[] = useSelector((state) =>
    get(state, [authSliceName, 'userInfo', 'menuList'], [])
  );

  const navList: NavItemProps[] = mapMenuItem(menuList, currentLang.value as LocalizationKey);

  return (
    <Box>
      <NavItem
        key="DASH"
        item={{
          menuName: t('dashboard.title'),
          menuCode: 'DASH',
          path: DASHBOARD_URI,
          icon: configIcons.DASH
        }}
        isExpandMenu={isExpandMenu}
      />
      {navList.map((list: NavItemProps) => {
        const { menuCode, menuName, subMenuList } = list;
        return (
          <List key={menuCode} disablePadding>
            {isExpandMenu && <ListSubheaderStyle>{menuName}</ListSubheaderStyle>}
            {subMenuList &&
              subMenuList.map((item: NavItemProps) => (
                <NavItem key={item.menuCode} item={item} isExpandMenu={isExpandMenu} />
              ))}
          </List>
        );
      })}
    </Box>
  );
}
