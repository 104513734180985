export const DEFAULT_SORT_BY = 'createdDate';

export const PERIODS = {
  ALL: 'ALL',
  TODAY: 'TODAY',
  LAST7DAYS: 'WEEK',
  LAST30DAYS: 'MONTH',
  CUSTOM: 'CUSTOM'
};

export const PERIODS_OPTIONS: Array<any> = [
  {
    value: PERIODS.ALL,
    title: {
      ko: '전체',
      en: 'All'
    }
  },
  {
    value: PERIODS.TODAY,
    title: {
      ko: '오늘',
      en: 'Today'
    }
  },
  {
    value: PERIODS.LAST7DAYS,
    title: {
      ko: '최근 7일',
      en: 'Last 7 days'
    }
  },

  {
    value: PERIODS.CUSTOM,
    title: {
      ko: '기간 설정',
      en: 'Custom'
    }
  }
];
