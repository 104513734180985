import merge from 'lodash/merge';
import {
  SelectProps,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent
} from '@mui/material';

import { experimentalStyled as styled } from '@mui/material/styles';

import { MSelect } from 'components/@material-extend';
import { SELECT_ALL } from 'constants/common';
import useLocales from 'hooks/useLocales';

type FormControlOption = {
  hasFirstOption?: boolean;
  helperText?: string | string[];
};

export const WrapperInputSearch = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  background: '#ffff',
  width: '100%'
}));

export interface MFormControlSelectProps extends SelectProps {
  options?: FormControlOption;
  allValues?: Array<any>;
  onChange?: (event: any, value: any) => void;
  value?: any;
  isSearch?: boolean;
  searchValue?: string;
  onSearch?: (value: string) => void;
}
export default function MFormControlSelect(props: MFormControlSelectProps) {
  const { t } = useLocales();
  const { onChange, children, allValues, value: defaultValue } = props;

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    if (typeof onChange !== 'function') return;
    if (allValues) {
      const { value }: { value: any } = e.target;
      if (value[value.length - 1] === SELECT_ALL) {
        const newValues = allValues.length === defaultValue.length ? [] : allValues;
        const event = merge({ target: { value: newValues } }, e);
        onChange(event, newValues);
      } else {
        onChange(e, value);
      }
    } else {
      onChange(e, e.target.value);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>
        {props.label} {props.required ? '*' : null}
      </InputLabel>
      <MSelect {...props} onChange={handleChange}>
        {props?.options?.hasFirstOption && (
          <MenuItem value="">{t('common.form.select.placeholder')}</MenuItem>
        )}
        {allValues && allValues.length && (
          <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
            {t('common.form.select.selectUnselectAll')}
          </MenuItem>
        )}

        {children}
      </MSelect>

      {props?.options?.helperText && (
        <FormHelperText error>{props.options.helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
