import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { errorHandler } from 'store/errorHandlerSaga';
import { loadingActions } from 'components/loading/slices';
import { UserSearchParamsModel } from 'features/system/ProjectManagement/models';

import { RecommendationModel } from './models';
import { fetchUsersByProject, fetchUsersListIncludeAdmin } from './apis';

const initialState = {
  assigneeList: [],
  currentProjectCode: '',
  loading: false
};

const assigneeInputSliceName = 'assigneeRecommendation';

const assigneeInputSlice = createSlice({
  name: assigneeInputSliceName,
  initialState,
  reducers: {
    fetchAssigneesProcessing: (state) => {
      state.loading = true;
    },
    fetchAssigneesFailure: (state) => {
      state.assigneeList = [];
      state.currentProjectCode = '';
      state.loading = false;
    },
    fetchAssigneesSuccess: (state, { payload }) => {
      state.assigneeList = payload.assigneeList;
      state.currentProjectCode = payload.currentProjectCode;
      state.loading = false;
    },
    resetAssigneeList: (state) => {
      state.assigneeList = initialState.assigneeList;
      state.loading = false;
    }
  }
});

const { actions: reducerActions, reducer: assigneeInputReducer } = assigneeInputSlice;

const assigneeInputSliceSaga = createSliceSaga({
  name: assigneeInputSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *fetchAssignees(action): any {
      try {
        yield put(reducerActions.fetchAssigneesProcessing());
        const { projCode } = action.payload;
        const { data: usersList } = yield call(
          fetchUsersListIncludeAdmin,
          UserSearchParamsModel.toPlain(action.payload)
        );
        if (usersList?.data) {
          const assigneeInputData = RecommendationModel.toClass(usersList.data);
          yield put(
            reducerActions.fetchAssigneesSuccess({
              assigneeList: assigneeInputData,
              currentProjectCode: projCode
            })
          );
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.fetchAssigneesFailure());
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchUserByProject(action): any {
      try {
        yield put(reducerActions.fetchAssigneesProcessing());
        const { projCode } = action.payload;
        const { data: usersList } = yield call(fetchUsersByProject, action.payload);
        if (usersList?.data) {
          const assigneeInputData = RecommendationModel.toClass(usersList.data);
          yield put(
            reducerActions.fetchAssigneesSuccess({
              assigneeList: assigneeInputData,
              currentProjectCode: projCode
            })
          );
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.fetchAssigneesFailure());
      } finally {
        yield put(loadingActions.stopLoading());
      }
    }
  }
});

const { saga: assigneeInputSaga, actions: sagaActions } = assigneeInputSliceSaga;

const assigneeInputActions = {
  ...reducerActions,
  ...sagaActions
};
export {
  assigneeInputSliceName,
  assigneeInputActions,
  assigneeInputReducer,
  assigneeInputSaga,
  initialState
};
