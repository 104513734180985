import { createSlice } from '@reduxjs/toolkit';
import { call, put, all } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import FileSaver from 'file-saver';
import toArray from 'lodash/toArray';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { MENTION_TYPES } from 'constants/common';
import toastHelper from 'helpers/toast';
import { errorHandler } from 'store/errorHandlerSaga';
import { UserListModel, UserSearchParamsModel } from 'features/system/ProjectManagement/models';
import { loadingActions } from 'components/loading/slices';
import { convertToFormData } from 'utils/helpers';

import {
  fetchTicketDetail,
  fetchTicketHistory,
  logWork,
  changeStatus,
  exportTicket,
  fetchUsersByProject,
  fetchTicketComments,
  createTicketComment,
  editTicketComment,
  deleteTicketComment,
  fetchTicketLogWork,
  deleteTicketLogWork,
  updateTicketLogWork,
  getWorkflowDetail,
  fetchTicketRelates,
  addTicketRelates,
  deleteTicketRelate,
  getTicketMergeRequest,
  getTicketBranch,
  getTicketCommit,
  getTicketDeploymentStatus,
  getTicketDeploymentHistory,
  updateDeploymentSchedule,
  checkRegularGroup,
  exportTicketCicd,
  deleteBranchMR
} from './apis';
import {
  TicketModel,
  TicketGetParamsModel,
  TicketHistoryModel,
  TicketLogWorkModel,
  TicketChangeStatusParamsModel,
  TicketCommentListModel,
  TicketCommentModel,
  TicketCommentParamsModel,
  TicketCommentDeleteModel,
  TicketLogWorkListModel,
  TicketLogWorkItemModel,
  TicketRelatesListModel,
  AddTicketRelateModel,
  DeleteTicketRelateModel,
  TicketMergeRequestModel,
  TicketMergeRequestDetailModel,
  TicketDeploymentStatusModel,
  TicketBranchDetailModel,
  TicketCommitDetailModel,
  GetTicketDeploymentStatusModel,
  GetTicketBranchHistoryModel
} from './models';
import { STATUS_TICKET_NEED_APPROVE } from './constants';
import { TicketFormModel } from '../TicketForm/models';
import { deleteTicket, editTicket, restoreIntegration } from '../TicketForm/apis';

const initialState = {
  data: null,
  history: null,
  comment: null,
  actionsSucceed: false,
  actionStatusChangeSucceed: false,
  actionCommentChangeSucceed: false,
  actionAddTicketRelatesSucceed: false,
  ticketLogWork: {
    list: []
  },
  workflowDetail: null,
  ticketRelates: [],
  ticketMergeRequests: [],
  ticketBranches: [],
  ticketCommits: [],
  ticketDeploymentStatuses: [],
  ticketDeploymentHistory: [],
  suggestionGroup: {},
  actionAfterChangeStatus: {},
  actionDeleteBranchMR: false
};

const ticketDetailSliceName = 'ticketDetail';

const ticketDetailSlice = createSlice({
  name: ticketDetailSliceName,
  initialState,
  reducers: {
    fetchTicketDetailSuccess: (state, { payload }) => {
      state.data = payload;
      state.actionsSucceed = false;
      state.actionStatusChangeSucceed = false;
    },
    fetchTicketHistorySuccess: (state, { payload }) => {
      state.history = payload;
    },
    fetchTicketCommentsSuccess: (state, { payload }) => {
      state.comment = payload;
      state.actionCommentChangeSucceed = false;
    },
    fetchAllTicketDetailSuccess: (state, { payload }) => {
      state.data = payload.data;
      state.ticketLogWork = payload.ticketLogWork;
      state.ticketRelates = payload.ticketRelates;
      state.history = payload.ticketHistory;
      state.actionsSucceed = false;
      state.actionStatusChangeSucceed = false;
      state.actionAddTicketRelatesSucceed = false;
    },
    fetchWorkflowDetailSuccess: (state, { payload }) => {
      state.workflowDetail = payload;
    },
    modifyTicketStatus: (state, { payload }) => {
      state.actionsSucceed = payload;
    },
    modifyStatusChange: (state, { payload }) => {
      state.actionStatusChangeSucceed = payload;
    },
    modifyCommentChange: (state, { payload }) => {
      state.actionCommentChangeSucceed = payload;
    },
    modifyAddTicketRelatesStatus: (state, { payload }) => {
      state.actionAddTicketRelatesSucceed = payload;
    },
    getTicketMergeRequestSuccess: (state, { payload }) => {
      state.ticketMergeRequests = payload;
      state.actionDeleteBranchMR = false;
    },
    getTicketBranchSuccess: (state, { payload }) => {
      state.ticketBranches = payload;
      state.actionDeleteBranchMR = false;
    },
    getTicketCommitSuccess: (state, { payload }) => {
      state.ticketCommits = payload;
      state.actionDeleteBranchMR = false;
    },
    getTicketDeploymentStatusSuccess: (state, { payload }) => {
      state.ticketDeploymentStatuses = payload;
    },
    getTicketDeploymentHistorySuccess: (state, { payload }) => {
      state.ticketDeploymentHistory = payload;
    },
    resetDetailData: (state) => {
      state.data = null;
    },
    setSuggestionGroup: (state, { payload }) => {
      state.suggestionGroup = payload;
    },
    setActionAfterChangeStatus: (state, { payload }) => {
      state.actionAfterChangeStatus = payload;
    },
    modifyDeleteBranchMRStatus: (state, { payload }) => {
      state.actionDeleteBranchMR = payload;
    }
  }
});

const { actions: reducerActions, reducer: ticketDetailReducer } = ticketDetailSlice;

const ticketDetailSliceSaga = createSliceSaga({
  name: ticketDetailSliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *reloadTicketDetail(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { paramsByQuery, callback, anchorEl } = action.payload;
        const [
          { data: ticketDetailRes },
          { data: ticketLogworkRes },
          { data: ticketRelatesRes }
        ] = yield all([
          call(fetchTicketDetail, TicketGetParamsModel.toPlain(paramsByQuery)),
          call(fetchTicketLogWork, TicketGetParamsModel.toPlain(paramsByQuery)),
          call(fetchTicketRelates, TicketGetParamsModel.toPlain(paramsByQuery))
        ]);

        const ticketDetailData = TicketModel.toClass(ticketDetailRes.data);
        const ticketLogworkData = TicketLogWorkListModel.toClass(ticketLogworkRes.data);
        const ticketRelatesData = TicketRelatesListModel.toClass(ticketRelatesRes.data);
        yield put(
          reducerActions.fetchAllTicketDetailSuccess({
            data: ticketDetailData,
            ticketLogWork: ticketLogworkData,
            ticketRelates: ticketRelatesData
          })
        );
        callback && callback(anchorEl);
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },

    *fetchAllTicketDetail(action): any {
      try {
        yield put(loadingActions.showLoading());
        const [
          { data: ticketDetailRes },
          { data: ticketLogworkRes },
          { data: ticketRelatesRes },
          { data: ticketHistoryRes }
        ] = yield all([
          call(fetchTicketDetail, TicketGetParamsModel.toPlain(action.payload)),
          call(fetchTicketLogWork, TicketGetParamsModel.toPlain(action.payload)),
          call(fetchTicketRelates, TicketGetParamsModel.toPlain(action.payload)),
          call(fetchTicketHistory, TicketGetParamsModel.toPlain(action.payload))
        ]);

        const ticketDetailData = TicketModel.toClass(ticketDetailRes.data);
        const ticketLogworkData = TicketLogWorkListModel.toClass(ticketLogworkRes.data);
        const ticketRelatesData = TicketRelatesListModel.toClass(ticketRelatesRes.data);
        const ticketHistoryData = TicketHistoryModel.toClass(ticketHistoryRes.data);
        yield put(
          reducerActions.fetchAllTicketDetailSuccess({
            data: ticketDetailData,
            ticketLogWork: ticketLogworkData,
            ticketRelates: ticketRelatesData,
            ticketHistory: ticketHistoryData
          })
        );
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchTicketDetail(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { data: ticketDetailRes } = yield call(
          fetchTicketDetail,
          TicketGetParamsModel.toPlain(action.payload)
        );
        if (ticketDetailRes) {
          const ticketDetailData = TicketModel.toClass(ticketDetailRes.data);
          yield put(reducerActions.fetchTicketDetailSuccess(ticketDetailData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchTicketDetailAndHistory(action): any {
      try {
        const [{ data: ticketDetailRes }, { data: ticketHistoryRes }] = yield all([
          call(fetchTicketDetail, TicketGetParamsModel.toPlain(action.payload)),
          call(fetchTicketHistory, TicketGetParamsModel.toPlain(action.payload))
        ]);

        const ticketDetailData = TicketModel.toClass(ticketDetailRes.data);
        const ticketHistoryData = TicketHistoryModel.toClass(ticketHistoryRes.data);
        yield put(reducerActions.fetchTicketDetailSuccess(ticketDetailData));
        yield put(reducerActions.fetchTicketHistorySuccess(ticketHistoryData));
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchTicketHistory(action): any {
      try {
        const { data: ticketHistoryRes } = yield call(
          fetchTicketHistory,
          TicketGetParamsModel.toPlain(action.payload)
        );

        if (ticketHistoryRes) {
          const ticketHistoryData = TicketHistoryModel.toClass(ticketHistoryRes.data);
          yield put(reducerActions.fetchTicketHistorySuccess(ticketHistoryData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *logWork(action): any {
      try {
        yield put(loadingActions.showLoading());
        const submitParams = convertToFormData(TicketLogWorkModel.toPlain(action.payload));
        yield call(logWork, submitParams);
        yield put(reducerActions.modifyTicketStatus(true));
        yield put(reducerActions.setActionAfterChangeStatus({}));
        toastHelper.success('helpDesk.ticketManagement.succeedMess.logWork');
      } catch (error) {
        yield put(reducerActions.modifyTicketStatus(false));
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *changeTicketStatus(action): any {
      const { value, callback, backToList } = action.payload;
      try {
        const { approval, prevStatusType } = value;
        yield put(loadingActions.showLoading());
        const res = yield call(changeStatus, TicketChangeStatusParamsModel.toPlain(value));
        // Case Change status ticket by Approver
        if (res.data) {
          if (approval) {
            const messageApprove =
              approval === STATUS_TICKET_NEED_APPROVE.APPROVE ? 'approvalTicket' : 'rejectTicket';
            toastHelper.success(`helpDesk.ticketManagement.succeedMess.${messageApprove}`);
          }
          if (backToList) backToList();

          const requiredAction = get(res, ['data', 'data', 'requiredAction']);

          // show logwork or any action config in workflow
          if (requiredAction) {
            yield put(
              reducerActions.setActionAfterChangeStatus({
                requiredAction,
                preStatus: prevStatusType
              })
            );
          }

          callback && callback(value.statusType);
          yield put(reducerActions.modifyStatusChange(true));
        }
      } catch (error) {
        callback && callback(value.prevStatusType);
        yield put(reducerActions.modifyStatusChange(false));
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *exportTicketDetail(action): any {
      try {
        const { blob, filename } = yield call(
          exportTicket,
          TicketGetParamsModel.toPlain(action.payload)
        );
        FileSaver.saveAs(blob, filename);
        toastHelper.success('file.downloadSuccess');
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *searchUsersByProject(action): any {
      try {
        const { params, keyword, renderList } = action.payload;
        const searchParam = UserSearchParamsModel.toPlain(params);
        const { data: userRes } = yield call(fetchUsersByProject, searchParam);

        if (userRes) {
          const usersListData = UserListModel.toClass(userRes?.data?.content);
          const arrayData: any[] = toArray(usersListData);
          if (arrayData.length === 0) {
            arrayData.push({
              type: MENTION_TYPES.USER_NOT_FOUND,
              searchTerm: keyword,
              disabled: true
            });
          }
          renderList([...arrayData, { type: MENTION_TYPES.GUIDELINE, disabled: true }], keyword);
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },

    // comments
    *fetchTicketComments(action): any {
      try {
        const { data: ticketCommentRes } = yield call(
          fetchTicketComments,
          TicketCommentParamsModel.toPlain(action.payload)
        );

        if (ticketCommentRes) {
          const ticketCommentData = TicketCommentListModel.toClass(ticketCommentRes.data);
          yield put(reducerActions.fetchTicketCommentsSuccess(ticketCommentData));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *createTicketComment(action): any {
      try {
        const submitParams = TicketCommentModel.toPlain(action.payload);
        yield call(createTicketComment, submitParams);
        yield put(reducerActions.modifyCommentChange(true));
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyCommentChange(false));
      }
    },
    *editTicketComment(action): any {
      try {
        const submitParams = TicketCommentModel.toPlain(action.payload);
        yield call(editTicketComment, submitParams);
        yield put(reducerActions.modifyCommentChange(true));
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyCommentChange(false));
      }
    },
    *deleteTicketComment(action): any {
      try {
        const submitParams = TicketCommentDeleteModel.toPlain(action.payload);
        yield call(deleteTicketComment, submitParams);
        yield put(reducerActions.modifyCommentChange(true));
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyCommentChange(false));
      }
    },
    *deleteTicketLogWork(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield call(deleteTicketLogWork, TicketLogWorkItemModel.toPlain(action.payload));
        yield put(reducerActions.modifyTicketStatus(true));
        toastHelper.success('helpDesk.ticketManagement.logwork.delete');
      } catch (error) {
        yield put(reducerActions.modifyTicketStatus(false));
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *updateTicketLogWork(action): any {
      try {
        yield put(loadingActions.showLoading());
        const submitParams = convertToFormData(TicketLogWorkModel.toPlain(action.payload));
        yield call(updateTicketLogWork, submitParams);
        yield put(reducerActions.modifyTicketStatus(true));
        toastHelper.success('helpDesk.ticketManagement.logwork.update');
      } catch (error) {
        yield put(reducerActions.modifyTicketStatus(false));
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchWorflowOfTicket(action): any {
      try {
        yield put(loadingActions.showLoading());

        const workflowDetail = yield call(getWorkflowDetail, action.payload);
        yield put(reducerActions.fetchWorkflowDetailSuccess(workflowDetail));
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *addTicketRelates(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield call(addTicketRelates, AddTicketRelateModel.toPlain(action.payload));
        yield put(reducerActions.modifyAddTicketRelatesStatus(true));

        toastHelper.success('helpDesk.ticketManagement.ticketRelates.succeedMess.add');
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyAddTicketRelatesStatus(false));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *deleteTicketRelate(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield call(deleteTicketRelate, DeleteTicketRelateModel.toPlain(action.payload));
        yield put(reducerActions.modifyAddTicketRelatesStatus(true));
        toastHelper.success('helpDesk.ticketManagement.ticketRelates.succeedMess.delete');
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyAddTicketRelatesStatus(false));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *getTicketMergeRequest(action): any {
      try {
        const { data: ticketMergeRequestRes } = yield call(
          getTicketMergeRequest,
          TicketMergeRequestModel.toPlain(action.payload)
        );
        yield put(
          reducerActions.getTicketMergeRequestSuccess(
            TicketMergeRequestDetailModel.toClass(ticketMergeRequestRes.data)
          )
        );
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.getTicketMergeRequestSuccess([]));
      }
    },
    *getTicketBranch(action): any {
      try {
        const { data: ticketBranchRes } = yield call(
          getTicketBranch,
          TicketMergeRequestModel.toPlain(action.payload)
        );
        yield put(
          reducerActions.getTicketBranchSuccess(
            TicketBranchDetailModel.toClass(ticketBranchRes.data)
          )
        );
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.getTicketBranchSuccess([]));
      }
    },
    *getTicketCommit(action): any {
      try {
        const { data: ticketCommitRes } = yield call(
          getTicketCommit,
          TicketMergeRequestModel.toPlain(action.payload)
        );
        yield put(
          reducerActions.getTicketCommitSuccess(
            TicketCommitDetailModel.toClass(ticketCommitRes.data)
          )
        );
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.getTicketCommitSuccess([]));
      }
    },
    *getTicketDeploymentStatus(action): any {
      try {
        const { data: ticketTicketDeploymentStatuRes } = yield call(
          getTicketDeploymentStatus,
          GetTicketDeploymentStatusModel.toPlain(action.payload)
        );
        yield put(
          reducerActions.getTicketDeploymentStatusSuccess(
            TicketDeploymentStatusModel.toClass(ticketTicketDeploymentStatuRes.data)
          )
        );
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *getTicketDeploymentHistory(action): any {
      try {
        const { data: ticketTicketDeploymentStatuRes } = yield call(
          getTicketDeploymentHistory,
          GetTicketBranchHistoryModel.toPlain(action.payload)
        );
        yield put(
          reducerActions.getTicketDeploymentHistorySuccess(
            TicketDeploymentStatusModel.toClass(ticketTicketDeploymentStatuRes.data)
          )
        );
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *downloadTicketDeploymentHistory(action): any {
      try {
        const { blob, filename } = yield call(exportTicketCicd, action.payload);
        FileSaver.saveAs(blob, filename);
        toastHelper.success('file.downloadSuccess');
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *editDeploymentScheduleTicket(action): any {
      try {
        const { value, offShowToastLoading } = action.payload;
        if (!offShowToastLoading) {
          yield put(loadingActions.showLoading());
        }
        const submitParams = TicketFormModel.toPlain(value);
        yield call(editTicket, convertToFormData(submitParams));
        if (!offShowToastLoading) {
          toastHelper.success('helpDesk.ticketManagement.succeedMess.edit');
          yield put(reducerActions.modifyTicketStatus(true));
        }
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyTicketStatus(false));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *deleteTicket(action): any {
      try {
        const { value, callback } = action.payload;
        yield call(deleteTicket, value);
        toastHelper.success('helpDesk.ticketManagement.deleteSuccess');
        if (callback) callback();
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyTicketStatus(false));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *restoreIntegration(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield call(restoreIntegration, action.payload);
        toastHelper.success('helpDesk.ticketManagement.succeedMess.edit');
        yield put(reducerActions.modifyTicketStatus(true));
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyTicketStatus(false));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *updateDeploymentSchedule(action): any {
      try {
        yield put(loadingActions.showLoading());
        yield call(updateDeploymentSchedule, action.payload);
        yield put(reducerActions.modifyTicketStatus(true));
        toastHelper.success('helpDesk.ticketManagement.succeedMess.edit');
      } catch (error) {
        yield put(errorHandler(error));
        yield put(reducerActions.modifyTicketStatus(false));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *checkRegularGroup(action): any {
      try {
        const { data: resRegularGroup } = yield call(checkRegularGroup, action.payload);
        if (!isEmpty(resRegularGroup.data)) {
          yield put(reducerActions.setSuggestionGroup(resRegularGroup.data));
        }
      } catch (error) {
        yield put(errorHandler(error));
      }
    },
    *deleteBrachMR(action): any {
      try {
        yield call(deleteBranchMR, action.payload);
        toastHelper.success('system.projectCicdMgt.message.deleteSuccess');
        yield put(reducerActions.modifyDeleteBranchMRStatus(true));
      } catch (error) {
        yield put(errorHandler(error));
      }
    }
  }
});

const { saga: ticketDetailSaga, actions: sagaActions } = ticketDetailSliceSaga;

const ticketDetailActions = {
  ...reducerActions,
  ...sagaActions
};
export {
  ticketDetailSliceName,
  ticketDetailActions,
  ticketDetailReducer,
  ticketDetailSaga,
  initialState
};
