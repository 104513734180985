import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { Box, Divider, Typography, Stack, Drawer } from '@mui/material';

import { AuthDialogButtons } from 'features/auth/components/authButtons';
import useLocales from 'hooks/useLocales';
import { authSliceName, authActions, initialState } from 'features/auth/slices';
import Scrollbar from 'components/Scrollbar';
import LabeledCheckbox from 'components/LabeledCheckbox';
import { ACTIVE_YN } from 'constants/commonCodes';
import { SettingName, SETTING_NAME_LIST, checkPermissionByMenuCode } from 'utils/permissionHelper';

const DRAWER_WIDTH = 360;

export default function DashboardSetting({ open, setOpen }: any) {
  const dispatch = useDispatch();
  const { t, currentLang } = useLocales();
  const [settings, setSettings] = useState<Array<any>>([]);

  const dashboardSettings = useSelector(
    (state) => get(state, [authSliceName, 'dashboardSettings'], []),
    shallowEqual
  );

  const allMenuCodes = useSelector(
    (state) => get(state, [authSliceName, 'menuCodes'], []),
    shallowEqual
  );

  const userMenuCodes: Array<string> = useSelector((state) => {
    const permissions = get(state, [authSliceName, 'userInfo', 'buttonPermission'], []);
    return permissions.map((menu: any) => get(menu, ['menuCode'], ''));
  }, shallowEqual);

  const isSubmittingSettingForm = useSelector((state) =>
    get(state, [authSliceName, 'isSubmittingSettingForm'], initialState.isSubmittingSettingForm)
  );

  const handleClose = () => {
    setOpen(false);
    setSettings([]);
  };

  const handleSettingChanged = (event: any, name: string, checked: boolean) => {
    event.stopPropagation();
    event.preventDefault();

    setSettings(
      settings.map((item: any) => (item.settingName === name ? { ...item, value: checked } : item))
    );
  };

  const handleSaveSettings = () => {
    dispatch(
      authActions.updateDashboardSettings({
        params: settings.reduce(
          (acc, item) => ({
            ...acc,
            [item.settingName]: item.value ? ACTIVE_YN.Yes : ACTIVE_YN.No
          }),
          {}
        ),
        callback: handleClose
      })
    );
  };

  useEffect(() => {
    if (
      isEmpty(settings) &&
      !isEmpty(dashboardSettings) &&
      open &&
      !isEmpty(userMenuCodes) &&
      !isEmpty(userMenuCodes)
    ) {
      setSettings(
        SETTING_NAME_LIST.map((settingName: SettingName) => ({
          settingName,
          label: t(`dashboard.setting.${settingName}`),
          value: dashboardSettings[settingName] === ACTIVE_YN.Yes,
          canEdit: checkPermissionByMenuCode({
            settingName,
            allMenuCodes,
            userMenuCodes
          })
        }))
      );
    }
  }, [dashboardSettings, settings, t, currentLang, open, allMenuCodes, userMenuCodes]);

  useEffect(() => {
    if (open) {
      dispatch(authActions.getDashboardSettings());
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [dispatch, open]);

  const isNotCheckAll = useMemo(() => settings.some((item) => item.value === false), [settings]);
  const onCheckAll = () => {
    const cloneSetting = cloneDeep(settings);
    cloneSetting.forEach((item) => {
      item.value = isNotCheckAll;
    });
    setSettings(cloneSetting);
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        onClick={(e) => e.preventDefault()}
        BackdropProps={{ className: 'MuiBackdrop-invisible' }}
      >
        <Box sx={{ width: DRAWER_WIDTH }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ py: 2, pr: 1, pl: 2.5 }}
          >
            <Typography variant="subtitle1">{t('dashboard.settings')}</Typography>
            <Box
              sx={{ width: 'max-content' }}
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
                {t('dashboard.emailSetting.checkOrRemoveAll')}
              </Typography>
              <LabeledCheckbox checked={!isNotCheckAll} onClick={onCheckAll} />
            </Box>
          </Stack>
          <Divider />

          <Scrollbar sx={{ height: 1 }}>
            {settings.map((item: any) =>
              item.canEdit ? (
                <Stack direction="row" alignItems="center" key={item.settingName} sx={{ mt: 1 }}>
                  <LabeledCheckbox
                    name={item.settingName}
                    checked={item.value}
                    onClick={(event) => handleSettingChanged(event, item.settingName, !item.value)}
                  />

                  <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {item.label}
                  </Typography>
                </Stack>
              ) : (
                <></>
              )
            )}
          </Scrollbar>
          <Box sx={{ p: 3, position: 'absolute', bottom: '8px', right: 0 }}>
            <AuthDialogButtons
              isEdit
              onDiscard={handleClose}
              onSubmit={handleSaveSettings}
              isSubmitting={isSubmittingSettingForm}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
