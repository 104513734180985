import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Outlet, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import get from 'lodash/get';
import ShowTimeout from 'features/auth/components/ShowTimeout';
import useTabTracker from 'hooks/useCloseTab';

import { authSliceName, authActions } from 'features/auth/slices';
import useLocales from 'hooks/useLocales';
import { AppProvider } from 'contexts/AppProvider';
//
import Page from 'components/Page';
import { SocketProvider } from 'contexts/SocketProvider';
import ShowDuplicateLogin from 'features/auth/components/ShowDuplicateLogin';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled(Page)({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { t } = useLocales();

  const [open, setOpen] = useState(false);
  const [isExpandMenu, setIsExpandMenu] = useState(true);

  const isChangedPass = useSelector((state) =>
    get(state, [authSliceName, 'userInfo', 'corpUserInfo', 'isChangedPass'], 'Y')
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onCollapseExpandMenu = () => setIsExpandMenu(!isExpandMenu);

  useTabTracker();
  useEffect(() => {
    if (isChangedPass === 'N') {
      dispatch(authActions.logout({ navigate }));
    }
  }, [dispatch, isChangedPass, navigate]);

  if (isChangedPass === 'N') return <></>;
  return (
    <AppProvider>
      <RootStyle title={t('dashboard.title')}>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} isExpandMenu={isExpandMenu} />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          onCollapseExpandMenu={onCollapseExpandMenu}
          isExpandMenu={isExpandMenu}
        />
        <MainStyle>
          <SocketProvider>
            <Outlet />
            <ShowDuplicateLogin />
          </SocketProvider>
          <ShowTimeout />
        </MainStyle>
      </RootStyle>
    </AppProvider>
  );
}
