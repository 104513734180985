import MultipartModel from 'models/MultipartModel';
import { Expose, Transform } from 'class-transformer';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { BASIC_DATE_FORMAT, FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';
import { TransformDateModel, TransformArrayModel } from 'utils/transformer';

import { TicketFileModel } from './TicketFileModel';
import { TicketFile } from '../types';
import { ProjectCICDType } from '../../TicketDetail/types';

export class TicketFormModel extends MultipartModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'projName' })
  projName: string | undefined;

  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'appName' })
  appName: string | undefined;

  @Expose({ name: 'appVersionId' })
  appVersionId: string | undefined;

  @Expose({ name: 'appVersion' })
  appVersion: string | undefined;

  @Expose({ name: 'statusType' })
  statusType: string | undefined;

  @Expose({ name: 'type' })
  ticketType: string | undefined;

  @Expose({ name: 'priority' })
  priority: string | undefined;

  @Expose({ name: 'serviceType' })
  serviceType: string | undefined;

  @Expose({ name: 'serviceDetail' })
  serviceDetail: string | undefined;

  @Expose({ name: 'targetCompDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  targetCompDate: number | undefined;

  @Expose({ name: 'issueDetected' })
  @TransformDateModel(BASIC_DATE_FORMAT)
  issueDetected: number | null | undefined;

  @Expose({ name: 'title' })
  summary: string | undefined;

  @Expose({ name: 'description' })
  description: string | undefined;

  @Expose({ name: 'techSolution' })
  'techSolution0': string | undefined;

  @Expose({ name: 'techSolutionOne' })
  'techSolution1': string | undefined;

  @Expose({ name: 'techSolutionTwo' })
  'techSolution2': string | undefined;

  @Expose({ name: 'techSolutionThree' })
  'techSolution3': string | undefined;

  @Expose({ name: 'techSolutionFour' })
  'techSolution4': string | undefined;

  @Expose({ name: 'assigneeUserId' })
  assignee: string | undefined;

  @Expose({ name: 'assigneeName', toClassOnly: true })
  assigneeName: string | undefined;

  @Expose({ name: 'assigneeAvtUrl', toClassOnly: true })
  assigneeAvtUrl: string | undefined;

  @Expose({ name: 'estimation' })
  estimation: string | undefined;

  @Expose({ name: 'affectedVersion' })
  affectedVersion: string | undefined;

  @Expose({ name: 'files', toPlainOnly: true })
  @Transform((item) => (item && !isEmpty(item.value) ? item.value : undefined))
  files: File[] | undefined;

  @Expose({ name: 't1Files', toPlainOnly: true })
  @Transform((item) => (item && !isEmpty(item.value) ? item.value : undefined))
  t1Files: File[] | undefined;

  @Expose({ name: 't2Files', toPlainOnly: true })
  @Transform((item) => (item && !isEmpty(item.value) ? item.value : undefined))
  t2Files: File[] | undefined;

  @Expose({ name: 't3Files', toPlainOnly: true })
  @Transform((item) => (item && !isEmpty(item.value) ? item.value : undefined))
  t3Files: File[] | undefined;

  @Expose({ name: 't4Files', toPlainOnly: true })
  @Transform((item) => (item && !isEmpty(item.value) ? item.value : undefined))
  t4Files: File[] | undefined;

  @Expose({ name: 't5Files', toPlainOnly: true })
  @Transform((item) => (item && !isEmpty(item.value) ? item.value : undefined))
  t5Files: File[] | undefined;

  @Expose({ name: 'tech1Files' })
  @TransformArrayModel(TicketFileModel)
  tech1Files: Array<TicketFile> | undefined;

  @Expose({ name: 'tech2Files' })
  @TransformArrayModel(TicketFileModel)
  tech2Files: Array<TicketFile> | undefined;

  @Expose({ name: 'tech3Files' })
  @TransformArrayModel(TicketFileModel)
  tech3Files: Array<TicketFile> | undefined;

  @Expose({ name: 'tech4Files' })
  @TransformArrayModel(TicketFileModel)
  tech4Files: Array<TicketFile> | undefined;

  @Expose({ name: 'tech5Files' })
  @TransformArrayModel(TicketFileModel)
  tech5Files: Array<TicketFile> | undefined;

  @Expose({ name: 'ticketFiles' })
  @TransformArrayModel(TicketFileModel)
  ticketFiles: Array<TicketFile> | undefined;

  @Expose({ name: 'createdBy', toClassOnly: true })
  createdBy: string | undefined;

  @Expose({ name: 'createdName', toClassOnly: true })
  createdName: string | undefined;

  @Expose({ name: 'createdUserAvtUrl', toClassOnly: true })
  createdUserAvtUrl: string | undefined;

  @Expose({ name: 'createdDate', toClassOnly: true })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT_INCLUDE_SECOND))
  createdDate: number | undefined;

  @Expose({ name: 'updatedBy', toClassOnly: true })
  updatedBy: string | undefined;

  @Expose({ name: 'updatedName', toClassOnly: true })
  updatedName: string | undefined;

  @Expose({ name: 'updatedDate', toClassOnly: true })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT_INCLUDE_SECOND))
  updatedDate: number | undefined;

  @Expose({ name: 'ticketTypeName' })
  ticketTypeName: string | undefined;

  @Expose({ name: 'serviceTypeName' })
  serviceTypeName: string | undefined;

  @Expose({ name: 'serviceDetailName' })
  serviceDetailName: string | undefined;

  @Expose({ name: 'statusTypeName' })
  statusTypeName: string | undefined;

  @Expose({ name: 'priorityName' })
  priorityName: string | undefined;

  @Expose({ name: 'projCicdCodes' })
  projCicdCodes: string[] | undefined;

  @Expose({ name: 'integrateSolution' })
  integrateSolution: string | undefined;

  @Expose({ name: 'projCicdName' })
  projCicdName: string | undefined;

  @Expose({ name: 'approval' })
  approval: string | undefined;

  @Expose({ name: 'approver' })
  approver: string | undefined;

  // @Expose({ name: 'approvalDate', toClassOnly: true })
  // @Transform((v) => v.value && dayjs(v.value).format(FULL_DATE_FORMAT_INCLUDE_SECOND))
  // approvalDate: number | undefined;

  @Expose({ name: 'approvalDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  approvalDate: number | null | undefined;

  @Expose({ name: 'approvalOpinion' })
  approvalOpinion: string | undefined;

  // @Expose({ name: 'withdrawDate', toClassOnly: true })
  // @Transform((v) => v.value && dayjs(v.value).format(FULL_DATE_FORMAT_INCLUDE_SECOND))
  // withdrawDate: number | undefined;

  @Expose({ name: 'withdrawDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  withdrawDate: number | null | undefined;

  @Expose({ name: 'withdrawYn' })
  withdrawYn: string | undefined;

  @Expose({ name: 'deploymentSchedule' })
  deploymentSchedule: string | undefined;

  @Expose({ name: 'deploymentScheduleMap' })
  deploymentScheduleMap: string | undefined;

  @Expose({ name: 'ticketRelated' })
  ticketRelated: string | undefined;

  @Expose({ name: 'disableCancelYn' })
  disableCancelYn: string | undefined;

  @Expose({ name: 'disableCicdFieldYn' })
  disableCicdFieldYn: string | undefined;

  @Expose({ name: 'beforeStatus' })
  beforeStatus: string | undefined;

  @Expose({ name: 'logged' })
  logged: string | undefined;

  @Expose({ name: 'timeLeft' })
  timeLeft: string | undefined;

  @Expose({ name: 'remaining' })
  remaining: string | undefined;

  @Expose({ name: 'estimated' })
  @TransformDateModel(BASIC_DATE_FORMAT)
  estimated: number | undefined;

  @Expose({ name: 'privateYn' })
  privateYn: string | undefined;

  @Expose({ name: 'regularGroup' })
  regularGroup: string | undefined;

  @Expose({ name: 'assigneeUserStatus' })
  assigneeUserStatus: string | undefined;

  @Expose({ name: 'hasRelated' })
  hasRelated: string | undefined;

  @Expose({ name: 'operationDate', toClassOnly: true })
  @Transform((v) => v.value && dayjs(v.value).format(FULL_DATE_FORMAT_INCLUDE_SECOND))
  operationDate: number | undefined;

  @Expose({ name: 'projectCicds' })
  projectCicds: Array<ProjectCICDType> | undefined;

  @Expose({ name: 'rglGrpPipline' })
  rglGrpPipline: Array<ProjectCICDType> | undefined;
}
