import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { errorHandler } from 'store/errorHandlerSaga';
import { convertToFormData } from 'utils/helpers';
import { uploadImageToEditor } from './apis';
import { UploadImageModel } from './models';

const initialState = {
  isLoading: false,
  filterImage: false
};

const quillSliceName = 'quill';

const quillSlice = createSlice({
  name: quillSliceName,
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setFilterImage: (state, { payload }) => {
      state.filterImage = payload;
    }
  }
});

const { actions: reducerActions, reducer: quillReducer } = quillSlice;

const quillSliceSaga = createSliceSaga({
  name: quillSliceName,
  sagaType: SagaType.TakeEvery,
  caseSagas: {
    *uploadImageToEditor(action): any {
      const { params, callback, errorCallback } = action.payload;
      try {
        yield put(reducerActions.showLoading());
        const submitParams = UploadImageModel.toPlain(params);
        const { data } = yield call(uploadImageToEditor, convertToFormData(submitParams));
        if (data) {
          callback(data);
          yield put(reducerActions.setFilterImage(true));
        }
      } catch (error) {
        yield put(errorHandler(error));
        errorCallback(error);
      } finally {
        yield put(reducerActions.stopLoading());
      }
    }
  }
});

const { saga: quillSaga, actions: sagaActions } = quillSliceSaga;

const quillActions = {
  ...reducerActions,
  ...sagaActions
};
export { quillSliceName, quillActions, quillSaga, quillReducer, initialState };
