export const NEW_BUTTON_ID = 'btnCreateYn';
export const SEARCH_BUTTON_ID = 'btnSearchYn';
export const DELETE_BUTTON_ID = 'btnDeleteYn';
export const EDIT_BUTTON_ID = 'btnUpdateYn';
export const RESET_PASS_BUTTON_ID = 'btnEtc1Yn';
export const EXPORT_BUTTON_ID = 'btnEtc1Yn';
export const DOWNLOAD_EXCEL = 'btnExcelYn';
export const DOWNLOAD_PDF = 'btnPdfYn';
export const PERMISSION_BUTTON_ID = 'permissionBtn';
export const PRIVATE_TICKET = 'btnEtc2Yn';
export const LAYOUT_TICKET = 'btnEtc3Yn';

export const FORM_TABS = {
  loginForm: 'loginForm',
  forgotForm: 'forgotForm',
  changePwd: 'changePwd'
};

export const ROLE_USER_IMPORT = {
  activatedAdmin: 'license.activated.admin.y',
  activatedUser: 'license.activated.admin.n',
  expiredAdmin: 'license.expired.admin.y',
  expiredUser: 'license.expired.admin.n'
};
