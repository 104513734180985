// material

import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function ChevronRightIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 17.5L11.5 12.5L6.5 7.5"
          stroke="#637381"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 17.5L18.5 12.5L13.5 7.5"
          stroke="#637381"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}
