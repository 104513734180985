import { ButtonProps } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import useLocales from 'hooks/useLocales';
import MButton from '../MButton';

// For automation test
const TEST_ID = 'resetPassBtn';

const ResetPassButton = (props: ButtonProps) => {
  const { t } = useLocales();
  return (
    <MButton test-id={TEST_ID} variant="outlined" startIcon={<ReplayIcon />} {...props}>
      {t('common.button.resetPassword')}
    </MButton>
  );
};

export default ResetPassButton;
