import { ButtonProps } from '@mui/material';
import ExportButton from 'components/@material-extend/buttons/ExportButton';
import AuthButton from './AuthButton';
import { EXPORT_BUTTON_ID } from '../../constants';

const AuthExportButton = (props: ButtonProps) => (
  <AuthButton btnId={EXPORT_BUTTON_ID}>
    <ExportButton {...props} />
  </AuthButton>
);

export default AuthExportButton;
