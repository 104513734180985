import { FORCE_LOGOUT, LOGOUT_IDLE, LOGOUT_TIMEOUT, TIMEOUT_ACTIVITY } from 'constants/common';
import { SIGN_IN_URI } from 'constants/routes';
import { authActions } from 'features/auth/slices';
import { useAuth } from 'features/auth/useAuth';

import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAccessToken, getCurrentCorpCode, getCurrentUser } from 'utils/authHelper';

let timer: any = null;
const clearTimer = () => {
  if (timer) {
    clearInterval(timer);
  }
};

const ShowTimeout = () => {
  const dispatch = useDispatch();
  const { timeOut } = useAuth();
  const navigate: any = useNavigate();
  const timeOutRef = useRef<any>();

  const handleLogout = useCallback(() => {
    dispatch(authActions.setConnectSocket(false));

    dispatch(
      authActions.postUserLogout({
        value: {
          username: getCurrentUser(),
          key: LOGOUT_IDLE,
          token: getAccessToken(),
          corpCode: getCurrentCorpCode()
        }
      })
    );
    sessionStorage.setItem(FORCE_LOGOUT, LOGOUT_TIMEOUT);
    sessionStorage.setItem(TIMEOUT_ACTIVITY, timeOut);
    navigate(SIGN_IN_URI);
    clearTimer();
  }, [navigate, timeOut, dispatch]);

  const checkTimeOut = useCallback(
    (count: number) => {
      const timeOutSeconds = 3600 * timeOut;
      if (count > timeOutSeconds) {
        handleLogout();
      }
    },
    [handleLogout, timeOut]
  );

  const viewEvent = useCallback(() => {
    timeOutRef.current = 0;
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(() => {
      timeOutRef.current += 1;
      checkTimeOut(timeOutRef.current);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', viewEvent);
    document.addEventListener('scroll', viewEvent);
    document.addEventListener('keydown', viewEvent);
    viewEvent();

    return () => {
      document.removeEventListener('mousedown', viewEvent);
      document.removeEventListener('scroll', viewEvent);
      document.removeEventListener('keydown', viewEvent);
      clearTimer();
    };
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default ShowTimeout;
