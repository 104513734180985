import { useState } from 'react';
import { isDesktop } from 'react-device-detect';

// material
import { Tooltip, TooltipProps, ClickAwayListener } from '@mui/material';

const MTooltip = ({ children, ...otherProps }: TooltipProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isDesktop) {
    return <Tooltip {...otherProps}>{children}</Tooltip>;
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        {...otherProps}
        onClick={handleTooltipOpen}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default MTooltip;
