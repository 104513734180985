import { StaticDatePicker } from '@mui/lab';
import { withStyles } from '@mui/styles';

const UnusedDatePickerComponents = {
  LeftArrowIcon: () => <></>,
  RightArrowIcon: () => <></>,
  SwitchViewIcon: () => <></>
};

const StyledStaticDatePicker = withStyles((theme) => ({
  root: {
    // hide month/year select
    '& .css-8v7him': {
      display: 'none'
    }
  }
}))(StaticDatePicker);

const MStaticDatePicker = (props: any) => (
  <StyledStaticDatePicker
    {...props}
    components={UnusedDatePickerComponents}
    displayStaticWrapperAs="desktop"
    renderInput={() => <></>}
  />
);

export default MStaticDatePicker;
