import { CommonCodeType } from 'features/system/CommonCodeManagement/type';
import { get } from 'lodash';

export const TICKET_DETAIL_TABS = {
  COMMENT: 'Comment',
  HISTORY: 'History',
  WORK_LOG: 'WorkLog',
  DEPLOYMENT_STATUS: 'DeploymentStatus'
};

export const TAG_REGEX = /data-info="(.*?)"/g;

export const STATUS_TICKET_NEED_APPROVE = {
  CLOSE: 'CLOSED',
  OPEN: 'OPEN',
  APPROVE: 'APR_REQ',
  REJECT: 'REJECTED',
  WITHDRW: 'WITHDRW',
  CANCEL: 'CANCEL'
};

export const STATUS_ENABLE_SERIVCE_TYPE = [
  STATUS_TICKET_NEED_APPROVE.WITHDRW,
  STATUS_TICKET_NEED_APPROVE.REJECT,
  STATUS_TICKET_NEED_APPROVE.APPROVE
];

export const DEPLOYMENT_SCHEDULE = {
  REGULAR: 'REGULAR',
  FREQUENT: 'FREQUENT',
  NOTSELECTED: 'NOTSELECTED'
};

export const APRDEV_STATUS = 'APRDEV';
export const OPEN_STATUS = 'OPEN';
export const STATUS_SHOW_DEPLOYMENT_SCHEDULE = [
  'URGENT_DB',
  APRDEV_STATUS,
  'APRS',
  'UAT',
  'UAT_COMP',
  'RE_OPENED',
  'APRP',
  'CANCEL',
  'CLOSED',
  'PLAPPR',
  'PMAPPR',
  'PDBC',
  'VDP'
];

export const getStatusName = (
  listStatus: CommonCodeType[],
  statusCode: string | undefined,
  currentLang: string
) => {
  if (listStatus) {
    const statusItem = listStatus.find((item: CommonCodeType) => item.value === statusCode);
    return get(statusItem, ['title', currentLang]) || '';
  }
  return '';
};
