import { shallowEqual, useSelector } from 'react-redux';
import { authSliceName } from 'features/auth/slices';
import get from 'lodash/get';

import { PRIVATE_TICKET } from '../../constants';

const AuthPrivateTicket = ({ children }: any) => {
  const buttonTickets = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'buttonTickets'], []),
    shallowEqual
  );

  const btnPrivate = buttonTickets?.find((item: string) => item === PRIVATE_TICKET);

  return btnPrivate ? children : null;
};

export default AuthPrivateTicket;
