import { toast } from 'material-react-toastify';
import i18next from 'i18next';
import { Icon } from '@iconify/react';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

import {
  StyleSnackbarText,
  StyleSnackbarIcon,
  ColorSchema,
  StyleToastContent,
  WrapperContent
} from './styles';

type SnackbarIconProps = {
  icon: Object;
  color: ColorSchema;
};
function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  const theme = useTheme<Theme>();
  return (
    <StyleSnackbarIcon styleProps={{ color }} component="span" theme={theme}>
      <Icon icon={icon} width={20} height={20} />
    </StyleSnackbarIcon>
  );
}
const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export const renderToast = (message: any, type: ColorSchema, icon?: any, timeOut = 5000) => {
  const contentToast = (
    <StyleToastContent>
      <SnackbarIcon icon={icon} color={type} />
      <StyleSnackbarText>
        <WrapperContent>
          {isHTML(message) ? (
            <>
              {/* eslint-disable-next-line */}
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </>
          ) : (
            message
          )}
        </WrapperContent>
      </StyleSnackbarText>
    </StyleToastContent>
  );
  toast(contentToast, { position: 'top-right', autoClose: timeOut, style: { width: 'auto' } });
};

const getText = (messageKey: string, messageObj?: any) =>
  isHTML(messageKey) ? messageKey : i18next.t(messageKey, messageObj);

class ToastHelper {
  success = (messageKey: string, messageObj?: any, timeOut?: number): void => {
    renderToast(getText(messageKey, messageObj), 'success', checkmarkCircle2Fill, timeOut);
  };

  error = (messageKey: string, messageObj?: any, timeOut?: number): void => {
    renderToast(getText(messageKey, messageObj), 'error', alertCircleFill, timeOut);
  };

  warning = (messageKey: string, messageObj?: any, timeOut?: number): void => {
    renderToast(getText(messageKey, messageObj), 'warning', alertTriangleFill, timeOut);
  };

  info = (messageKey: string, messageObj?: any, timeOut?: number): void => {
    renderToast(getText(messageKey, messageObj), 'info', infoFill, timeOut);
  };
}

export default new ToastHelper();
