// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import MFormControlSelect, {
  MFormControlSelectProps
} from 'components/@material-extend/MFormControlSelect';

// ----------------------------------------------------------------------

export type RHFSelectProps = MFormControlSelectProps & {
  name: string;
  hasSearch?: boolean;
  children: any;
  allValues?: Array<any>;
  isSearch?: boolean;
  searchValue?: string;
  onSearch?: (value: string) => void;
};

function RHFSelect({ name, children, options, allValues, ...other }: RHFSelectProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...otherFieldProps }, fieldState: { error } }) => (
        <MFormControlSelect
          {...otherFieldProps}
          error={!!error}
          options={{ ...options, helperText: error?.message }}
          {...other}
          allValues={allValues}
        >
          {children}
        </MFormControlSelect>
      )}
    />
  );
}

export default RHFSelect;
