import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/common';
import { ILocaleString } from 'models/LocaleModel';

import { BaseUserListModel } from './BaseUserListModel';
import { SearchCriteria } from '../types';

export class UserModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && UserList.toClass(item.value))
  users: Array<any> | undefined;

  @Expose({ name: 'totalElements' })
  totalElements: number | undefined;
}

export class UserList extends BaseUserListModel {
  @Expose({ name: 'email' })
  email: string | undefined;

  @Expose({ name: 'roleName' })
  roleName: string | undefined;

  @Expose({ name: 'roleCode' })
  roleCode: string | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'activeName' })
  activeName: string | undefined;

  @Expose({ name: 'createdBy' })
  createdBy: string | undefined;

  @Expose({ name: 'createdDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  createdDate: string | undefined;

  @Expose({ name: 'createdName' })
  createdName: string | undefined;

  @Expose({ name: 'phoneNo' })
  phoneNo: string | undefined;

  @Expose({ name: 'countryCode' })
  countryCode: string | undefined;

  @Expose({ name: 'deptName' })
  deptName: string | undefined;

  @Expose({ name: 'updatedBy' })
  updatedBy: string | undefined;

  @Expose({ name: 'updatedDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  updatedDate: string | undefined;

  @Expose({ name: 'updatedName' })
  updatedName: string | undefined;

  @Expose({ name: 'approveStatus' })
  approveStatus: string | undefined;

  @Expose({ name: 'approveStatusMap' })
  approveStatusMap: ILocaleString | undefined;

  @Expose({ name: 'checked' })
  checked: boolean | undefined;

  @Expose({ name: 'solutionType' })
  solutionType: string | undefined;

  @Expose({ name: 'solutionName' })
  solutionName: string | undefined;

  @Expose({ name: 'endpoint' })
  endpoint: string | undefined;

  @Expose({ name: 'solutionMap' })
  solutionMap: string | undefined;

  @Expose({ name: 'integrationYn' })
  integrationYn: string | undefined;

  @Expose({ name: 'deniedNote' })
  deniedNote: string | undefined;

  @Expose({ name: 'timeOut' })
  timeOut: string | undefined;

  @Expose({ name: 'phoneVerify' })
  phoneVerify: string | undefined;

  @Expose({ name: 'newPhoneNo' })
  newPhoneNo: string | undefined;
}

export class UserSearchModel extends BaseModel {
  @Expose({ name: 'direction' })
  direction: string | undefined;

  @Expose({ name: 'itemPerPage' })
  itemPerPage: number | undefined;

  @Expose({ name: 'pageNum' })
  pageNum: number | undefined;

  @Expose({ name: 'sortBy' })
  sortBy: string | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteria | undefined;

  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;
}

export class UserExcelDownloadParamModel extends UserSearchModel {
  @Expose({ name: 'columns' })
  columns: Array<string> | undefined;

  @Expose({ name: 'labelNames' })
  labelNames: Array<string> | undefined;
}
