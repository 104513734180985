import { COMMON_CODE_URL, LOAD_SERVICE_CLASSFICATION, SUB_COMMON_CODE_URL } from 'constants/apiUrl';
import apiClient from 'apis/apiClient';

export const fetchCommonCodeList = (params: Array<any>) =>
  apiClient
    .post(COMMON_CODE_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchSubCodeByGroupCode = (params: any) =>
  apiClient
    .post(SUB_COMMON_CODE_URL, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const loadServiceClassfication = (params: any) =>
  apiClient
    .post(LOAD_SERVICE_CLASSFICATION, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
