export const dashboard = {
  title: '대시보드',
  welcomeMessage: {
    greeting: '{{name}}님,안녕하세요!',
    welcomeTo: 'MetaDesk에 오신 것을 환영합니다!',
    howCanHelp: '무엇을 도와드릴까요?',
    help: '? 도움말',
    useManual: '사용자 취급 설명서',
    downloadUserManual: 'MetaDesk',
    forEngLang: '영어 문서',
    forKoLang: '문서',
    pleaseRefer: '사용자 매뉴얼을 참고하세요.',
    emptyFiles: '문서가 존재하지 않습니다. 관리자에게 문의해주세요.'
  },
  myRecentTickets: '나의 최근 티켓',
  metrics: {
    progressingRate: {
      title: '티켓 처리 현황',
      service: '서비스',
      serviceTooltip: `모든 서비스 티켓에서 '완료'를 제외한 모든 상태의 서비스 티켓 수입니다.`,
      incident: '장애',
      incidentTooltip: `모든 장애 티켓에서 '완료'를 제외한 모든 상태의 장애 티켓 수입니다.`,
      serviceReqDeploy: '서비스 요청 (배포)',
      serviceReqDeployTooltip: `모든 서비스 요청(배포) 티켓에서 '완료'를 제외한 모든 상태의 서비스 요청(배포) 티켓 수입니다.`,
      serviceDeploy: '서비스 (배포)',
      serviceDeployTooltip: `모든 서비스(배포) 티켓에서 '완료'를 제외한 모든 상태의 서비스(배포) 티켓 수입니다.`,
      serviceRequset: '서비스 요청',
      serviceRequsetTooltip: `모든 서비스 요청 티켓에서 '완료'를 제외한 모든 상태의 서비스 요청 티켓 수입니다.`,
      tooltipProgessRate: `모든 {{ticketType}} 티켓에서 '완료'를 제외한 모든 상태의 {{ticketType}} 티켓 수입니다.`
    },
    ticketCreated: {
      title: '티켓 등록 현황',
      tooltip: '로그인한 사용자가 배정된 프로젝트의 티켓 중 최근 30일간 등록된 티켓 수'
    },
    unassigned: {
      title: '미할당 티켓',
      tooltip: '미취소, 미해결, 미완료와 미배정된 티켓 수입니다.'
    },
    notClosedTickets: {
      title: '완료 티켓',
      tooltip:
        '현재 완료되지 않은 티켓 수입니다. 취소, 해결 및 완료 상태를 제외한 모든 상태의 티켓이 포함됩니다.'
    },
    inProgressTickets: {
      title: '진행중인 티켓',
      tooltip: `로그인한 사용자가 배정된 프로젝트의 티켓 중 "오픈", "취소", "완료" 및 "승인 요청" 상태를 제외한 모든 상태의 티켓 수`
    },
    ticketTargets: {
      last30Days: '최근 30일',
      vsPrev30Days: '이전 30일 대비',
      vsYesterday: '전일 대비'
    },
    waitingForProgress: {
      title: '대기중 티켓',
      tooltip: `로그인한 사용자가 배정된 프로젝트의 티켓 중 "오픈" 상태인 티켓 수`
    },
    closeTicket: {
      title: '완료 티켓',
      tooltip: `로그인한 사용자가 배정된 프로젝트의 티켓 중 최근 30일간 등록된 "취소", "완료" 상태인 티켓 수`
    }
  },
  announcement: '공지사항',
  dashboardStatus: {
    title: '산출물 현황'
  },
  notFound: '조회 결과가 없습니다.',
  notFoundAnnouncement: '새 공지사항이 없습니다.',
  profile: '사용자 정보',
  settings: '설정',
  customDashboard: '대시보드 개인화 설정',
  emailMeWhen: '이메일 알림 설정',
  email: '이메일',
  kakao: '카카오톡',
  projectMember: '프로젝트 멤버',
  project: '프로젝트',
  setting: {
    unassigned: '미할당 티켓',
    ticketProgRate: '티켓 처리 현황',
    closeTicket: '완료 티켓',
    ticketCreated: '티켓 등록 현황',
    announcement: '공지사항',
    calendar: '캘린더',
    contractStatus: '계약 현황',
    documentStatus: '산출물 현황',
    inProgTicket: '진행중인 티켓',
    myRecentTicket: '나의 최근 티켓',
    waitingForProgress: '대기중 티켓'
  },
  emailSetting: {
    ticketCreated: '티켓 등록',
    ticketUpdated: '티켓 수정',
    ticketAssigned: '티켓 배정',
    ticketStatus: '티켓 상태 변경',
    ticketComment: '티켓 댓글',
    accountRegistrationRequest: '회원가입 요청',
    announcement: '공지사항',
    checkOrRemoveAll: '전체 체크/해제'
  },
  contractStatus: {
    title: '계약 현황',
    created: '{{contractID}} 계약이 등록되었습니다.',
    willExpire: '{{contractID}} 계약이 {{dateCount}}일 후 종료됩니다.',
    expired: '{{contractID}} 계약이 오늘 종료됩니다.',
    completed: '{{contractID}} 계약이 완료되었습니다.'
  },
  notifications: {
    title: '알림',
    myNotifications: '나의 알림목록',
    seeUnread: '안읽음',
    unread: '안읽음',
    seeAll: '전체 보기',
    all: '전체',
    totalUnreadMessage: '읽지 않은 알림  {{total}} 건이 있습니다'
  },
  schedule: {
    title: '일정 - {{year}}년 {{month}}월'
  },
  userStatus: {
    available: '대화 가능',
    busy: '다른 업무 중',
    doNotDisturb: '방해 금지',
    beRightBack: '곧 돌아오겠음',
    appearAway: '자리 비움으로 표시',
    appearOffline: '오프라인으로 표시',
    duration: '기간',
    resetStatus: '상태 재설정',
    status: '상태',
    resetAfter: '이후 상태 재설정',
    timeReset: {
      thirtyMinutes: '30분',
      oneHour: '한 시간',
      twoHour: '두 시간',
      today: '오늘',
      thisWeek: '이번주',
      custom: '기간 설정',
      timeReuire: '시간은 필수 항목입니다.'
    }
  }
};
