import { FILE_EXTENTION, MIME_TYPES } from 'constants/common';
import { DEFAULT_FORMATS } from 'components/editor/quill/constants';

export const DEFAULT_PRIORITY = 'MEDIUM';
export const MAX_NUMBER_OF_TECHNICAL_SOLUTIONS = 5;
export const TECH_SOLUTION_FIELD_PREFIX = 'techSolution';
export const TECH_SOLUTION_FIELD_LIST = [...Array(MAX_NUMBER_OF_TECHNICAL_SOLUTIONS)].map(
  (_, index: number) => `${TECH_SOLUTION_FIELD_PREFIX}${index}`
);

export const TECT_SOLUTION_FILES = [...Array(MAX_NUMBER_OF_TECHNICAL_SOLUTIONS)].map(
  (_, index: number) => `tech${index + 1}Files`
);

export const TECT_SOLUTION_FILES_UPLOAD = [...Array(MAX_NUMBER_OF_TECHNICAL_SOLUTIONS)].map(
  (_, index: number) => `t${index + 1}Files`
);

export const ATTACHMENT_ACCEPTED_TYPES = [
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.BMP,
  MIME_TYPES.GIF,
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.PPT,
  MIME_TYPES.PPTX,
  MIME_TYPES.XLS,
  MIME_TYPES.XLSX,
  MIME_TYPES.PDF,
  MIME_TYPES.MP4,
  MIME_TYPES.ZIP,
  MIME_TYPES.TXT,
  MIME_TYPES.JAR,
  MIME_TYPES.LOG,
  MIME_TYPES.HEIC,
  FILE_EXTENTION.XLS_E,
  FILE_EXTENTION.XLSX_E,
  FILE_EXTENTION.JPG_E,
  FILE_EXTENTION.JEPG_E,
  FILE_EXTENTION.PNG_E,
  FILE_EXTENTION.GIF_E,
  FILE_EXTENTION.DOC_E,
  FILE_EXTENTION.DOCX_E,
  FILE_EXTENTION.TXT_E,
  FILE_EXTENTION.PDF_E,
  FILE_EXTENTION.PPT_E,
  FILE_EXTENTION.PPTX_E,
  FILE_EXTENTION.HWP_E
].join(',');

export const DEFAULT_ESTIMATION_VALUE = '0h';
export const ESTIMATION_PLACEHOLDER = '1w 2d 6h';

export const DEFAULT_QUILL_FORMATS = DEFAULT_FORMATS.filter(
  (item) => item !== 'video' && item !== 'image'
);

export const SERVICE_OPERATION = 'OPERATION';

export const STATUS_OPEN_DEVTEST_ASSSIGN_DEV = ['OPEN', 'ASSIGNED', 'DEV_TEST', 'APRDEV'];
export const STATUS_APPROVE_WITHDRAW = ['APR_REQ', 'WITHDRW'];

export const STATUS_ALLOW_CHANGE_PROJECT = {
  DEPLOY: ['OPEN', 'ASSIGNED', 'APRDEV'],
  SERVICE_REQUEST: STATUS_APPROVE_WITHDRAW,
  SERVICE_REQUEST_DEPLOY: [...STATUS_APPROVE_WITHDRAW, ...STATUS_OPEN_DEVTEST_ASSSIGN_DEV]
};
