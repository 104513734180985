import { LoadingButtonProps } from '@mui/lab';

import ApplyButton from 'components/@material-extend/buttons/ApplyButton';
import AuthButton from './AuthButton';

import { EDIT_BUTTON_ID } from '../../constants';

const AuthApplyButton = (props: LoadingButtonProps) => (
  <AuthButton btnId={EDIT_BUTTON_ID}>
    <ApplyButton {...props} />
  </AuthButton>
);

export default AuthApplyButton;
