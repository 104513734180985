import apiClient from 'apis/apiClient';
import {
  LOGIN,
  CHANGE_PASSWORD,
  GET_USER_INFO,
  LOGOUT,
  GET_CORP_LIST,
  FORGOT_PASSWORD,
  HEALTH_CHECK_TOKEN,
  DASHBOARD_SETTING,
  GET_ACCESS_TOKEN,
  POST_LOGOUT
} from 'constants/apiUrl';

export const login = (payload: any) =>
  apiClient
    .post(LOGIN, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
export const changePwd = (payload: any) =>
  apiClient
    .post(CHANGE_PASSWORD, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
export const forgotPwd = (payload: any) =>
  apiClient
    .post(FORGOT_PASSWORD, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
export const logout = () =>
  apiClient
    .get(LOGOUT)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const getUserInfo = () =>
  apiClient
    .post(GET_USER_INFO)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const getCorpList = () =>
  apiClient
    .post(GET_CORP_LIST)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const healthCheckToken = (params: any) =>
  apiClient
    .post(HEALTH_CHECK_TOKEN, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const getDashboardSettings = () =>
  apiClient
    .get(DASHBOARD_SETTING)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const updateDashboardSettings = (params: any) =>
  apiClient
    .post(DASHBOARD_SETTING, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const refreshToken = (params: any) =>
  apiClient
    .post(GET_ACCESS_TOKEN, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const postLogout = (payload: any) =>
  apiClient
    .post(POST_LOGOUT, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
