import useLocales from 'hooks/useLocales';
import { ButtonAuth } from 'interfaces/ButtonAuth';
import MLoadingButton from '../MLoadingButton';

// For automation test
const TEST_ID = 'saveBtn';

const SaveButton = ({ labelName, ...other }: ButtonAuth) => {
  const { t } = useLocales();
  return (
    <MLoadingButton test-id={TEST_ID} {...other} color="primary" variant="contained">
      {labelName || <span>{t('common.button.save')}</span>}
    </MLoadingButton>
  );
};

export default SaveButton;
