import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';

import { ILocaleString } from 'models/LocaleModel';
import { stringToNumber } from 'utils/formatNumber';
import { TreeViewType } from '../types';

export class TreeModel extends BaseModel {
  @Expose({ name: 'menuCode' })
  menuCode: string | undefined;

  @Expose({ name: 'level' })
  @Transform(({ value }) => stringToNumber(value), { toPlainOnly: true })
  level: number | undefined;

  @Expose({ name: 'parentCode' })
  parentCode: string | undefined;

  @Expose({ name: 'subMenuList' })
  @Transform((item) => item && TreeModel.toClass(item.value))
  subMenuList: Array<TreeViewType> | undefined;

  @Expose({ name: 'name' })
  name: ILocaleString | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'path' })
  path: string | undefined;

  @Expose({ name: 'order' })
  order: number | undefined;

  @Expose({ name: 'desc' })
  desc: string | undefined;

  @Expose({ name: 'icon' })
  icon: string | undefined;
}
