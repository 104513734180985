import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class RecommendationModel extends BaseModel {
  @Expose({ name: 'username' })
  username: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'avatarUrl' })
  avatarUrl: string | undefined;

  @Expose({ name: 'order' })
  order: number | undefined;

  @Expose({ name: 'userStatus' })
  userStatus: number | undefined;
}
