// form
import { useFormContext, Controller } from 'react-hook-form';

import QuillEditor from 'components/editor/quill';
import { QuillEditorProps } from 'components/editor/quill/interfaces';

// ----------------------------------------------------------------------
export type RHFEditorProps = QuillEditorProps & {
  name: string;
};

function RHFEditor({ name, disabled, ...other }: RHFEditorProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...otherFieldProps }, fieldState: { error } }) => (
        <QuillEditor
          {...otherFieldProps}
          error={!!error}
          helperText={error?.message}
          disabled={disabled}
          showToolbar
          {...other}
          ref={ref}
        />
      )}
    />
  );
}

export default RHFEditor;
