import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useLocation } from 'react-router';
import { SELECT_CORP_URI } from 'constants/routes';

// components
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  textAlign: 'center',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const { pathname } = useLocation();

  const linkTo = pathname === SELECT_CORP_URI ? SELECT_CORP_URI : '/';

  return (
    <>
      <HeaderStyle>
        <Box
          component={RouterLink}
          to={linkTo}
          sx={{ display: 'inline-flex', textDecoration: 'unset' }}
        >
          <Logo sx={{ width: 175 }} />
        </Box>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
