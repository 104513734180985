import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { useRef, useState } from 'react';
// icon
import { Icon } from '@iconify/react';
import personFill from '@iconify/icons-eva/person-fill';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// service
import useLocales from 'hooks/useLocales';
import { authActions, authSliceName } from 'features/auth/slices';
// components
import { MIconButton } from 'components/@material-extend';
import MenuPopover from 'components/MenuPopover';
import SvgIconStyle from 'components/SvgIconStyle';
import { useInjectReducer, useInjectSaga } from 'hooks/useInjector';
import ConfigStatus from 'containers/ConfigStatus';
import AvatarUser from 'containers/ConfigStatus/AvatarUser';

import {
  userStatusReducer,
  userStatusSaga,
  userStatusSliceName
} from 'containers/ConfigStatus/slices';

import DashboardSetting from './DashboardSetting';
import EmailMeWhen from './EmailMeWhen';
import { emailConfigSliceName, emailReducer, emailSaga } from './EmailMeWhen/slices';
import { WrapperIconAccount } from './styles';

// ----------------------------------------------------------------------
const TEST_ID = 'accountHeader';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openEmailMeWhenSettings, setOpenEmailMeWhenSettings] = useState(false);
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const { t } = useLocales();

  const MENU_OPTIONS = [{ label: t('dashboard.profile'), icon: personFill, linkTo: '/profile' }];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onLogout = () => {
    dispatch(authActions.logout({ navigate }));
  };
  const userProfile = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'corpUserInfo'], {}),
    shallowEqual
  );
  useInjectReducer({
    key: emailConfigSliceName,
    reducer: emailReducer
  });
  useInjectSaga({ key: emailConfigSliceName, saga: emailSaga });

  useInjectReducer({
    key: userStatusSliceName,
    reducer: userStatusReducer
  });
  useInjectSaga({ key: userStatusSliceName, saga: userStatusSaga });

  // const setOfflineStatus = useCallback(() => {
  //   dispatch(
  //     userStatusActions.updateUserStatus({
  //       userStatus: USER_STATUS.APPEAR_OFFLINE,
  //       username: userProfile.username
  //     })
  //   );
  // }, [userProfile, dispatch]);

  // useEffect(() => {
  //   window.addEventListener('unload', (e) => {
  //     setOfflineStatus();
  //   });
  // }, [setOfflineStatus]);

  return (
    <>
      <WrapperIconAccount>
        <MIconButton
          test-id={TEST_ID}
          ref={anchorRef}
          onClick={handleOpen}
          className="okokokoko"
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            transform: 'none !important',
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            })
          }}
        >
          <AvatarUser
            alt="My Avatar"
            url={userProfile.avatarUrl}
            statusKey={userProfile.userStatus}
          />
        </MIconButton>
      </WrapperIconAccount>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 240 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userProfile.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userProfile.email}
          </Typography>

          <ConfigStatus />
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <MenuItem
          onClick={() => {
            setOpen(false);
            setOpenSettings(true);
          }}
        >
          <SvgIconStyle
            src="/static/icons/ic_analytics.svg"
            sx={{ width: '24px', height: '24px', mr: 2 }}
          />
          {t('dashboard.customDashboard')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpen(false);
            setOpenEmailMeWhenSettings(true);
          }}
        >
          <MailOutlineIcon sx={{ width: 24, height: 24, mr: 2 }} />
          {t('dashboard.emailMeWhen')}
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={onLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <DashboardSetting open={openSettings} setOpen={setOpenSettings} />
      <EmailMeWhen open={openEmailMeWhenSettings} setOpen={setOpenEmailMeWhenSettings} />
    </>
  );
}
