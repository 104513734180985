import HelpIcon from '@mui/icons-material/Help';

import MTooltip from './MTooltip';

const HelpTooltip = (props: any) => (
  <MTooltip {...props}>
    <HelpIcon color="primary" sx={{ ml: 1 }} />
  </MTooltip>
);

export default HelpTooltip;
