import { useEffect } from 'react';

// hook form
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  FieldNamesMarkedBoolean,
  UnpackNestedValue,
  useForm,
  UseFormRegisterReturn,
  UseFormReset,
  UseFormReturn,
  UseFormWatch,
  WatchObserver
} from 'react-hook-form';

// yup
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// utils
import isFunction from 'lodash/isFunction';

import useDeepCompareEffect from 'use-deep-compare-effect';

export interface HookFormConfig<TFieldValues extends FieldValues = FieldValues> {
  initialValues: DefaultValues<TFieldValues>;
  validationSchema?: Yup.AnyObjectSchema;
  onSubmit: (values: any) => void;

  shouldWatchAll?: boolean;
  onFormChange?: WatchObserver<TFieldValues>;
}
export interface HookFormReturn<TFieldValues extends FieldValues = FieldValues> {
  errors: FieldErrors<TFieldValues>;
  values: UnpackNestedValue<TFieldValues>;
  touched: FieldNamesMarkedBoolean<TFieldValues>;
  dirty: boolean;
  initialValues: DefaultValues<TFieldValues>;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  getFieldProps: (field: any) => UseFormRegisterReturn;
  setValue: (
    field: any,
    value: any,
    shouldValidate?: boolean | undefined,
    shouldTouch?: boolean | undefined
  ) => void;
  methods: UseFormReturn<TFieldValues, object>;
  watch: UseFormWatch<TFieldValues>;
  reset: UseFormReset<TFieldValues>;
}

/** Field input value, name, and event handlers */
export interface FieldInputProps<Value> {
  /** Value of the field */
  value: Value;
  /** Name of the field */
  name: string;
  /** Multiple select? */
  multiple?: boolean;
  /** Is the field checked? */
  checked?: boolean;
  /** Change event handler */
  onChange: any;
  /** Blur event handler */
  onBlur: any;
}

function useHookForm<TFieldValues extends FieldValues = FieldValues>(
  props: HookFormConfig<TFieldValues>
): HookFormReturn<TFieldValues> {
  const { initialValues, validationSchema, onSubmit, shouldWatchAll = false, onFormChange } = props;

  const methods = useForm<TFieldValues>({
    resolver: validationSchema && yupResolver(validationSchema),
    defaultValues: initialValues,

    // TODO
    mode: 'onBlur'
  });

  const {
    watch,
    register,
    handleSubmit,
    getValues,
    setValue: setFieldValue,
    formState: { errors, isDirty, touchedFields },
    reset
  } = methods;

  // Reset form when initialValues change
  useDeepCompareEffect(() => {
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (!isFunction(onFormChange)) {
      return () => {};
    }

    const subscription = watch(onFormChange);
    return () => subscription.unsubscribe();
  }, [onFormChange, watch]);

  const values = shouldWatchAll ? watch() : getValues();

  const getFieldProps = (name: any) => register(name);
  const setValue = (
    field: any,
    value: any,
    shouldValidate = true,
    shouldTouch?: boolean | undefined
  ) => {
    setFieldValue(field, value, { shouldValidate, shouldTouch });
  };

  return {
    errors,
    values,
    touched: touchedFields,
    dirty: isDirty,
    handleSubmit: handleSubmit(onSubmit),
    getFieldProps,
    setValue,
    methods,
    watch,
    initialValues,
    reset
  };
}

export default useHookForm;
