// material
import { alpha, experimentalStyled as styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

// theme
import typography, { FONT_PRIMARY } from 'theme/typography';
import { QuillEditorLabels } from './interfaces';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(
  ({
    theme,
    styleProps,
    labels
  }: {
    theme: Theme;
    styleProps: { error: boolean; height?: number | string };
    labels: QuillEditorLabels;
  }) => ({
    borderRadius: theme.shape.borderRadius,
    border: styleProps.error
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.grey[500_32]}`,
    '& .ql-container.ql-snow': {
      borderColor: 'transparent',
      fontSize: theme.typography.pxToRem(14)
    },
    '& .ql-snow .ql-editor .ql-code-block-container': {
      background: theme.palette.background.head,
      color: theme.palette.text.primary,
      padding: theme.spacing(2)
    },
    '& .ql-editor': {
      height: styleProps.height || theme.typography.pxToRem(200),
      resize: 'vertical',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      '&.ql-blank::before': {
        fontStyle: 'normal',
        color: theme.palette.text.disabled
      },
      '& pre.ql-syntax': {
        fontFamily: 'Consolas, monospace',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100]
      },
      '& p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6': {
        marginBlockEnd: theme.typography.pxToRem(4)
      }
    },
    // Blockquote
    '&& .ql-editor blockquote': {
      border: 'none',
      fontFamily: FONT_PRIMARY,
      lineHeight: 1.5,
      fontSize: theme.typography.pxToRem(16),
      position: 'relative',
      margin: `${theme.spacing(1)} auto`,
      padding: theme.spacing(2, 2, 2, 6),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.neutral,
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('md')]: {
        width: '80%'
      },
      '&& p, && span': {
        marginBottom: '0',
        fontSize: 'inherit',
        color: theme.palette.text.secondary
      },
      '&:before': {
        left: theme.spacing(2),
        top: 0,
        display: 'block',
        fontSize: theme.typography.pxToRem(48),
        content: '"\\201C"',
        position: 'absolute',
        color: theme.palette.text.disabled
      }
    },
    // Localized labels
    '&& .ql-tooltip': labels && {
      '&::before': labels.link && {
        content: `"${labels.link}"`
      },
      'a.ql-action': {
        '&::after': labels.edit && {
          content: `"${labels.edit}"`
        }
      },
      'a.ql-remove': {
        '&::before': labels.remove && {
          content: `"${labels.remove}"`
        }
      },
      '&.ql-editing::before': labels.enter && {
        content: `"${labels.enter}"`
      },
      '&.ql-editing a.ql-action': labels.save && {
        '&::after': {
          content: `"${labels.save}"`
        }
      }
    },
    // Custom font family label
    '&& .ql-formats .ql-picker.ql-font .ql-picker-item[data-label="Batang"]::before': labels
      ?.fontLabels?.batang && {
      content: `"${labels.fontLabels.batang}"`
    },
    '&& .ql-formats .ql-picker.ql-font .ql-picker-item[data-label="Malgun Gothic"]::before': labels
      ?.fontLabels?.malgunGothic && {
      content: `"${labels.fontLabels.malgunGothic}"`
    },
    // Quill Mention
    '&& .ql-mention-list-container': {
      backgroundColor: theme.palette.common.white,
      color: alpha(theme.palette.common.black, 0.87),
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: theme.customShadows.z12,
      maxHeight: theme.typography.pxToRem(200),
      overflow: 'auto',
      borderRadius: theme.typography.pxToRem(4),
      zIndex: 1,
      'ul.ql-mention-list': {
        listStyle: 'none',
        margin: 0,
        padding: `${theme.typography.pxToRem(8)} 0`,
        position: 'relative'
      },
      'li.ql-mention-list-item': {
        WebkitTapHighlightColor: 'transparent',
        backgroundColor: 'transparent',
        outline: 0,
        border: 0,
        margin: 0,
        borderRadius: 0,
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        color: 'inherit',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1rem',
        lineHeight: '1.5',
        letterSpacing: '0.00938em',
        display: 'flex',
        WebkitBoxPack: 'start',
        justifyContent: 'flex-start',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        position: 'relative',
        textDecoration: 'none',
        minHeight: theme.typography.pxToRem(48),
        padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(16)}`,
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        '&.selected': {
          textDecoration: 'none',
          backgroundColor: alpha(theme.palette.common.black, 0.04)
        }
      }
    },
    '&& .mention': {
      userSelect: 'all',
      span: {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.main
      }
    }
  })
);

export const CounterLabelStyle = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[500_32]}`,
  padding: theme.spacing(1),
  textAlign: 'right'
}));

// Minimal theme styling
const QuillEditorToolbarStyle = styled('div')(({ theme }) => {
  const isRTL = theme.direction === 'rtl';

  return {
    '& .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill': {
      fill: theme.palette.primary.main
    },
    '& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected': {
      color: theme.palette.primary.main
    },
    '& .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter': {
      stroke: theme.palette.primary.main
    },
    '& .ql-stroke': {
      stroke: theme.palette.text.primary
    },
    '& .ql-fill, .ql-stroke.ql-fill': {
      fill: theme.palette.text.primary
    },
    '& .ql-picker, .ql-picker-options, .ql-picker-item, .ql-picker-label, button': {
      '&:focus': { outline: 'none' }
    },
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.grey[500_32]}`,
      '& .ql-formats': {
        float: 'left',
        marginRight: 0
      },

      // Button
      '& button': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.typography.pxToRem(4),
        color: theme.palette.text.primary
      },

      // Select
      '& .ql-picker-label': {
        ...typography.subtitle2,
        color: theme.palette.text.primary,
        '&:before': {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: `calc(100% - ${theme.typography.pxToRem(18)})`,
          overflow: 'hidden'
        }
      },
      '&& .ql-picker-label svg': {
        ...(isRTL && {
          right: '0',
          left: 'auto'
        })
      },
      '& .ql-color,& .ql-background,& .ql-align ': {
        '& .ql-picker-label': {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      '&& .ql-expanded': {
        '& .ql-picker-label': {
          borderRadius: theme.typography.pxToRem(4),
          color: theme.palette.text.disabled,
          borderColor: 'transparent',
          backgroundColor: theme.palette.action.focus,
          '& .ql-stroke': { stroke: theme.palette.text.disabled }
        },
        '& .ql-picker-options': {
          padding: 0,
          marginTop: theme.typography.pxToRem(4),
          border: 'none',
          maxHeight: theme.typography.pxToRem(200),
          overflow: 'auto',
          boxShadow: theme.customShadows.z20,
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.background.paper
        },
        '& .ql-picker-item': {
          color: theme.palette.text.primary
        },

        // Align
        '&.ql-align': {
          '& .ql-picker-options': { padding: 0, display: 'flex' },
          '& .ql-picker-item': {
            width: theme.typography.pxToRem(32),
            height: theme.typography.pxToRem(32),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },
        // Color & Background
        '&.ql-color, &.ql-background': {
          '& .ql-picker-options': {
            padding: theme.typography.pxToRem(8)
          },
          '& .ql-picker-item': {
            margin: theme.typography.pxToRem(3),
            borderRadius: theme.typography.pxToRem(4),
            '&.ql-selected': { border: `1px solid ${theme.palette.common.black}` }
          }
        },
        // Font, Size, Header
        '&.ql-font, &.ql-size, &.ql-header': {
          '& .ql-picker-options': {
            padding: theme.spacing(1, 0)
          },
          '& .ql-picker-item': {
            padding: theme.spacing(0.5, 1.5)
          }
        }
      }
    }
  };
});

const EditorViewerStyle = styled(Box)(({ theme }) => ({
  fontFamily: FONT_PRIMARY,
  // List
  '& ul, & ol': {
    ...typography.body1,
    paddingLeft: theme.spacing(5),
    '& li': {
      lineHeight: 2
    }
  },
  fontSize: theme.typography.pxToRem(14),
  whiteSpace: 'pre-wrap',
  'p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6': {
    marginBlockEnd: theme.typography.pxToRem(4)
  },
  'pre.ql-syntax': {
    fontFamily: 'Consolas, monospace',
    padding: theme.spacing(1),
    overflow: 'auto'
  },
  // Blockquote
  '&& blockquote': {
    fontFamily: FONT_PRIMARY,
    lineHeight: 1.5,
    fontSize: theme.typography.pxToRem(16),
    position: 'relative',
    margin: `${theme.spacing(1)} auto`,
    padding: theme.spacing(2, 2, 2, 6),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
      width: '80%'
    },
    '&& p, && span': {
      marginBottom: '0',
      fontSize: 'inherit',
      color: theme.palette.text.secondary
    },
    '&:before': {
      left: theme.spacing(2),
      top: 0,
      display: 'block',
      fontSize: theme.typography.pxToRem(48),
      content: '"\\201C"',
      position: 'absolute',
      color: theme.palette.text.disabled
    }
  },

  // Code Block
  '& code.hljs': {
    background: theme.palette.background.head,
    padding: theme.spacing(2)
  },
  '& .ql-code-block-container': {
    '& select.ql-ui': {
      display: 'none'
    }
  },
  // Mention
  '&& .mention': {
    userSelect: 'all',
    span: {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.primary.main
    }
  }
}));

const LoaderStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
}));

export { QuillEditorToolbarStyle, RootStyle, EditorViewerStyle, LoaderStyle };
