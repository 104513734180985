// material

import { Box, BoxProps } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const BoxStyled = styled(Box)(({ theme }) => ({
  background: '#eceef0',
  display: 'flex',
  padding: 5,
  borderRadius: ' 50%'
}));

export default function LinkTicketIcon({ ...other }: BoxProps) {
  return (
    <BoxStyled {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M7.5 9.74997C7.82209 10.1806 8.23302 10.5369 8.70491 10.7947C9.17681 11.0525 9.69863 11.2058 10.235 11.2442C10.7713 11.2826 11.3097 11.2052 11.8135 11.0173C12.3173 10.8294 12.7748 10.5353 13.155 10.155L15.405 7.90497C16.0881 7.19772 16.4661 6.25046 16.4575 5.26722C16.449 4.28398 16.0546 3.34343 15.3593 2.64815C14.664 1.95287 13.7235 1.55849 12.7403 1.54995C11.757 1.5414 10.8098 1.91938 10.1025 2.60247L8.8125 3.88497"
          stroke="#919EAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 8.24992C10.1779 7.81933 9.76698 7.46304 9.29508 7.20522C8.82319 6.9474 8.30137 6.79409 7.76501 6.75567C7.22865 6.71726 6.69031 6.79465 6.18649 6.98259C5.68267 7.17053 5.22516 7.46462 4.845 7.84492L2.595 10.0949C1.9119 10.8022 1.53393 11.7494 1.54247 12.7327C1.55101 13.7159 1.9454 14.6565 2.64068 15.3517C3.33596 16.047 4.2765 16.4414 5.25974 16.45C6.24298 16.4585 7.19024 16.0805 7.8975 15.3974L9.18 14.1149"
          stroke="#919EAB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </BoxStyled>
  );
}
