// material
import { Tabs, Tab, Box } from '@mui/material';

interface TabInfo {
  value?: string;
  label?: string;
  icon?: JSX.Element;
  component: JSX.Element;
  disabled?: boolean;
}

interface MTabsProps {
  currentTab?: any;
  tabsInfo: TabInfo[];
  onChange?: (value: any) => void;
  renderTabPane?: (tab: TabInfo) => JSX.Element;
}

function MTabs({ currentTab, tabsInfo, onChange, renderTabPane }: MTabsProps) {
  const handleChangeTab = (_e: React.SyntheticEvent, newValue: string) => {
    !!onChange && onChange(newValue);
  };

  return (
    <>
      <Tabs
        value={currentTab}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={handleChangeTab}
      >
        {tabsInfo.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label || tab.value}
            icon={tab.icon}
            value={tab.value}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>

      {tabsInfo.map((tab) => {
        const isMatched = tab.value === currentTab;
        return (
          isMatched &&
          (renderTabPane ? (
            renderTabPane(tab)
          ) : (
            <Box sx={{ mt: 5 }} key={tab.value}>
              {tab.component}
            </Box>
          ))
        );
      })}
    </>
  );
}

export default MTabs;
