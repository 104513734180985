import { forwardRef } from 'react';
import { useTheme, Theme, Avatar, AvatarProps } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { STATUS_ICON_TYPE, USER_STATUS } from 'constants/userStatus';
import { StatusIconRender } from 'containers/ConfigStatus/type';
import StatusIcon from 'containers/ConfigStatus/StatusIcon';

// ----------------------------------------------------------------------

type AvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

// ----------------------------------------------------------------------

export interface MAvatarProps extends AvatarProps {
  color?: AvatarColor;
  userStatus?: STATUS_ICON_TYPE;
  showUserStatus?: boolean;
}

const WrapperMAvatar = styled('div')(({ theme }) => ({
  position: 'relative'
}));

const WrapperStatusIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 1,
  right: 1,
  width: 12,
  height: 12,

  svg: {
    width: '100%',
    height: '100%'
  }
}));

const MAvatar = forwardRef<HTMLDivElement, MAvatarProps>(
  (
    {
      color = 'default',
      children,
      sx,
      userStatus = USER_STATUS.AVAILABLE,
      showUserStatus = false,
      ...other
    },
    ref
  ) => {
    const theme = useTheme<Theme>();

    if (color === 'default') {
      return (
        <WrapperMAvatar sx={sx}>
          <Avatar ref={ref} sx={sx} {...other}>
            {children}
          </Avatar>
          {showUserStatus ? (
            <WrapperStatusIcon>
              <StatusIcon
                statusKey={userStatus}
                renderProps={(iconStatus: StatusIconRender) => <>{iconStatus.icon}</>}
              />
            </WrapperStatusIcon>
          ) : null}
        </WrapperMAvatar>
      );
    }

    return (
      <WrapperMAvatar sx={sx}>
        <Avatar
          ref={ref}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette[color].contrastText,
            backgroundColor: theme.palette[color].main,
            ...sx
          }}
          {...other}
        >
          {children}
        </Avatar>
        {showUserStatus ? (
          <WrapperStatusIcon>
            <StatusIcon
              statusKey={userStatus}
              renderProps={(iconStatus: StatusIconRender) => <>{iconStatus.icon}</>}
            />
          </WrapperStatusIcon>
        ) : null}
      </WrapperMAvatar>
    );
  }
);

export default MAvatar;
