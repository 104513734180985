import { Expose, Transform } from 'class-transformer';
import BaseModel from 'models/BaseModel';
import { LocaleStringModel } from 'models/LocaleModel';

export class CommonCode extends BaseModel {
  @Expose({ name: 'code' })
  value: String | undefined;

  @Expose({ name: 'name' })
  @Transform(({ value }) => LocaleStringModel.toClass(value))
  title: String | undefined;

  @Expose({ name: 'subCodeList' })
  @Transform(({ value }) => {
    if (!value || value.length < 1) return null;
    return CommonCode.toClass(value);
  })
  subCodeList: Array<CommonCode> | undefined;

  @Expose({ name: 'theme' })
  theme: String | undefined;

  @Expose({ name: 'order' })
  order: number | undefined;

  @Expose({ name: 'subCode' })
  subCode: string | undefined;

  @Expose({ name: 'groupCode' })
  groupCode: string | undefined;

  @Expose({ name: 'defaultYn' })
  defaultYn: string | undefined;

  @Expose({ name: 'commonFiles' })
  commonFiles: Array<any> | undefined;
}
