import { combineReducers } from '@reduxjs/toolkit';

import { authSliceName, authReducer } from 'features/auth/slices';
import { commonCodeSliceName, commonCodeReducer } from 'features/commonCode/slices';
import { loadingSliceName, loadingReducer } from 'components/loading/slices';
import { ticketFormSliceName, ticketFormReducer } from 'features/helpDesk/ticket/TicketForm/slices';
import {
  userInformationSliceName,
  userInformationReducer
} from 'features/system/UserInformation/slices';

function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    [authSliceName]: authReducer,
    [loadingSliceName]: loadingReducer,
    [commonCodeSliceName]: commonCodeReducer,
    [ticketFormSliceName]: ticketFormReducer,
    [userInformationSliceName]: userInformationReducer,
    ...injectedReducers
  });
  return rootReducer;
}

export default createReducer;
