export const helpDesk = {
  title: 'Service Desk',
  ticketManagement: {
    title: 'Ticket Management',
    createTicket: 'Create new ticket',
    editTicket: 'Edit ticket',
    configLayout: 'Ticket Layout Configuration',
    bulkCreateSetup: 'Import tickets',
    table: 'Table',
    kanban: 'Kanban',
    ticket: 'Ticket',
    approvalRequest: 'Approval Request',
    warningDelete: 'Are you sure you want to remove this ticket?',
    deleteSuccess: 'Ticket is deleted successfully',
    requiredItem: 'Required Items',
    optionalItem: 'Optional Items',
    ticketDetail: {
      title: 'Ticket Details',
      details: 'Details',
      logWork: 'Register work details',
      logWorkWarning: 'Please log your work',
      regularValue: 'Regular group information',
      regularRange: 'The value is not in the available range [1-9999999]',
      regularGroup: 'Regular Group',
      integratedProject: 'Integrated Project',
      warningCheckRegular: 'Please check for duplication.',
      regularGroupUsed: 'This regular group is in use. Please enter a different value',
      inputRegularData: 'Please enter regular group information',
      suggestion: 'Suggestion',
      cancel: {
        default: 'Are you sure you want to cancel?',
        staging: 'Do you want to cancel the Stg Build/Deploy Approval?',
        production: 'Do you want to cancel the Prd Build/Deploy Approval?',
        confirmRegular: 'Do you need to proceed with the build/deployment?'
      },
      pic: 'PIC',
      preview: 'Workflow',
      cicdStatus: 'CI/CD Status',
      gitlabStatus: 'Gitlab Status',
      cicdProject: 'CI/CD Projects',
      gitlabProject: 'Gitlab Projects',
      confirmRestoreIntegration: 'Are you sure to restore the integration of this ticket?',
      deployment: {
        title: 'Development',
        tabs: {
          branch: 'Branch',
          commit: {
            creator: 'Creator'
          }
        },
        branch: 'Branch',
        commit: 'Commit',
        build: 'Build',
        deploy: 'Deploy',
        mergeRequest: 'Merge request',
        repo: 'Repo',
        form: {
          id: 'ID',
          title: 'Title',
          status: 'Status',
          creator: 'Creator',
          assignee: 'Assignee',
          lastUpdated: 'Last updated',
          repoName: 'Repo name',
          fileAdded: 'Added file',
          fileChanged: 'Changed file',
          fileRemoved: 'Deleted file',
          commitTime: 'Commit time',
          commitId: 'Commit ID',
          approver: 'Approver',
          updater: 'Updater',
          updaterId: 'Updater ID',
          creatorName: 'Creator Name',
          creatorId: 'Creator ID',
          approverName: 'Approver Name',
          approverID: 'Approver ID',
          commitMessage: 'Commit Message',
          detail: 'Detail'
        },
        status: {
          opened: 'Opened',
          closed: 'Closed',
          updated: 'Updated',
          approved: 'Approved',
          merged: 'Merged',
          deleted: 'Deleted',
          reopened: 'Re - Opened',
          delete: 'Deleted'
        }
      },
      tooltip: {
        viewStatusWorkflow: 'View status workflow',
        editTicketDetail: 'Edit ticket details',
        downloadWord: 'Download ticket details to Word file'
      },
      regular: 'Regular',
      irregular: 'Irregular',
      nonSelected: 'Non-Selected',
      descriptionAttachment: 'Description Attachment',
      history: {
        field: 'Field',
        originalValue: 'Before ',
        newValue: 'After',
        createdIssue: 'created issue',
        madeChanges: 'made changes'
      }
    },
    ticketDeploymentStatus: {
      projectId: 'CI/CD Project ID',
      projectName: 'CI/CD Project Name',
      ticketStatus: 'Ticket Status',
      type: 'Type',
      environment: 'Environment',
      buildTime: 'Deploy Start Date & Time',
      updatedName: 'Updated By',
      updatedDate: 'Updated Date',
      putCreator: 'Put Creator',
      executor: 'Executor',
      buildDuration: 'Build Duration',
      buildType: 'Build Type',
      deployDuration: 'Deploy Duration',
      deployType: 'Deploy Type',
      completedDateTime: 'Deploy Completed Date & Time',
      buildStartDateTime: 'Build Start Date & Time',
      buildCompletedDateTime: 'Build Completed Date & Time',
      cicdStartDateTime: 'Start Date & Time',
      cicdCompletedDateTime: 'Completed Date & Time',
      branchTagInfor: 'Branch|Tag information',
      buildNumber: 'Build No.',
      deployNumber: 'Deploy No.',
      buildDeployNumber: 'Build/Deploy No.',
      pipelineName: 'Pipeline Name',
      pipelineAssign: 'Assignment Status',
      assignCICD: 'Assigned',
      unAssignCICD: 'Unassigned ',
      buildDeployDuration: 'Build/Deploy Duration',
      activation: 'Activation status'
    },
    ticketComment: {
      newComment: 'New Comment',
      comment: 'Comment',
      internal: 'Internal',
      internalComment: 'Internal Comment',
      deleteWarning: 'Are you sure you want to delete this comment?',
      edited: 'Edited',
      noComment: 'There is no comment yet.',
      guideline: 'Try typing @name',
      userNotFound: 'Found no matching users for {{searchTerm}}.',
      currentStatus: 'Current Status'
    },
    form: {
      filter: 'Filters',
      project: 'Project',
      application: 'Application',
      version: 'Version',
      ticketType: 'Ticket Type',
      priority: 'Priority',
      creator: 'Creator',
      serviceType: 'Service Type',
      serviceDetails: 'Service Details',
      completionDate: 'Target Completion Date',
      operationDate: 'Operation Date',
      ticketTile: 'Summary',
      title: 'Title',
      description: 'Description',
      technicalSolution: 'Technical Solution',
      techAttachment: 'Technical Sotlution Attachement ',
      assignee: 'Assignee',
      unassign: 'Unassign',
      estimation: 'Estimation',
      affectedVersion: 'Affected Version',
      issueDetected: 'Issue Detected',
      ticketID: 'Ticket ID',
      summary: 'Summary',
      createdStartDate: 'Creation Start Date',
      createdEndDate: 'Creation End Date',
      history: 'History',
      estimated: 'Estimated',
      remaining: 'Remaining',
      logged: 'Logged',
      timeLeft: 'Time Left',
      privateYn: 'Privacy',
      user: 'User',
      timeSpent: 'Time Spent',
      date: 'Date',
      workDescription: 'Work Description',
      less: 'Less',
      more: 'More',
      creationDate: 'Creation Date',
      updateDate: 'Update Date',
      logWork: 'Log work',
      moreTechSol: 'More Technical Solutions',
      projectCicd: 'CI/CD Project',
      approvedRejectedPerson: 'Approve/Reject Person',
      approvedRejectedDate: 'Approve/Reject Date Time',
      approvalOpinion: 'Approve/Reject Opinion',
      withdrawDate: 'Withdraw Date',
      approveRejectPerson: 'Approve/Reject Person',
      integrateSolution: 'Integration Project',
      deploymentSchedule: 'Deployment Schedule',
      other: 'Other',
      linkedTicket: 'Linked Ticket',
      downloadServiceTarget: 'Download Service Target Form',
      public: 'Public',
      private: 'Private',
      privateTicket: 'Private Ticket',
      regularGroupPipeline: 'Regular Group (Pipeline)'
    },
    succeedMess: {
      create: 'Ticket has been successfully created.',
      edit: 'Ticket has been successfully updated.',
      logWork: 'Work has been logged successfully.',
      approvalTicket: 'Ticket has been successfully approved',
      rejectTicket: 'Your ticket has been rejected.',
      createApprove: 'Approval request ticket is successfully created.',
      checkRegularGroup: 'Regular group is available.',
      successUploadFile: ' New ticket  {{totalTicket}} has been created.'
    },
    errorMess: {
      dsbStatusForBuild: 'Please proceed with the build/deployment before updating the status.',
      periodFrom: 'Period from must be earlier than Period to and max range with 30 days.',
      periodTo: 'Period to must be later than Period from and max range with 30 days.',
      createEndDate:
        'End Created Date must be later than Start Created Date and max range with 30 days.',
      overlapCicdProject:
        'There is a processing ticket for the CICD project({{projects}}). Please close the ticket({{tickets}}) to creating a new ticket',
      cantChangeFromCloseDone:
        'The ticket status can not be changed after changing to Closed/Done.',
      validCSVFile: 'Please upload *.CSV file only and not exceeding 2MB',
      warningHistoryChange:
        'Information has already been modified. Would you like to re-view the ticket details?'
    },
    hint: {
      estimated: 'The amount of time originally anticipated to resolve the issue.',
      remaining: 'The difference between Estimated and Logged',
      logged: 'The amount of time logged working on the issue so far',
      timeLeft: 'The difference between Target completion date and Current date',
      estimation: 'Please input 1mo, 1w, 1d, 1h.',
      affectedVersion: 'The version in which a bug or problem was found.',
      validateCompletionDate: 'Target completion date must be greater or equal creation date',
      selectProjectFirst: 'Please select project first.',
      maxsimunFileCSV: 'The maximum the upload size is 2MB',
      csvMessage: 'You need to provide the data in a CSV file format.'
    },
    ticketList: 'Ticket List',
    messageDefaultSearch: `By default, the list returns items with status different from 'CANCEL', 'CLOSED'.`,
    logwork: {
      title: '<bold>{{createdBy}}</bold> logged <bold>{{timeSpent}}</bold> for {{date}}',
      warning: 'Are you sure you want to remove this log work entry?',
      delete: 'The log work entry has been successfully deleted.',
      update: 'Work log has been updated.',
      noWork: 'No work has yet been logged on this ticket.',
      dateValidation: 'Date must be less than or equal to current date.',
      changeStatus: 'Change Status'
    },
    ticketRelates: {
      title: 'Ticket Link',
      linkButton: 'Link',
      registerButton: 'Register',
      warning: 'Are you sure you want to remove this ticket link?',
      submitWarning: 'You must select at least one ticket to link',
      linkToPost: 'Link ticket(s) to the post',
      succeedMess: {
        add: 'The tickets was linked successfully',
        delete: ' Ticket link has been successfully deleted.'
      },
      form: {
        searchBy: 'Search by',
        ticketIdTitle: 'Ticket ID/Ticket Title'
      }
    },
    approveTicket: {
      approvalRejectOpinion: 'Approve/Reject Opinion',
      approvalOpinion: 'Approve Opinion',
      approveOpinion: 'Approval Opinion',
      rejectOpinion: 'Rejection Opinion'
    },
    waringTicketType:
      'By changing the ticket type, do you accept to initialize the ticket status to the first stage?',
    warningChangeProject:
      'By changing the project, do you accept to initialize the ticket status to the first stage?',
    createOption: {
      importFile: 'Import ticket',
      downloadTemplate: 'Download template'
    }
  },
  knowledgeManagement: {
    tabs: {
      section: {
        title: 'Section',
        sectionList: 'Section List',
        create: 'Create new section',
        edit: 'Edit section',
        confirmDelete: 'Are you sure you want to remove this section?',
        succeedMess: {
          create: 'Section has been successfully created.',
          edit: 'Section has been successfully updated.',
          delete: 'Section has been successfully deleted.'
        }
      },
      category: {
        title: 'Category',
        categoryList: 'Category List',
        create: 'Create new category',
        edit: 'Edit category',
        confirmDelete: 'Are you sure you want to remove this category?',
        succeedMess: {
          create: 'Category has been successfully created.',
          edit: 'Category has been successfully updated.',
          delete: 'Category has been successfully deleted.'
        }
      },
      document: {
        title: 'Document',
        headerTitle: 'Document Management',
        documentList: 'Document List',
        create: 'Create new document',
        edit: 'Edit document',
        confirmDelete: 'Are you sure you want to remove this document?',
        succeedMess: {
          create: 'Document has been successfully created.',
          edit: 'Document has been successfully updated.',
          delete: 'Document has been successfully deleted.'
        }
      },
      question: {
        title: 'FAQ',
        headerTitle: 'FAQ Management',
        faqList: 'Question List',
        create: 'Create new question',
        edit: 'Edit question',
        confirmDelete: 'Are you sure you want to remove this FAQ?',
        succeedMess: {
          create: 'FAQ has been successfully created.',
          edit: 'FAQ has been successfully updated.',
          delete: 'FAQ has been successfully deleted.'
        }
      }
    },
    form: {
      sectionID: 'Section ID',
      sectionName: 'Section Name',
      order: 'Order',
      project: 'Project',
      publish: 'Publish',
      language: 'Language',
      primaryLanguage: 'Primary Language',
      supportedLanguages: 'Supported Languages',
      additionalLanguages: 'Additional Languages',
      categoryID: 'Category ID',
      categoryName: 'Category Name',
      description: 'Description',
      answer: 'Answer',
      section: 'Section',
      category: 'Category',
      document: 'Document',
      documentID: 'Document ID',
      documentName: 'Document Name',
      questionID: 'Question ID',
      questionName: 'Question Name',
      attachments: 'Attachments',
      author: 'Author',
      all: 'All'
    },
    addTranslation: 'Add translation',
    editTranslation: 'Edit translation',
    viewMoreItems: 'Click to view more items.',
    succeedMess: {
      languageRegistered: '{{language}} translation is registered.',
      languageUpdated: '{{language}} translation is updated.',
      languageDeleted: '{{language}} translation is deleted.'
    }
  },
  viewAnnouncement: {
    title: 'Announcements',
    searchForm: {
      title: 'Filter',
      urgent: 'Urgent only'
    },
    loading: 'Loading...',
    viewCount: '{{ viewCount }} Views'
  },
  announcementManagement: {
    title: 'Announcement Management',
    create: 'Create new announcement',
    edit: 'Edit announcement',
    confirmDelete: 'Are you sure you want to remove this announcement?',
    searchForm: {
      publishDate: {
        last30days: 'Last 30 days',
        last7days: 'Last 7 days',
        last3days: 'Last 3 days'
      }
    },
    table: {
      column: {
        announcementId: 'Announcement No.',
        title: 'Title',
        project: 'Project',
        announcementType: 'Announcement Type',
        status: 'Status',
        urgent: 'Urgent',
        attachments: 'Attachments',
        publish: 'Publish',
        publishDate: 'Publish date',
        creator: 'Creator',
        modifiedBy: 'Modified By',
        modifiedDate: 'Modified Date'
      }
    },
    succeedMess: {
      create: 'Announcement has been successfully created.',
      edit: 'Announcement has been successfully updated.',
      delete: 'Announcement has been successfully deleted.'
    },
    errorMess: {
      validateLengthProjects: 'At least one project must be selected.'
    },
    announcementList: 'Announcement List'
  },
  viewDocument: {
    title: 'View Document',
    viewMore: 'View more',
    banner: {
      mainText: 'Find',
      subText: 'your document'
    },
    tabs: {
      all: 'All'
    },
    button: {
      back: 'Back to list'
    }
  },
  viewFAQ: {
    title: 'View FAQ',
    banner: {
      mainText: 'How',
      subText: 'can we help you?'
    }
  },
  scheduleManagement: {
    title: 'Schedule Management',
    createEvent: 'Create event',
    editEvent: 'Edit event',
    noEvent: 'No scheduled event.',
    editRecurringEvents: 'Edit recurring events',
    deleteRecurringEvents: 'Delete recurring events',
    form: {
      public: 'Public',
      personal: 'Personal',
      project: 'Project',
      title: 'Title',
      description: 'Description',
      location: 'Location',
      allDay: 'All-day',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      repeat: 'Repeat',
      every: 'Every',
      for: 'For',
      occurrences: ' occurrences',
      recurrencePattern: 'Recurrence pattern',
      startingFrom: 'Starting from {{start}}',
      creator: 'Creator'
    },
    confirmDelete: 'Are you sure you want to remove this event?',
    succeedMess: {
      create: 'Event has been successfully created.',
      edit: 'Event has been successfully updated.',
      delete: 'Event has been successfully deleted.'
    },
    errorMess: {
      compareDate: 'Start date must be earlier than end date.',
      compareTime: 'End time must be later than or equal to start time.',
      timeType: 'Please input HH:mm.',
      overlappingOccurrences: 'Unable to schedule overlapping occurrences.'
    },
    allDay: 'all-day',
    today: 'Today',
    calendarViewMonth: 'Month',
    calendarViewAgenda: 'Agenda',
    week: 'Week',
    updatePattern: {
      title: 'Update pattern',
      content:
        'You have updated the recurring pattern. New pattern will be applied for all events in the series.'
    },
    updateOriginalEvent: {
      title: 'Update series',
      content: 'Updates to original event will be applied for all events in the series.'
    },
    deleteOriginalEvent: {
      title: 'Delete series',
      content: 'Updates to original event will be applied for all events in the series.'
    }
  },
  internalSharingBoard: {
    title: 'Internal Sharing Board',
    form: {
      title: 'Title',
      project: 'Project',
      type: 'Type',
      organization: 'Organization',
      attachments: 'Attachments',
      period: 'Period',
      periodFrom: 'Period from',
      periodTo: 'Period to',
      creator: 'Creator',
      creationDate: 'Creation date',
      modifiedBy: 'Modified by',
      modifiedDate: 'Modified date',
      description: 'Description'
    },
    internalSharingList: 'Post List',
    confirmDelete: 'Are you sure you want to remove this post?',
    succeedMess: {
      create: 'Post has been successfully created.',
      edit: 'Post has been successfully updated.',
      delete: 'Post has been successfully deleted.'
    },
    error: {
      requiredPeriodFrom: 'Please input valid period from',
      requiredPeriodTo: 'Please input valid period to',
      compareTime: 'Period from must be earlier than Period to.'
    },
    create: 'Create new post',
    edit: 'Edit post'
  },
  reportingManagement: {
    title: 'Reporting',
    reportGroup: {
      ticketCreated: 'Ticket Created',
      ticketClosed: 'Ticket Closed',
      ticketCreatedClosed: 'Created\nVS\nClosed',
      averageFirstResponseTime: 'Average First\nResponse Time',
      averageResolutionTime: 'Average\nResolution Time',
      agentActivity: 'Agent Activity',
      FAQRegistered: 'FAQ Registered',
      documentRegistered: 'Document Registered'
    },
    by: 'by',
    total: 'Total',
    created: 'Created',
    resolved: 'Resolved',
    filterBy: 'Filter by',
    categories: 'Categories',
    totalDocuments: 'Total Documents',
    period: 'Period',
    errorMsg: {
      periodExceed30days: 'Search period must not exceed 30 days.',
      noReportCreated: 'No report screen has been created. Please contact the system administrator.'
    },
    table: {
      projectId: 'Project No.',
      projectName: 'Project Name',
      applicationId: 'Application ID',
      applicationName: 'Application Name',
      version: 'Version',
      priority: 'Priority',
      ticketType: 'Ticket Type',
      serviceType: 'Service Type',
      serviceDetail: 'Service Details',
      ticket: 'Ticket'
    }
  },
  ticketCreated: {
    title: 'Ticket Created',
    downloadTooltip: 'Download Excel',
    table: {
      title: 'Ticket Created',
      projectId: 'Project ID',
      projectName: 'Project Name'
    }
  },
  ticketClosed: {
    title: 'Ticket Closed',
    downloadTooltip: 'Download Excel',
    table: {
      title: 'Ticket Closed',
      projectId: 'Project ID',
      projectName: 'Project Name'
    }
  },
  ticketCreatedClosed: {
    title: 'Created VS Closed',
    downloadTooltip: 'Download Excel',
    table: {
      title: 'Created VS Closed',
      projectId: 'Project ID',
      projectName: 'Project Name',
      ticketStatus: 'Ticket Status'
    }
  },
  totalTickets: 'Total Tickets',
  averageFirstResponseTime: {
    title: 'Average First Response Time',
    ticketFirstResponse: 'Tickets with First Response',
    firstResponseTimeByTicketType: 'Average First Response Time by Ticket Type',
    newTicketNoResponse: 'New Tickets with No Response',
    firstResponseTimeByPriority: 'Average First Response Time by Priority',
    downloadTooltip: 'Download PDF'
  },
  averageResolutionTime: {
    title: 'Average Resolution Time',
    ARTByPriorityTitle: 'Average Resolution Time by Priority',
    ARTByTicketTypeTitle: 'Average Resolution Time by Ticket Type',
    ticketResolvedTitle: 'Ticket Resolved',
    downloadTooltip: 'Download PDF'
  },
  documentRegistered: {
    title: 'Document Registered',
    categoriesRegistered: 'Number of registered categories by section',
    documentRegistered: 'Number of registered document by category',
    downloadTooltip: 'Download PDF'
  },
  FAQRegistered: {
    title: 'FAQ Registered',
    categoriesRegistered: 'Number of registered categories by section',
    downloadTooltip: 'Download PDF'
  },
  agentActivity: {
    title: 'Agent Activity',
    chartTitle: 'Activities Performed',
    downloadTooltip: 'Download PDF',
    types: {
      assigned: 'Max Assigned',
      created: 'Max Created',
      internal: 'Max Internal',
      public: 'Max Public'
    },
    table: {
      title: 'Agent Leaderboard',
      username: 'User Name',
      name: 'Name',
      assigned: 'Assigned',
      created: 'Created',
      internal_respond: 'Internal Response ',
      public_respond: ' Public Response',
      top10: 'Top 10',
      top20: 'Top 20'
    }
  },
  internalTechnicalPost: {
    title: 'Information/Technology Sharing Notice Board',
    postList: 'Post List',
    form: {
      keyword: 'Keyword',
      title: 'Title',
      largeCategory: 'Large Category',
      middleCategory: 'Middle Category',
      smallCategory: 'Small Category',
      attachments: 'Attachments',
      description: 'Content',
      createdDate: 'Created Date',
      creator: 'Creator',
      updateDate: 'Update Date'
    },
    confirmDelete: 'Are you sure you want to remove this post?',
    succeedMess: {
      create: 'Post has been successfully created.',
      edit: 'Post has been successfully updated.',
      delete: 'Post has been successfully deleted.'
    },
    create: 'Register new post',
    edit: 'Edit post',
    noMatchTicketLink: 'There is no matched ticket information. Please try again.'
  },
  internalTechnicalPostDetail: {
    sharingBoard: 'Sharing Board',
    details: 'Details',
    ticketLink: 'Ticket Link'
  }
};
