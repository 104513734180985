import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Box, Divider, Drawer, Stack, Typography } from '@mui/material';
import set from 'lodash/set';
import get from 'lodash/get';

// hook form
import { AuthDialogButtons } from 'features/auth/components/authButtons';
import { ACTIVE_YN } from 'constants/commonCodes';
import useLocales from 'hooks/useLocales';
import LabeledCheckbox from 'components/LabeledCheckbox';

import TicketStatusConfig from './TicketStausConfig';
import ListStatusByTicketType from './ListStatusByTicketType';
import { emailConfigSliceName, emailActions } from './slices';
import { KEY_NOTITFI, OptionConfig, OptionNotificationsType } from './types';
import { initialOption } from './contants';

const DRAWER_WIDTH = 360;

export default function EmailMeWhen({ open, setOpen }: any) {
  const dispatch = useDispatch();
  const refStatusByTicketType = useRef<any>({});
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useLocales();

  const emailMeWhenSettings = useSelector(
    (state) => get(state, [emailConfigSliceName, 'emailMeWhenSettings']),
    shallowEqual
  );

  const [optionNotifications, setOptionNotification] = useState<OptionNotificationsType>(
    initialOption
  );

  const handleSaveSettings = () => {
    if (
      refStatusByTicketType.current &&
      typeof refStatusByTicketType.current.submitListStatus === 'function'
    ) {
      refStatusByTicketType.current.submitListStatus();
    }
    if (isEdit) {
      dispatch(
        emailActions.updateEmailMeWhenSettings({
          params: optionNotifications,
          callback: handleClose
        })
      );
    }

    handleClose();
  };

  const isShowSelectTicketStatus = useMemo(() => {
    const { sendKakaoYn, sendMailYn } = get(optionNotifications, 'ticketStatus') || {};
    return sendKakaoYn === ACTIVE_YN.Yes || sendMailYn === ACTIVE_YN.Yes;
  }, [optionNotifications]);

  const handleClose = () => {
    setOpen(false);
    dispatch(emailActions.clearForm());
  };

  const handleToggleOptionNotifi = useCallback(
    (event: any, key: KEY_NOTITFI, option: OptionConfig) => {
      event.preventDefault();
      setIsEdit(true);

      const newOption = { ...optionNotifications };

      const item: any = { ...get(newOption, key, {}) };
      const currentValue = get(item, option);

      const newChecked = !currentValue || currentValue === ACTIVE_YN.No;
      item[option] = newChecked ? ACTIVE_YN.Yes : ACTIVE_YN.No;
      newOption[key] = item;

      setOptionNotification(newOption);

      if (key === 'ticketStatus') {
        dispatch(emailActions.fetchTreeStatusSuccess(null));
      }
    },
    [optionNotifications, dispatch]
  );

  const handleCheckAll = useCallback(
    (event: any, option: string, currentValue: boolean) => {
      event.preventDefault();

      const newValue = { ...optionNotifications };

      Object.keys(newValue).forEach((key: string) => {
        if (get(newValue, key)) {
          const item = { ...get(newValue, key) };
          item[option] = currentValue ? ACTIVE_YN.No : ACTIVE_YN.Yes;
          set(newValue, [key], item);
        }
      });

      setOptionNotification(newValue);
      setIsEdit(true);
    },
    [optionNotifications]
  );

  useEffect(() => {
    if (emailMeWhenSettings) {
      setOptionNotification(emailMeWhenSettings);
    }
  }, [emailMeWhenSettings]);

  useEffect(() => {
    if (open) {
      dispatch(emailActions.getEmailMeWhenSettings());
      dispatch(emailActions.fetchProjects());
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [dispatch, open]);

  const handleShowTicketStatusConfig = () => {
    const newOption = { ...optionNotifications };
    const value = isShowSelectTicketStatus ? ACTIVE_YN.No : ACTIVE_YN.Yes;

    const itemTicketStatus: any = { ...get(newOption, 'ticketStatus', {}) };
    itemTicketStatus.sendKakaoYn = value;
    itemTicketStatus.sendMailYn = value;
    newOption.ticketStatus = itemTicketStatus;
    setIsEdit(true);
    setOptionNotification(newOption);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{ className: 'MuiBackdrop-invisible' }}
    >
      <div
        style={{
          width: DRAWER_WIDTH,
          maxWidth: '100vw',
          height: '100%'
        }}
      >
        <TicketStatusConfig
          onChecked={handleToggleOptionNotifi}
          onCheckAll={handleCheckAll}
          optionNotifications={optionNotifications}
        />

        <Divider />
        <Stack
          sx={{ pt: 1, pr: 1, pl: 2.5 }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography width="60%" variant="subtitle1">
            {t('dashboard.emailSetting.ticketStatus')}
          </Typography>
          <LabeledCheckbox
            checked={isShowSelectTicketStatus}
            onClick={handleShowTicketStatusConfig}
          />
        </Stack>
        {isShowSelectTicketStatus ? (
          <ListStatusByTicketType ref={refStatusByTicketType} isShow={isShowSelectTicketStatus} />
        ) : null}

        <Box sx={{ m: 3 }}>
          <AuthDialogButtons onSubmit={handleSaveSettings} isEdit onDiscard={handleClose} />
        </Box>
      </div>
    </Drawer>
  );
}
