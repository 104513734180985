import SaveButton from 'components/@material-extend/buttons/SaveButton';
import { ButtonAuth } from 'interfaces/ButtonAuth';
import AuthButton from './AuthButton';
import { EDIT_BUTTON_ID } from '../../constants';

const AuthEditButton = (props: ButtonAuth) => (
  <AuthButton btnId={EDIT_BUTTON_ID}>
    <SaveButton {...props} />
  </AuthButton>
);

export default AuthEditButton;
