import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { Typography, Stack, Divider, Box } from '@mui/material';
import LabeledCheckbox from 'components/LabeledCheckbox';
import Scrollbar from 'components/Scrollbar';
import { ACTIVE_YN } from 'constants/commonCodes';
import useLocales from 'hooks/useLocales';
import { useMemo } from 'react';
import { ItemConfigStyled, TitleConfigStyled, WrapperIconChecked } from './styles';
import { ItemSetting, KEY_NOTITFI, OptionConfig, OptionNotificationsType } from './types';
import { SEND_EMAIL } from './contants';

type TicketStatusConfigProps = {
  optionNotifications: OptionNotificationsType;
  onChecked: (e: any, key: KEY_NOTITFI, option: OptionConfig) => void;
  onCheckAll: (e: any, option: string, currentValue: boolean) => void;
};

const EMAIL_SETTING_LIST = [
  'ticketCreated',
  'ticketAssigned',
  'ticketUpdated',
  'ticketComment',
  'announcement'
];

const TicketStatusConfig = ({
  optionNotifications,
  onChecked,
  onCheckAll
}: TicketStatusConfigProps) => {
  const { t } = useLocales();

  const orderOptions = useMemo(() => {
    if (optionNotifications && optionNotifications.accountRegistrationRequest) {
      return [...EMAIL_SETTING_LIST, 'accountRegistrationRequest'];
    }
    return EMAIL_SETTING_LIST;
  }, [optionNotifications]);

  const filterListOption = Object.keys(optionNotifications)
    .filter((key: string) => orderOptions.includes(key))
    .reduce((obj: any, key: string) => {
      const cloneOption: any = cloneDeep(optionNotifications);
      obj[key] = cloneOption[key];
      return obj;
    }, {});

  const isCheckAll = useMemo(() => {
    let checkAllEmail = true;
    Object.keys(filterListOption).forEach((key) => {
      if (get(filterListOption, [key, SEND_EMAIL]) === ACTIVE_YN.No) {
        checkAllEmail = false;
      }
    });

    return {
      checkAllEmail
    };
  }, [filterListOption]);

  const listOptionConfig = useMemo(() => {
    const configs = orderOptions.map((key: string) => {
      const item = get(optionNotifications, key, {});
      return {
        ...item,
        key,
        label: t(`dashboard.emailSetting.${key}`)
      };
    });
    return configs;
  }, [optionNotifications, t, orderOptions]);

  return (
    <>
      <Stack sx={{ py: 2, pr: 1, pl: 2.5 }}>
        <Typography variant="subtitle1">{t('dashboard.emailMeWhen')}</Typography>
      </Stack>
      <Divider />
      <ItemConfigStyled direction="row">
        <TitleConfigStyled />
        <WrapperIconChecked>
          <Box
            sx={{ width: 'max-content' }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <Typography variant="subtitle1">
              {t('dashboard.emailSetting.checkOrRemoveAll')}
            </Typography>
          </Box>
          <LabeledCheckbox
            checked={isCheckAll.checkAllEmail}
            onClick={(e) => onCheckAll(e, SEND_EMAIL, isCheckAll.checkAllEmail)}
          />
        </WrapperIconChecked>
      </ItemConfigStyled>
      <Stack direction="column" sx={{ flex: 1 }}>
        <Scrollbar sx={{ height: 1 }}>
          {listOptionConfig.map(
            ({ sendKakaoYn, sendMailYn, label, key }: ItemSetting, index: number) => (
              <ItemConfigStyled key={index}>
                <TitleConfigStyled>
                  <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {label}
                  </Typography>
                </TitleConfigStyled>

                <WrapperIconChecked>
                  <LabeledCheckbox
                    checked={sendMailYn === ACTIVE_YN.Yes}
                    onClick={(event) => onChecked(event, key, SEND_EMAIL)}
                  />
                </WrapperIconChecked>
              </ItemConfigStyled>
            )
          )}
        </Scrollbar>
      </Stack>
    </>
  );
};

export default TicketStatusConfig;
