import { MenuItem, MenuItemProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useLocales from 'hooks/useLocales';

interface MMenuItemAllProps extends MenuItemProps {
  isAllSelected: Boolean;
}

const useStyles: any = makeStyles(() => ({
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));
const MMenuItemAll = (props: MMenuItemAllProps) => {
  const { t } = useLocales();
  const classes = useStyles();
  const { isAllSelected } = props;
  return (
    <>
      <MenuItem
        {...props}
        classes={{
          root: isAllSelected ? classes.selectedAll : ''
        }}
      >
        {t('common.form.select.all')}
      </MenuItem>
    </>
  );
};
export default MMenuItemAll;
