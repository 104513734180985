import { ReactNode } from 'react';

import { useAuth } from 'features/auth/useAuth';

type AuthVisibleProps = {
  children: ReactNode;
};

// Render children component if the user role is NOT customer
const AuthVisible = ({ children }: AuthVisibleProps) => {
  const { isCustomer } = useAuth();

  return isCustomer ? null : <>{children}</>;
};

export default AuthVisible;
