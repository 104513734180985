import { Theme, TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

type MInputOptionProps = {
  success?: boolean;
};

type MInputPropsExtend = {
  option?: MInputOptionProps;
  // `resize` property works when `multiline` = true
  resize?: 'none' | 'both' | 'horizontal' | 'vertical' | 'block' | 'inline';
};

export type MInputProps = TextFieldProps & MInputPropsExtend;

const useStyles = makeStyles<Theme, MInputProps>((theme: Theme) => ({
  input: (props) => ({
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props.option?.success ? theme.palette.success.main : theme.palette.grey[500_32]
    },
    '&.Mui-disabled input': {
      borderRadius: theme.shape.borderRadius
    }
  }),
  textField: (props) => ({
    '& textarea': {
      resize: props.multiline ? props.resize || 'vertical' : 'none'
    }
  })
}));

export default function MInput(props: MInputProps) {
  const classes = useStyles(props);
  const { InputProps, ...rest } = props;
  return (
    <TextField
      className={classes.textField}
      InputProps={{
        className: classes.input,
        ...InputProps
      }}
      {...rest}
    />
  );
}
