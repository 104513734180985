import { useSelector } from 'react-redux';
import get from 'lodash/get';
import LoadingScreen from 'components/LoadingScreen';
import { loadingSliceName, initialState } from './slices';

const LoadingContainer = () => {
  const isLoading = useSelector((state) =>
    get(state, [loadingSliceName, 'isLoading'], initialState.isLoading)
  );

  return isLoading ? <LoadingScreen isFullScreen /> : <></>;
};

export default LoadingContainer;
