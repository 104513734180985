import { MIME_TYPES, DEFAULT_TABLE_VALUES } from 'constants/common';
import { DESCENDANT } from 'components/table/constants';

export const ORG_NAME_REGEX = /^[a-zA-Z0-9]{2,5}$/;
export const ORG_NAME_MAX_LENGTH = 100;
export const TICKET_TYPE_SERVICE = 'SERVICE';
export const TICKET_TYPE_INCIDENT = 'INCIDENT';
export const DEFAULT_TICKET_TYPE = TICKET_TYPE_SERVICE;
export const DEFAULT_PRIORITY = 'MEDIUM';

export const ATTACHMENT_ACCEPTED_TYPES = [
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.BMP,
  MIME_TYPES.GIF,
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.PPT,
  MIME_TYPES.PPTX,
  MIME_TYPES.XLS,
  MIME_TYPES.XLSX,
  MIME_TYPES.PDF,
  MIME_TYPES.MP4,
  MIME_TYPES.LOG,
  MIME_TYPES.HEIC
].join(',');

export const DEFAULT_ESTIMATION_VALUE = '0h';
export const ESTIMATION_PLACEHOLDER = '1w 2d 6h';

export const EXCEL_DOWNLOAD_PARAM = {
  direction: 'DESC',
  sortBy: 'updatedDate',
  menuName: 'ticketManagement',
  usePaging: 'N',
  columnNames: [
    'ticketTypeName',
    'serviceTypeName',
    'serviceDetailName',
    'ticketCode',
    'title',
    'priorityName',
    'assigneeName',
    'statusTypeName',
    'projName',
    'appName',
    'appVersion',
    'affectedVersion',
    'createdName',
    'createdDate',
    'operationDate',
    'updatedName',
    'updatedDate',
    'targetCompDate',
    'ticketRelated',
    'approver',
    'approvalDate',
    'approvalOpinion',
    'withdrawDate',
    'estimation',
    'remaining',
    'logged',
    'timeLeft',
    'privateYn',
    'deploymentSchedule',
    'projCicdName',
    'regularGroup',
    'description',
    'techSolution'
  ]
};

export const DEFAULT_SORT_BY = 'createdDate';

export const allowedTicketRelateSearchType = (t: any) => [
  {
    id: 'title',
    label: t('helpDesk.ticketManagement.form.ticketTile')
  },
  {
    id: 'ticketCode',
    label: t('helpDesk.ticketManagement.form.ticketID')
  }
];

export const allowedSearchType = (t: any) => [
  {
    id: 'assigneeName',
    label: t('helpDesk.ticketManagement.form.assignee')
  },
  {
    id: 'approver',
    label: t('helpDesk.ticketManagement.ticketDetail.deployment.form.approver')
  },
  {
    id: 'unassign',
    label: t('helpDesk.ticketManagement.form.unassign')
  },
  {
    id: 'createdName',
    label: t('helpDesk.ticketManagement.form.creator')
  },
  {
    id: 'title',
    label: t('helpDesk.ticketManagement.form.summary')
  },
  {
    id: 'ticketCode',
    label: t('helpDesk.ticketManagement.form.ticketID')
  },
  {
    id: 'regularGroup',
    label: t('helpDesk.ticketManagement.ticketDetail.regularGroup')
  }
];

export const defaultTableValues = {
  ...DEFAULT_TABLE_VALUES,
  direction: DESCENDANT,
  sortBy: DEFAULT_SORT_BY
};

export enum VIEW {
  table = 'table',
  board = 'board'
}

export const VIEW_TICKET_MGT = 'view_ticket_mgt';

export const SEARCH_TICKET_VALUE = 'search_ticket_value';
export const TABLE_TICKET_VALUE = 'table_ticket_value';

export const EXPAND_SEARCH_FORM = 'expand_search_form';

export const TICKET_TAB = {
  ticketList: 'TicketList',
  approveList: 'ApproveList'
};

export const ortherOption = (t: any) => [
  {
    value: 'TICKET_RELATED',
    label: t('helpDesk.ticketManagement.form.linkedTicket')
  }
];

export const TAB_LIST_OR_APPROVE = 'view_list_or_approve';

export const DATE_KEY_FORM = {
  START_DATE_TABLE: 'startDatetTable',
  END_DATE_TABLE: 'endDatetTable',
  START_DATE_KANBAN: 'startDateKanban',
  END_DATE_KANBAN: 'endDatetKanban'
};
