import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';

// components
import LoadingScreen from 'components/LoadingScreen';

// ----------------------------------------------------------------------
import * as ROUTES from 'constants/routes';
import PrivateRoute from 'features/auth/components/PrivateRoute';

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: '/',
      element: <PrivateRoute comp={<DashboardLayout />} />,
      children: [
        // Dashboard
        { path: ROUTES.DASHBOARD_URI, element: <Dashboard /> },
        // System
        { path: ROUTES.USER_PROFILE, element: <UserProfile /> },
        { path: ROUTES.USER_MANANGEMENT, element: <UserManagement /> },
        { path: ROUTES.CORP_MGT, element: <CorpMgt /> },
        { path: ROUTES.ORGANIZATION_MANAGEMENT, element: <OrgManagement /> },
        { path: ROUTES.CONTRACT_MANAGEMENT, element: <ContractManagement /> },
        { path: ROUTES.PROJECT_MANAGEMENT, element: <ProjectManagement /> },
        { path: ROUTES.APPLICATION_MANAGEMENT, element: <ApplicationManagement /> },
        { path: ROUTES.MENU_MANAGEMENT, element: <MenuManagement /> },
        { path: ROUTES.COMMON_CODE_MANAGEMENT, element: <CommonCodeManagement /> },
        { path: ROUTES.PERMISSION_MANAGEMENT, element: <PermissionManagement /> },
        { path: ROUTES.WORKFLOW_MANAGEMENT, element: <WorkflowsManagement /> },
        { path: ROUTES.LICENSE_MGT, element: <LicenseManagement /> },
        { path: ROUTES.DYNAMIC_CONTENT_MANAGEMENT, element: <DynamicContentManagement /> },
        { path: ROUTES.LOGIN_LOG, element: <LoginLog /> },
        { path: ROUTES.ACTION_LOG, element: <ActionLog /> },
        { path: ROUTES.USER_GUIDE_SETTING, element: <UserGuideSetting /> },
        { path: ROUTES.CONCURRENT_USER_HISTORY, element: <ConcurrentUserAndHistory /> },

        // Help desk
        { path: ROUTES.TICKET_DETAIL, element: <TicketDetail /> },
        { path: ROUTES.TICKET_MANAGEMENT, element: <Ticket /> },
        { path: ROUTES.TICKETS_CLOSE, element: <Ticket /> },
        { path: ROUTES.TICKETS_CREATE, element: <Ticket /> },
        { path: ROUTES.TICKETS_IN_PROGRESS, element: <Ticket /> },
        { path: ROUTES.TICKETS_WAITING_PROGRESS, element: <Ticket /> },
        { path: ROUTES.TICKETS_UNASSIGN, element: <Ticket /> },
        { path: ROUTES.DOCUMENT_MANAGEMENT, element: <DocumentManagement /> },
        { path: ROUTES.FAQ_MANAGEMENT, element: <FAQManagement /> },
        { path: ROUTES.VIEW_ANNOUNCEMENT, element: <ViewAnnouncement /> },
        { path: ROUTES.ANNOUNCEMENT_MANAGEMENT, element: <AnnouncementManagement /> },
        { path: ROUTES.VIEW_DOCUMENT, element: <ViewDocument /> },
        { path: ROUTES.VIEW_DETAIL_DOCUMENT, element: <ViewDetailDocument /> },
        { path: ROUTES.VIEW_FAQ, element: <ViewFAQ /> },
        { path: ROUTES.VIEW_DETAIL_FAQ, element: <ViewDetailFAQ /> },
        { path: ROUTES.SCHEDULE_MANAGEMENT, element: <ScheduleManagement /> },
        { path: ROUTES.INTERNAL_SHARING_BOARD, element: <InternalSharingBoard /> },
        { path: ROUTES.NOTIFICATIONS, element: <Notifications /> },
        { path: ROUTES.REPORTING_MANAGEMENT, element: <ReportingManagement /> },
        { path: ROUTES.INTERNAL_TECHNICAL_POST, element: <InternalTechnicalPost /> },
        { path: ROUTES.INTERNAL_TECHNICAL_POST_DETAIL, element: <InternalTechnicalPostDetail /> }
      ]
    },
    {
      path: ROUTES.SIGN_IN_URI,
      element: <LoginPage />
    },
    {
      path: ROUTES.SIGN_UP_URI,
      element: <SignUp />
    },
    {
      path: ROUTES.CHANGE_PASSWORD_URL,
      element: <ChangePassword />
    },
    {
      path: ROUTES.REGISTER_URI,
      element: <Register />
    },
    {
      path: ROUTES.IMPORT_LICENSE_URI,
      element: <ImportLicense />
    },
    {
      path: ROUTES.VERIFY_USER,
      element: <VerifyUser />
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: ROUTES.SELECT_CORP_URI, element: <SelectCorporation /> },
        { path: ROUTES.NOTFOUND_URI, element: <NotFound /> },
        { path: ROUTES.FORBIDDEN_URI, element: <Forbidden /> },
        { path: ROUTES.SERVICE_UNAVAILABLE_URI, element: <ServiceUnavailable /> },
        { path: ROUTES.TIMEOUT_URI, element: <Timeout /> },
        { path: ROUTES.MAINTENANCE_URI, element: <PageMaintenance /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    }
  ]);
}

// IMPORT COMPONENTS
// Auth flow
const LoginPage = Loadable(lazy(() => import('features/auth/LoginPage')));
const SelectCorporation = Loadable(lazy(() => import('features/auth/SelectCorporationPage')));
const SignUp = Loadable(lazy(() => import('features/signUp')));
const Register = Loadable(lazy(() => import('features/Register')));
const ImportLicense = Loadable(lazy(() => import('features/ImportLicense')));
const VerifyUser = Loadable(lazy(() => import('features/VerifyUser')));

// Static pages
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Forbidden = Loadable(lazy(() => import('../pages/Page403')));
const ServiceUnavailable = Loadable(lazy(() => import('../pages/Page500')));
const Timeout = Loadable(lazy(() => import('../pages/PageTimeout')));
const PageMaintenance = Loadable(lazy(() => import('../pages/PageMaintenance')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('features/Dashboard')));
const ChangePassword = Loadable(lazy(() => import('../features/auth/ChangePassword')));
// Dashboard - System
const UserProfile = Loadable(lazy(() => import('features/system/UserProfile')));
const UserManagement = Loadable(lazy(() => import('../features/system/UserManagement')));
const CorpMgt = Loadable(lazy(() => import('../features/system/CorpManagement')));
const ContractManagement = Loadable(lazy(() => import('../features/system/ContractManagement')));
const ProjectManagement = Loadable(lazy(() => import('../features/system/ProjectManagement')));
const OrgManagement = Loadable(lazy(() => import('../features/system/OrgManagement')));
const ApplicationManagement = Loadable(
  lazy(() => import('../features/system/ApplicationManagement'))
);
const MenuManagement = Loadable(lazy(() => import('../features/system/MenuManagement')));
const CommonCodeManagement = Loadable(
  lazy(() => import('../features/system/CommonCodeManagement'))
);
const PermissionManagement = Loadable(
  lazy(() => import('../features/system/PermissionManagement'))
);
const WorkflowsManagement = Loadable(lazy(() => import('../features/system/WorkflowManagement')));

const LicenseManagement = Loadable(lazy(() => import('../features/system/LicenseManagement')));

const DynamicContentManagement = Loadable(
  lazy(() => import('../features/system/DynamicContentManagement'))
);

const LoginLog = Loadable(lazy(() => import('../features/system/LogMangement/LoginLog')));

const ActionLog = Loadable(lazy(() => import('../features/system/LogMangement/ActionLog')));
const UserGuideSetting = Loadable(lazy(() => import('../features/system/UserGuideSetting')));
const ConcurrentUserAndHistory = Loadable(
  lazy(() => import('../features/system/ConcurrentUserAndHistory'))
);

// Dashboard - Help desk
const Ticket = Loadable(lazy(() => import('../features/helpDesk/ticket')));
const TicketDetail = Loadable(lazy(() => import('../features/helpDesk/ticket/TicketDetail')));
const ViewAnnouncement = Loadable(
  lazy(() => import('../features/helpDesk/announcement/ViewAnnouncement'))
);
const AnnouncementManagement = Loadable(
  lazy(() => import('../features/helpDesk/announcement/AnnouncementManagement'))
);
const DocumentManagement = Loadable(
  lazy(() => import('../features/helpDesk/knowledgeManagement/Document/DocumentManagement'))
);
const FAQManagement = Loadable(
  lazy(() => import('../features/helpDesk/knowledgeManagement/FAQ/FAQManagement'))
);
const ViewDocument = Loadable(
  lazy(() => import('../features/helpDesk/knowledgeManagement/Document/ViewDocument'))
);
const ViewDetailDocument = Loadable(
  lazy(() => import('../features/helpDesk/knowledgeManagement/Document/ViewDetailDocument'))
);
const ViewFAQ = Loadable(
  lazy(() => import('../features/helpDesk/knowledgeManagement/FAQ/ViewFAQ'))
);
const ViewDetailFAQ = Loadable(
  lazy(() => import('../features/helpDesk/knowledgeManagement/FAQ/ViewDetailFAQ'))
);
const ScheduleManagement = Loadable(lazy(() => import('../features/helpDesk/ScheduleManagement')));
const InternalSharingBoard = Loadable(lazy(() => import('../features/helpDesk/InternalSharing')));
const ReportingManagement = Loadable(
  lazy(() => import('../features/helpDesk/reporting/ReportingManagement'))
);
const Notifications = Loadable(lazy(() => import('../features/notifications')));

const InternalTechnicalPost = Loadable(
  lazy(() => import('../features/helpDesk/InternalTechnicalPost'))
);

const InternalTechnicalPostDetail = Loadable(
  lazy(() => import('../features/helpDesk/InternalTechninalPostDetail'))
);
