import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class CorpListModel extends BaseModel {
  @Expose({ name: 'corpName' })
  corpName: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'roleCode' })
  roleCode: string | undefined;

  @Expose({ name: 'roleName' })
  roleName: string | undefined;

  @Expose({ name: 'logoUrl' })
  logoUrl: string | undefined;
}
