// material

import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function FolderIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="215"
        height="134"
        viewBox="0 0 215 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M195.767 26.3675H152.493L142.876 17.6714H118.835C113.546 17.6714 109.218 21.5847 109.218 26.3675V43.7599H205.383V35.0637C205.383 30.2808 201.056 26.3675 195.767 26.3675Z"
            fill="#FFA000"
          />
          <path
            d="M195.767 26.3672H118.835C113.546 26.3672 109.218 30.2805 109.218 35.0633V78.5441C109.218 83.327 113.546 87.2403 118.835 87.2403H195.767C201.056 87.2403 205.383 83.327 205.383 78.5441V35.0633C205.383 30.2805 201.056 26.3672 195.767 26.3672Z"
            fill="#FFCA28"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M146.803 39.412H103.528L93.9117 30.7158H69.8704C64.5813 30.7158 60.2539 34.6291 60.2539 39.412V56.8043H156.419V48.1081C156.419 43.3253 152.092 39.412 146.803 39.412Z"
            fill="#229A16"
          />
          <path
            d="M146.803 39.4116H69.8704C64.5813 39.4116 60.2539 43.3249 60.2539 48.1078V91.5886C60.2539 96.3715 64.5813 100.285 69.8704 100.285H146.803C152.092 100.285 156.419 96.3715 156.419 91.5886V48.1078C156.419 43.3249 152.092 39.4116 146.803 39.4116Z"
            fill="#AAF27F"
          />
        </g>
        <g opacity="0.8">
          <path
            d="M96.1654 55.4564H52.891L43.2745 46.7603H19.2332C13.9441 46.7603 9.6167 50.6735 9.6167 55.4564V72.8487H105.782V64.1526C105.782 59.3697 101.454 55.4564 96.1654 55.4564Z"
            fill="#0089D0"
          />
          <path
            d="M96.1654 55.4561H19.2332C13.9441 55.4561 9.6167 59.3693 9.6167 64.1522V107.633C9.6167 112.416 13.9441 116.329 19.2332 116.329H96.1654C101.454 116.329 105.782 112.416 105.782 107.633V64.1522C105.782 59.3693 101.454 55.4561 96.1654 55.4561Z"
            fill="#74CAFF"
          />
        </g>
      </svg>
    </Box>
  );
}
