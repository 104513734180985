import { Typography } from '@mui/material';
import useLocales from 'hooks/useLocales';
import AttachedItem from 'components/upload/AttachedItem';
import { FilerServiceTargetType } from '../../types';
import { WrapperItemFile, WrapperListItemFile } from '../../styles';

type DownloadServiceTargetProps = {
  fileServiceTargets: Array<FilerServiceTargetType>;
};

const DownloadServiceTarget = ({ fileServiceTargets }: DownloadServiceTargetProps) => {
  const { t } = useLocales();

  return (
    <>
      {fileServiceTargets.length ? (
        <>
          <Typography fontWeight="bold">
            {t('helpDesk.ticketManagement.form.downloadServiceTarget')}:
          </Typography>
          <WrapperListItemFile>
            {fileServiceTargets.map((file: FilerServiceTargetType) => (
              <WrapperItemFile key={file.id}>
                <AttachedItem
                  name={file.fileName.split('.')[0]}
                  url={file.fileUrl}
                  extension={`${file.fileName.split('.')[1]}`}
                  size="sm"
                  canRemove={false}
                />
              </WrapperItemFile>
            ))}
          </WrapperListItemFile>
        </>
      ) : null}
    </>
  );
};

export default DownloadServiceTarget;
