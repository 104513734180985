import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class BaseUserListModel extends BaseModel {
  @Expose({ name: 'username' })
  username: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'orgName' })
  orgName: string | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'title' })
  title: string | undefined;
}
