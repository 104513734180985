import { Box, Typography, DialogTitle, Breakpoint, Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import { DialogAnimate } from 'components/animate';

type CommonDialogProps = {
  title?: any;
  children: any;
  isOpenDialog: boolean;
  onCloseDialog?: VoidFunction;
  maxWidth?: Breakpoint;
  styleCustom?: any;
  paddingTopContent?: number;
  styleTitle?: any;
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  closeButton: () => ({
    position: 'absolute',
    right: theme.typography.pxToRem(22),
    top: theme.typography.pxToRem(22),
    color: theme.palette.grey[500],
    cursor: 'pointer'
  })
}));

const CommonDialog = (props: CommonDialogProps) => {
  const {
    title,
    children,
    isOpenDialog,
    onCloseDialog,
    maxWidth = 'md',
    styleCustom,
    paddingTopContent = 3,
    styleTitle
  } = props;
  const classes = useStyles(props);

  return (
    <DialogAnimate
      open={isOpenDialog}
      onClose={onCloseDialog}
      maxWidth={!styleCustom && maxWidth}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      sx={styleCustom}
    >
      <DialogTitle>
        <Typography sx={styleTitle} fontWeight="bold" color="primary">
          {title}
        </Typography>
        {onCloseDialog ? (
          <CloseIcon aria-label="close" className={classes.closeButton} onClick={onCloseDialog} />
        ) : null}
      </DialogTitle>
      <Box sx={{ p: 3, pt: paddingTopContent }}>{children}</Box>
    </DialogAnimate>
  );
};

export default CommonDialog;
