import { ButtonProps } from '@mui/material';

import SearchButton from 'components/@material-extend/buttons/SearchButton';
import AuthButton from './AuthButton';

import { SEARCH_BUTTON_ID } from '../../constants';

const AuthSearchButton = (props: ButtonProps) => (
  <AuthButton btnId={SEARCH_BUTTON_ID}>
    <SearchButton {...props} />
  </AuthButton>
);

export default AuthSearchButton;
