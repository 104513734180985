/* eslint-disable func-names */
import { useEffect, useRef } from 'react';
import Quill from 'quill';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';

import i18next from 'i18next';

import QuillMention from 'quill-mention';

// icons
import { Icon } from '@iconify/react';
import ReactDOMServer from 'react-dom/server';
import codeFill from '@iconify/icons-eva/code-fill';
import roundUndo from '@iconify/icons-ic/round-undo';
import roundRedo from '@iconify/icons-ic/round-redo';
import tableIcon from '@iconify/icons-ic/round-table-view';
import link2Fill from '@iconify/icons-eva/link-2-fill';
import image2Fill from '@iconify/icons-eva/image-2-fill';
import roundSubscript from '@iconify/icons-ic/round-subscript';
import roundFunctions from '@iconify/icons-ic/round-functions';
import roundFormatBold from '@iconify/icons-ic/round-format-bold';
import roundSuperscript from '@iconify/icons-ic/round-superscript';
import roundLocalMovies from '@iconify/icons-ic/round-local-movies';
import roundFormatQuote from '@iconify/icons-ic/round-format-quote';
import roundFormatClear from '@iconify/icons-ic/round-format-clear';
import roundFormatItalic from '@iconify/icons-ic/round-format-italic';
import roundFormatColorFill from '@iconify/icons-ic/round-format-color-fill';
import roundFormatColorText from '@iconify/icons-ic/round-format-color-text';
import roundFormatAlignLeft from '@iconify/icons-ic/round-format-align-left';
import roundFormatUnderlined from '@iconify/icons-ic/round-format-underlined';
import roundFormatAlignRight from '@iconify/icons-ic/round-format-align-right';
import roundFormatAlignCenter from '@iconify/icons-ic/round-format-align-center';
import roundFormatListBulleted from '@iconify/icons-ic/round-format-list-bulleted';
import roundFormatListNumbered from '@iconify/icons-ic/round-format-list-numbered';
import roundFormatAlignJustify from '@iconify/icons-ic/round-format-align-justify';
import roundFormatStrikethrough from '@iconify/icons-ic/round-format-strikethrough';
import roundFormatIndentDecrease from '@iconify/icons-ic/round-format-indent-decrease';
import roundFormatIndentIncrease from '@iconify/icons-ic/round-format-indent-increase';
import roundFormatTextdirectionLToR from '@iconify/icons-ic/round-format-textdirection-l-to-r';
import roundFormatTextdirectionRToL from '@iconify/icons-ic/round-format-textdirection-r-to-l';

import { QuillEditorToolbarStyle } from './styles';
import { FONT_SIZE_OPTIONS, FONT_FAMILY_OPTIONS, DEFAULT_FONT_SIZE } from './constants';
import { QuillEditorToolbarProps } from './interfaces';
import MentionPostBlot from './mention/MentionPostBlot';
import ImageUploader from './image/ImageUploader';
import SmartBreak from './line-break/LineBreakBlot';
import QuillBetterTable from './table';
import MentionUserBlot from './mention/MentionUserBlot';

// ----------------------------------------------------------------------

// Register modules
Quill.register('line-break', SmartBreak);

Quill.register('modules/mentionUser', QuillMention);
Quill.register(MentionUserBlot, true);

Quill.register('modules/mentionPost', QuillMention);
Quill.register(MentionPostBlot, true);

Quill.register('modules/imageUploader', ImageUploader);

Quill.register('modules/better-table', QuillBetterTable);

const Size = Quill.import('attributors/style/size');
Size.whitelist = FONT_SIZE_OPTIONS;
Quill.register(Size, true);

const Font = Quill.import('attributors/style/font');
Font.whitelist = FONT_FAMILY_OPTIONS;
Quill.register(Font, true);

const Icons = Quill.import('ui/icons');
function renderIcon(icon: object) {
  return ReactDOMServer.renderToString(<Icon icon={icon} width={18} height={18} />);
}

Icons.bold = renderIcon(roundFormatBold);
Icons.italic = renderIcon(roundFormatItalic);
Icons.underline = renderIcon(roundFormatUnderlined);
Icons.strike = renderIcon(roundFormatStrikethrough);
Icons.link = renderIcon(link2Fill);
Icons.image = renderIcon(image2Fill);
Icons.code = renderIcon(image2Fill);
Icons['code-block'] = renderIcon(codeFill);
Icons.list.bullet = renderIcon(roundFormatListBulleted);
Icons.list.ordered = renderIcon(roundFormatListNumbered);
Icons.indent['+1'] = renderIcon(roundFormatIndentIncrease);
Icons.indent['-1'] = renderIcon(roundFormatIndentDecrease);
Icons.script.sub = renderIcon(roundSubscript);
Icons.script.super = renderIcon(roundSuperscript);
Icons.direction[''] = renderIcon(roundFormatTextdirectionLToR);
Icons.direction.rtl = renderIcon(roundFormatTextdirectionRToL);
Icons.color = renderIcon(roundFormatColorText);
Icons.background = renderIcon(roundFormatColorFill);
Icons.video = renderIcon(roundLocalMovies);
Icons.blockquote = renderIcon(roundFormatQuote);
Icons.clean = renderIcon(roundFormatClear);
Icons.formula = renderIcon(roundFunctions);
Icons.align[''] = renderIcon(roundFormatAlignLeft);
Icons.align.center = renderIcon(roundFormatAlignCenter);
Icons.align.justify = renderIcon(roundFormatAlignJustify);
Icons.align.right = renderIcon(roundFormatAlignRight);
Icons['better-table'] = renderIcon(tableIcon);

// ----------------------------------------------------------------------

export const undoChange = () => {
  // @ts-ignore
  this.quill.history.undo();
};

export const redoChange = () => {
  // @ts-ignore
  this.quill.history.redo();
};

const t = i18next.t.bind(i18next);

function FormatItem({
  format,
  fontFamilies,
  fontSizes,
  toolbarId,
  onClickInsertTable
}: {
  format: string;
  fontFamilies?: string[];
  fontSizes?: string[];
  toolbarId?: string;
  onClickInsertTable?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const headingLevels = [...new Array(6)].map((_, index) => index + 1);
  const getTooltipTitle = (format: string) => t(`editor.tooltip.${camelCase(format)}`);

  switch (format) {
    case 'font':
      return (
        <select className="ql-font" defaultValue="" title={getTooltipTitle(format)}>
          <option value="">{t('editor.font')}</option>
          {fontFamilies &&
            fontFamilies.map((font) => (
              <option key={font} value={font}>
                {font}
              </option>
            ))}
        </select>
      );
    case 'size':
      return (
        <select
          className="ql-size"
          defaultValue={DEFAULT_FONT_SIZE}
          title={getTooltipTitle(format)}
        >
          {fontSizes &&
            fontSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
        </select>
      );
    case 'header':
      return (
        <select className="ql-header" defaultValue="" title={getTooltipTitle(format)}>
          {headingLevels.map((heading) => (
            <option key={heading} value={heading}>
              {`${t('editor.heading')} ${heading}`}
            </option>
          ))}
          <option value="">{t('editor.normal')}</option>
        </select>
      );

    case 'color':
    case 'background':
    case 'align':
      // Hotfix for Quill lib's issue noted in https://github.com/quilljs/quill/issues/2287
      return !isMounted.current ? (
        <select className={`ql-${format}`} title={getTooltipTitle(format)} />
      ) : (
        <></>
      );

    case 'bold':
    case 'italic':
    case 'underline':
    case 'strike':
    case 'code-block':
    case 'blockquote':
    case 'link':
    case 'image':
    case 'video':
    case 'formula':
    case 'clean':
      return <button type="button" className={`ql-${format}`} title={getTooltipTitle(format)} />;

    case 'list':
      return (
        <div className="ql-formats" title={getTooltipTitle(format)}>
          <button
            type="button"
            className="ql-list"
            value="ordered"
            title={t('editor.tooltip.list.number')}
          />
          <button
            type="button"
            className="ql-list"
            value="bullet"
            title={t('editor.tooltip.list.bullet')}
          />
        </div>
      );
    case 'indent':
      return (
        <div className="ql-formats">
          <button
            type="button"
            className="ql-indent"
            value="-1"
            title={t('editor.tooltip.indent.decrease')}
          />
          <button
            type="button"
            className="ql-indent"
            value="+1"
            title={t('editor.tooltip.indent.increase')}
          />
        </div>
      );
    case 'script':
      return (
        <div className="ql-formats">
          <button
            type="button"
            className="ql-script"
            value="super"
            title={t('editor.tooltip.script.superscript')}
          />
          <button
            type="button"
            className="ql-script"
            value="sub"
            title={t('editor.tooltip.script.subscript')}
          />
        </div>
      );
    case 'direction':
      return (
        <button
          type="button"
          className="ql-direction"
          value="rtl"
          title={getTooltipTitle(format)}
        />
      );
    case 'undo':
      return (
        <button type="button" className="ql-undo" title={getTooltipTitle(format)}>
          <Icon icon={roundUndo} width={18} height={18} />
        </button>
      );
    case 'redo':
      return (
        <button type="button" className="ql-redo" title={getTooltipTitle(format)}>
          <Icon icon={roundRedo} width={18} height={18} />
        </button>
      );
    case 'better-table':
      return (
        <button
          type="button"
          // id={`${toolbarId}-insert-table`}
          aria-describedby={`${toolbarId}-insert-table-popover`}
          className={`ql-${format}`}
          onClick={onClickInsertTable}
          title={getTooltipTitle(format)}
        />
      );
  }
  return null;
}

function FormatList({
  formats,
  toolbarId = '',
  onClickInsertTable,
  ...others
}: {
  toolbarId?: string;
  formats: any[];
  fontFamilies?: string[];
  fontSizes?: string[];
  headings?: string[];
  onClickInsertTable?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <div className="ql-formats">
      {formats &&
        formats.map((format, index) =>
          isArray(format) ? (
            <FormatList
              toolbarId={toolbarId}
              key={index}
              formats={format}
              onClickInsertTable={onClickInsertTable}
              {...others}
            />
          ) : (
            <FormatItem
              toolbarId={toolbarId}
              key={format}
              format={format}
              onClickInsertTable={onClickInsertTable}
              {...others}
            />
          )
        )}
    </div>
  );
}

export default function QuillEditorToolbar({
  id,
  fontFamilies,
  fontSizes,
  formats = [],
  onClickInsertTable,
  ...other
}: QuillEditorToolbarProps) {
  return (
    <QuillEditorToolbarStyle {...other}>
      <div id={id}>
        <FormatList
          toolbarId={id}
          formats={formats}
          fontSizes={fontSizes}
          fontFamilies={fontFamilies}
          onClickInsertTable={onClickInsertTable}
        />
      </div>
    </QuillEditorToolbarStyle>
  );
}
