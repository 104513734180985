// Ref
// https://github.com/NoelOConnell/quill-image-uploader/blob/master/src/quill.imageUploader.js

import isFunction from 'lodash/isFunction';

// Quill libs
import ImageUploader from 'quill-image-uploader';

import { IMAGE_MIME_REGEX } from 'constants/common';
import { Range, UnprivilegedEditor } from '../interfaces';

export interface ImageUploaderOptionProps {
  accept?: string;
  upload: (file: File) => Promise<string>;
}

export default class CustomImageUploader extends ImageUploader {
  quill: UnprivilegedEditor;

  options: ImageUploaderOptionProps;

  fileHolder?: HTMLElement;

  range?: Range;

  constructor(quill: UnprivilegedEditor, options: ImageUploaderOptionProps) {
    super(quill, options);

    this.quill = quill;
    this.options = options;
  }

  // overriding method
  selectLocalImage() {
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement('input');
    this.fileHolder.setAttribute('type', 'file');
    this.fileHolder.setAttribute('accept', this.options.accept || 'image/*');
    this.fileHolder.setAttribute('style', 'visibility:hidden');

    this.fileHolder.onchange = super.fileChanged.bind(this);

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      this.fileHolder && document.body.removeChild(this.fileHolder);
    });
  }

  // overriding method
  handleDrop(event: React.DragEvent): void {
    const file = event.dataTransfer.files[0];

    const accept = this?.options?.accept;

    if (accept && accept !== 'image/*' && !accept.includes(file.type)) {
      return;
    }

    super.handleDrop(event);
  }

  // overriding method
  handlePaste(event: React.ClipboardEvent): void {
    const clipboard = event.clipboardData || (window as any).clipboardData;

    const accept = this?.options?.accept;

    // IE 11 is .files other browsers are .items
    if (clipboard && (clipboard.items || clipboard.files)) {
      const items = clipboard.items || clipboard.files;

      for (let i = 0; i < items.length; i++) {
        const file = isFunction(items[i].getAsFile) ? items[i].getAsFile() : items[i];
        if (
          file && accept && accept !== 'image/*'
            ? accept.includes(file?.type || '')
            : IMAGE_MIME_REGEX.test(items[i].type)
        ) {
          this.range = this.quill.getSelection();
          setTimeout(() => {
            this.range = this.quill.getSelection();
            this.readAndUploadFile(file);
          }, 0);
        }
      }
    }
  }

  // Override super's method
  readAndUploadFile(file: any) {
    let isUploadReject = false;

    const fileReader = new FileReader();

    fileReader.addEventListener(
      'load',
      () => {
        if (!isUploadReject) {
          // Remove base64 image before inserting loading image
          this.removeBase64Image();
          const base64ImageSrc = fileReader.result;
          this.insertBase64Image(base64ImageSrc); // this base 64 image is for ui loading
        }
      },
      false
    );

    if (file) {
      fileReader.readAsDataURL(file);
    }

    this.options.upload(file).then(
      (imageUrl) => {
        this.insertToEditor(imageUrl);
      },
      (error) => {
        isUploadReject = true;
        this.removeBase64Image();
        console.warn(error);
      }
    );
  }
}
