import { ButtonProps } from '@mui/material';
import useLocales from 'hooks/useLocales';
import MButton from '../MButton';

// For automation test
const TEST_ID = 'newTicketBtn';

const NewTicketButton = (props: ButtonProps) => {
  const { t } = useLocales();
  return (
    <MButton test-id={TEST_ID} {...props}>
      {t('common.button.newTicket')}
    </MButton>
  );
};

export default NewTicketButton;
