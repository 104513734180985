import { experimentalStyled as styled } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';

export const ItemConfigStyled = styled(Stack)(({ theme }) => ({
  padding: ` 0 ${theme.spacing(1)}`,
  marginTop: theme.spacing(1),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const WrapperIconChecked = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const TitleConfigStyled = styled('div')(({ theme }) => ({
  width: '60%'
}));

export const GroupCheckBoxTicketStatus = styled('div')(({ theme }) => ({
  display: 'flex'
}));

export const WrapperItemTicketStatus = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  paddingRight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
