// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function ForbiddenIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="273"
        height="200"
        viewBox="0 0 273 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 110.434C0 133.465 8.49327 154.453 22.4695 170.315C38.5034 188.517 61.7542 199.962 87.6712 200C98.9979 200.016 109.828 197.841 119.777 193.869C124.92 191.814 130.677 192.05 135.659 194.484C141.503 197.335 148.047 198.934 154.957 198.934C157.811 198.934 160.606 198.659 163.31 198.138C171.028 196.654 178.052 193.144 183.819 188.171C187.444 185.045 192.087 183.474 196.832 183.479H196.912C212.591 183.479 227.154 178.628 239.245 170.315C250.055 162.892 258.882 152.706 264.764 140.745C269.891 130.323 272.778 118.549 272.778 106.088C272.778 63.3485 238.809 28.6963 196.912 28.6963C192.641 28.6963 188.457 29.0643 184.374 29.751C173.565 11.9 154.208 0 132.126 0C122.803 0 113.964 2.12094 106.053 5.9229C98.5291 9.52685 91.8396 14.6479 86.3673 20.8889C67.7861 21.1911 50.6048 27.3833 36.5425 37.7175C14.4069 53.9751 0 80.4902 0 110.434Z"
            fill="url(#paint0_linear_5499:6459)"
          />
        </g>
        <g filter="url(#filter0_d_5499:6459)">
          <ellipse
            opacity="0.15"
            cx="103.491"
            cy="54.236"
            rx="25.3588"
            ry="24.7907"
            fill="#FFC107"
          />
          <ellipse
            cx="103.491"
            cy="54.2358"
            rx="13.3014"
            ry="13.0034"
            fill="url(#paint1_linear_5499:6459)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.161 170.341C158.801 170.341 174.722 154.776 174.722 135.577C174.722 116.377 158.801 100.812 139.161 100.812C119.522 100.812 103.601 116.377 103.601 135.577C103.601 154.776 119.522 170.341 139.161 170.341ZM139.161 157.46C151.524 157.46 161.546 147.662 161.546 135.577C161.546 123.491 151.524 113.694 139.161 113.694C126.799 113.694 116.777 123.491 116.777 135.577C116.777 147.662 126.799 157.46 139.161 157.46Z"
            fill="#04297A"
          />
          <path
            d="M213.08 134.48C216.792 135.696 219.704 137.712 221.816 140.528C223.992 143.344 225.08 146.8 225.08 150.896C225.08 157.36 222.904 162.384 218.552 165.968C214.264 169.552 208.472 171.344 201.176 171.344C197.464 171.344 193.944 170.928 190.616 170.096C187.352 169.264 184.568 168.24 182.264 167.024L185.24 156.656C190.744 159.408 196.056 160.784 201.176 160.784C204.76 160.784 207.576 159.952 209.624 158.288C211.736 156.624 212.792 154.16 212.792 150.896C212.792 144.112 208.664 140.72 200.408 140.72H193.592V129.2H200.312C203.768 129.2 206.392 128.432 208.184 126.896C210.04 125.296 210.968 123.056 210.968 120.176C210.968 117.68 210.104 115.696 208.376 114.224C206.712 112.752 204.312 112.016 201.176 112.016C198.616 112.016 196.12 112.4 193.688 113.168C191.32 113.936 188.856 115.056 186.296 116.528L184.472 105.68C189.848 102.864 195.832 101.456 202.424 101.456C208.824 101.456 213.784 103.056 217.304 106.256C220.888 109.392 222.68 113.52 222.68 118.64C222.68 122.288 221.848 125.488 220.184 128.24C218.52 130.928 216.152 133.008 213.08 134.48Z"
            fill="#0089D0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.4308 146.996C99.4308 144.699 98.5829 143.87 96.227 143.87H89.764V106.864C89.764 104.378 88.9161 103.732 86.5602 103.732H81.3481C79.5694 103.727 77.8933 104.546 76.8309 105.94L45.558 143.87C44.7737 144.974 44.3829 146.299 44.4461 147.641V150.956C44.4461 153.26 45.2939 154.088 47.6429 154.088H78.7142V166.147C78.7142 168.444 79.5621 169.273 81.911 169.273H86.5255C88.8814 169.273 89.7292 168.444 89.7292 166.147V154.088H96.227C98.7358 154.088 99.4308 153.26 99.4308 150.956V146.996ZM58.2756 143.87L78.7142 119.195V143.87H58.2756Z"
            fill="#0089D0"
          />
          <path
            opacity="0.24"
            d="M241.875 65.1231C241.875 62.5866 239.772 60.5305 237.177 60.5305C236.924 60.5303 236.67 60.5507 236.42 60.5916C235.321 58.5879 233.186 57.3355 230.86 57.3306H230.631C231.386 54.3629 230.183 51.2483 227.61 49.5093C225.037 47.7703 221.631 47.7703 219.058 49.5093C216.485 51.2483 215.282 54.3629 216.037 57.3306H215.808C213.494 57.2556 211.323 58.4193 210.144 60.3661C208.965 62.3129 208.965 64.7333 210.144 66.6802C211.323 68.627 213.494 69.7906 215.808 69.7157H237.789V69.675C240.125 69.3721 241.871 67.4267 241.875 65.1231Z"
            fill="#0089D0"
          />
          <path
            opacity="0.24"
            d="M59.2483 110.583C59.2483 109.537 58.8236 108.535 58.0677 107.796C57.3118 107.057 56.2866 106.642 55.2176 106.642C55.0011 106.646 54.7852 106.664 54.5713 106.696C53.6235 104.974 51.7835 103.901 49.783 103.904H49.5885C50.2435 101.357 49.2144 98.6794 47.0056 97.184C44.7967 95.6887 41.871 95.6887 39.6622 97.184C37.4533 98.6794 36.4243 101.357 37.0793 103.904H36.8847C34.898 103.835 33.0311 104.831 32.0167 106.503C31.0023 108.174 31.0023 110.253 32.0167 111.925C33.0311 113.596 34.898 114.592 36.8847 114.523H55.7388V114.489C57.7445 114.23 59.2448 112.56 59.2483 110.583Z"
            fill="#0089D0"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_5499:6459"
            x="27.2559"
            y="25.4453"
            width="234.619"
            height="165.898"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="8" dy="8" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5499:6459" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5499:6459"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_5499:6459"
            x1="186.858"
            y1="235.573"
            x2="283.466"
            y2="12.848"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0089D0" />
            <stop offset="1" stopColor="#E7F6FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5499:6459"
            x1="76.888"
            y1="54.2358"
            x2="102.888"
            y2="80.8318"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE16A" />
            <stop offset="1" stopColor="#B78103" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
