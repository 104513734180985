import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// material
import { Box, BoxProps } from '@mui/material';
import { TLink } from './@material-extend/MBreadcrumbs';
// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
  breadcrumbs?: TLink[];
  actionButton?: JSX.Element;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', breadcrumbs = [], actionButton, ...other }, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
);

export default Page;
