import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class UserCURDModel extends BaseModel {
  @Expose({ name: 'username' })
  username: string | undefined;

  @Expose({ name: 'email' })
  email: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'deptName' })
  deptName: string | undefined;

  @Expose({ name: 'title' })
  title: string | undefined;

  @Expose({ name: 'phoneNo' })
  phoneNo: string | undefined;

  @Expose({ name: 'countryCode' })
  countryCode: string | undefined;

  @Expose({ name: 'country' })
  country: string | undefined;

  @Expose({ name: 'roleCode' })
  roleCode: string | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'approveStatus' })
  approveStatus: string | undefined;

  @Expose({ name: 'deniedNote' })
  deniedNote: string | undefined;

  @Expose({ name: 'timeOut' })
  timeOut: number | undefined;
}

export class UserResetPasswordModel extends BaseModel {
  @Expose({ name: 'username' })
  username: string | undefined;

  @Expose({ name: 'newPwd' })
  newPwd: string | undefined;
}

export class UserCheckDuplicationModel extends BaseModel {
  @Expose({ name: 'username' })
  username: string | undefined;

  @Expose({ name: 'email' })
  email: string | undefined;

  @Expose({ name: 'isEdit' })
  isEdit: boolean | undefined;
}
