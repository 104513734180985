import axios, { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';
import clone from 'lodash/clone';
import mime from 'mime-types';
import set from 'lodash/set';
import dayjs from 'dayjs';

import {
  BASE_API_TIMEOUT,
  FILENAME_REGEX,
  FILE_EXTENSION_REGEX,
  MULTIPART_API_TIMEOUT
} from 'constants/common';

import {
  GET_ACCESS_TOKEN,
  LOGIN,
  HTTP_AUTHORIZATION_HEADER,
  MENU_CODE,
  INVALID_CREDENTIAL_ERR
} from 'constants/apiUrl';

import * as auth from 'utils/authHelper';
import { BASE_API_URL } from 'constants/appConfig';
import { SIGN_IN_URI } from 'constants/routes';

import { AUTH_STATE } from 'features/auth/slices';

// const test = 'http://192.168.229.40:8080/api';
// export const GS = 'https://133.186.229.233/api/';
const apiClient = axios.create({
  baseURL: BASE_API_URL,
  timeout: BASE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

export const downloadClient = axios.create({
  baseURL: BASE_API_URL,
  timeout: BASE_API_TIMEOUT,
  responseType: 'blob' // important
});

export const multipartConfig = {
  headers: {
    'content-type': 'multipart/form-data'
  },
  timeout: MULTIPART_API_TIMEOUT
};

const setRequestConfig = (axiosConfig: AxiosRequestConfig, passCorp: boolean) => {
  // after refresh token, we re-call the original api with the config was added nescessary fields
  // return the config with only token updated
  if (typeof axiosConfig.data === 'string') {
    const token = auth.getAccessToken();
    axiosConfig.headers[HTTP_AUTHORIZATION_HEADER] = `Bearer ${token}`;
    return axiosConfig;
  }

  const config = clone({
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      'Accept-Language': i18next.language,
      'Time-Zone': dayjs().format('Z')
    }
  });

  if (
    config.url &&
    (config.url.indexOf(LOGIN) !== -1 || config.url.indexOf(GET_ACCESS_TOKEN) !== -1)
  ) {
    // Dont pass token with public API
    return config;
  }

  const token = auth.getAccessToken();
  if (token) {
    config.headers[HTTP_AUTHORIZATION_HEADER] = `Bearer ${token}`;
    config.headers[MENU_CODE] = AUTH_STATE.currentMenuCode || '';
    // Set the cord code into all request data
    if (passCorp) {
      if (config.data instanceof FormData) {
        config.data.append('corpCode', auth.getCurrentCorpCode() || '');
      } else {
        set(config, 'data.corpCode', auth.getCurrentCorpCode());
      }
    }
  }

  return config;
};

// Add a request interceptor
apiClient.interceptors.request.use(
  (axiosConfig) => setRequestConfig(axiosConfig, true),
  (error) => Promise.reject(error)
);

downloadClient.interceptors.request.use(
  (axiosConfig) => setRequestConfig(axiosConfig, true),
  (error) => Promise.reject(error)
);

const logUserOut = () => {
  auth.clearUserCredential();
  if (window.location.pathname !== SIGN_IN_URI) window.location.href = SIGN_IN_URI;
};

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (err) => {
    const error = { ...err };
    const originalRequest = error.config;

    // refresh token has been expired
    if (error.response?.status === 401 && originalRequest.url === GET_ACCESS_TOKEN) {
      logUserOut();
      return Promise.reject();
    }

    if (error.response?.status === 401 && !originalRequest.retry) {
      auth.setAccessToken('');
      originalRequest.retry = true;
      const refreshToken = auth.getRefreshToken();
      if (refreshToken) {
        return apiClient
          .post(GET_ACCESS_TOKEN, {
            refreshToken,
            corpCode: auth.getCurrentCorpCode() || ''
          })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              const {
                data: {
                  data: { accessToken }
                }
              } = response;
              auth.setAccessToken(accessToken);
              // auth.setExpiredAt();
              // re-call the original api with the config
              // which was added nescessary fields in the first calls
              return apiClient(originalRequest);
            }
            return Promise.reject();
          });
      }
      if (originalRequest.url && originalRequest.url.indexOf(LOGIN) !== -1) {
        // if url is login - it means that credential is invalid
        error.code = 401;
        error.type = INVALID_CREDENTIAL_ERR;
        return Promise.reject(error);
      }
      logUserOut();
      return Promise.reject();
    }
    return Promise.reject(error);
  }
);

downloadClient.interceptors.response.use(
  async (response): Promise<any> => {
    const { headers, data } = response;

    if (headers['content-disposition']) {
      let filename = '';
      const contentDisposition = headers['content-disposition'];

      const matches = FILENAME_REGEX.exec(contentDisposition);
      if (matches !== null && matches[1]) {
        filename = decodeURIComponent(matches[1].replace(/['"]/g, '') || '');
      }

      // Get extension of the file
      const extension = FILE_EXTENSION_REGEX.exec(filename)![1];
      const mimeType = mime.lookup(extension) || undefined;

      // convert to Blob
      const blob = new Blob([data], {
        // Convert Extension to Mime
        type: mimeType
      });

      return { blob, filename };
    }

    return { blob: data };
  },
  (err) => Promise.reject(err)
);

export const apiForCorp = axios.create({
  baseURL: BASE_API_URL,
  timeout: BASE_API_TIMEOUT,
  responseType: 'blob' // important
});

// Add a request interceptor
apiForCorp.interceptors.request.use(
  (axiosConfig) => setRequestConfig(axiosConfig, false),
  (error) => Promise.reject(error)
);

export const apiPassCorp = axios.create({
  baseURL: BASE_API_URL,
  timeout: BASE_API_TIMEOUT
});

// Add a request interceptor
apiPassCorp.interceptors.request.use(
  (axiosConfig) => setRequestConfig(axiosConfig, false),
  (error) => Promise.reject(error)
);

export const apiClientMicrosoft = axios.create({
  baseURL: BASE_API_URL,
  timeout: BASE_API_TIMEOUT
});

export default apiClient;
