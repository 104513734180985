import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class UserSearchWithConditionsModel extends BaseModel {
  @Expose({ name: 'usePaging' })
  usePaging: string | undefined;

  @Expose({ name: 'itemPerPage' })
  itemPerPage: number | undefined;

  @Expose({ name: 'pageNum' })
  pageNum: number | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteriaConditionsModel | undefined;
}

export class SearchCriteriaConditionsModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'searchType' })
  searchType: string | undefined;

  @Expose({ name: 'searchValue' })
  searchValue: string | undefined;

  @Expose({ name: 'status' })
  status: string | undefined;

  @Expose({ name: 'orgType' })
  orgType: string | undefined;
}
