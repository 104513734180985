/**
 * Buttons used for Authenticated Dialogs
 */
import { Theme, Box, ButtonProps } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { DiscardButton } from 'components/@material-extend';
import {
  AuthDeleteButton,
  AuthEditButton,
  AuthNewButton,
  AuthNewTicketButton
} from 'features/auth/components/authButtons';

type AuthDialogButtonsProps = {
  isEdit: boolean;
  isRemovable?: boolean;
  onDiscard: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
  RemoveButton?: (props: ButtonProps) => JSX.Element;
  isSubmitting?: boolean;
  onSubmit?: () => void;
  labelName?: string;
  disabledSubmit?: boolean;
  formName?: string;
  hideDiscardButton?: boolean;
};

const useStyles = makeStyles<Theme, AuthDialogButtonsProps>((theme: Theme) => ({
  buttonGroups: ({ isEdit, isRemovable }) => ({
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: isEdit && isRemovable ? 'space-between' : 'flex-end'
  }),
  rightButtons: {
    display: 'flex',
    '&> button:not(:first-child)': {
      marginLeft: theme.typography.pxToRem(16)
    }
  }
}));

const AuthDialogButtons = (props: AuthDialogButtonsProps) => {
  const classes = useStyles(props);
  const {
    isEdit,
    isRemovable = false,
    onRemove,
    onDiscard,
    RemoveButton = AuthDeleteButton,
    isSubmitting = false,
    onSubmit,
    labelName,
    disabledSubmit = false,
    formName = '',
    hideDiscardButton = false
  } = props;
  let SubmitButton = AuthEditButton;

  if (!isEdit) {
    SubmitButton = formName === 'tikcetForm' ? AuthNewTicketButton : AuthNewButton;
  }

  const handelSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Box
      className={classes.buttonGroups}
      style={{ justifyContent: isRemovable ? 'space-between' : 'flex-end', width: '100%' }}
    >
      {isRemovable && <div>{isEdit ? <RemoveButton onClick={onRemove} /> : null}</div>}
      <Box className={classes.rightButtons}>
        {!hideDiscardButton && <DiscardButton onClick={onDiscard} />}
        <SubmitButton
          type="submit"
          loading={isSubmitting}
          onClick={handelSubmit}
          labelName={labelName}
          disabled={disabledSubmit}
        />
      </Box>
    </Box>
  );
};

export default AuthDialogButtons;
