import hljs from 'highlight.js';

import { KEY_CODES } from 'constants/common';
import { enterHandler } from './helpers';

/**
 * https://quilljs.com/docs/modules/clipboard/
 * The Clipboard handles copy, cut and paste between Quill and external applications.
 */
const clipboard = {
  matchVisual: false
};

/**
 * https://quilljs.com/docs/modules/history/
 * The History module is responsible for handling undo and redo for Quill.
 */
const history = {
  delay: 500,
  maxStack: 100,
  userOnly: true
};

/**
 * https://quilljs.com/docs/modules/keyboard/
 * The Keyboard module enables custom behavior for keyboard events in particular contexts.
 * Quill uses this to bind formatting hotkeys and prevent undesirable browser side effects.
 */
const keyboard = {
  bindings: {
    'shift enter': {
      key: KEY_CODES.ENTER,
      shiftKey: true,
      handler: enterHandler
    },
    'alt enter': {
      key: KEY_CODES.ENTER,
      altKey: true,
      handler: enterHandler
    },
    'ctrl enter': {
      key: KEY_CODES.ENTER,
      ctrlKey: true,
      handler: enterHandler
    },
    'cmd enter': {
      key: KEY_CODES.ENTER,
      metaKey: true,
      handler: enterHandler
    }
  }
};

/**
 * The Syntax Module enhances the Code Block format
 * by automatically detecting and applying syntax highlighting.
 * The highlight.js library is used as a dependency to parse and tokenize code blocks.
 * https://github.com/highlightjs/highlight.js/
 */

const modules = {
  clipboard,
  history,
  keyboard,
  syntax: {
    highlight: (text: any) => hljs.highlightAuto(text).value
  }
};

export default modules;
