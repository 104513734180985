import Quill from 'quill';
import QuillTableSelection from 'quill-better-table/src/modules/table-selection';
import { css, getRelativeRect } from 'quill-better-table/src/utils';

import { TableCell } from './TableBlots';
import { computeBoundaryFromRects } from '../helpers';

const PRIMARY_COLOR = '#0089D0';
const LINE_POSITIONS = ['left', 'right', 'top', 'bottom'];
const ERROR_LIMIT = 2;

class TableSelection extends QuillTableSelection {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(table: any, quill: Quill, options: any) {
    super(table, quill, options);
  }

  correctBoundary() {
    const tableContainer = Quill.find(this.table);

    if (!tableContainer) return;
    const tableCells = tableContainer.descendants(TableCell);

    tableCells.forEach((tableCell: TableCell) => {
      const { x, y, width, height } = getRelativeRect(
        tableCell.domNode.getBoundingClientRect(),
        this.quill.root.parentNode
      );
      const isCellIntersected =
        ((x + ERROR_LIMIT >= this.boundary.x && x + ERROR_LIMIT <= this.boundary.x1) ||
          (x - ERROR_LIMIT + width >= this.boundary.x &&
            x - ERROR_LIMIT + width <= this.boundary.x1)) &&
        ((y + ERROR_LIMIT >= this.boundary.y && y + ERROR_LIMIT <= this.boundary.y1) ||
          (y - ERROR_LIMIT + height >= this.boundary.y &&
            y - ERROR_LIMIT + height <= this.boundary.y1));
      if (isCellIntersected) {
        this.boundary = computeBoundaryFromRects(this.boundary, { x, y, width, height });
      }
    });
  }

  helpLinesInitial() {
    const parent = this.quill.root.parentNode;
    LINE_POSITIONS.forEach((direction) => {
      this[direction] = document.createElement('div');
      this[direction].classList.add('qlbt-selection-line');
      this[direction].classList.add(`qlbt-selection-line-${direction}`);

      css(this[direction], {
        position: 'absolute',
        display: 'none',
        'background-color': PRIMARY_COLOR
      });
      parent.appendChild(this[direction]);
    });
  }

  repositionHelpLines() {
    const tableViewScrollLeft = this.table.parentNode.scrollLeft;
    css(this.left, {
      display: 'block',
      left: `${this.boundary.x - tableViewScrollLeft - 1}px`,
      top: `${this.boundary.y - 1}px`,
      height: `${this.boundary.height + 1}px`,
      width: '1px'
    });

    css(this.right, {
      display: 'block',
      left: `${this.boundary.x1 - tableViewScrollLeft}px`,
      top: `${this.boundary.y - 1}px`,
      height: `${this.boundary.height + 1}px`,
      width: '1px'
    });

    css(this.top, {
      display: 'block',
      left: `${this.boundary.x - 1 - tableViewScrollLeft}px`,
      top: `${this.boundary.y - 1}px`,
      width: `${this.boundary.width + 1}px`,
      height: '1px'
    });

    css(this.bottom, {
      display: 'block',
      left: `${this.boundary.x - 1 - tableViewScrollLeft}px`,
      top: `${this.boundary.y1}px`,
      width: `${this.boundary.width + 1}px`,
      height: '1px'
    });
  }

  computeSelectedTds() {
    const tableContainer = Quill.find(this.table);
    const tableCells = tableContainer?.descendants(TableCell);

    const computed = tableCells?.reduce((selectedCells: any[], tableCell: any) => {
      const { x, y, width, height } = getRelativeRect(
        tableCell.domNode.getBoundingClientRect(),
        this.quill.root.parentNode
      );
      const isCellIncluded =
        x + ERROR_LIMIT >= this.boundary.x &&
        x - ERROR_LIMIT + width <= this.boundary.x1 &&
        y + ERROR_LIMIT >= this.boundary.y &&
        y - ERROR_LIMIT + height <= this.boundary.y1;

      if (isCellIncluded) {
        selectedCells.push(tableCell);
      }

      return selectedCells;
    }, []);

    return computed;
  }
}

export default TableSelection;
