const system = {
  title: 'System Common',
  userManagement: {
    title: 'User Management',
    newUserHeader: 'Create new user',
    updateUserHeader: 'Edit user information',
    usernameNote: '(*) Username will be used for login and is not editable once saved.',
    resetPasswordWarning: 'Are you sure you want to reset password of this user?',
    duplicationWarning: 'Please check for duplication.',
    confirmDelete: 'Are you sure you want to remove this user?',
    noteIntegration: 'MetaCICD integration is in use',
    dsbIntegration: 'Disable MetaCICD integration',
    timeOut: 'Session timeout',
    assignedProject: 'Assigned Projects',
    noProjectAssigned: 'No Project Assigned',
    succeedMess: {
      create: 'User has been successfully created.',
      edit: 'User has been successfully updated.',
      resetPassword:
        'Password reset successfully. User will be logged out from all current sessions if any.',
      deleteUser: 'User has been successfully deleted.',
      verifyPhone:
        'The verify message was successfully sent to user <bold>{{username}}</bold> Email/Kakao.'
    },
    userList: 'User List',
    user: 'User',
    inviteUser: {
      title: 'Invite user(s)',
      orgHelperText: 'Select organization for invited users.',
      roleHelperText: 'Select role for invited users.',
      emailHelperText:
        'Press Enter key to confirm the email. It is possible to invite many users at the same time.',
      success: 'Invite user(s) successfully.'
    },
    resetPassword: {
      headerTitle: 'Reset Password',
      title: 'Issue temporary password',
      manually: 'Manually issue password',
      content: 'Send an email with the password reset link to <bold>{{email}}</bold>',
      dividerText: 'or',
      buttonResetPassword: 'Issue'
    },
    registration: {
      title: 'Registration Request',
      table: {
        title: 'Request list',
        action: 'Approval YN',
        requestDate: 'Request Date',
        authentication: 'Authentication ',
        approve: 'Approve',
        denied: 'Denied',
        deny: 'Deny'
      },
      form: {
        titleDenied: 'Approval Reject',
        deniedMessage: 'Communicate the reasons for the refusal of approval to the requester',
        denialReason: 'Approve Reject reason',
        titleSingleApprove: 'User registration request approval',
        titleMultiApprove: 'User registration request approval - Multi'
      },
      message: {
        approveMessage: 'The registration request is approved successfully',
        deniedMessage: 'The registration request is denied',
        confirmRemoveUserApprove: 'Are you sure you want to remove the registration request?'
      }
    },
    changePassword: {
      title: 'Change password',
      form: {
        currentPass: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password'
      },
      message: {
        chassSuccess: 'Password changed successfully',
        matchPassword: "The passwords you entered don't match. Please try again."
      }
    },
    timeout: {
      timeoutSettingMessage: 'Session timeout setting:',
      timeOption: {
        default: 'Default(2hrs)',
        threeHours: '3hrs',
        sixHours: '6hrs',
        twelve: '12hrs',
        twentyFour: '24hrs'
      }
    }
  },
  userInformation: {
    title: 'User Information'
  },
  orgManagement: {
    title: 'Organization Management',
    newOrgHeader: 'Create new organization',
    updateOrgHeader: 'Organization Information',
    confirmDelete: 'Are you sure you want to remove this organization?',
    validateDelete: 'Please update the status to ‘Inactive’ then delete the item.',
    form: {
      orgName: 'Organization Name',
      orgID: 'Organization ID',
      type: 'Type'
    },
    errorMess: {
      orgCode: 'Organization ID must contain 2–5 characters and cannot contain special characters.'
    },
    succeedMess: {
      create: 'Organization has been successfully created.',
      edit: 'Organization has been successfully updated.',
      delete: 'Organization has been successfully deleted.'
    },
    organizationList: 'Organization List'
  },
  selectCoporation: {
    title: 'Select corporation'
  },
  corpMgt: {
    title: 'Corporation Management',
    owner: ' Owner',
    allCorp: 'All Corporation',
    titleTabOwner: 'Ownership Management ',
    titleAllCorpMgt: 'All Corporation Management',
    field: {
      select: {
        corpId: 'Corporation ID',
        corpName: 'Corporation name'
      },
      input: {
        placeholder: 'Enter'
      }
    },
    gridHeader: {
      no: 'No.',
      corpId: 'Corporation ID',
      corpName: 'Corporation Name',
      status: 'Status',
      logo: 'Logo',
      registrant: 'Registrant'
    },
    form: {
      defaultValueCreator: 'MetaDesk Admin',
      createTitle: 'Create new corporation',
      updateTitle: 'Corporation Information',
      corpId: 'Corporation ID',
      corpName: 'Corporation Name',
      status: 'Status',
      active: 'Active',
      confirmDelete:
        'If you delete the corp, {{numberUser}} user(s) belonging to that corp will also be deleted at the same time. Are you sure you want to delete it?'
    },
    text: {
      upload: 'Please upload .png, .jpg, .bmp, .gif less than {{fileSize}}.'
    },
    errorMess: {
      corpCode: 'Corporation ID must contain 2–5 characters and cannot contain special characters.',
      warningDeleteStatusActive: 'Please update the status to ‘Inactive’ then delete the item.'
    },
    succeedMess: {
      delete: 'Corporation has been successfully deleted.',
      create: 'Corporation has been successfully created.',
      edit: 'Corporation has been successfully updated.'
    },
    corporationList: 'Corporation List'
  },
  profile: {
    title: 'Profile',
    uploadImgDesc: 'Please upload  .png, .jpg, .bmp, .gif\nless than {{fileSize}}.',
    editFormTitle: 'Edit user information',
    saveChanges: 'Save Changes',
    succeedMess: {
      edit: 'Your profile has been successfully updated.',
      needVerify: 'Please verify your phone number via Email/Kakao message.'
    },
    edit: 'Edit',
    remove: 'Remove'
  },
  contractManagement: {
    title: 'Contract Management',
    newContractHeader: 'Create new contract',
    updateContractHeader: 'Edit contract',
    form: {
      contractType: 'Contract Type',
      picName: 'PIC name',
      startDate: 'Contract Start Date',
      endDate: 'Contract End Date',
      contractInfo: 'Contract Information',
      contractPoint: 'Contract point',
      primaryContact: 'Primary Contact'
    },
    errorMess: {
      contractNo: 'Contract No. must consist only of uppercase, lowercase, numbers and hyphen(-).',
      compareDate: 'Start date must be earlier than end date.',
      fileSizeAndType:
        'Please upload .xlsx, .xls, .docx, .doc, .pptx, .ppt, .pdf, .png, .jpg, .jpeg, .zip, .jar, .txt files not exceeding {{fileSize}}/file.',
      dateType: 'Please enter a valid date.'
    },
    succeedMess: {
      create: 'Contract has been successfully created.',
      edit: 'Contract has been successfully updated.',
      delete: 'Contract has been successfully deleted.'
    },
    confirmDelete: 'Are you sure you want to remove this contract?',
    contractList: 'Contract List'
  },
  projectManagement: {
    title: 'Project Management',
    grid: {
      applications: 'Applications',
      projInfo: 'Project Information',
      contractName: 'Contract Name',
      assignUser: 'Assign user'
    },
    assignedUser: {
      title: 'Assigned user',
      search: {
        select: {
          name: 'Name',
          userID: 'Username'
        },
        searchPlaceholder: 'Search User'
      },
      grid: {
        userID: 'Username',
        name: 'Name',
        org: 'Organization',
        title: 'Title',
        remove: 'Remove'
      },
      succeedMess: {
        add: 'User have been successfully assigned.',
        delete: 'Selected user has been successfully unassigned.'
      },
      confirmDelete: 'Are you sure you want to remove this user?',
      errorMess: 'Duplicated values already assigned.'
    },
    confirmDeleteProject: 'Are you sure you want to remove this project?',
    form: {
      title: {
        create: 'Create new project',
        edit: 'Edit project'
      },
      field: {
        projNo: 'Project No.',
        projName: 'Project name',
        orgID: 'Organization ID',
        orgName: 'Organization name',
        projType: 'Project type',
        projInfo: 'Project information',
        appName: 'Application name',
        version: 'Version',
        serverDetail: 'Server details',
        releaseDate: 'Release date',
        contractNo: 'Contract No.',
        contractName: 'Contract name',
        contractSDate: 'Contract start date',
        contractEDate: 'Contract end date',
        serviceType: 'Service Type',
        assignServiceType: 'Assign Service Target'
      },
      tab: {
        title: {
          app: 'Application',
          contract: 'Contract'
        }
      },
      succeedMess: {
        create: 'Project has been successfully created.',
        edit: 'Project has been successfully updated.',
        delete: 'Project has been successfully deleted.'
      },
      errorMess: {
        validateFieldProjCode:
          'Project No. must not exceed 15 characters, consist only of uppercase, lowercase, numbers, underdash(), and hyphen.',
        duplicatedAppCode:
          'This application already registered for the project. Please try another value.',
        warningDeleteStatusActive: 'Please update the status to ‘Inactive’ then delete the item.',
        validateApplication: 'At least one application must be registered.'
      }
    },
    projectList: 'Project List',
    assignService: {
      application: 'Application',
      serviceClassfication: 'Service Classification',
      user: 'User',
      serviceType: 'Service Target'
    }
  },
  applicationManagement: {
    headerTitle: 'Application Version Management',
    tabs: {
      application: {
        title: 'Application',
        create: 'Create new application',
        edit: 'Edit application',
        confirmDelete: 'Are you sure you want to remove this application?',
        succeedMess: {
          edit: 'Application has been successfully updated',
          create: 'Application has been successfully created',
          delete: 'Application has been successfully deleted'
        },
        errorMess: {
          specialCharacter:
            'Only numbers and uppercase English letters of 2 to 5 characters can be entered.'
        },
        applicationList: 'Application List'
      },
      version: {
        title: 'Version',
        create: 'Create new version',
        edit: 'Edit version',
        confirmDelete: 'Are you sure you want to remove this version?',
        succeedMess: {
          create: 'Version has been successfully created.',
          edit: 'Version has been successfully updated',
          delete: 'Version has been successfully deleted'
        },
        errorMess: {
          fileSizeAndType:
            'Please upload .xlsx, .xls, .docx, .doc, .pptx, .ppt, .pdf, .zip, .jar, .txt files not exceeding {{fileSize}}/file.'
        },
        versionList: 'Version List'
      }
    },
    form: {
      searchInput: {
        placeholder: 'Search application name...'
      },
      applicationId: 'Application ID',
      applicationName: 'Application Name',
      version: 'Versions',
      versionCode: 'Version Code',
      status: 'Status',
      description: 'Description',
      creator: 'Creator',
      versionName: 'Version Name',
      environment: 'Environment',
      platform: 'Platform',
      server: 'Server',
      serverDetail: 'Server Details',
      deployed: 'Deployed',
      releaseDate: 'Release Date',
      releaseDateTime: 'Release Date',
      releaseTime: 'Release Time',
      source: 'Source Path',
      releaseNote: 'Release Note',
      creationDate: 'Creation Date',
      updater: 'Updater',
      updateDate: 'Update Date',
      remark: 'Remark'
    }
  },
  menuManagement: {
    title: 'Menu Management',
    tree: {
      title: 'Menu Tree',
      showAll: 'Show all'
    },
    form: {
      title: 'Menu Information',
      koName: 'Korean name',
      enName: 'English name',
      menuLv: 'Menu Level',
      parentMenu: 'Parent menu',
      menuPath: 'Menu Path',
      order: 'Order',
      des: 'Description',
      menuCode: 'Menu Code',
      lv1: 'Level 1',
      lv2: 'Level 2',
      lv3: 'Level 3',
      icon: 'Icon',
      none: 'None'
    },
    changeIcon: 'Change Icon',
    showAllBtn: 'Show all',
    showLessBtn: 'Show less',
    confirmDelete: 'Are you sure you want to remove this menu?',
    succeedMess: {
      delete: 'Menu has been successfully deleted.',
      create: 'Menu has been successfully created.',
      edit: 'Menu has been successfully updated.'
    },
    validateField: {
      deleteItemActive: 'Please update the status to ‘Inactive’ then delete the item.',
      menuCode: 'Menu Code must contain less than 10 characters and consist only of uppercase.',
      menuName: 'Menu Name must contain less than 50 characters.',
      desc: 'Description must contain less than 250 characters.',
      order: 'Order must be a whole number greater than 0.',
      required: {
        menuCode: 'Menu Code is required field.',
        menuNameKo: 'Menu Name (KO) is required field.',
        menuNameEn: 'Menu Name (EN) is required field.',
        menuLv: 'Menu Level is required field.',
        parentMenu: 'Parent menu is required field.'
      }
    },
    confirmMsg: {
      submenuUpdate: 'The submenu(s) of this item will be updated to level {{level}}.'
    }
  },
  configurationManagement: {
    headerTitle: 'Configuration Management',
    commonCodeManagement: {
      headerTitle: 'Common Code Management',
      commonCode: 'Common Code',
      subCode: 'Sub Code',
      subDetailCode: 'Sub-detail code',
      editCommonCodeName: 'Edit Common Code Name',
      createCommonCode: 'Create Common Code',
      workflowProcessing: 'Workflow Processing Status Y/N',
      form: {
        searchInput: {
          placeholder: 'Search code...'
        },
        code: 'Code',
        englishName: 'English Name ',
        koreanName: 'Korean Name ',
        order: 'Order',
        status: 'Status',
        backgroundColour: 'Background colour',
        formUpload: 'Form Upload',
        default: 'Default'
      },
      message: {
        maxLengthCode:
          'Code must not exceed {{length}} characters, consist only of uppercase, numbers, underdash(), and hyphen',
        minOrder: 'Please input whole numbers greater than 0.',
        orderRange: 'Order have range 1 ~ 9999'
      },
      succeedMess: {
        create: 'Sub-code has been successfully created.',
        edit: 'Sub-code has been successfully updated.',
        delete: 'Sub-code has been successfully deleted.'
      },
      confirmDelete: 'Are you sure you want to remove this sub-code?',
      confirmDeleteParentCode: 'Are you sure you want to remove this code?'
    }
  },
  permissionManagement: {
    headerTitle: 'Permission management',
    form: {
      roles: 'Roles',
      menuTree: 'Menu Tree',
      checkAll: 'Check all',
      showAll: 'Show all',
      action: 'Action',
      roleName: 'Role Name'
    },
    succeedMess: {
      create: 'Role successfully created.',
      delete: 'Role has been successfully deleted',
      update: 'Changes have been successfully saved'
    },
    confirmDeleteRole: 'Are you sure you want to remove this role?'
  },
  workflowManagement: {
    groupTitle: 'Configuration Management',
    title: 'Workflows',
    titleActionLogwork: 'Additional settings when changing status',
    messageConfigAction: 'If you select work history, you must register the worklog.',
    table: {
      title: 'Workflow list',
      no: 'No.',
      name: 'Workflow Name',
      desc: 'Description',
      type: 'Ticket Type',
      createdBy: 'Creator',
      createdDate: 'Creation Date',
      updatedBy: 'Modified by',
      updatedDate: 'Modified date',
      incident: 'Incident',
      service: 'Service',
      deploy: 'Service',
      build: 'Build',
      request: 'Request'
    },
    detail: {
      edit: 'Edit Workflow',
      create: 'Create Workflow',
      label: {
        name: 'Name',
        ticketType: 'TicketType',
        desc: 'Description',
        statuses: 'Statuses',
        add: 'Setting when state changes'
      },
      placeholder: {
        name: 'Enter name',
        desc: 'Enter description'
      },
      errorMess: {
        validateLengthStatuses: 'At least one status must be selected.'
      },
      instruction: {
        title: 'Instruction',
        addPointTitle: 'Adding connections:',
        addPointDesc:
          'Start by selecting a node. Its color should change to indicate that it is been selected. Next hold shift while clicking another node to connect the two. Keep holding shift and clicking other nodes to connect to those as well.',
        removePointTitle: 'Removing connections:',
        removePointDesc:
          'Just repeat the steps from the "adding connections" section to delete that connection between two nodes.'
      },
      currentStatus: 'Current Status',
      newStatus: 'New Status'
    },
    succeedMess: {
      delete: 'Workflow has been successfully deleted.',
      create: 'Workflow has been successfully created.',
      edit: 'Workflow has been successfully updated.'
    },
    actions: {
      start: 'Start',
      end: 'End',
      requiredAction: 'Required Action',
      logwork: 'Logwork',
      PicDisplay: 'PIC Display'
    }
  },
  cicdMgt: {
    title: 'CI/CD Api Management',
    table: {
      corporationCode: 'Corporation code',
      commonCode: 'Common Code',
      endPoint: 'End Point',
      param: 'Param',
      method: 'Method',
      ticketType: 'Ticket Type',
      codeName: 'Code Name'
    },
    form: {
      tittle: 'CI/CD  Management',
      token: 'Token',
      code: 'Code'
    },
    message: {
      deleteSuccess: 'Item has been successfully deleted.',
      createSuccess: 'Item successfully created.',
      updateSuccess: 'Item successfully updated.'
    }
  },
  projectCicdMgt: {
    title: 'Integrated solution project management',
    table: {
      title: 'Integrated Solution Project List',
      corporationCode: 'Corporation Code',
      projectCode: 'Project Code',
      projectCiCdName: 'Integrated Solution Project Name',
      projectCiCdCode: 'Integrated Solution Project Code',
      projectGitlabCode: 'Gitlab Project Code',
      projectName: 'Project Name',
      project: 'Project',
      integratedSolutionName: 'Integrated Solution Name',
      status: 'Status'
    },
    form: {
      createTitle: 'Create Integrated Solution Project',
      updateTitle: 'Edit Integrated Solution Project',
      integratedSolutionName: '통합 솔루션명'
    },
    message: {
      confirmDelete: 'Are you sure you want to remove this item ?',
      deleteSuccess: 'Item  has been successfully deleted.',
      createSuccess: 'Item successfully created.',
      updateSuccess: 'Item successfully updated.',
      gitlab: {
        availableCode: 'Integrated Solution Project Code is available.',
        warningCheckCode: 'Please check whether Gitlab project code is duplicated'
      },
      cicd: {
        availableCode: 'Integrated Solution Project Code is available.',
        warningCheckCode: 'Please check whether CICD project code is duplicated.'
      }
    }
  },
  exposeApiMgt: {
    title: 'Expose API Management',
    table: {
      title: 'Expose API List',
      corporationCode: 'Corporation Code',
      host: 'Host',
      payload: 'Payload',
      response: 'Response',
      token: 'Token',
      path: 'Path',
      endPoint: 'End Point'
    },
    form: {
      createTitle: 'Create Expose API',
      updateTitle: 'Update Expose API'
    },
    message: {
      addSuccess: 'Item has been successfully created.',
      deleteSuccess: 'Item successfully deleted',
      updateSuccess: 'Item successfully updated.'
    }
  },
  integrationSolutionMgt: {
    title: 'Integrated solution integration mgmt. by project',
    table: {
      title: 'Integration Solution List',
      corporationCode: 'Corporation Code',
      endpoint: 'Endpoint',
      payload: 'Payload',
      response: 'Response',
      token: 'Token',
      solutionType: 'Solution Type',
      solutionName: 'Solution Name',
      statusInformation: 'Status Information',
      active: 'Active',
      creator: 'Creator',
      createDate: 'Create Date',
      modifyBy: 'Modify By',
      modifyDate: 'Modify Date',
      projectName: 'Project',
      thirdParty: 'Third Party'
    },
    form: {
      createTitle: 'Create Solution Registration',
      updateTitle: 'Update Solution Registration'
    },
    message: {
      addSuccess: 'Item has been successfully created.',
      deleteSuccess: 'Item has been successfully deleted.',
      updateSuccess: 'Item has been successfully updated.',
      deleteItem: 'Please update the status to ‘Inactive’ then delete the item.',
      errorHTTP: 'Endpoint start with http or https'
    }
  },
  thirdPartyMgt: {
    title: 'Integrated Solution Management',
    kakaoName: 'KaKaoTalk BizMessage - NHN AlimTalk',
    cicdName: 'MetaCICD - Account Synchronization',
    gitlabConnectName: 'GitLab Connection',
    form: {
      senderKey: 'Sender Key',
      alimtalkPath: 'Alim Talk Path',
      secretKey: 'Secret Key',
      createTitle: 'Create Third Party',
      updateTitle: '{{solution}} sync change',
      kakaoName: 'KaKaoTalk BizMessage - NHN AlimTalk'
    },
    table: {
      title: 'Third Party List'
    },
    warningChangeActive: 'Do you want to remove the synchronized account of this user?'
  },
  userWorkflowPermission: {
    title: 'User Workflow Permission',
    userList: 'User List',
    workflow: 'Workflow',
    changeSuccess: 'Changes have been successfully saved.'
  },
  licenseMgt: {
    title: 'License Management',
    form: {
      clientName: 'Client name',
      deviceName: 'Device name',
      expiredDate: 'Expired date',
      licenseType: 'License type',
      userCount: 'User count',
      ticketCount: 'Ticket count',
      serviceCount: 'Service count',
      clientDescription: 'Client description',
      licenseKey: 'License key',
      keyRegistrationActive: 'Key Registration And Activation',
      importLicenseKey: 'Import License Key'
    },
    confirm: {
      message: 'Do you want to activate the new License key ?'
    }
  },
  dynamicContentManagement: {
    title: 'Dynamic language mgmt.',
    scope: 'Classification',
    content: 'Detail',
    defaultContent: 'Default content',
    variantionContent: 'Change content',
    enContent: 'English content',
    koContent: 'Korean content'
  },
  logManagement: {
    loginLog: {
      title: 'Login Log',
      searchForm: {
        userName: 'Username',
        loginType: 'Login Type',
        status: 'Status',
        period: 'Period'
      },
      table: {
        logDate: 'Log date&time',
        userName: 'Username',
        loginType: 'Login Type',
        ipAddress: 'IP address',
        status: 'Status',
        remark: 'Remark'
      },
      status: {
        success: 'Success',
        fail: 'Failed',
        block: 'Block'
      }
    }
  },
  actionLog: {
    headTitle: 'Action Log',
    form: {
      userName: 'Username',
      method: 'Method',
      status: 'Status',
      period: 'Period',
      logDate: 'Log date&time',
      menuName: 'Menu Name',
      url: 'URI',
      exceptionNote: 'Exception Note',
      periodTo: 'Period To',
      periodFrom: 'Period From'
    }
  },
  userGuideSetting: {
    headTitle: 'User Manual Management',
    form: {
      roles: 'Roles',
      documentInfomation: 'Document Information',
      popUpTitle: 'Pop-up Title',
      engName: 'English Name',
      koName: 'Korean Name',
      forKoLang: 'For Korean Language',
      forEngLang: 'For English Language',
      message: {
        updateSuccess: 'Update user guide success'
      }
    }
  },
  concurrentUserAndHistory: {
    headTitle: 'Concurrent user and history',
    table: {
      logDate: 'Log date&time',
      userCount: 'User count',
      variance: 'Increase or decrease count',
      percent: 'Increase/decrease rate (%)',
      note: 'Note'
    },
    popup: {
      concurrentUser: 'Concurrent User',
      user: 'user(s)'
    }
  }
};

export { system };
