import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import dayjs from 'dayjs';

import { FULL_DATE_FORMAT } from 'constants/common';
import { IHistoryMessage } from 'models/LocaleModel';

export class NotificationDataModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && NotificationModel.toClass(item.value))
  notificationList: NotificationModel | undefined;

  @Expose({ name: 'totalElements' })
  totalElements: number | undefined;
}

export class NotificationModel extends BaseModel {
  @Expose({ name: 'fragments' })
  message: IHistoryMessage | undefined;

  @Expose({ name: 'changeType' })
  changeType: string | undefined;

  @Expose({ name: 'readYn' })
  readYn: string | undefined;

  @Expose({ name: 'noId' })
  noId: number | undefined;

  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'createdDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  createdDate: number | undefined;
}
