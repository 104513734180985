import apiClient from 'apis/apiClient';
import { REGISTER_USER, FETCH_CORP_ORG_PUBLIC, FETCH_VERIFY_TYPE } from 'constants/apiUrl';

export const registerUser = (params: any) =>
  apiClient
    .post(REGISTER_USER, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const fetchCorpOrgPublic = (params: any) =>
  apiClient
    .post(FETCH_CORP_ORG_PUBLIC, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const getVerifyType = (params: any) =>
  apiClient
    .post(FETCH_VERIFY_TYPE, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
