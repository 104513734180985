import { DEFAULT_TABLE_VALUES } from 'constants/common';

export const SEARCH_NOTIFICATION_DEFAULT = {
  ...DEFAULT_TABLE_VALUES,
  sortBy: 'createdDate',
  direction: 'DESC'
};

export const NOTIFICATION_TAB = {
  UNREAD: 'UNREAD',
  ALL: 'ALL'
};

export const UPDATE_FIELD = 'UPDATE_FIELD';

export const CHANGE_TYPE_NOTIFICATION = {
  UPDATE_FIELD: 'UPDATE_FIELD',
  STATUS_UPDATE: 'STATUS_UPDATE',
  ASSIGN_TO_ME: 'ASSIGN_TO_ME',
  CREATE_TICKET: 'CREATE_TICKET',
  UNASSIGNED_USER: 'UNASSIGNED_USER',
  LOG_WORK: 'LOG_WORK',
  COMMENT: 'COMMENT',
  TAG: 'TAG'
};

export const SERVICE_TYPE = 'serviceType';
