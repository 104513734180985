import MultipartModel from 'models/MultipartModel';
import { Expose } from 'class-transformer';

export class UploadImageModel extends MultipartModel {
  @Expose({ name: 'file' })
  file: File | undefined;

  @Expose({ name: 'type' })
  type: string | undefined;
}
