import * as common from './common';
import * as system from './system';
import * as login from './login';
import * as helpDesk from './helpDesk';
import * as dashboard from './dashboard';
import * as signUp from './signup';

const kr = {
  translation: {
    ...common,
    ...system,
    ...login,
    ...helpDesk,
    ...dashboard,
    ...signUp
  }
};

export default kr;
