import { useState, createContext } from 'react';

import useLocales from 'hooks/useLocales';

import { AlertDialog } from '@material-extend';

type AppContextInitialState = {
  setAlertDialogProps: (alertDialogProps: any) => void;
  hideDialog: VoidFunction;
};

const initialState: AppContextInitialState = {
  setAlertDialogProps: () => {},
  hideDialog: () => {}
};

export const AppContext = createContext(initialState);

interface Props {
  children: React.ReactNode;
}

const ALERT_DIALOG_DEFAULT = { message: '', isOpenDialog: false };

const AppProvider: React.FC<Props> = ({ children }) => {
  const { t } = useLocales();
  const [alertDialogProps, setAlertDialogProps] = useState(ALERT_DIALOG_DEFAULT);
  const hideDialog = () => {
    setAlertDialogProps(ALERT_DIALOG_DEFAULT);
  };

  return (
    <AppContext.Provider value={{ setAlertDialogProps, hideDialog }}>
      {children}
      <AlertDialog {...alertDialogProps}>
        <p>{t(alertDialogProps.message)}</p>
      </AlertDialog>
    </AppContext.Provider>
  );
};

export default AppProvider;
