import { classToPlain, plainToClass } from 'class-transformer';
import dayjs from 'dayjs';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

import IBaseModel from './IBaseModel';

// TODO: Needs refactoring. This recursive function may affects the performance
/**
 * Filter and formatted Dayjs fields
 * to make sure the model data will not throw error
 * when passing through class-transformer's functions
 * @param data: Model object
 * @returns An object of data that has formatted Dayjs fields
 */
const recursivelyConvertData = (data: object): object =>
  mapValues(data, (item: any) => {
    // If `item` is a Day.js object,
    // convert the item to string format (ISO8601 standard)
    // before passing it to `plainToClass` to prevent error
    if (dayjs.isDayjs(item)) {
      return item.format();
    }
    if (isPlainObject(item) && !isEmpty(item)) {
      return recursivelyConvertData(item);
    }
    return item;
  });

class BaseModel implements IBaseModel {
  static toClass(data: unknown, options = {}) {
    return plainToClass(this, data, {
      excludeExtraneousValues: true,
      ...options
    });
  }

  static toPlain(data: any, options = {}) {
    const convertedData = isPlainObject(data) ? recursivelyConvertData(data) : data;

    return classToPlain(
      plainToClass(this, convertedData, {
        ignoreDecorators: true
      }),
      { excludeExtraneousValues: true, ...options }
    );
  }
}

export default BaseModel;
