import { IconButton, IconButtonProps } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// For automation test
const TEST_ID = 'editBtn';

const EditIconButton = (props: IconButtonProps) => (
  <IconButton test-id={TEST_ID} aria-label="edit" {...props}>
    <EditIcon fontSize="inherit" />
  </IconButton>
);

export default EditIconButton;
