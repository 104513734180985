import apiClient from 'apis/apiClient';
import { ADD_LIST_STATUS, EMAIL_SETTING, GET_STATUS_TICKET_BY_PROJECT } from 'constants/apiUrl';

export const getEmailMeWhenSettings = () =>
  apiClient
    .get(EMAIL_SETTING)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const updateEmailMeWhenSettings = (params: any) =>
  apiClient
    .post(EMAIL_SETTING, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const getStatusByProjectCode = (params: any) =>
  apiClient
    .post(GET_STATUS_TICKET_BY_PROJECT, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });

export const addListStatus = (params: any) =>
  apiClient
    .post(ADD_LIST_STATUS, params)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
