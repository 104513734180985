import { ListItem, ListItemIcon, ListSubheader, Box } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import typography from 'theme/typography';
import SvgIconStyle from 'components/SvgIconStyle';
import { IconifyIcon } from '@iconify/react/dist/icon';

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...typography.overline,
  fontSize: '0.875rem',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
  textTransform: 'capitalize'
}));

export const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...typography.body2,
  height: theme.typography.pxToRem(48),
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: theme.typography.pxToRem(3),
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: theme.typography.pxToRem(4),
    borderBottomLeftRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.primary.main
  }
}));

export const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  width: theme.typography.pxToRem(22),
  height: theme.typography.pxToRem(22),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const ListItemIconBoxStyle = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(4),
  height: theme.typography.pxToRem(4),
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: 'text.disabled'
}));

export const ArrowIconStyle = styled(Box)<IconifyIcon>(({ theme }) => ({
  width: theme.typography.pxToRem(16),
  height: theme.typography.pxToRem(16),
  ml: 1
}));

export const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

export const ICONS_NAME = [
  'ic_analytics',
  'ic_anm',
  'ic_app',
  'ic_application',
  'ic_blog',
  'ic_calendar',
  'ic_cart',
  'ic_charts',
  'ic_chat',
  'ic_conf',
  'ic_contract',
  'ic_corp',
  'ic_dashboard',
  'ic_doc',
  'ic_ecommerce',
  'ic_faq',
  'ic_faqmgt',
  'ic_mail',
  'ic_menu',
  'ic_post',
  'ic_proj',
  'ic_role',
  'ic_schedule',
  'ic_tick',
  'ic_user',
  'ic_viewAnn',
  'ic_viewDoc',
  'ic_viewFAQ'
];

// Mapping icons for each menu
const ICONS = {
  user: getIcon('ic_user'),
  corp: getIcon('ic_corp'),
  org: getIcon('ic_org'),
  pm: getIcon('ic_proj'),
  ctm: getIcon('ic_contract'),
  tick: getIcon('ic_ticket'),
  anm: getIcon('ic_ann'),
  faq: getIcon('ic_faqmgt'),
  doc: getIcon('ic_docmgt'),
  appl: getIcon('ic_app'),
  default: getIcon('ic_dashboard'),
  role: getIcon('ic_cart'),
  menu: getIcon('ic_menu'),
  perm: getIcon('ic_permission'),
  vdoc: getIcon('ic_viewDoc'),
  vfaq: getIcon('ic_viewFAQ'),
  vann: getIcon('ic_viewAnn'),
  schdl: getIcon('ic_schedule'),
  post: getIcon('ic_post'),
  conf: getIcon('ic_conf'),
  report: getIcon('ic_charts')
};

export const configIcons: Record<string, any> = {
  DASH: ICONS.default,
  USER: ICONS.user,
  CORP: ICONS.corp,
  ORGM: ICONS.org,
  PROJ: ICONS.pm,
  CONT: ICONS.ctm,
  TICK: ICONS.tick,
  FAQM: ICONS.faq,
  ANNO: ICONS.anm,
  DOCS: ICONS.doc,
  APPL: ICONS.appl,
  ROLE: ICONS.role,
  MENU: ICONS.menu,
  PERM: ICONS.perm,
  VDOC: ICONS.vdoc,
  VFAQ: ICONS.vfaq,
  VANN: ICONS.vann,
  SCHDL: ICONS.schdl,
  CONF: ICONS.conf,
  REPORT: ICONS.report,
  SHBO: ICONS.post
};
