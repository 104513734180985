import Quill from 'quill';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import { TFunction } from 'i18next';

import {
  MENTION_ALLOW_CHARS_REGEX,
  MENTION_USER_DENOTATION_CHAR,
  MENTION_TYPES,
  MENTION_HASHTAG_DENOTATION_CHAR,
  MIN_CHARS_MENTION,
  HASHTAG_ALLOWED_CHARS_REGEX
} from 'constants/common';

import { TableCell } from './table/TableBlots';
import { Rectangle, RelativeRectangle } from './interfaces';

const Delta = Quill.import('delta');

// ---------------------------------------------------------------------------

export function insertContent(
  quill: typeof Quill,
  range: { index: number; length: number },
  content: any
) {
  // Insert `content` at cursor position
  // @ts-ignore
  quill.history.cutoff();
  const delta = new Delta().retain(range.index).delete(range.length).insert(content);
  // @ts-ignore
  quill.updateContents(delta, Quill.sources.USER);
  // @ts-ignore
  quill.history.cutoff();
  // Position cursor after inserted `content`
  // @ts-ignore
  quill.setSelection(range.index + 1, Quill.sources.SILENT);
}

export function enterHandler(range: { index: number; length: number }) {
  // @ts-ignore
  insertContent(this.quill, range, '\n');
}

export function isQuillEmpty(quill: any) {
  if (!quill) return true;
  if ((quill.getContents()?.ops || []).length !== 1) {
    return false;
  }
  return quill.getText().trim().length === 0;
}

/**
 * getRelativeRect
 * @param  {Object} targetRect  rect data for target element
 * @param  {Element} container  container element
 * @return {Object}             an object with rect data
 */
export function getRelativeRect(targetRect: Rectangle, container: Element): RelativeRectangle {
  const containerRect = container.getBoundingClientRect();

  return {
    x: targetRect.x - containerRect.x - container.scrollLeft,
    y: targetRect.y - containerRect.y - container.scrollTop,
    x1: targetRect.x - containerRect.x - container.scrollLeft + targetRect.width,
    y1: targetRect.y - containerRect.y - container.scrollTop + targetRect.height,
    width: targetRect.width,
    height: targetRect.height
  };
}

export function rowId() {
  const id = Math.random().toString(36).slice(2, 6);
  return `row-${id}`;
}
export function cellId() {
  const id = Math.random().toString(36).slice(2, 6);
  return `cell-${id}`;
}
export function isTableCell(blot: any): boolean {
  return blot.statics.blotName === TableCell.blotName;
}

export function isInTableCell(current: any): boolean {
  // eslint-disable-next-line no-nested-ternary
  return current && current.parent
    ? isTableCell(current.parent)
      ? true
      : isInTableCell(current.parent)
    : false;
}

/**
 * getEventComposedPath
 *  compatibility fixed for Event.path/Event.composedPath
 *  Event.path is only for chrome/opera
 *  Event.composedPath is for Safari, FF
 *  Neither for Micro Edge
 * @param {Event} evt
 * @return {Array} an array of event.path
 */
export function getEventComposedPath(evt: any) {
  let path;
  // chrome, opera, safari, firefox
  path = evt.path || (evt.composedPath && evt.composedPath());

  // other: edge
  if (path === undefined && evt.target) {
    path = [];
    let { target } = evt;
    path.push(target);

    while (target && target.parentNode) {
      target = target.parentNode;
      path.push(target);
    }
  }

  return path;
}

export function computeBoundaryFromRects(
  startRect: Rectangle,
  endRect: Rectangle
): RelativeRectangle {
  const x = Math.min(
    startRect.x,
    endRect.x,
    startRect.x + startRect.width - 1,
    endRect.x + endRect.width - 1
  );

  const x1 = Math.max(
    startRect.x,
    endRect.x,
    startRect.x + startRect.width - 1,
    endRect.x + endRect.width - 1
  );

  const y = Math.min(
    startRect.y,
    endRect.y,
    startRect.y + startRect.height - 1,
    endRect.y + endRect.height - 1
  );

  const y1 = Math.max(
    startRect.y,
    endRect.y,
    startRect.y + startRect.height - 1,
    endRect.y + endRect.height - 1
  );

  const width = x1 - x;
  const height = y1 - y;

  return { x, x1, y, y1, width, height };
}

export function getColToolCellIndexByBoundary(
  cells: TableCell[],
  boundary: RelativeRectangle,
  conditionFn: (cellRect: RelativeRectangle, boundary: RelativeRectangle) => boolean,
  container: Element
) {
  return cells.reduce((findIndex: number | boolean, cell: TableCell) => {
    const cellRect: RelativeRectangle = getRelativeRect(cell.getBoundingClientRect(), container);
    if (conditionFn(cellRect, boundary)) {
      findIndex = cells.indexOf(cell);
    }
    return findIndex;
  }, false);
}

export function getColToolCellIndexesByBoundary(
  cells: TableCell[],
  boundary: RelativeRectangle,
  conditionFn: (cellRect: RelativeRectangle, boundary: RelativeRectangle) => boolean,
  container: Element
) {
  return cells.reduce((findIndexes: number[], cell: TableCell) => {
    const cellRect: RelativeRectangle = getRelativeRect(cell.getBoundingClientRect(), container);
    if (conditionFn(cellRect, boundary)) {
      findIndexes.push(cells.indexOf(cell));
    }
    return findIndexes;
  }, []);
}

export function css(domNode: any, rules: any) {
  forOwn(rules, (value: any, key: string) => {
    if (value) {
      domNode.style[key] = value;
    }
  });
}

export const generateMentionUserModule = (t: TFunction) => ({
  allowedChars: MENTION_ALLOW_CHARS_REGEX,
  mentionDenotationChars: [MENTION_USER_DENOTATION_CHAR],
  isolateCharacter: true,
  dataAttributes: ['id', 'denotationChar', 'link', 'target', 'disabled', 'username', 'name'],
  renderItem: (item: any) => {
    if (item.type === MENTION_TYPES.GUIDELINE) {
      return `<i>${t('helpDesk.ticketManagement.ticketComment.guideline')}</i>`;
    }
    if (item.type === MENTION_TYPES.USER_NOT_FOUND) {
      return `<i>${t('helpDesk.ticketManagement.ticketComment.userNotFound', {
        searchTerm: `<strong>${item.searchTerm}</strong>`
      })}<i>`;
    }
    return `${item.name} - ${MENTION_USER_DENOTATION_CHAR}${item.username}`;
  },
  onSelect: (item: any, insertItem: any) => {
    insertItem(
      {
        username: item.username,
        name: item.name,
        denotationChar: item.denotationChar
      },
      true
    );
  }
});

export const generateMentionPostModule = (t: TFunction) => ({
  minChars: MIN_CHARS_MENTION,
  allowedChars: HASHTAG_ALLOWED_CHARS_REGEX,
  mentionDenotationChars: [MENTION_HASHTAG_DENOTATION_CHAR],
  isolateCharacter: true,
  dataAttributes: ['id', 'denotationChar', 'link', 'target', 'disabled', 'title'],
  renderItem: (item: any) => {
    if (item.type === MENTION_TYPES.GUIDELINE) {
      return `<i>${t('common.editorSearch.guideline.hashtag')}</i>`;
    }

    if (item.type === MENTION_TYPES.USER_NOT_FOUND) {
      return `<i>${t('common.editorSearch.post.notFound', {
        searchTerm: `<strong>${item.searchTerm}</strong>`
      })}<i>`;
    }

    return `${MENTION_HASHTAG_DENOTATION_CHAR}${item.title}`;
  },
  onSelect: (item: any, insertItem: any) => {
    insertItem(
      {
        title: item.title,
        denotationChar: item.denotationChar
      },
      true
    );
  }
});

export const checkIsAddingNewCodeBlock = (delta: any) => {
  const ops = get(delta, 'ops', []);
  if (ops.length === 0 || ops.length > 3) return false;
  const codeBlock = ops.find((item: any) => Boolean(get(item, 'attributes.code-block')));
  return get(codeBlock, 'attributes.code-block') === 'plain';
};
