import dayjs from 'dayjs';

import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  REMEMBERED_USER,
  CURRENT_CORP_CODE,
  EXPIRED_AT,
  USER_LOGGED_AT,
  AFTER_LOGIN,
  CURRENT_USER,
  CORP_USER_LOGIN_BY_MICROSOFT,
  NEED_CHANGE_PASSWORD
} from 'constants/common';
import {
  EXPAND_SEARCH_FORM,
  SEARCH_TICKET_VALUE,
  TABLE_TICKET_VALUE,
  TAB_LIST_OR_APPROVE
} from 'features/helpDesk/ticket/TicketManagement/constants';
import { SEARCH_VALUE_APPROVAL } from 'features/helpDesk/ticket/ApproveTicket/constants';

export const clearUserCredential = () => {
  clearAccessToken();
  clearExpiredAt();
  clearRefreshToken();
  clearCurrentCorpCode();
  clearSearchInTicket();
  removeUserLoggedAt();
  removeCorpLoginByMicrosoft();
  removeAfterLogin();
  removeNeedChangePass();
};

export const clearSearchInTicket = () => {
  removeSearchTicket();
  removeExpandSearch();
};

// access token
export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(USER_LOGGED_AT, dayjs().valueOf().toString());
};

export const setNeedChangePass = (value: boolean) =>
  localStorage.setItem(NEED_CHANGE_PASSWORD, JSON.stringify(value));

export const getNeedChangePass = () => {
  const value = localStorage.getItem(NEED_CHANGE_PASSWORD);
  if (value) {
    return Boolean(JSON.parse(value));
  }
  return false;
};
export const removeNeedChangePass = () => localStorage.removeItem(NEED_CHANGE_PASSWORD);

export const setUserName = (userName: string) => {
  localStorage.setItem(CURRENT_USER, userName);
};

export const getCurrentUser = () => localStorage.getItem(CURRENT_USER);

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const clearAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);

export const getUserLoggedAt = () => localStorage.getItem(USER_LOGGED_AT);

export const removeUserLoggedAt = () => localStorage.removeItem(USER_LOGGED_AT);

export const removeViewTabTikcet = () => localStorage.removeItem(TAB_LIST_OR_APPROVE);
export const removeCorpLoginByMicrosoft = () =>
  localStorage.removeItem(CORP_USER_LOGIN_BY_MICROSOFT);

export const removeSearchTicket = () => {
  localStorage.removeItem(SEARCH_TICKET_VALUE);
  localStorage.removeItem(TABLE_TICKET_VALUE);
  localStorage.removeItem(SEARCH_VALUE_APPROVAL);
};

export const removeExpandSearch = () => localStorage.removeItem(EXPAND_SEARCH_FORM);

// refresh token
export const setRefreshToken = (refreshToken: string) =>
  localStorage.setItem(REFRESH_TOKEN, refreshToken);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const clearRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN);

export const setCorpUserLoginByMicrosoft = (value: string) =>
  localStorage.setItem(CORP_USER_LOGIN_BY_MICROSOFT, value);

export const getCorpUserLoginByMicrosoft = () => {
  const listCorp = localStorage.getItem(CORP_USER_LOGIN_BY_MICROSOFT);
  if (listCorp) {
    return JSON.parse(listCorp);
  }
  return [];
};

// expired at
export const setExpiredAt = (timestamp: number) =>
  localStorage.setItem(EXPIRED_AT, dayjs().add(timestamp, 'hour').valueOf().toString());

export const getExpiredAt = () => localStorage.getItem(EXPIRED_AT);

export const clearExpiredAt = () => localStorage.removeItem(EXPIRED_AT);

export const getAferLogin = () => localStorage.getItem(AFTER_LOGIN);
export const setAfterLogin = (value: number) =>
  localStorage.setItem(AFTER_LOGIN, JSON.stringify(value));
export const removeAfterLogin = () => localStorage.removeItem(AFTER_LOGIN);

export const setRememberFeature = (isRemember: Boolean, userName: string) => {
  if (isRemember) {
    localStorage.setItem(REMEMBERED_USER, userName);
  } else {
    localStorage.removeItem(REMEMBERED_USER);
  }
};

export const getRememberedUser = () => localStorage.getItem(REMEMBERED_USER);

// current corp
export const setCurrentCorpCode = (corpCode: string) => {
  localStorage.setItem(CURRENT_CORP_CODE, corpCode);
};

export const getCurrentCorpCode = () => localStorage.getItem(CURRENT_CORP_CODE);

export const clearCurrentCorpCode = () => localStorage.removeItem(CURRENT_CORP_CODE);
