import { forwardRef, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import get from 'lodash/get';

import { UserType } from 'interfaces/UserType';
import MAutocomplete, { MAutocompleteProps } from 'components/@material-extend/MAutocomplete';
import { useInjectReducer, useInjectSaga } from 'hooks/useInjector';
import isEmpty from 'lodash/isEmpty';
import useLocales from 'hooks/useLocales';

import {
  assigneeInputActions,
  assigneeInputSliceName,
  assigneeInputReducer,
  assigneeInputSaga,
  initialState
} from './slices';

type CustomProps = {
  projectCode: string;
  serviceType?: string;
  includesAdmin?: boolean;
  appCode?: string;
};

export type AssigneeInputProps = MAutocompleteProps<UserType> & CustomProps;

const UNASSIGNED_OPTION: UserType = { username: '', avatarUrl: '', name: '' };

const AssigneeInput = (
  { projectCode, serviceType, appCode, includesAdmin = true, ...otherProps }: AssigneeInputProps,
  ref: React.Ref<unknown>
) => {
  const dispatch = useDispatch();
  const { t } = useLocales();

  useInjectReducer({
    key: assigneeInputSliceName,
    reducer: assigneeInputReducer
  });
  useInjectSaga({ key: assigneeInputSliceName, saga: assigneeInputSaga });

  const assigneeList = useSelector(
    (state) => get(state, [assigneeInputSliceName, 'assigneeList'], initialState.assigneeList),
    shallowEqual
  );

  useEffect(() => {
    // Fetch assigned users by projectCode
    if (isEmpty(projectCode)) {
      // If Project Code is empty -> clear assignee list
      dispatch(assigneeInputActions.resetAssigneeList());
      return;
    }
    if (includesAdmin) {
      dispatch(
        assigneeInputActions.fetchAssignees({
          projCode: projectCode,
          searchCriteria: {
            searchType: 'username',
            searchValue: ''
          }
        })
      );
    } else {
      dispatch(
        assigneeInputActions.fetchUserByProject({
          projCode: projectCode,
          serviceType,
          appCode
        })
      );
    }
  }, [dispatch, projectCode, appCode, serviceType, includesAdmin]);

  const getOptionLabel = (option: UserType) => {
    if (!isEmpty(option.name) && !isEmpty(option.username)) {
      return `${option.name} - @${option.username}`;
    }
    return isEmpty(option.username) ? t('common.form.unassigned') : option.username;
  };

  return (
    <MAutocomplete
      getOptionLabel={getOptionLabel}
      options={assigneeList}
      keyString="username"
      inputRef={ref}
      blankOption={UNASSIGNED_OPTION}
      filterSelectedOptions
      {...otherProps}
    />
  );
};
export default forwardRef(AssigneeInput);
