import { LocalizationProvider, LocalizationProviderProps } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import dayjs from 'dayjs';

export interface MLocalizationProviderProps
  extends Omit<LocalizationProviderProps, 'dateAdapter'> {}

const MLocalizationProvider = ({
  dateLibInstance = dayjs,
  ...otherProps
}: MLocalizationProviderProps) => (
  <LocalizationProvider
    {...otherProps}
    dateAdapter={AdapterDayjs}
    dateLibInstance={dateLibInstance}
  />
);

export default MLocalizationProvider;
