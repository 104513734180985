import { ButtonProps } from '@mui/material';
import useLocales from 'hooks/useLocales';
import MButton from '../MButton';

const AddButton = (props: ButtonProps) => {
  const { t } = useLocales();
  return (
    <MButton {...props} variant="contained" color="primary">
      {t('common.button.add')}
    </MButton>
  );
};

export default AddButton;
