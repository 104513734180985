// form
import { useFormContext, Controller } from 'react-hook-form';

import MInput, { MInputProps } from 'components/@material-extend/MInput';

// ----------------------------------------------------------------------

export type RHFInputProps = MInputProps & {
  name: string;
};

function RHFInput({ name, ...other }: RHFInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...otherFieldProps }, fieldState: { error } }) => (
        <MInput
          {...otherFieldProps}
          error={!!error}
          helperText={error?.message}
          {...other}
          inputRef={ref}
        />
      )}
    />
  );
}

export default RHFInput;
