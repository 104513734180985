import { useState } from 'react';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';

// material
import { IconButton, Box, Typography, MenuItem, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { MAccordion } from '@material-extend';

// hook form
import { RHFController } from 'components/hook-form';
import { RHFEditorProps } from 'components/hook-form/RHFEditor';

import useLocales from 'hooks/useLocales';
import { FormCard, FormGridItem } from 'components/layouts';
import QuillEditorWithImage from 'containers/QuillEditorWithImage';

import { DEFAULT_QUILL_FORMATS } from '../../constants';

type TechnicalSolutionItemProps = RHFEditorProps & {
  isPrimary?: boolean;
  index: string | number;
  onRemove?: (index: number | string) => void;
  onChangeValue: (name: string, value: any) => void;
};

const FieldTitle = ({ index }: { index: number | string }) => {
  const { t } = useLocales();

  return (
    <Typography component="span" fontWeight="bold">
      {t('helpDesk.ticketManagement.form.technicalSolution')}
      {isNumber(index) ? ` (${index + 1})` : ''}
    </Typography>
  );
};

const TechnicalSolutionItem = (props: TechnicalSolutionItemProps) => {
  const { isPrimary = false, index, name, onRemove, onChangeValue } = props;

  const menuId = `${name}-menu`;
  const buttonId = `${name}-button`;

  const { t } = useLocales();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    !isPrimary && isFunction(onRemove) && onRemove(index);
    setAnchorEl(null);
  };

  return (
    <>
      {isPrimary ? (
        <>
          <FormGridItem spacing={12}>
            <FieldTitle index={index} />
          </FormGridItem>
          <Box sx={{ pt: 2 }}>
            <FormCard>
              <RHFController name={name}>
                {({ field, fieldState: { error } }: any) => (
                  <QuillEditorWithImage
                    type="ticketForm"
                    name={field.name}
                    value={field.value}
                    onChange={(val: any) => {
                      onChangeValue(name, val);
                    }}
                    formats={DEFAULT_QUILL_FORMATS}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </RHFController>
            </FormCard>
          </Box>
        </>
      ) : (
        <>
          <MAccordion
            title={<FieldTitle index={index} />}
            rightContent={
              <IconButton
                id={buttonId}
                aria-controls={isMenuOpen ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            }
          >
            <FormGridItem spacing={12}>
              <FormCard>
                <RHFController name={name}>
                  {({ field, fieldState: { error } }: any) => (
                    <QuillEditorWithImage
                      type="ticketForm"
                      name={field.name}
                      value={field.value}
                      onChange={(val: any) => {
                        onChangeValue(name, val);
                      }}
                      formats={DEFAULT_QUILL_FORMATS}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </RHFController>
              </FormCard>
            </FormGridItem>
          </MAccordion>
          <Menu
            id={menuId}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': buttonId
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleRemove}>
              <DeleteIcon color="error" fontSize="small" sx={{ mr: 1 }} />
              <Typography component="span" color="error">
                {t('common.button.remove')}
              </Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default TechnicalSolutionItem;
