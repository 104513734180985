export const login = {
  title: {
    signIn: 'Sign in',
    enterDetail: 'Enter your details below.'
  },
  label: {
    language: 'Language',
    pwd: 'Password',
    userName: 'Username',
    remember: 'Remember username',
    forgotPwd: 'Forgot password?',
    needHelp: 'Need help signing in?   ',
    contact: 'Contact us',
    findPwd: 'Find my password',
    changePwd: 'Change password',
    email: 'Email',
    ko: '한국어',
    en: 'English',
    currentPwd: 'Current Password',
    newPwd: 'New Password',
    confirmPwd: 'Confirm Password',
    loginPage: 'Login page',
    needSignup: "Don't have an account?",
    confirmLogout:
      'The account is being accessed from a different environment. Do you still want to log in? (If login is successful, accounts connected to other environments will be logged out.)',
    needLogout:
      'You are logged in from a different environment. You will navigate to the login screen.'
  },
  paragraph: {
    titleResetPass: 'Reset Password',
    body1: 'Follow these simple steps to reset your account:',
    body2: '1. Enter your Username and Email address',
    body3: '2. Wait for your recovery details to be sent',
    body4: '3. Follow the instructions in the recovery email to login into your account.'
  },
  button: {
    signIn: 'Sign In',
    resetPwd: 'Reset password',
    save: 'Save',
    signUp: 'Sign Up'
  },
  errorField: {
    required: {
      pwd: 'Please input your password!',
      user: 'Please input your username!',
      email: 'Email is required',
      currentPwd: 'Please input your current password!',
      newPwd: 'Please input new password!',
      confirmPwd: 'Please confirm your new password!'
    },
    format: {
      pwd:
        'Password must contain one or more alphabets, numbers, and special characters and be at least 8 characters.'
    }
  },
  successMess: {
    forgotPwd: 'Please check your email to set a new password.',
    changePwd: 'Password is changed.'
  },
  license: {
    title: 'Import license',
    message:
      'The MetaDesk system has been not activated. Please activate system by clicking <bold>{{import}}</bold>',
    import: 'Import License Key here',
    form: {
      title: 'Import your license key',
      note: 'Please import your license key to active system',
      importKey: 'Import key',
      active: 'Activate',
      warning: 'Please upload .do file not exceeding 10MB/file.'
    },
    importSuccess: {
      title: 'License key is activated',
      des1: 'Your license key is activated successfully',
      des2: 'Thank you for using MetaDesk',
      backToLogin: 'Back to login'
    },
    validate: {
      licenseActivatedAdmins:
        'The MetaDesk system has not activated. Please activate system by click the link as below.',
      licenseActivatedUser:
        'The MetaDesk system has not activated. Please contact the Admin (metadesk_admin@metanet.co.kr) for your information.',
      licenseExpiredAdmin:
        'The License has expired. Please renew the License by click the link as below.',
      licenseExpiredUser:
        'The License is expired. Please contact the Admin (metadesk_admin@metanet.co.kr) for your information.'
    }
  },
  verify: {
    title: 'Verify User',
    verified: 'Verified!',
    message: 'You have successfully verified your contact number.',
    timeOut: '{{timeOut}}h 동안 활동이 없습니다. 다시 로그인 해주세요.'
  }
};
