import { useState } from 'react';
import { withStyles } from '@mui/styles';
import { DatePicker, DatePickerProps } from '@mui/lab';
import { MInput } from 'components/@material-extend';
import { BASIC_DATE_FORMAT, BASIC_DATE_MASK } from 'constants/common';
import useLocales from 'hooks/useLocales';
import Backdrop from '@mui/material/Backdrop';
import dayjs from 'dayjs';

export const calendarHeaderstyles = {
  root: {
    // change month & year order in calendar header
    '& .PrivatePickersFadeTransitionGroup-root:first-child': {
      order: 2
    },
    '& .PrivatePickersFadeTransitionGroup-root:last-child': {
      order: 1
    },
    '& .css-3vloho-MuiButtonBase-root-MuiIconButton-root': {
      order: 3
    }
  }
};

const StyledDatePicker = withStyles(calendarHeaderstyles)(DatePicker);

export interface MDatePickerProps
  extends Omit<DatePickerProps, 'renderInput' | 'onChange' | 'value'> {
  name: string;
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  inputOptions?: any;
  error?: boolean;
  helperText?: string;
  value?: any;
}

const MDatePicker = (props: MDatePickerProps) => {
  const {
    value,
    name,
    onChange,
    inputOptions,
    error,
    helperText,
    label,
    readOnly,
    ...others
  } = props;
  const { t } = useLocales();
  const [openPicker, setOpenPicker] = useState(false);

  const handleChange = (currentValue: any) => {
    onChange(name, currentValue);
    if (dayjs(value).format('DD-MM') !== dayjs(currentValue).format('DD-MM')) {
      setOpenPicker(false);
    }
  };
  const handleBackdropClick = () => {
    setOpenPicker(false);
  };

  return (
    <>
      <StyledDatePicker
        value={value}
        onChange={handleChange}
        mask={BASIC_DATE_MASK}
        inputFormat={BASIC_DATE_FORMAT}
        label={label}
        InputAdornmentProps={{ onClick: () => setOpenPicker(!openPicker) }}
        toolbarTitle={label || t('common.form.datePicker.selectDate')}
        okText={t('common.button.ok')}
        cancelText={t('common.button.cancel')}
        open={openPicker}
        {...others}
        renderInput={(params) => {
          const paramsInput = {
            ...params,
            inputProps: { ...params.inputProps, readOnly }
          };
          return (
            <MInput {...paramsInput} {...inputOptions} error={error} helperText={helperText} />
          );
        }}
      />
      <Backdrop
        sx={{
          background: 'unset'
        }}
        open={openPicker}
        onClick={handleBackdropClick}
      />
    </>
  );
};

export default MDatePicker;
