import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import { FULL_DATE_FORMAT } from 'constants/common';
import dayjs from 'dayjs';
import { Application } from '../types';

export class ApplicationModel extends BaseModel {
  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'desc' })
  desc: string | undefined;

  @Expose({ name: 'useYn' })
  useYn: string | undefined;

  @Expose({ name: 'applicationVersion' })
  @Transform((v) => v && ApplicationVersionsModel.toClass(v.value))
  applicationVersion: Array<Application> | undefined;
}

export class ApplicationVersionsModel extends BaseModel {
  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'envType' })
  envType: string | undefined;

  @Expose({ name: 'serverType' })
  serverType: string | undefined;

  @Expose({ name: 'platformType' })
  platformType: string | undefined;

  @Expose({ name: 'version' })
  version: string | undefined;

  @Expose({ name: 'serverDetail' })
  serverDetail: string | undefined;

  @Expose({ name: 'useYn' })
  useYn: string | undefined;

  @Expose({ name: 'deployedYn' })
  deployedYn: string | undefined;

  @Expose({ name: 'releaseDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  releaseDate: string | undefined;

  @Expose({ name: 'sourceUrl' })
  sourceUrl: string | undefined;

  @Expose({ name: 'releaseNoteUrl' })
  releaseNoteUrl: string | undefined;

  @Expose({ name: 'remark' })
  remark: string | undefined;
}

export class ApplicationCheckModel extends BaseModel {
  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'appVersionId' })
  appVersionId: string | undefined;
}
