import { experimentalStyled as styled, Theme } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import MSelect from 'components/@material-extend/MSelect';

import Page from 'components/Page';

export const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    heigh: '100vh'
  }
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.typography.pxToRem(theme.breakpoints.values.sm - 136),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

export const HeaderStyle = styled('header')(
  ({ theme, isDesktop }: { theme: Theme; isDesktop: boolean }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: isDesktop ? 'absolute' : 'relative',
    padding: theme.spacing(3),
    left: theme.typography.pxToRem(-16),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(4, 4, 0, 4),
      left: 0
    }
  })
);

export const ContentStyle = styled('div')(
  ({ theme, isDesktop }: { theme: Theme; isDesktop: boolean }) => ({
    margin: 'auto',
    display: isDesktop ? 'flex' : 'contents',
    height: '96vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  })
);

export const ButtonStyle = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, '20%', 0),
  width: '60%'
}));
export const FormControlStyle = styled(FormControl)(({ theme }) => ({
  marginTop: theme.typography.pxToRem(15),
  width: '100%'
}));
export const IconLogo = styled('img')(({ theme }) => ({
  width: theme.typography.pxToRem(114.65),
  height: theme.typography.pxToRem(58)
}));

export const IconStyle = styled(Box)(({ theme }) => ({
  marginBottom: theme.typography.pxToRem(40)
}));

export const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%'
}));

export const IconLogoSelect = styled('img')(({ theme }) => ({
  width: theme.typography.pxToRem(35),
  height: theme.typography.pxToRem(35),
  margin: theme.spacing(0, 2, 0, 0),
  display: 'inline'
}));

export const ButtonSubmitStyle = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, '20%'),
  width: '60%'
}));

export const ForgotButton = styled(Button)(({ theme }) => ({
  marginTop: 5,
  float: 'right',
  background: 'none !important'
}));

export const StyledMSelect = withStyles({
  root: {
    height: 56
  },
  select: {
    display: 'flex !important',
    alignItems: 'center'
  }
})(MSelect);

export const useStyles = makeStyles(() => ({
  inputLabel: {
    '& .MuiInputLabel-shrink': {
      fontSize: '1rem'
    }
  }
}));

export const WrapperLicense = styled('div')(({ theme }) => ({
  fontSize: 12
}));

export const TypographyImport = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  display: 'inline',
  fontSize: 12
}));

export const GroupImportFile = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.typography.pxToRem(5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid',
  borderRadius: theme.typography.pxToRem(10)
}));

export const ButtonActiveStyle = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  width: '100%'
}));

export const TypographyWarning = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: 11
}));

export const FileName = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontStyle: 'italic'
}));

export const GroupButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const WrapperAppVersion = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

export const LabelSytems = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  marginTop: theme.spacing(2)
}));

export const LabelVersionLogin = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  marginLeft: theme.spacing(1.4)
}));

export const WrapperForm = styled('div')(() => ({
  zIndex: 10
}));
