import { GET_USER_BY_PROJ_SERVICE, SEARCH_PROJECT_USER_URL_INCLUDE_ADMIN } from 'constants/apiUrl';
import apiClient from 'apis/apiClient';

export const fetchUsersListIncludeAdmin = (params: any) =>
  apiClient
    .post(SEARCH_PROJECT_USER_URL_INCLUDE_ADMIN, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const fetchUsersByProject = (params: any) =>
  apiClient
    .post(GET_USER_BY_PROJ_SERVICE, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
