import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function DocIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9065 12.7464L13.3023 1H13.1824C7.3519 1.00991 2.62479 5.72821 2.604 11.5586V11.6786L14.9065 12.7464Z"
          fill="#ED6C47"
        />
        <path
          d="M13.4227 1H13.3027V11.6786L18.6511 13.8143L24.0002 11.6786V11.5586C23.9794 5.72853 19.2528 1.01036 13.4227 1Z"
          fill="#FF8F6B"
        />
        <path
          d="M23.9998 11.6785V11.7959C23.9781 17.627 19.2509 22.3458 13.4198 22.357H13.184C7.35324 22.3449 2.62658 17.6267 2.604 11.7959V11.6785H23.9998Z"
          fill="#D35230"
        />
        <path
          opacity="0.1"
          d="M13.8371 6.24816V17.6422C13.834 18.0385 13.5939 18.3945 13.2276 18.5458C13.1105 18.5951 12.9849 18.6205 12.8579 18.6205H5.12908C4.97913 18.4485 4.83636 18.2704 4.70112 18.0866C3.3386 16.2729 2.60259 14.0654 2.60401 11.7969V11.562C2.60143 9.51576 3.20044 7.51391 4.32655 5.80541C4.44333 5.6219 4.56831 5.44375 4.70112 5.27148H12.8579C13.3962 5.27505 13.8321 5.70985 13.8371 6.24816Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M13.3023 6.78292V18.177C13.3023 18.3034 13.2768 18.4285 13.2276 18.545C13.076 18.911 12.7201 19.1507 12.324 19.1536H5.61619C5.44698 18.9817 5.28461 18.8037 5.12908 18.6197C4.97913 18.4477 4.83636 18.2696 4.70112 18.0858C3.3386 16.2721 2.60259 14.0646 2.60401 11.7961V11.5612C2.60159 9.51522 3.20059 7.51368 4.32655 5.80542H12.3232C12.8618 5.80899 13.2978 6.24429 13.3023 6.78292Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M13.3023 6.78292V17.1091C13.2974 17.6474 12.8615 18.0822 12.3232 18.0858H4.70112C3.3386 16.2721 2.60259 14.0646 2.60401 11.7961V11.5612C2.60159 9.51522 3.20059 7.51368 4.32655 5.80542H12.3232C12.8618 5.80899 13.2978 6.24429 13.3023 6.78292Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M12.7676 6.78292V17.1091C12.7626 17.6474 12.3267 18.0822 11.7884 18.0858H4.70112C3.3386 16.2721 2.60259 14.0646 2.60401 11.7961V11.5612C2.60159 9.51522 3.20059 7.51368 4.32655 5.80542H11.7884C12.327 5.80899 12.7631 6.24429 12.7676 6.78292Z"
          fill="black"
        />
        <path
          d="M1.98079 5.80542H11.787C12.3279 5.80497 12.7669 6.24284 12.7678 6.78374V16.5735C12.7669 17.1144 12.3279 17.5523 11.787 17.5519H1.98079C1.72096 17.5523 1.47161 17.4494 1.28766 17.2659C1.1037 17.0824 1.00022 16.8334 1 16.5735V6.78374C1.00022 6.52392 1.1037 6.27483 1.28766 6.09133C1.47161 5.90784 1.72096 5.80499 1.98079 5.80542Z"
          fill="url(#paint0_linear_power_point)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7763 8.93819C8.26173 8.55796 7.62887 8.37282 6.99051 8.41576H4.40137V14.9231H5.6713V12.6231H6.91412C7.36495 12.6323 7.81193 12.5385 8.22101 12.3488C8.57904 12.1821 8.88117 11.9153 9.09091 11.5807C9.29821 11.2407 9.40448 10.8486 9.3973 10.4504C9.43408 9.87764 9.20502 9.31983 8.7763 8.93819ZM6.76381 11.6292H5.67049V9.42282H6.80652C7.64164 9.42337 8.0592 9.78206 8.0592 10.4989C8.0797 10.8143 7.95938 11.1227 7.73063 11.3409C7.45429 11.5516 7.11046 11.6542 6.76381 11.6292Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_power_point"
            x1="-3.60551"
            y1="8.86486"
            x2="4.0441"
            y2="22.161"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CA4C28" />
            <stop offset="0.5" stopColor="#C5401E" />
            <stop offset="1" stopColor="#B62F14" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
