import { MDatePicker, MMenuItem } from '@material-extend';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { RHFController, RHFProvider, RHFSelect } from 'components/hook-form';
import { FormGridContainer, FormGridItem } from 'components/layouts';
import { AuthDialogButtons } from 'features/auth/components/authButtons';
import useHookForm from 'hooks/useHookForm';
import useLocales from 'hooks/useLocales';
import { TIME_RESET } from 'constants/userStatus';
import { useFormSchema } from 'hooks/useFormSchema';

import { DurationDataType, HourRestType, StatusLabelType } from './type';
import { getHoursSelectBox, getListStatusSelectbox, getTimeDurationSelectbox } from './helper';

type DurationFormProps = {
  onDiscard: () => void;
  onSubmit: (value: DurationDataType) => void;
};

const currentDate = dayjs(new Date());
const getDurationSchema = (t: any) =>
  Yup.object().shape({
    status: Yup.string().required(
      t('common.error.required', { field: t('dashboard.userStatus.status') })
    ),
    typeReset: Yup.string().required(
      t('common.error.required', { field: t('dashboard.userStatus.resetStatus') })
    ),
    timeReset: Yup.string()
      .nullable()
      .test(
        'timeValidation',
        t('dashboard.userStatus.timeReset.timeReuire'),
        (val: any, schedule: any) => {
          const { typeReset } = schedule.parent;
          if (typeReset === TIME_RESET.CUSTOM && isEmpty(val)) {
            return false;
          }
          return true;
        }
      )
  });

const DurationForm = ({ onDiscard, onSubmit }: DurationFormProps) => {
  const { t } = useLocales();

  const { schema: durationSchema } = useFormSchema(getDurationSchema);

  const hookForm = useHookForm({
    initialValues: {
      status: '',
      typeReset: '',
      dateReset: currentDate,
      timeReset: ''
    },
    validationSchema: durationSchema,
    onSubmit: (value) => {
      onSubmit(value);
    }
  });

  const { methods, handleSubmit, watch, setValue } = hookForm;

  const statusOption = getListStatusSelectbox(t);
  const timeResetoption = getTimeDurationSelectbox(t);

  const watchTypeReset = watch('typeReset');
  const watchDateReset = watch('dateReset');

  const listHours = useMemo(() => {
    const hoursResetoption = getHoursSelectBox();
    const currentDate = dayjs(new Date());
    const isHigher = dayjs(watchDateReset).diff(dayjs(currentDate)) > 0;
    if (isHigher) {
      return hoursResetoption;
    }
    const result: HourRestType[] = [];
    const currentHour = currentDate.hour();
    const currentMinutes = currentDate.minute();
    hoursResetoption.forEach((item) => {
      if (
        item.hour > currentHour ||
        (item.hour === currentHour && item.minitues > currentMinutes)
      ) {
        result.push(item);
      }
    });
    return result;
  }, [watchDateReset]);

  return (
    <>
      <RHFProvider methods={methods} onSubmit={handleSubmit}>
        <FormGridContainer>
          <FormGridItem spacing={12}>
            <RHFSelect
              name="status"
              label={t('dashboard.userStatus.status')}
              options={{
                hasFirstOption: true
              }}
            >
              {statusOption.map((item: StatusLabelType) => (
                <MMenuItem key={item.value} value={item.value}>
                  {item.lable}
                </MMenuItem>
              ))}
            </RHFSelect>
          </FormGridItem>
          <FormGridItem spacing={12}>
            <RHFSelect
              name="typeReset"
              label={t('dashboard.userStatus.resetAfter')}
              options={{
                hasFirstOption: true
              }}
            >
              {timeResetoption.map((item: StatusLabelType) => (
                <MMenuItem key={item.value} value={item.value}>
                  {item.lable}
                </MMenuItem>
              ))}
            </RHFSelect>
          </FormGridItem>

          {watchTypeReset === TIME_RESET.CUSTOM ? (
            <>
              <FormGridItem spacing={8}>
                <RHFController name="dateReset">
                  {({ field: { ref, ...otherFieldProps }, fieldState: { error } }: any) => (
                    <MDatePicker
                      {...otherFieldProps}
                      inputOptions={{
                        fullWidth: true
                      }}
                      minDate={dayjs(new Date())}
                      error={!!error}
                      helperText={error?.message}
                      onChange={setValue}
                    />
                  )}
                </RHFController>
              </FormGridItem>
              <FormGridItem spacing={4}>
                <RHFSelect
                  name="timeReset"
                  options={{
                    hasFirstOption: true
                  }}
                >
                  {listHours.map((item: HourRestType) => (
                    <MMenuItem key={item.value} value={item.value}>
                      {item.lable}
                    </MMenuItem>
                  ))}
                </RHFSelect>
              </FormGridItem>
            </>
          ) : null}
        </FormGridContainer>
        <AuthDialogButtons isEdit onDiscard={onDiscard} />
      </RHFProvider>
    </>
  );
};

export default DurationForm;
