import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class ChangePwdModel extends BaseModel {
  @Expose({ name: 'confirmPassword' })
  confirmPwd: string | undefined;

  @Expose({ name: 'newPassword' })
  newPwd: string | undefined;

  @Expose({ name: 'password' })
  currentPwd: string | undefined;
}
