export const login = {
  title: {
    signIn: '로그인',
    enterDetail: '로그인 정보를 입력하세요.'
  },
  label: {
    language: '언어',
    pwd: '비밀번호',
    userName: '사용자명',
    remember: '사용자명 저장',
    forgotPwd: '비밀번호 재설정',
    needHelp: '도움이 필요하면 ',
    contact: '관리자에게 문의하세요.',
    findPwd: '관리자에게 문의하세요',
    email: '이메일',
    changePwd: '비밀번호 변경',
    ko: '한국어',
    en: 'English',
    currentPwd: '현재 비밀번호',
    newPwd: '새로운 비밀번호',
    confirmPwd: '새 비밀번호 확인',
    loginPage: '돌아가기',
    needSignup: '계정이 없으신가요?',
    confirmLogout:
      '다른 환경에서 해당 계정이 접속 중입니다. 그래도 로그인하시겠습니까? (로그인 성공 시, 다른 환경에 접속되어있는 계정은 로그아웃 됩니다.)',
    needLogout: '다른 환경에서 로그인 되어 있어서 로그인 화면으로 이동됩니다.'
  },
  paragraph: {
    titleResetPass: '비밀번호 재설정',
    body1: '비밀번호를 복구하려면 다음 단계를 따르세요.',
    body2: '1. 사용자명과 이메일 주소를 입력하세요.',
    body3: '2. 비밀번호 복구에 대한 안내 이메일을 확인해주세요.',
    body4: '3. 이메일 내용에 따라 계정 복구 단계를 완료하세요.'
  },
  button: {
    signIn: '로그인',
    resetPwd: '비밀번호 초기화',
    save: '저장',
    signUp: '회원가입'
  },
  errorField: {
    required: {
      pwd: '비밀번호를 입력해주세요!',
      user: '사용자명을 입력해주세요!',
      email: '이메일이 필요합니다',
      currentPwd: '현재 비밀번호를 입력하세요!',
      newPwd: '새로운 비밀번호를 입력하세요!',
      confirmPwd: '새 비밀번호를 확인하세요!'
    },
    format: {
      pwd: '비밀번호는 하나 이상의 알파벳, 숫자, 특수문자를 포함하고, 최소 8자이상이어야 합니다.'
    }
  },
  successMess: {
    forgotPwd: '비밀번호를 재설정하기 위해서 본인 이메일을 확인해주세요.',
    changePwd: '비밀번호가 변경되었습니다.'
  },
  license: {
    title: '라이선스 입력',
    message:
      '시스템이 활성되지 않습니다. <bold>{{import}}</bold>을 클릭해서 시스템을 활성하십시오.',
    import: '라이선스 키 입력',
    form: {
      title: '라이선스 키 입력',
      note: '시스템을 활성하기 위한 라이선스 키를 입력하십시오.',
      importKey: '키 입력',
      active: '사용',
      warning: '10MB 이하 .do 파일을 업로드하십시오.'
    },
    importSuccess: {
      title: '라이선스 키가 활성됩니다.',
      des1: '라이선스 키가 정상적으로 활성되었습니다.',
      des2: 'MetaDesk 사용해주셔서 감사합니다.',
      backToLogin: '로그인 화면 이동'
    },
    validate: {
      licenseActivatedAdmins:
        'MetaDesk 시스템이 활성화 되지 않습니다. 아래 링크를 클릭하여 시스템을 활성화하십시요!',
      licenseActivatedUser:
        'MetaDesk 시스템이 활성화되지 않습니다. 플랫폼 관리자(metadesk_admin@metanet.co.kr)에게 문의하시기 바랍니다.',
      licenseExpiredAdmin:
        '라이선스가 만료되었습니다. 아래 링그를 클릭하여 라이선스를 갱신하십시오!',
      licenseExpiredUser:
        '라이센스가 만료되었습니다. 플랫폼 관리자(metadesk_admin@metanet.co.kr)에게 문의하시기 바랍니다.'
    }
  },
  verify: {
    title: 'Verify User',
    verified: 'Verified!',
    message: 'You have successfully verified your contact number.',
    timeOut: 'It has been over {{timeOut}}h without activity. Please Login again'
  }
};
