import { Key, useState } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha } from '@mui/material/styles';
import { Collapse, ListItemText, List, useTheme, Theme } from '@mui/material';
import { ListItemStyle, ListItemIconStyle, ListItemIconBoxStyle, ArrowIconStyle } from '../styles';

export type NavItemProps = {
  menuName: string;
  menuCode: Key;
  path: string | null;
  icon?: JSX.Element | null;
  info?: JSX.Element;
  subMenuList?: NavItemProps[];
};
export type NavIProps = {
  item: NavItemProps;
  isExpandMenu?: any;
};
export default function NavItem({ item, isExpandMenu }: NavIProps) {
  const theme = useTheme<Theme>();
  const { pathname } = useLocation();
  const { menuName, path, icon, info, subMenuList } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: true }, pathname) : false;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (subMenuList && subMenuList.length > 0) {
    return (
      <>
        <ListItemStyle
          disableGutters
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isExpandMenu && <ListItemText disableTypography primary={menuName} />}
          {info && info}
          <ArrowIconStyle
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
          />
        </ListItemStyle>
        {isExpandMenu && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subMenuList.map((item) => {
                const { menuName, path, icon } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <ListItemStyle
                    disableGutters
                    key={menuName}
                    // @ts-ignore
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      {icon || (
                        <ListItemIconBoxStyle
                          component="span"
                          sx={{
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main'
                            })
                          }}
                        />
                      )}
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={menuName} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      disableGutters
      // @ts-ignore
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isExpandMenu && <ListItemText disableTypography primary={item.menuName} />}
      {info && info}
    </ListItemStyle>
  );
}
