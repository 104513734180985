import { ReactNode, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';

import { NOT_PRIVATE_ROUTES } from 'constants/routes';
import { authSliceName } from 'features/auth/slices';
import { NEW_BUTTON_ID, EDIT_BUTTON_ID, PRIVATE_TICKET } from 'features/auth/constants';
import { useLocation } from 'react-router';

type AuthButtonProps = {
  btnId: string;
  children: ReactNode;
};

const AuthButton = ({ btnId, children }: AuthButtonProps) => {
  const { pathname } = useLocation();
  const buttonPermission = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'buttonPermission'], []),
    shallowEqual
  );

  const allMenuCodes = useSelector(
    (state) => get(state, [authSliceName, 'menuCodes'], []),
    shallowEqual
  );

  const foundMenu = useMemo(
    () =>
      get(
        allMenuCodes.find((item: any) => item.menuPath === pathname),
        'menuCode'
      ),
    [pathname, allMenuCodes]
  );

  const menuButtonList = useMemo(() => {
    // Bypass button permission for Dashboard page
    if (NOT_PRIVATE_ROUTES.includes(pathname)) {
      return { buttonList: [NEW_BUTTON_ID, EDIT_BUTTON_ID, PRIVATE_TICKET] };
    }
    return buttonPermission.find((item: { menuCode: string }) => item.menuCode === foundMenu);
  }, [buttonPermission, pathname, foundMenu]);

  return menuButtonList && menuButtonList.buttonList.indexOf(btnId) !== -1 ? <>{children}</> : null;
};

export default AuthButton;
