import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo
} from 'react';

import flatten from 'lodash/flatten';
import isString from 'lodash/isString';
import { isIOS } from 'react-device-detect';

// highlight
import 'utils/highlight';

// material
import { Theme } from '@mui/material/styles';
import { Box, Typography, FormHelperText, useTheme } from '@mui/material';

// hooks
import useLocales from 'hooks/useLocales';
import LoadingScreen from 'components/LoadingScreen';

import ReactQuill from 'react-quill-with-table';

// css
import 'react-quill-with-table/dist/quill.snow.css';
import 'quill-better-table/dist/quill-better-table.css';

import { filterImageQuillEditor } from 'utils/helpers';

import EditorToolbar, { redoChange, undoChange } from './QuillEditorToolbar';
import { DEFAULT_FORMATS, DEFAULT_TOOLBAR_OPTIONS, TABLE_MODULE_NAME } from './constants';
import modulesConfig from './modules';
import { RootStyle, CounterLabelStyle, LoaderStyle } from './styles';
import { QuillEditorProps, Sources, UnprivilegedEditor } from './interfaces';
import { isQuillEmpty, checkIsAddingNewCodeBlock } from './helpers';
import TablePopover from './table/TablePopover';

// ----------------------------------------------------------------------

const QuillEditor = forwardRef(
  (
    {
      name = '',
      label,
      error = false,
      height,
      value,
      onChange,
      onBlur,
      formats = DEFAULT_FORMATS,
      toolbarOptions = DEFAULT_TOOLBAR_OPTIONS,
      sx,
      helperText,
      showCounter = true,
      modules,
      loading,
      showToolbar = true,
      disabled,
      stateSetFilterImage = false,
      setFilterImage,
      ...others
    }: QuillEditorProps,
    ref: any
  ) => {
    const theme = useTheme<Theme>();
    const { t } = useLocales();
    const currentRange = useRef<any>(null);

    // Table button
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isPopoverOpen = Boolean(anchorEl);

    const toolbarId = `${name}-${toolbarOptions.id}`;
    const popoverId = isPopoverOpen ? `${toolbarId}-insert-table-popover` : undefined;

    const allModules = useMemo(
      () => ({
        ...modulesConfig,
        ...modules,
        table: false,
        [TABLE_MODULE_NAME]: {
          operationMenu: {
            items: {
              insertColumnLeft: {
                text: t('editor.insertColumnLeft')
              },
              insertColumnRight: {
                text: t('editor.insertColumnRight')
              },
              insertRowUp: {
                text: t('editor.insertRowUp')
              },
              insertRowDown: {
                text: t('editor.insertRowDown')
              },
              mergeCells: {
                text: t('editor.mergeCells')
              },
              unmergeCells: {
                text: t('editor.unmergeCells')
              },
              deleteRow: {
                text: t('editor.deleteRow')
              },
              deleteColumn: {
                text: t('editor.deleteColumn')
              },
              deleteTable: {
                text: t('editor.deleteTable')
              }
            }
          }
        },
        toolbar: {
          container: `#${toolbarId}`,
          handlers: { undo: undoChange, redo: redoChange }
        }
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [toolbarId]
    );

    const editorRef: any = useRef(null);
    const quillEditor = editorRef?.current?.editor;
    const tableModule = quillEditor?.getModule(TABLE_MODULE_NAME);

    // Get quill editor instance
    useImperativeHandle(ref, () =>
      quillEditor
        ? {
            ...quillEditor,
            // Note even when Quill is empty,
            // there is still a blank line represented by ‘\n’, so getLength will return 1.
            getText: () => quillEditor.getText().trim() || '', //  Remove the default line break `\n` at the end of the text
            getLength: () => quillEditor.getLength() - 1 || 0
          }
        : null
    );

    const handleBlur = (previousSelection: any, source: Sources, editor: UnprivilegedEditor) => {
      onBlur && onBlur(editor.getHTML());
    };

    const handleChange = (
      value: string,
      delta: any,
      source: Sources,
      editor: UnprivilegedEditor
    ) => {
      // Don't trigger updates if the source of changes is not the user
      if (!onChange || source !== 'user') {
        return;
      }

      const isAddingNewCodeBlock = checkIsAddingNewCodeBlock(delta);

      // add a new line after a code block added
      if (isAddingNewCodeBlock) {
        onChange(value);
        const selection = quillEditor?.getSelection();
        quillEditor?.insertEmbed(selection, 'break', true, 'user');
        return;
      }

      // Check if the editor has any valid content
      /**
       * This logic is currently causing issue
       * when typing Hangul on iOS devices,
       * this will be enhanced later
       */
      if (!isIOS && isQuillEmpty(editor)) {
        onChange('');
        return;
      }
      if (stateSetFilterImage) {
        onChange(filterImageQuillEditor(value));
        setFilterImage && setFilterImage();
      } else {
        onChange(value);
      }
    };

    const handleClickInsertTable = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!quillEditor.hasFocus()) {
        quillEditor.focus();
      }
      const selection = quillEditor?.getSelection();

      currentRange.current = selection;

      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleInsertTable = useCallback(
      (row: number, column: number) => {
        tableModule?.insertTable(row, column, currentRange.current);
      },
      [tableModule]
    );

    useEffect(
      () => () => {
        setAnchorEl(null);
      },
      []
    );

    return (
      <div>
        {label && (
          <Box sx={{ mb: 1 }}>
            <Typography fontWeight="bold">{label}</Typography>
          </Box>
        )}
        <RootStyle
          className="root-quill-editor"
          theme={theme}
          styleProps={{ error, height }}
          labels={{
            link: t('editor.visitLink'),
            edit: t('editor.editLink'),
            save: t('editor.saveLink'),
            remove: t('editor.removeLink'),
            enter: t('editor.enterLink'),
            fontLabels: {
              batang: t('editor.fontFamily.batang'),
              malgunGothic: t('editor.fontFamily.malgunGothic')
            }
          }}
        >
          <div style={{ display: showToolbar ? 'block' : 'none' }}>
            <EditorToolbar
              {...toolbarOptions}
              id={toolbarId}
              formats={formats}
              onClickInsertTable={handleClickInsertTable}
            />
          </div>
          <Box sx={{ position: 'relative', overflow: 'hidden' }}>
            <ReactQuill
              ref={editorRef}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              modules={allModules}
              formats={flatten(formats)}
              readOnly={disabled}
              {...others}
            />
            {loading && (
              <LoaderStyle>
                <LoadingScreen />
              </LoaderStyle>
            )}
          </Box>

          {/* Hide the counter if iOS devices */}
          {!isIOS && showCounter && (
            <CounterLabelStyle>
              <Typography variant="caption">
                {t('editor.counterLabel', { count: isString(value) ? value.length : 0 })}
              </Typography>
            </CounterLabelStyle>
          )}
        </RootStyle>

        {helperText && (
          <FormHelperText error sx={{ px: 2 }}>
            {helperText}
          </FormHelperText>
        )}

        <TablePopover
          id={popoverId}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onInsertTable={handleInsertTable}
        />
      </div>
    );
  }
);

export default QuillEditor;
