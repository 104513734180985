import { useEffect } from 'react';
import { POST_LOGOUT } from 'constants/apiUrl';
import { clearUserCredential, getAccessToken, getCurrentCorpCode } from 'utils/authHelper';
import { BASE_API_URL } from 'constants/appConfig';
import { useAuth } from 'features/auth/useAuth';

const SESSION_KEY = 'tab_tracker_session';
const ALL_TABS_KEY = 'all_tabs';
const LAST_CLOSE_TIME = 'last_close';
const LAST_INTERACTION_TIME = 'lastInteractionTime';

const useTabTracker = () => {
  const { currentUser } = useAuth();
  useEffect(() => {
    // Only start counting tab if logged in
    if (!getAccessToken()) {
      if (localStorage.getItem(LAST_CLOSE_TIME)) {
        localStorage.removeItem(LAST_CLOSE_TIME);
      }
      return;
    }
    let sessionId = sessionStorage.getItem(SESSION_KEY);

    // Store ID of session
    if (!sessionId) {
      sessionId = Math.random().toString(36).substring(2);
      sessionStorage.setItem(SESSION_KEY, sessionId);
    }

    const updateTabCount = (tabs: string[]) => {
      localStorage.setItem(ALL_TABS_KEY, JSON.stringify(tabs));
    };

    const onInitTab = () => {
      const currentTabs = JSON.parse(localStorage.getItem(ALL_TABS_KEY) || '[]');
      if (!currentTabs.includes(sessionId)) {
        currentTabs.push(sessionId);
        updateTabCount(currentTabs);
      }
    };

    // This function will be executed when closing or reloading tabs.
    // Closing browser will also lead to this unload tab event.
    const onUnloadTab = () => {
      localStorage.setItem(LAST_INTERACTION_TIME, Date.now().toString());
      let currentTabs = JSON.parse(localStorage.getItem(ALL_TABS_KEY) || '[]');

      currentTabs = currentTabs.filter((id: string) => id !== sessionId);
      updateTabCount(currentTabs);
      if (currentTabs.length === 0 && getAccessToken()) {
        localStorage.removeItem(ALL_TABS_KEY);
        localStorage.removeItem(LAST_CLOSE_TIME);
      }
    };

    const handleUnload = () => {
      const lastInteractionTime = localStorage.getItem(LAST_INTERACTION_TIME);

      const currentTabs = JSON.parse(localStorage.getItem(ALL_TABS_KEY) || '[]');
      if (lastInteractionTime) {
        const currentTime = Date.now();
        // eslint-disable-next-line radix
        const timeDifference = currentTime - parseInt(lastInteractionTime);
        // Determine the tab that closed within 4 milliseconds , the user closed the last tab and the user still login.
        if (timeDifference < 4 && !currentTabs.length && getAccessToken()) {
          const body = {
            token: getAccessToken(),
            username: currentUser,
            corpCode: getCurrentCorpCode(),
            key: 'LOGOUT'
          };
          const headers = {
            type: 'application/json'
          };
          const blob = new Blob([JSON.stringify(body)], headers);

          navigator.sendBeacon(`${BASE_API_URL}${POST_LOGOUT}`, blob);
          clearUserCredential();
        }
      }
    };

    onInitTab();

    window.addEventListener('beforeunload', onUnloadTab);
    window.addEventListener('unload', handleUnload);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('beforeunload', onUnloadTab);
      window.removeEventListener('unload', handleUnload);
      onUnloadTab();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return null;
};

export default useTabTracker;
