import { useNavigate } from 'react-router';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

import { renderToast } from 'helpers/toast';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import { IMPORT_LICENSE_URI } from 'constants/routes';
import useLocales from 'hooks/useLocales';
import { authSliceName } from 'features/auth/slices';
import { ROLE_USER_IMPORT } from 'features/auth/constants';

import { WrapperLicense, TypographyImport } from '../../styles';

const ToastImportLicense = () => {
  const navigate: any = useNavigate();
  const { t } = useLocales();
  const isShowImportLicense = useSelector((state) =>
    get(state, [authSliceName, 'isShowImportLicense'], false)
  );
  const roleUserLogin = useSelector((state) => get(state, [authSliceName, 'roleUserLogin']));

  const onImportLicense = useCallback(() => {
    navigate(IMPORT_LICENSE_URI);
  }, [navigate]);

  const ToastMessage = useMemo(() => {
    switch (roleUserLogin) {
      case ROLE_USER_IMPORT.activatedAdmin:
        return t('login.license.validate.licenseActivatedAdmins');
      case ROLE_USER_IMPORT.activatedUser:
        return t('login.license.validate.licenseActivatedUser');
      case ROLE_USER_IMPORT.expiredAdmin:
        return t('login.license.validate.licenseExpiredAdmin');
      case ROLE_USER_IMPORT.expiredUser:
        return t('login.license.validate.licenseExpiredUser');
      default:
        return null;
    }
  }, [roleUserLogin, t]);

  const ButtonImport = useMemo(
    () =>
      roleUserLogin === ROLE_USER_IMPORT.expiredAdmin ||
      roleUserLogin === ROLE_USER_IMPORT.activatedAdmin ? (
        <>
          <br />
          <TypographyImport color="primary" variant="body2" onClick={onImportLicense}>
            {t('login.license.import')}
          </TypographyImport>
        </>
      ) : null,
    [roleUserLogin, t, onImportLicense]
  );

  useEffect(() => {
    if (isShowImportLicense && ToastMessage) {
      renderToast(
        <WrapperLicense>
          {ToastMessage}
          {ButtonImport}
        </WrapperLicense>,
        'error',
        alertTriangleFill
      );
    }
    // eslint-disable-next-line
  }, [isShowImportLicense, ToastMessage, ButtonImport]);
  return null;
};

export default ToastImportLicense;
