import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class ForgotPwdModel extends BaseModel {
  @Expose({ name: 'email' })
  email: string | undefined;

  @Expose({ name: 'username' })
  userName: string | undefined;
}
