import { Card, Stack, Grid, GridSize } from '@mui/material';

export const FormCard = ({
  zIndex = 'unset',
  children
}: {
  children: any;
  zIndex?: number | string;
}) => <Card sx={{ p: 3, mb: 2, overflow: 'unset', zIndex }}>{children}</Card>;

export const FormGroup = ({ children }: { children: any }) => <Stack spacing={3}>{children}</Stack>;

export const FormGroupItem = ({ children, center }: { children: any; center?: boolean }) => (
  <Stack
    direction={{ xs: 'column', sm: 'row' }}
    spacing={{ xs: 3, sm: 2 }}
    sx={{
      ...(center
        ? {
            display: 'flex',
            alignItems: 'center'
          }
        : {})
    }}
  >
    {children}
  </Stack>
);

export const FormGridContainer = ({ children }: { children: any }) => (
  <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
    {children}
  </Grid>
);

export const FormGridItem = ({
  children,
  spacing,
  alignItems,
  justifyContent,
  zeroMinWidth
}: {
  children: any;
  spacing: boolean | GridSize | undefined;
  alignItems?: string;
  justifyContent?: string;
  zeroMinWidth?: boolean;
}) => (
  <Grid
    item
    xs={12}
    md={spacing}
    zeroMinWidth
    sx={{
      display: (alignItems || justifyContent) && 'flex',
      alignItems,
      justifyContent
    }}
  >
    {children}
  </Grid>
);
