// containers
import ToastContainer from 'containers/ToastContainer';
import ToastImportLicense from 'features/auth/LoginPage/components/ToastImportLicense';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// locales
import './locales/i18n';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import LoadingContainer from './components/loading';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <ScrollToTop />
          <LoadingContainer />
          <ToastContainer />
          <ToastImportLicense />
          <Router />
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
