import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';

// components
import { CommonDialog } from 'components/@material-extend';
import {
  ticketFormSliceName,
  initialState,
  ticketFormActions
} from 'features/helpDesk/ticket/TicketForm/slices';
import TicketForm from 'features/helpDesk/ticket/TicketForm';
import useLocales from 'hooks/useLocales';
import NewTicketButton from 'components/@material-extend/buttons/NewTicketButton';
import { authSliceName } from 'features/auth/slices';
import { NEW_BUTTON_ID } from 'features/auth/constants';

// hard code ticket menu for only the new ticket button
// because it can be used on any routes
const TICKET_MENU_CODE = 'TICK';

// ----------------------------------------------------------------------

export default function NewTicketDialog() {
  const { t } = useLocales();
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const dispatch: any = useDispatch();

  const showNewTicketForm = () => setIsOpenFormDialog(true);

  const closeNewTicketForm = () => setIsOpenFormDialog(false);

  const actionsTicketFormSucceed = useSelector((state) =>
    get(
      state,
      [ticketFormSliceName, 'actionsCreateTicketSucceed'],
      initialState.actionsCreateTicketSucceed
    )
  );

  useEffect(() => {
    if (actionsTicketFormSucceed) {
      setIsOpenFormDialog(false);
      dispatch(ticketFormActions.modifyCreateTicketStatus(false));
    }
  }, [actionsTicketFormSucceed, dispatch]);

  // [Create ticket] in the header must follow the permission of [Create] button in Ticket mgmt
  const buttonPermission = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'buttonPermission'], []),
    shallowEqual
  );

  const buttonList: any = useMemo(() => {
    const ticketPermission = find(buttonPermission, ['menuCode', TICKET_MENU_CODE]);
    return get(ticketPermission, ['buttonList']);
  }, [buttonPermission]);

  if (!buttonList?.includes(NEW_BUTTON_ID)) return <></>;

  return (
    <>
      <NewTicketButton onClick={showNewTicketForm} />

      <CommonDialog
        isOpenDialog={isOpenFormDialog}
        title={t('helpDesk.ticketManagement.createTicket')}
        maxWidth="lg"
      >
        <TicketForm onDiscard={closeNewTicketForm} />
      </CommonDialog>
    </>
  );
}
