import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import dayjs from 'dayjs';

import { FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';
import { IHistoryMessage } from 'models/LocaleModel';

export class TicketHistoryModel extends BaseModel {
  @Expose({ name: 'createdName' })
  createdName: string | undefined;

  @Expose({ name: 'createdBy' })
  createdBy: string | undefined;

  @Expose({ name: 'changeType' })
  changeType: string | undefined;

  @Expose({ name: 'updatedName' })
  updatedName: string | undefined;

  @Expose({ name: 'createdDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT_INCLUDE_SECOND))
  createdDate: number | undefined;

  @Expose({ name: 'historyFieldEditors' })
  @Transform((item) => item && HistoryFieldEditorsModel.toClass(item.value))
  historyFieldEditors: Array<any> | undefined;
}

export class HistoryFieldEditorsModel extends BaseModel {
  @Expose({ name: 'field' })
  field: string | undefined;

  @Expose({ name: 'fieldLang' })
  fieldLang: IHistoryMessage | undefined;

  @Expose({ name: 'newValue' })
  newValue: string | undefined;

  @Expose({ name: 'newValueLang' })
  newValueLang: IHistoryMessage | undefined;

  @Expose({ name: 'oldValue' })
  oldValue: string | undefined;

  @Expose({ name: 'oldValueLang' })
  oldValueLang: IHistoryMessage | undefined;

  @Expose({ name: 'newName' })
  newName: IHistoryMessage | undefined;

  @Expose({ name: 'oldName' })
  oldName: IHistoryMessage | undefined;
}
