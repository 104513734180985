import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function DocIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title />
        <g id="Layer_2">
          <g id="Layer_1-2">
            <path
              fill="#F2F2F1"
              d="M363.8,103.7v304.6c0,14.8-12,26.9-26.9,26.9l0,0H140.5c-14.8,0-26.9-12-26.9-26.9l0,0V163.9l87.2-87.1h136.1    C351.8,76.8,363.8,88.8,363.8,103.7z"
            />
            <path
              fill="#D3D2D0"
              d="M113.7,163.9l87.1-87.1v60.4c0,14.8-12,26.8-26.8,26.8L113.7,163.9z"
            />
            <polygon
              fill="#D3D2D0"
              points="363.8,309.1 363.8,400.4 196.6,400.4 140.2,385.5 113.7,385.5 113.7,324.1 140.2,324.1 140.2,324     196.6,309.1   "
            />
            <path
              fill="#343C4F"
              d="M196.6,298.3h194.1c11.9,0,21.6,9.7,21.6,21.6l0,0V368c0,11.9-9.7,21.6-21.6,21.6l0,0H196.6l0,0V298.3    L196.6,298.3z"
            />
            <polygon fill="#1E2537" points="196.6,389.6 140.2,374.6 140.2,313.2 196.6,298.3   " />
            <rect fill="#343C4F" height="61.4" width="40.6" x="99.7" y="313.3" />
            <polygon fill="#181D2A" points="99.7,313.3 113.7,305 113.7,313.3   " />
            <polygon fill="#181D2A" points="99.7,374.6 113.7,383 113.7,374.6   " />
            <path
              fill="#FFFFFF"
              d="M236.8,374.3v-45.3h-11.6v-13.1h38v13.1h-11.6v45.3L236.8,374.3z"
            />
            <path
              fill="#FFFFFF"
              d="M301.9,315.9h16.8l-17.9,27.6l20.2,30.8h-16.9l-12.6-20.7l-12,20.7h-16.6l20.4-31.1l-18-27.3h16.9l10,16.7    L301.9,315.9z"
            />
            <path
              fill="#FFFFFF"
              d="M332.3,374.3v-45.3h-11.6v-13.1h38v13.1h-11.5v45.3L332.3,374.3z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
