import { createSlice } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { errorHandler } from 'store/errorHandlerSaga';
import { loadingActions } from 'components/loading/slices';
import { fetchCcuHistoryData } from './apis';
import { CUserAndHisSearchModel, CUserAndHisModel } from './models/CUserAndHisModel';

const initialState = {
  data: {},
  dataPopup: {}
};

const concurrentUserAndHistorySliceName = 'concurrentUserAndHistory';

const concurrentUserAndHistorySlice = createSlice({
  name: concurrentUserAndHistorySliceName,
  initialState,
  reducers: {
    fetchCcuHistorySuccess: (state, { payload }) => {
      state.data = payload;
    },
    fetchCcuHistoryPopupSuccess: (state, { payload }) => {
      state.dataPopup = payload;
    }
  }
});

const {
  actions: reducerActions,
  reducer: concurrentUserAndHistoryReducer
} = concurrentUserAndHistorySlice;

const concurrentUserAndHistorySliceSaga = createSliceSaga({
  name: concurrentUserAndHistorySliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    *fetchConcurrentUserAndHistory(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { data } = yield call(
          fetchCcuHistoryData,
          CUserAndHisSearchModel.toPlain(action.payload)
        );

        if (data) {
          const ccuHistoryData = CUserAndHisModel.toClass(data.data);
          yield put(reducerActions.fetchCcuHistorySuccess(ccuHistoryData));
        }
        yield put(loadingActions.showLoading());
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    },
    *fetchConcurrentUserAndHistoryPopup(action): any {
      try {
        yield put(loadingActions.showLoading());
        const { data } = yield call(
          fetchCcuHistoryData,
          CUserAndHisSearchModel.toPlain(action.payload)
        );

        if (data) {
          const ccuHistoryData = CUserAndHisModel.toClass(data.data);
          yield put(reducerActions.fetchCcuHistoryPopupSuccess(ccuHistoryData));
        }
        yield put(loadingActions.showLoading());
      } catch (error) {
        yield put(errorHandler(error));
      } finally {
        yield put(loadingActions.stopLoading());
      }
    }
  }
});

const {
  saga: concurrentUserAndHistorySaga,
  actions: sagaActions
} = concurrentUserAndHistorySliceSaga;

const concurrentUserAndHistoryActions = {
  ...reducerActions,
  ...sagaActions
};
export {
  concurrentUserAndHistorySliceName,
  concurrentUserAndHistoryActions,
  concurrentUserAndHistoryReducer,
  concurrentUserAndHistorySaga,
  initialState
};
