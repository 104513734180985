import { ReactNode } from 'react';
// form
import { FormProvider, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

export type RHFProviderProps = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};

export default function RHFProvider({ children, onSubmit, methods }: RHFProviderProps) {
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} noValidate>
        {children}
      </form>
    </FormProvider>
  );
}
