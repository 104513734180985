import { SPECIAL_CHARACTERS_REGEX } from 'constants/common';
import { getNameOfFile } from './helpers';

export const rangeValidation = ({ val, min, max }: { val: string; min: number; max: number }) =>
  Boolean(!val || (val && val.length >= min && val.length <= max));

export const isFilenameValid = (file: File) => {
  const filename = getNameOfFile(file.name);
  return file && !SPECIAL_CHARACTERS_REGEX.test(filename);
};
