export const ACTIVE_COMMON_CODE = 'ACTIVE_YN';
export const PROJECT_TYPE_COMMON_CODE = 'PROJECT_TYPE';

export const ACTIVE_YN = {
  Yes: 'Y',
  No: 'N'
};
export const CONTRACT_TYPE_COMMON_CODE = 'CONTRACT_TYPE';
export const CONTRACT_STATUS_TYPE = 'CONTRACT_STATUS_TYPE';

export const PUBLISH_DATE = 'PUBLISH_DATE';
export const PUBLISH_YN = 'PUBLISH_YN';
export const ANNOUN_TYPE = 'ANNOUN_TYPE';
export const PROJECT_TYPE = 'PROJECT_TYPE';

export const ENV_TYPE = 'ENV_TYPE';
export const PLATFORM_TYPE = 'PLATFORM_TYPE';
export const SERVER_TYPE = 'SERVER';
export const DEPLOYED_YN = 'DEPLOYED_YN';
export const TICKET_TYPE = 'TICKET_TYPE';
export const TICKET_INCIDENT = 'TICKET_INCIDENT';
export const TICKET_SERVICE = 'TICKET_SERVICE';
export const TICKET_DEPLOY = 'TICKET_DEPLOY';
export const TICKET_BUILD = 'TICKET_BUILD';
export const TICKET_REQUEST = 'TICKET_REQUEST';
export const TICKET_REQUEST_DEPLOY = 'TICKET_REQDEPLOY';
export const DEPLOY_SCHEDULE = 'DEPLOY_SCHEDULE';
