import { Avatar, Typography } from '@mui/material';

import { STATUS_ICON_TYPE } from 'constants/userStatus';
import { MiniIconStatusWrapper, WrapperAvatarUser } from './styled';
import StatusIcon from './StatusIcon';
import { StatusIconRender } from './type';

type AvatarUserType = {
  statusKey: STATUS_ICON_TYPE;
  url: string;
  alt?: string;
};
const AvatarUser = ({ url, statusKey, alt = 'My Avatar' }: AvatarUserType) => (
  <WrapperAvatarUser>
    <Avatar alt={alt} src={url} />
    <MiniIconStatusWrapper>
      <StatusIcon
        statusKey={statusKey}
        renderProps={(iconStatus: StatusIconRender) => (
          <>
            {iconStatus.icon}
            <Typography>{iconStatus.lable}</Typography>
          </>
        )}
      />
    </MiniIconStatusWrapper>
  </WrapperAvatarUser>
);

export default AvatarUser;
