export const HTTP_AUTHORIZATION_HEADER = 'Authorization';
export const MENU_CODE = 'Menu-Code';
export const INVALID_CREDENTIAL_ERR = 'InvalidCredential';

export const BASE_AUTH = `/auth`;
export const LOGIN = `${BASE_AUTH}/users/login`;
export const LOGOUT = `${BASE_AUTH}/users/logout`;
export const SIGNUP = `${BASE_AUTH}/signUp`;
export const GET_ACCESS_TOKEN = `${BASE_AUTH}/users/refreshToken`;
export const CHANGE_PASSWORD = `${BASE_AUTH}/users/changePassword`;
export const RESET_PASSWORD = `${BASE_AUTH}/users/resetPassword`;
export const FORGOT_PASSWORD = `${BASE_AUTH}/users/forgotPassword`;
export const HEALTH_CHECK_TOKEN = `${BASE_AUTH}/users/health-check-token`;
export const POST_LOGOUT = `${BASE_AUTH}/users/close-browser`;
export const BASE_SYSTEM = '/system';
export const TICKET_SYSTEM = '/ticket';
export const GET_ROLES_URL = `${BASE_SYSTEM}/roles/get-by-corp-code`;
export const COMMON_CODE_URL = `${BASE_SYSTEM}/common-codes/get-by-group-code`;
export const SUB_COMMON_CODE_URL = `${BASE_SYSTEM}/common-codes/get-subcode-by-groupcode`;
export const GET_USER_INFO = `${BASE_SYSTEM}/menus/me`;
export const GET_CORP_LIST = `${BASE_SYSTEM}/corporations/get-by-current-user`;
export const DELETE_USER = `${BASE_SYSTEM}/users/delete`;
export const DOWNLOAD_USER = `${BASE_SYSTEM}/export/corp-user`;
export const SYSTEM_SETTING_URL = `${BASE_SYSTEM}/settings`;
export const DASHBOARD_SETTING = `${SYSTEM_SETTING_URL}/dashboard`;
export const GENERAL_SETTING = `${SYSTEM_SETTING_URL}/general-setting`;
export const UPDATE_GENERAL_SETTING = `${SYSTEM_SETTING_URL}/save-general-setting`;
export const TABLE_SETTING = `${BASE_SYSTEM}/setting-tables`;
export const TABLE_SETTING_LIST = `${TABLE_SETTING}/list`;
export const SIGN_UP_USER = `${BASE_SYSTEM}/users/sign-up`;
export const REGISTER_USER = `${BASE_SYSTEM}/users/register`;
export const FETCH_CORP_ORG_PUBLIC = `${BASE_SYSTEM}/corporations/find-public-corp`;
export const USER_ASSINGED_PROJECTS = `${BASE_SYSTEM}/projects/by-user-name`;
export const FETCH_VERIFY_TYPE = `${BASE_SYSTEM}/common-codes/get-public-by-group-code`;

// email setting

export const EMAIL_SETTING = `${SYSTEM_SETTING_URL}/email`;
export const GET_STATUS_TICKET_BY_PROJECT = `${BASE_SYSTEM}/status-send-mail/list`;
export const ADD_LIST_STATUS = `${BASE_SYSTEM}/status-send-mail/add-list`;

// useMgt
export const CORP_USER_URL = `${BASE_SYSTEM}/corp-users`;
export const ACTIVE_USER_SEARCH_URL = `${CORP_USER_URL}/search-user-active`;
export const USER_SEARCH_URL = `${CORP_USER_URL}/search`;
export const GET_USER_INFORMATION_URL = `${CORP_USER_URL}/get`;
export const USER_URL = `${BASE_SYSTEM}/users`;
export const USER_UPDATE_PROFILE_URL = `${USER_URL}/update-profile`;
export const USER_REMOVE_AVATAR_URL = `${USER_URL}/remove-avatar`;
export const USER_CHECK_DUPLICATION = `${USER_URL}/check-duplicate-id`;
export const UPDATE_USER_STATUS = `${USER_URL}/update-user-status`;
export const VERIFY_NEW_PHONE = `${USER_URL}/request-new-phone-no`;
// User Registration
export const GET_LIST_USER_REGISTRATION = `${BASE_SYSTEM}/corp-users/search-for-approve`;
export const UPDATE_USER_APPROVE = `${BASE_SYSTEM}/users/approve-user`;
export const DEELTE_USER_APPROVE = `${BASE_SYSTEM}/users/request/delete`;
export const VERIFY_USER = `${BASE_SYSTEM}/users/verify-by-user`;
export const DELETE_MULTI_USER = `${BASE_SYSTEM}/corp-users/delete-user-request`;

// orgMgt
export const ORGANIZATIONS_URL = `${BASE_SYSTEM}/organizations`;
export const ORGANIZATIONS_BY_CORP_CODDE_URL = `${ORGANIZATIONS_URL}/get-by-corp-code`;
export const ORGANIZATIONS_SEARCH_URL = `${ORGANIZATIONS_URL}/search`;
export const ORGANIZATIONS_DELETE_URL = `${ORGANIZATIONS_URL}/delete`;
export const DOWNLOAD_ORGANIZATION_URL = `${BASE_SYSTEM}/export/organization`;
export const GET_LIST_ORGANZATION_DASHBOARD = `${ORGANIZATIONS_URL}/get-by-role`;

// corpMgt
export const CORPORATION_URL = `${BASE_SYSTEM}/corporations`;
export const SEARCH_CORP_URL = `${CORPORATION_URL}/search`;
export const DELETE_CORP_URL = `${CORPORATION_URL}/delete`;
export const DOWNLOAD_CORPORATION_URL = `${BASE_SYSTEM}/export/corporation`;
export const DOWNLOAD_ALL_CORPORATION_URL = `${BASE_SYSTEM}/export/all-corporation`;
export const COUNT_USER_IN_CORP = `${BASE_SYSTEM}/corporations/count-user-in-corp`;
export const GET_ALL_CORP = `${BASE_SYSTEM}/corporations/get-all`;
export const REMOVE_LOGO = `${BASE_SYSTEM}/corporations/remove-corp-avatar`;

// contractManagement
export const CONTRACTS_URL = `${BASE_SYSTEM}/contracts`;
export const CONTRACTS_SEARCH_URL = `${CONTRACTS_URL}/search`;
export const CONTRACT_GET_URL = `${CONTRACTS_URL}/get`;
export const CONTRACTS_DELETE_URL = `${CONTRACTS_URL}/delete`;
export const DOWNLOAD_CONTRACT_URL = `${BASE_SYSTEM}/export/contract`;
export const CONTRACT_STATUS_DASHBOARD = `${CONTRACTS_URL}/get-for-dashboard`;

// projectMgt
export const PROJECT_URL = `${BASE_SYSTEM}/projects`;
export const SEARCH_PROJECT_URL = `${PROJECT_URL}/search`;
export const GET_PROJECT_URL_BY_CORP_CODE = `${PROJECT_URL}/by-corpCode`;
export const GET_APPLICATION_BY_PROJ_CODE = `${BASE_SYSTEM}/applications/get-by-list-projcode`;
export const GET_PROJECT_ALL_URL_BY_CORP_CODE = `${PROJECT_URL}/all/corpCode`;
export const PROJECT_DELETE_URL = `${PROJECT_URL}/delete`;
export const GET_PROJECT_BY_ID = `${PROJECT_URL}/get`;
export const PROJECT_CHECK_APPLICATION = `${PROJECT_URL}/app-exist-ticket`;
export const PROJECT_USER_URL = `${BASE_SYSTEM}/project-users`;
export const SEARCH_PROJECT_USER_URL = `${PROJECT_USER_URL}/search`;
export const SEARCH_PROJECT_USER_URL_INCLUDE_ADMIN = `${PROJECT_USER_URL}/include-admin`;
export const DELETE_PROJECT_USER_URL = `${PROJECT_USER_URL}/delete`;
export const DOWNLOAD_PROJECT_URL = `${BASE_SYSTEM}/export/project`;
export const GET_WORKFLOW_BY_USER = `${BASE_SYSTEM}/perm-workflow/list`;
export const ADD_LIST_WORKFLOW = `${BASE_SYSTEM}/perm-workflow/add-list`;
export const GET_PROJECT_BY_ORGANIZATION = `${BASE_SYSTEM}/projects/by-org-role`;

// ticketMgt
export const TICKET_URL = `${TICKET_SYSTEM}/tickets`;
export const SEARCH_TICKET_URL = `${TICKET_URL}/search`;
export const GET_TICKET_FOR_LINK = `${TICKET_URL}/get-for-link`;
export const TICKET_DETAIL_URL = `${TICKET_URL}/get`;
export const TICKET_HISTORY_URL = `${TICKET_SYSTEM}/ticket-histories/search`;
export const TICKET_LOG_WORK_URL = `${TICKET_SYSTEM}/ticket-log-work`;
export const TICKET_LOG_WORK_SEARCH_URL = `${TICKET_LOG_WORK_URL}/search`;
export const TICKET_LOG_WORK_DELETE_URL = `${TICKET_LOG_WORK_URL}/delete`;
export const TICKET_CHANGE_STATUS_URL = `${TICKET_URL}/status`;
export const TICKET_METRICS_URL = `${TICKET_SYSTEM}/metrics`;
export const MY_RECENT_TICKETS_URL = `${TICKET_URL}/my-recent-tickets`;
export const TICKET_EXCEL_DOWNLOAD_URL = `${TICKET_URL}/excel`;
export const TICKET_EXPORT_DETAIL = `${TICKET_URL}/export-detail`;
export const TICKET_CICD_EXPORT = `${TICKET_URL}/export-cicd-status-excel`;

export const GET_ALL_STATUS_TICKET = `${BASE_SYSTEM}/common-codes/search`;
export const SEARCH_BOARD_URL = `${TICKET_URL}/search-kanban`;
export const TICKET_RELATES_URL = `${TICKET_SYSTEM}/ticket-relates`;
export const TICKET_RELATES_LIST_URL = `${TICKET_RELATES_URL}/list`;
export const TICKET_RELATES_DELETE_URL = `${TICKET_RELATES_URL}/delete`;
export const TICKET_MERGE_REQUEST = `${TICKET_URL}/search-ticket-mr`;
export const TICKET_BRANCH = `${TICKET_URL}/search-ticket-branch`;
export const TICKET_COMMIT = `${TICKET_URL}/search-ticket-commit`;
export const TICKET_DEPLOYMENT_STATUS = `${TICKET_SYSTEM}/cicdstatus/tab/build-deploy`;
export const TICKET_DEPLOYMENT_HISTORY = `${TICKET_SYSTEM}/cicdstatus/history/build-deploy`;
export const GET_FILE_SERVICE_TARGET = `${BASE_SYSTEM}/common-codes/get-list-file`;
export const DELETE_TICKET_DETAIL = `${TICKET_URL}/delete`;
export const RESOTE_INTEGRATION = `${TICKET_URL}/update-integrate-active`;
export const UPDATE_DEPLOYMENT_SCHEDULE = `${TICKET_URL}/update-regular-group`;
export const GET_TYPE_BY_PROJ = `${BASE_SYSTEM}/common-codes/get-ticket-type-by-project`;
export const CHECK_REGULAR_GROUP = `${TICKET_URL}/regular-group-check`;
export const SAVE_TICKET_FIELD_SETTING = `${BASE_SYSTEM}/settings/save-ticket-setting`;
export const CREATE_TICKET_FILE = `${TICKET_SYSTEM}/tickets/upload-csv`;
export const DOWNLOAD_CSV_TEMPLATE = `${TICKET_SYSTEM}/tickets/download-csv`;
export const DELETE_BRANCH_MR = `${TICKET_SYSTEM}/tickets/delete-ticket-dev-history`;
export const SOCKET_DUPLICATE_SESSION = '/user/topic/kick-out-user';
// ticket comments
export const TICKET_COMMENT_URL = `${TICKET_SYSTEM}/ticket-comments`;
export const SEARCH_TICKET_COMMENT_URL = `${TICKET_COMMENT_URL}/search`;
export const DELETE_TICKET_COMMENT_URL = `${TICKET_COMMENT_URL}/delete`;

// application
export const APPLICATION_URL = `${BASE_SYSTEM}/applications`;
export const APPLICATION_BY_CORP_CODE_URL = `${APPLICATION_URL}/get-by-corp-code`;

// document management
export const DOCUMENT_SECTION_URL = `${TICKET_SYSTEM}/doc-sections`;
export const SEARCH_DOCUMENT_SECTION_URL = `${DOCUMENT_SECTION_URL}/search`;
export const GET_DOCUMENT_SECTION_URL = `${DOCUMENT_SECTION_URL}/get`;
export const DELETE_DOCUMENT_SECTION_URL = `${DOCUMENT_SECTION_URL}/delete`;
export const DOCUMENT_SECTION_LANG_DETAIL = `${DOCUMENT_SECTION_URL}/i10values/detail`;
export const DOCUMENT_SECTION_LANG_UPDATE = `${DOCUMENT_SECTION_URL}/i10values`;
export const DOCUMENT_SECTION_LANG_DELETE = `${DOCUMENT_SECTION_URL}/i10values/delete`;
export const GET_ACTIVE_DOCUMENT_SECTION_LIST_URL = `${DOCUMENT_SECTION_URL}/active`;
export const DOCUMENT_CATEGORY_URL = `${TICKET_SYSTEM}/doc-categories`;
export const SEARCH_DOCUMENT_CATEGORY_URL = `${DOCUMENT_CATEGORY_URL}/search`;
export const GET_DOCUMENT_CATEGORY_URL = `${DOCUMENT_CATEGORY_URL}/get`;
export const DELETE_DOCUMENT_CATEGORY_URL = `${DOCUMENT_CATEGORY_URL}/delete`;
export const DOCUMENT_CATEGORY_LANG_DETAIL = `${DOCUMENT_CATEGORY_URL}/i10values/detail`;
export const DOCUMENT_CATEGORY_LANG_UPDATE = `${DOCUMENT_CATEGORY_URL}/i10values`;
export const DOCUMENT_CATEGORY_LANG_DELETE = `${DOCUMENT_CATEGORY_URL}/i10values/delete`;
export const DOCUMENT_DETAIL_URL = '';
export const GET_ACTIVE_DOCUMENT_CATEGORY_LIST_URL = `${DOCUMENT_CATEGORY_URL}/active`;
export const DOCUMENT_URL = `${TICKET_SYSTEM}/documents`;
export const DOCUMENT_STATUS_URL = `${DOCUMENT_URL}/my-recent`;
export const SEARCH_DOCUMENT_URL = `${DOCUMENT_URL}/search`;
export const GET_DOCUMENT_URL = `${DOCUMENT_URL}/get`;
export const GET_DOCUMENT_DETAIL_URL = `${DOCUMENT_URL}/detail`;
export const DELETE_DOCUMENT_URL = `${DOCUMENT_URL}/delete`;
export const DOCUMENT_LANG_DETAIL = `${DOCUMENT_URL}/i10values/detail`;
export const DOCUMENT_LANG_UPDATE = `${DOCUMENT_URL}/i10values`;
export const DOCUMENT_LANG_DELETE = `${DOCUMENT_URL}/i10values/delete`;

// announcementMgt
export const ANNOUNCEMENT_URL = `${TICKET_SYSTEM}/announcements`;
export const ANNOUNCEMENT_DETAIL_URL = `${ANNOUNCEMENT_URL}/get`;
export const VIEW_ANNOUNCEMENT_URL = `${ANNOUNCEMENT_URL}/search-publish-list`;
export const COUNT_VIEW_ANNOUNCEMENT_URL = `${ANNOUNCEMENT_URL}/view-count`;
export const SEARCH_ANNOUNCEMENT_URL = `${ANNOUNCEMENT_URL}/search`;
export const DELETE_ANNOUNCEMENT_URL = `${ANNOUNCEMENT_URL}/delete`;
export const MY_RECENT_ANNOUNCEMENT_URL = `${ANNOUNCEMENT_URL}/my-recent`;

// FAQ
export const FAQ_SECTION_URL = `${TICKET_SYSTEM}/faq-sections`;
export const SEARCH_FAQ_SECTION_URL = `${FAQ_SECTION_URL}/search`;
export const GET_FAQ_SECTION_URL = `${FAQ_SECTION_URL}/get`;
export const DELETE_FAQ_SECTION_URL = `${FAQ_SECTION_URL}/delete`;
export const FAQ_SECTION_LANG_DETAIL = `${FAQ_SECTION_URL}/i10values/detail`;
export const FAQ_SECTION_LANG_UPDATE = `${FAQ_SECTION_URL}/i10values`;
export const FAQ_SECTION_LANG_DELETE = `${FAQ_SECTION_URL}/i10values/delete`;
export const GET_ACTIVE_FAQ_SECTION_LIST_URL = `${FAQ_SECTION_URL}/active`;
export const FAQ_CATEGORY_URL = `${TICKET_SYSTEM}/faq-categories`;
export const SEARCH_FAQ_CATEGORY_URL = `${FAQ_CATEGORY_URL}/search`;
export const GET_FAQ_CATEGORY_URL = `${FAQ_CATEGORY_URL}/get`;
export const DELETE_FAQ_CATEGORY_URL = `${FAQ_CATEGORY_URL}/delete`;
export const FAQ_CATEGORY_LANG_DETAIL = `${FAQ_CATEGORY_URL}/i10values/detail`;
export const FAQ_CATEGORY_LANG_UPDATE = `${FAQ_CATEGORY_URL}/i10values`;
export const FAQ_CATEGORY_LANG_DELETE = `${FAQ_CATEGORY_URL}/i10values/delete`;
export const GET_ACTIVE_FAQ_CATEGORY_LIST_URL = `${FAQ_CATEGORY_URL}/active`;
export const FAQ_QUESTION_URL = `${TICKET_SYSTEM}/questions`;
export const SEARCH_FAQ_QUESTION_URL = `${FAQ_QUESTION_URL}/search`;
export const GET_FAQ_QUESTION_URL = `${FAQ_QUESTION_URL}/get`;
export const GET_FAQ_QUESTION_DETAIL_URL = `${FAQ_QUESTION_URL}/detail`;
export const DELETE_FAQ_QUESTION_URL = `${FAQ_QUESTION_URL}/delete`;
export const FAQ_LANG_DETAIL = `${FAQ_QUESTION_URL}/i10values/detail`;
export const FAQ_LANG_UPDATE = `${FAQ_QUESTION_URL}/i10values`;
export const FAQ_LANG_DELETE = `${FAQ_QUESTION_URL}/i10values/delete`;

// portals
export const VIEW_PORTALS_URL = `${TICKET_SYSTEM}/portals`;

export const GET_VIEW_DOCUMENT_CATEGORY_URL = `${VIEW_PORTALS_URL}/doc-category`;
export const GET_VIEW_DOCUMENT_SECTION_URL = `${VIEW_PORTALS_URL}/doc-section`;
export const GET_VIEW_DOCUMENT_QUESTIONS_URL = `${VIEW_PORTALS_URL}/doc-documents`;

export const GET_VIEW_FAQ_CATEGORY_URL = `${VIEW_PORTALS_URL}/faq-category`;
export const GET_VIEW_FAQ_SECTION_URL = `${VIEW_PORTALS_URL}/faq-section`;
export const GET_VIEW_FAQ_QUESTIONS_URL = `${VIEW_PORTALS_URL}/faq-questions`;

// schedule
export const SCHEDULE_MANAGEMENT_URL = `${TICKET_SYSTEM}/schedules`;
export const SEARCH_SCHEDULE_URL = `${SCHEDULE_MANAGEMENT_URL}/search-list`;
export const SEARCH_DATE_HAS_SCHEDULE_URL = `${SCHEDULE_MANAGEMENT_URL}/date-has-appointment`;
export const GET_SCHEDULE_DETAIL_URL = `${SCHEDULE_MANAGEMENT_URL}/get-detail`;
export const DELETE_SCHEDULE_DETAIL_URL = `${SCHEDULE_MANAGEMENT_URL}/delete`;

// application-version
export const APPLICATION_SYSTEM = `${BASE_SYSTEM}/applications`;
export const SEARCH_APPLICATIONS = `${APPLICATION_SYSTEM}/search`;
export const DELETE_APPLICATION = `${APPLICATION_SYSTEM}/delete`;
export const APPLICATION_VERSIONS_URL = `${BASE_SYSTEM}/application-versions`;
export const SEARCH_APPLICATION_VERSIONS_URL = `${APPLICATION_VERSIONS_URL}/search`;
export const GET_APPLICATION_VERSIONS_URL = `${APPLICATION_VERSIONS_URL}/get`;
export const DELETE_APPLICATION_VERSIONS_URL = `${APPLICATION_VERSIONS_URL}/delete`;
export const GET_VERSION_BY_APP_CODE = `${APPLICATION_VERSIONS_URL}/app-code`;
export const GET_VERSION_BY_MULTI_APP_CODES = `${APPLICATION_VERSIONS_URL}/proj-app-code`;

// internal-sharing-board
export const INTERNAL_SHARING_URL = `${TICKET_SYSTEM}/internal-posts`;
export const GET_LIST_INTERNAL = `${INTERNAL_SHARING_URL}/search`;
export const INTERNAL_SHARING_DETAIL = `${INTERNAL_SHARING_URL}/get`;
export const INTERNAL_SHARING_DELETE = `${INTERNAL_SHARING_URL}/delete`;

// common code management
export const COMMON_CODE_MANAGEMENT_URL = `${BASE_SYSTEM}/common-codes`;
export const SEARCH_COMMON_CODE_MANAGEMENT_URL = `${COMMON_CODE_MANAGEMENT_URL}/search`;
export const DELETE_COMMON_CODE_MANAGEMENT_URL = `${COMMON_CODE_MANAGEMENT_URL}/delete`;
export const GET_COMMON_CODE_DETAIL = `${COMMON_CODE_MANAGEMENT_URL}/get`;
export const EDIT_COMMON_CODE_NAME = `${COMMON_CODE_MANAGEMENT_URL}/updates-name`;

// WORKFLOW
export const SEARCH_WORKFLOW = `${BASE_SYSTEM}/workflows/search`;
export const GET_WORKFLOW_DETAIL = `${BASE_SYSTEM}/workflows/get`;
export const UPDATE_WORKFLOW_DETAIL = `${BASE_SYSTEM}/workflows`;
export const MAP_STATUS_REMOVED = `${TICKET_SYSTEM}/tickets/mapping-statuses`;
export const DELETE_WORKFLOW = `${BASE_SYSTEM}/workflows/delete`;
export const DELETE_ACTION_WORKFLOW = `${BASE_SYSTEM}/workflows/delete-workflow-action`;

// MENU MANAGEMENT
export const MENU_URL = `${BASE_SYSTEM}/menus`;
export const VIEW_TREE_URL = `${MENU_URL}/load-menu-tree`;
export const GET_MENU_PATH = `${MENU_URL}/get-by-corp-code`;
export const GET_ALL_MENU_PATH = `${MENU_URL}/path`;
export const DELETE_MENU = `${MENU_URL}/delete`;

// PERMISSION MANAGEMENT
export const ROLE_URL = `${BASE_SYSTEM}/roles`;
export const ROLE_MENU = `${BASE_SYSTEM}/role-menu`;
export const DELETE_ROLE_URL = `${ROLE_URL}/delete`;
export const GET_MENU_TREE = `${ROLE_MENU}/get-menu-tree`;
export const GET_TICKET_STATUSES = `${BASE_SYSTEM}/common-codes/get-ticket-status`;
export const SAVE_ROLE_ACTION = `${ROLE_MENU}/add-role-menu`;

export const UPLOAD_IMAGE_URL = '/content/files/upload';
// REPORTING
// TODO: update api url
export const CREATED_VS_RESOLVED_URL = `${TICKET_SYSTEM}/`;
export const SEARCH_CREATED_VS_RESOLVED_URL = `${CREATED_VS_RESOLVED_URL}/search`;
// REPORTING - TICKET CREATED
export const SEARCH_TICKET_CREATED_CHART = '/report/ticket/chart';
export const SEARCH_TICKET_CREATED_TABLE = '/report/ticket/table';
export const EXPORT_REPORT_CHART_AND_TABLE = '/report/ticket/export';
// REPORTING - AVERAGE FIRST RESPONSE TIME
export const SEARCH_AVERAGE_CHART = '/report/average/chart';
// REPORTING - DOCUMENT REGISTER
export const SEARCH_DOCUMENT_CATEGORY = '/report/document/category';
export const SEARCH_DOCUMENT_SECTION = '/report/document/section';
// REPORTING - FAQ REGISTER
export const SEARCH_FAQ_CATEGORY_CHART = '/report/faq/category';
export const SEARCH_FAQ_SECTION_CHART = '/report/faq/section';

// REPORTING - AVERAGE RESOLUTION TIME
export const SEARCH_AVERAGE_RESOLUTION_TIME_CHART = '/report/average/chart';

// REPORTING - AGENT ACTIVITY
export const SEARCH_AGENT_CHART = '/report/agents/chart';
export const SEARCH_AGENT_TABLE = '/report/agents/board';
export const SEARCH_AGENT_TYPES = '/report/agents/card';

// SOCKET
export const SOCKET_CHANNEL_URL = '/notification/ws-metadesk';
export const SOCKET_DASHBOARD_SCHEDULE = '/user/topic/dashboard-schedule';
export const SOCKET_DASHBOARD_METRIC = '/user/topic/dashboard-metric';
export const SOCKET_DASHBOARD_RECENT_TICKETS = '/user/topic/dashboard-myrecent-ticket';
export const SOCKET_DASHBOARD_ANNOUNEMENT = '/user/topic/dashboard-announcement';
export const SOCKET_DASHBOARD_DOCUMENT = '/user/topic/dashboard-document';
export const SOCKET_DASHBOARD_CONTRACT = '/topic/dashboard-contract';

// NOTIFICATION
const NOTIFICATION = '/ticket/notification';
export const SEARCH_NOTIFICATION = `${NOTIFICATION}/search`;
export const UPDATE_NOTIFICATION = `${NOTIFICATION}/read`;
export const UPDATE_READ_ALL_NOTIFICATION = `${NOTIFICATION}/mark-all-as-read`;
export const GET_UNREAD_NUMBER = `${NOTIFICATION}/unread-number`;

// Invite user
export const INVITE_USERS = `${BASE_SYSTEM}/invitates/user`;

// CICI MGT
export const GET_CODE_API_ALL = `${BASE_SYSTEM}/codeapis/all`;
export const GET_COMMON_CODE_BY_TICKET_TYPE = `${BASE_SYSTEM}/common-codes/get-by-workflow`;
export const CODE_API = `${BASE_SYSTEM}/codeapis`;
export const DELETE_CODE_API = `${BASE_SYSTEM}/codeapis/delete`;

export const PROJECT_CICD_URL = `${BASE_SYSTEM}/projectCiCds`;
export const GET_PROJECT_CICD_MGT = `${PROJECT_CICD_URL}/search`;
export const GET_PROJECT_CICD_BY_PROJ_CODE = `${PROJECT_CICD_URL}/proj-code`;
export const DELETE_PROJECT_CICD_API = `${BASE_SYSTEM}/projectCiCds/delete`;
export const CHECK_PR_CICD_CODE = `${BASE_SYSTEM}/projectCiCds/check-projectcicd`;
export const GET_INTEGRATED_PROJECT = `${BASE_SYSTEM}/projectCiCds/list-by-mdproject`;

// EXPOSE API MGT
export const GET_EXPOSE_API_LIST = `${BASE_SYSTEM}/exposes/search`;
export const EXPOSE_API = `${BASE_SYSTEM}/exposes`;
export const DELETE_EXPOSE_API = `${BASE_SYSTEM}/exposes/delete`;
export const GENERATE_TOKEN_EXPOSE = `${BASE_SYSTEM}/exposes/generate-token-expose`;
export const GET_PATH_LIST = `${BASE_SYSTEM}/exposes/path`;

// INTEGRATION SOLUTION MGT
export const INTEGRATION_SOLUTION_URL = `${BASE_SYSTEM}/integrates`;
export const GET_INTEGRATION_SOLUTION = `${INTEGRATION_SOLUTION_URL}/search`;
export const GET_KAKAOTALK = `${INTEGRATION_SOLUTION_URL}/search-kakaotalk`;
export const DELETE_INTEGRATION_SOLUTION = `${INTEGRATION_SOLUTION_URL}/delete`;
export const CHECK_INTEGRATION_ACTIVE = `${INTEGRATION_SOLUTION_URL}/active`;
export const CHECK_GITLAB_CICD_ACTIVE = `${INTEGRATION_SOLUTION_URL}/gitlab-cicd/active`;
export const GET_COMMON_CODE_BY_MULTI_PROJECT = `${COMMON_CODE_MANAGEMENT_URL}/get-by-project-integrate`;

// IMPORT LICENSE

export const IMPORT_LICENSE = `${BASE_SYSTEM}/licenses/upload`;
export const GET_LICENSE_INFO = `${BASE_SYSTEM}/licenses/load`;

// INTERNAL TECHNICAL POST
export const INTERNAL_TECHNICAL_POST_COMMENTS = `${TICKET_SYSTEM}/tech-post-comments`;
export const GET_INTERNAL_TECHNICAL_POST_COMMENTS = `${INTERNAL_TECHNICAL_POST_COMMENTS}/search`;
export const DELETE_INTERNAL_TECHNICAL_POST_COMMENT = `${INTERNAL_TECHNICAL_POST_COMMENTS}/delete`;
export const INTERNAL_TECHNICAL_POST_URL = `${TICKET_SYSTEM}/technical-posts`;
export const GET_INTERNAL_TECHNICAL_POST = `${INTERNAL_TECHNICAL_POST_URL}/search`;
export const DELETE_INTERNAL_TECHNICAL_POST = `${INTERNAL_TECHNICAL_POST_URL}/delete`;
export const GET_INTERNAL_TECHNICAL_POST_DETAIL = `${INTERNAL_TECHNICAL_POST_URL}/get`;

// TICKET LINK POST

export const TICKET_LINK_POST_URL = `${TICKET_SYSTEM}/tech-post-links`;
export const GET_TICKET_LINK = `${TICKET_LINK_POST_URL}/search`;
export const GET_TICKET_POST_LINK = `${TICKET_LINK_POST_URL}/list`;
export const DELETE_TICKET_POST_LINK = `${TICKET_LINK_POST_URL}/delete`;

// SERVICE CLASSFICATION

export const GET_LIST_SERVICE_CLASSFICATION = `${BASE_SYSTEM}/service-classificates/get-list`;
export const GET_LIST_USER_PRIORITY = `${BASE_SYSTEM}/service-classificates/user-priority-list`;
export const ADD_LIST_CLASSFICATION = `${BASE_SYSTEM}/service-classificates/add-list`;
export const LOAD_SERVICE_CLASSFICATION = `${BASE_SYSTEM}/service-classificates/get-service-type`;
export const GET_USER_BY_PROJ_SERVICE = `${BASE_SYSTEM}/service-classificates/search-user-priority-assign`;
export const GET_SERVICE_TYPE_BY_LIST_PROJECT = `${BASE_SYSTEM}/service-classificates/get-service-type-by-list-project`;

// LOG MGT
export const GET_LIST_LOGIN_LOG = `${BASE_SYSTEM}/action-log/search`;

// USER GUIDE SETTING
export const USER_GUIDE = `${BASE_SYSTEM}/user-guide`;
export const GET_LIST_USER_GUIDE = `${USER_GUIDE}/search`;

// CONCURRENT USER AND HISTORY
export const CCU_HISTORY = `${BASE_SYSTEM}/ccu-history`;
export const GET_LIST_CCU_HISTORY = `${CCU_HISTORY}/search`;
