import { useMemo } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  InputAdornment,
  InputProps,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Clear as ClearIcon } from '@mui/icons-material';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import union from 'lodash/union';

import { MInput, MAvatar } from 'components/@material-extend';
import { MInputProps } from 'components/@material-extend/MInput';
import { UserType } from 'interfaces/UserType';
import useLocales from 'hooks/useLocales';

type CustomProps<T> = {
  value: string | string[] | null;
  options?: readonly T[];
  disabledOptions?: readonly T[];
  name: string;
  keyString?: string;
  keyValue?: string;
  thumbnailKey?: string;
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  getOptionLabel?: (option: T) => string;
  onSetValueOtherField?: (value: any) => void;
  noAvatar?: Boolean;
  onClickClearButton?: () => void;
  clearOnBlur?: boolean;
  multiple?: boolean;
  filterSelectedOptions?: boolean;
  blankOption?: T;
  renderTags?: (value: T[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: any
  ) => React.ReactNode;
  onInputChange?: InputProps['onChange'];
  loading?: boolean;
  noOptionsText?: string;
};

type SelectedOptionType<T> = T | T[] | null;

export type MAutocompleteProps<T> = Omit<MInputProps, 'onChange'> & CustomProps<T>;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  optionName: {
    paddingLeft: theme.spacing(1)
  }
}));

export default function MAutocomplete<T = UserType>(props: MAutocompleteProps<T>) {
  const {
    options = [],
    disabledOptions = [],
    fullWidth,
    value,
    onChange,
    disabled,
    name,
    keyString = 'username',
    keyValue = 'username',
    thumbnailKey = 'avatarUrl',
    onSetValueOtherField,
    getOptionLabel,
    noAvatar,
    onClickClearButton,
    multiple = false,
    blankOption = null,
    filterSelectedOptions = false,
    renderTags,
    renderOption,
    onInputChange,
    InputProps,
    clearOnBlur = true,
    loading,
    noOptionsText,
    ...otherMAutocompleteProps
  } = props;

  const classes = useStyles();
  const { t } = useLocales();

  const emptyValue = multiple ? [] : '';

  const shouldShowAvatar = !noAvatar && !multiple;

  const selectedOption: SelectedOptionType<T> = useMemo(() => {
    if (multiple) {
      const selected = options.filter((option: T) => value?.includes(get(option, [keyValue])));
      return selected || [];
    }

    if (!isEmpty(value)) {
      const selected = options.find((option) => get(option, [keyValue]) === value);
      return selected || blankOption;
    }
    return blankOption || null;
  }, [multiple, value, blankOption, options, keyValue]);

  const handleRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: any) => (
    <li {...props}>
      {shouldShowAvatar ? (
        <MAvatar
          className={classes.avatar}
          alt={option.username}
          src={option.avatarUrl}
          showUserStatus
          userStatus={option.userStatus}
        />
      ) : null}
      <Typography className={classes.optionName}>{handleGetOptionLabel(option)}</Typography>
    </li>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const valueInput = get(params.inputProps, 'value', emptyValue);
    const thumbnailUrl = get(selectedOption, [thumbnailKey], '');
    const userStatus = get(selectedOption, 'userStatus');
    const mergedInputProps = {
      ...InputProps,
      ...params.InputProps,
      ...{
        startAdornment:
          shouldShowAvatar && !isEmpty(valueInput) && !isArray(selectedOption) ? (
            <InputAdornment position="start">
              <MAvatar
                className={classes.avatar}
                src={thumbnailUrl || ''}
                showUserStatus
                userStatus={userStatus}
              />
            </InputAdornment>
          ) : (
            params.InputProps.startAdornment || null
          )
      }
    };

    return (
      <MInput
        {...otherMAutocompleteProps}
        {...params}
        onChange={onInputChange}
        name={name}
        disabled={disabled}
        InputProps={mergedInputProps}
      />
    );
  };

  const handleGetOptionLabel = (option: T) => {
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    if (get(option, [keyString]) === '') {
      return '';
    }
    return get(option, [keyString]) || '';
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    reason: AutocompleteChangeReason
  ) => {
    switch (reason) {
      case 'clear': {
        onSetValueOtherField && onSetValueOtherField(emptyValue);
        onChange(name, emptyValue);
        break;
      }
      case 'selectOption': {
        const selectedObject =
          multiple && isArray(selectedOption) ? union(selectedOption, newValue) : newValue;
        const selectedValue =
          multiple && isArray(selectedOption)
            ? selectedObject.map((op: T) => get(op, [keyValue]))
            : get(selectedObject, [keyValue], emptyValue);

        onSetValueOtherField && onSetValueOtherField(selectedObject);
        onChange(name, selectedValue);
        break;
      }
      case 'removeOption': {
        if (!multiple) break;

        const selectedValue: string[] = newValue.map((op: T) => get(op, [keyValue]));
        onSetValueOtherField && onSetValueOtherField(newValue);
        onChange(name, selectedValue);
        break;
      }
    }
  };

  const showNoOptionText = noOptionsText || t('table.noMatchResults');
  return (
    <Autocomplete
      multiple={multiple}
      fullWidth={fullWidth}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      selectOnFocus
      clearOnBlur={clearOnBlur}
      handleHomeEndKeys
      renderOption={renderOption || handleRenderOption}
      renderInput={renderInput}
      getOptionLabel={handleGetOptionLabel}
      disabled={disabled}
      autoHighlight
      clearIcon={<ClearIcon fontSize="small" onClick={onClickClearButton} />}
      filterSelectedOptions={filterSelectedOptions}
      renderTags={renderTags}
      getOptionDisabled={(option: any) => disabledOptions.indexOf(option[keyValue]) !== -1}
      loading={loading}
      loadingText={t('common.loading')}
      noOptionsText={showNoOptionText}
    />
  );
}
