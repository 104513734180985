import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class TokenModel extends BaseModel {
  @Expose({ name: 'accessToken' })
  accessToken: string | undefined;

  @Expose({ name: 'refreshToken' })
  refreshToken: string | undefined;
}
