import { motion, AnimatePresence } from 'framer-motion';
import { varFadeInRight } from 'components/animate';
import isEmpty from 'lodash/isEmpty';

import { experimentalStyled as styled } from '@mui/material/styles';
import { Theme, useTheme, List } from '@mui/material';

import { getExtensionOfFile, getNameOfFile } from 'utils/helpers';
import { ViewFile } from 'interfaces/ViewFile';

import AttachedItem, { ThumbnailSizeType } from './AttachedItem';
import AttachedTextItem from './AttachedTextItem';

type AttachmentListType = 'card' | 'text';

const FileListStyle = styled(List)(
  ({ theme, styleProps }: { theme: Theme; styleProps: { type: AttachmentListType } }) => ({
    zIndex: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: theme.typography.pxToRem(8),
    flexDirection: styleProps.type === 'text' ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.typography.pxToRem(8),
    border: theme.palette.grey[500_24],
    color: theme.palette.text.secondary,
    backgroundColor: styleProps.type === 'text' ? 'transparent' : theme.palette.background.neutral,
    padding: styleProps.type === 'text' ? 0 : theme.spacing(2, 2),
    flexWrap: 'wrap',
    '& > *:not(:last-child)':
      styleProps.type === 'text'
        ? {
            marginBottom: theme.spacing(1)
          }
        : {
            marginRight: theme.typography.pxToRem(22)
          },
    li: {
      width: '100%'
    }
  })
);

const CardListItemStyle = styled(motion.div)({
  padding: 0,
  margin: 0,
  width: 'fit-content',
  height: 'fit-content',
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-flex'
});

export type FileInfo = {
  file: File;
  id: number;
};

type AttachedListProps = {
  initialFiles?: ViewFile[];
  currentFileInfo?: FileInfo[];
  onDownload?: (file: any) => void;
  onRemove?: (_file: any) => void;
  thumbnailSize?: ThumbnailSizeType;
  canRemove?: boolean;
  listType?: AttachmentListType;
  needTruncate?: boolean;
};

function AttachedList({
  initialFiles = [],
  currentFileInfo = [],
  onDownload,
  onRemove,
  thumbnailSize,
  canRemove,
  listType = 'text',
  needTruncate
}: AttachedListProps) {
  const theme = useTheme<Theme>();
  if (isEmpty(currentFileInfo) && isEmpty(initialFiles)) return null;
  return (
    <FileListStyle
      theme={theme}
      styleProps={{ type: listType }}
      disablePadding
      className="attachments"
    >
      <AnimatePresence>
        {Boolean(initialFiles) &&
          initialFiles.map(({ id, fileName, fileUrl }: ViewFile) =>
            listType === 'card' ? (
              <CardListItemStyle key={id}>
                <AttachedItem
                  fileId={id}
                  name={getNameOfFile(fileName)}
                  extension={getExtensionOfFile(fileName)}
                  url={fileUrl}
                  onRemove={onRemove}
                  onFileNameClick={onDownload}
                  size={thumbnailSize}
                  canRemove={canRemove}
                />
              </CardListItemStyle>
            ) : (
              <AttachedTextItem
                key={id}
                fileId={id}
                name={getNameOfFile(fileName)}
                extension={getExtensionOfFile(fileName)}
                url={fileUrl}
                onRemove={onRemove}
                onFileNameClick={onDownload}
                canRemove={canRemove}
                needTruncate={needTruncate}
              />
            )
          )}
        {Boolean(currentFileInfo) &&
          currentFileInfo.map((file) =>
            listType === 'card' ? (
              <CardListItemStyle key={file.id} {...varFadeInRight}>
                <AttachedItem
                  file={file.file}
                  onRemove={onRemove}
                  onFileNameClick={onDownload}
                  size={thumbnailSize}
                  canRemove={canRemove}
                />
              </CardListItemStyle>
            ) : (
              <AttachedTextItem
                key={file.id}
                file={file.file}
                onRemove={onRemove}
                {...varFadeInRight}
                onFileNameClick={onDownload}
                canRemove={canRemove}
                needTruncate={needTruncate}
              />
            )
          )}
      </AnimatePresence>
    </FileListStyle>
  );
}

export default AttachedList;
