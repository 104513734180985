import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { AnyObjectSchema } from 'yup';
import useLocales from './useLocales';

export const useFormSchema = (schemaFunc: (t: TFunction) => AnyObjectSchema) => {
  const { t } = useLocales();

  const schema = useMemo(() => schemaFunc(t), [t, schemaFunc]);

  return { schema };
};
