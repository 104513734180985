import apiClient from 'apis/apiClient';
import { GENERAL_SETTING, UPDATE_GENERAL_SETTING } from 'constants/apiUrl';

export const fetchDynamicContent = (params: any) =>
  apiClient
    .post(GENERAL_SETTING, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

export const updateDynamicContent = (params: any) =>
  apiClient
    .put(UPDATE_GENERAL_SETTING, params)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
