import { Expose, Transform } from 'class-transformer';

import BaseModel from 'models/BaseModel';
import { TransformDateModel } from 'utils/transformer';
import { FULL_DATE_FORMAT_INCLUDE_SECOND } from 'constants/common';
import { formatMilisecondToSeconds } from 'utils/helpers';

export class TicketMergeRequestModel extends BaseModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'repoName' })
  repoName: string | undefined;
}

export class TicketMergeRequestDetailModel extends BaseModel {
  @Expose({ name: 'assignee' })
  assignee: string | undefined;

  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'createdBy' })
  createdBy: string | undefined;

  @Expose({ name: 'title' })
  title: string | undefined;

  @Expose({ name: 'detail' })
  detail: string | undefined;

  @Expose({ name: 'status' })
  status: string | undefined;

  @Expose({ name: 'statusCodes' })
  statusCodes: string | undefined;

  @Expose({ name: 'mrId' })
  mrId: string | undefined;

  @Expose({ name: 'updatedDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  updatedDate?: string | undefined;

  @Expose({ name: 'repoName' })
  repoName: string | undefined;

  @Expose({ name: 'creatorId' })
  creatorId: string | undefined;

  @Expose({ name: 'creator' })
  creator: string | undefined;

  @Expose({ name: 'approverName' })
  approverName: string | undefined;

  @Expose({ name: 'approverId' })
  approverId: string | undefined;

  @Expose({ name: 'mrUrl' })
  mrUrl: string | undefined;

  @Expose({ name: 'checked' })
  checked: boolean | undefined;
}

export class TicketDeploymentStatusModel extends BaseModel {
  @Expose({ name: 'apprType' })
  apprType: string | undefined;

  @Expose({ name: 'apprTypeName' })
  apprTypeName: string | undefined;

  @Expose({ name: 'projcicdCode' })
  projcicdCode: string | undefined;

  @Expose({ name: 'projCicdName' })
  projCicdName: string | undefined;

  @Expose({ name: 'status' })
  status: string | undefined;

  @Expose({ name: 'ticketStatus' })
  ticketStatus: string | undefined;

  @Expose({ name: 'ticketStatusName' })
  ticketStatusName: string | undefined;

  @Expose({ name: 'statusName' })
  statusName: string | undefined;

  @Expose({ name: 'envType' })
  envType: string | undefined;

  @Expose({ name: 'envTypeName' })
  envTypeName: string | undefined;

  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'updatedBy' })
  updatedBy: string | undefined;

  @Expose({ name: 'executedName' })
  executedName: string | undefined;

  @Expose({ name: 'buildBranch' })
  buildBranch: string | undefined;

  @Expose({ name: 'pipelineNm' })
  pipelineNm: string | undefined;

  @Expose({ name: 'buildNumber' })
  buildNumber: string | undefined;

  @Expose({ name: 'updatedDate' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  updatedDate: string | undefined;

  @Expose({ name: 'buildTime' })
  @TransformDateModel(FULL_DATE_FORMAT_INCLUDE_SECOND)
  buildTime: string | undefined;

  @Expose({ name: 'pipelineAssign' })
  pipelineAssign: string | undefined;

  @Expose({ name: 'buildDuration' })
  @Transform(({ value }) => value && formatMilisecondToSeconds(value))
  buildDuration: string | undefined;

  @Expose({ name: 'deployTp' })
  deployTp: string | undefined;

  @Expose({ name: 'buildDeployYn' })
  buildDeployYn: string | undefined;
}

export class GetTicketDeploymentStatusModel extends BaseModel {
  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'apprTypes' })
  apprTypes: string[] | undefined;
}
