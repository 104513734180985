import { OptionalFiledConfigType } from '../../types';

export const FIELD_CONFIG = {
  priority: 'priority',
  issueDetected: 'operationDate',
  completionDate: 'completionDate',
  estimation: 'estimation',
  affectedVersion: 'affectedVersion'
};

export const getListRequireField = (t: any) => [
  t('helpDesk.ticketManagement.form.project'),
  t('helpDesk.ticketManagement.form.application'),
  t('helpDesk.ticketManagement.form.version'),
  t('helpDesk.ticketManagement.form.ticketType'),
  t('helpDesk.ticketManagement.form.serviceType'),
  t('helpDesk.ticketManagement.form.serviceDetails'),
  t('helpDesk.ticketManagement.form.assignee'),
  t('helpDesk.ticketManagement.form.approveRejectPerson')
];

export const getListOptionalField = (t: any): OptionalFiledConfigType[] => [
  {
    label: t('helpDesk.ticketManagement.form.priority'),
    field: FIELD_CONFIG.priority
  },
  {
    label: t('helpDesk.ticketManagement.form.issueDetected'),
    field: FIELD_CONFIG.issueDetected
  },
  {
    label: t('helpDesk.ticketManagement.form.completionDate'),
    field: FIELD_CONFIG.completionDate
  },
  {
    label: t('helpDesk.ticketManagement.form.estimation'),
    field: FIELD_CONFIG.estimation
  },
  {
    label: t('helpDesk.ticketManagement.form.affectedVersion'),
    field: FIELD_CONFIG.affectedVersion
  }
];
