import { Icon } from '@iconify/react';
import useLocales from 'hooks/useLocales';
import { ButtonAuth } from 'interfaces/ButtonAuth';
import editOutlineIcon from '@iconify/icons-eva/edit-2-outline';
import MButton from '../MButton';

// For automation test
const TEST_ID = 'permissionBtn';

const PermissionButton = ({ ...other }: ButtonAuth) => {
  const { t } = useLocales();
  return (
    <MButton
      test-id={TEST_ID}
      {...other}
      variant="outlined"
      startIcon={<Icon icon={editOutlineIcon} />}
    >
      {t('common.button.permission')}
    </MButton>
  );
};

export default PermissionButton;
