import { IconButton, IconButtonProps } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { withStyles } from '@mui/styles';

// For automation test
const TEST_ID = 'addBtn';

const StyledIconButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200]
  }
}))(IconButton);

const InfoIconButton = (props: IconButtonProps) => (
  <StyledIconButton {...props}>
    <InfoIcon test-id={TEST_ID} aria-label="add" />
  </StyledIconButton>
);

export default InfoIconButton;
