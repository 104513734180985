/* eslint-disable no-useless-escape */
import { LabelColor } from 'components/labels/Label';

import { ACTIVE_YN } from './commonCodes';

// Auth
export const ACCESS_TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const EXPIRED_AT = 'expired_at';
export const CURRENT_CORP_CODE = 'current_corp_code';
export const CUSTOMER_ROLE_CODE = 'R0005';
export const SYSTEM_ADMIN_ROLE_CODE = 'R0001';
export const REMEMBERED_USER = 'rememberedUser';
export const USER_LOGGED_AT = 'user_logged_at';
export const AFTER_LOGIN = 'afterLogin';
export const MAINTENANCE_AMIN_ROLE_CODE = 'R1001';
export const CURRENT_USER = 'culg';
export const CORP_USER_LOGIN_BY_MICROSOFT = 'corpulgbm';
export const NEED_CHANGE_PASSWORD = 'need_change_pass';
export const FORCE_LOGOUT = 'forceLogout';
export const CORPORATION_MENU_CODE = 'CORP';
export const TIMEOUT_ACTIVITY = 'TIMEOUT_ACTIVITY';

// API
export const BASE_API_TIMEOUT = 30000;
export const MULTIPART_API_TIMEOUT = 60000;
export const UNAUTHORIZED_STATUS = 401;
export const BAD_REQUEST_STATUS = 400;
export const INTERNAL_SERVER_ERROR_STATUS = 500;
export const MISSING_LICENSE = 402;
export const FORBIDDEN = 403;

export const RESPONSE_STATUS = {
  OK: 'OK'
};
export const API_STATUS_ECONNABORTED = 'ECONNABORTED';

// Contact Us Mail Address
export const CONTACT_US_MAIL =
  process.env.REACT_APP_CONTACT_US_MAIL || 'metadesk_admin@metanet.co.kr';

// Common codes
export const COMMON_CODE_TYPE = {
  GENDER: 'GENDER',
  ORGANIZATION_TYPE: 'ORGANIZATION_TYPE',
  STATUS: 'ACTIVE_YN',
  TICKET_TYPE: 'TICKET_TYPE',
  PRIORITY: 'PRIORITY',
  SERVICE_TYPE: 'SERVICE_TYPE',
  TICKET_SERVICE: 'TICKET_SERVICE',
  TICKET_INCIDENT: 'TICKET_INCIDENT',
  TICKET_DEPLOY: 'TICKET_DEPLOY',
  POST_TYPE: 'POST_TYPE',
  CMT_TYPE: 'CMT_TYPE',
  METHOD_REQUEST: 'METHOD_REQUEST',
  INTEGRATION: 'INTEGRATION',
  INTEGRATE_NAME: 'INTEGRATE_NAME',
  TICKET_REQUEST: 'TICKET_REQUEST',
  TECH_POST_LARGE: 'TECH_POST_LARGE',
  TECH_POST_MIDDLE: 'TECH_POST_MIDDLE',
  TECH_POST_SMALL: 'TECH_POST_SMALL',
  SERVICE_CLASSFICATION: 'PRJ_SER_CLSS',
  DEPLOYMENT_SCHEDULE: 'DEPLOY_SCHEDULE',
  REPORT_CUSTOM: 'REPORT_CUSTOM'
};

// Validation
export const VALIDATE_PWD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,30}$/;
export const PHONE_REGEX = /[0-9]$/;
export const ESTIMATION_TIME_REGEX = /^((\d{1,}(mo|w|d|h|m)?\s)+)?((\d{1,}(mo|w|d|h|m)?))$/;
export const PROJECT_CODE_REGEX = /^[a-zA-Z0-9_\\-]{1,15}$/;
// eslint-disable-next-line no-useless-escape
export const SPECIAL_CHARACTERS_REGEX = /[\[\]\{\}\\/"%:$?*-]+/;
export const SPECIAL_CHARACTERS =
  '"\\", "/", """, "%", ":", "$", "?", "*", "[", "]", "{", "}", "-"';
export const FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
export const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;
export const TIME_REGEX = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
export const MENTION_ALLOW_CHARS_REGEX = /[^\s]*$/;
export const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;
export const USER_NAME_REGEX = /^(?![-.])(?!.*(?:\.|-|\.git|\.atom)$)[a-z0-9_.-]{5,16}$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Input type
export const INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password'
};

// Format
export const BASIC_DATE_FORMAT = 'YYYY-MM-DD';
export const BASIC_DATE_MASK = '____-__-__';
export const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const FULL_DATE_FORMAT_12_HOURS = 'YYYY-MM-DD hh:mm A';
export const FULL_DATE_MASK = '____-__-__ __:__';
export const BASIC_TIME_FORMAT = 'HH:mm';
export const FULL_YEAR_MONTH_FORMAT = 'MMMM YYYY';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const FULL_DATE_FORMAT_INCLUDE_SECOND = 'YYYY-MM-DD HH:mm:ss';

// File

export const MAX_UPLOAD_SIZE_1MB = 1048576;
export const MAX_UPLOAD_SIZE_2MB = 2097152;
export const MAX_UPLOAD_SIZE_10MB = 10485760;
export const MAX_UPLOAD_SIZE_20MB = 20971520;
export const MAX_UPLOAD_SIZE_20MB_TEXT = 20471520;
export const MAX_UPLOAD_SIZE_40MB = 41943040;
export const MAX_UPLOAD_SIZE_10MB_STRING_FORMAT = '10MB';
export const MAX_UPLOAD_SIZE_20MB_STRING_FORMAT = '20MB';
export const MAX_UPLOAD_SIZE_40MB_STRING_FORMAT = '40MB';
export const MAX_LENGTH_TEXT = 100;
export const MAX_FILES = 20;

export const MIME_TYPES = {
  JPEG: 'image/jpeg', // .jpg & .jpeg
  PNG: 'image/png',
  BMP: 'image/bmp',
  GIF: 'image/gif',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  TXT: 'text/plain',
  MP4: 'video/mp4',
  ZIP: '.zip',
  JAR: '.jar', // use extension instead of mime type because of a Window OS's issue,
  LOG: '.log',
  HEIC: '.HEIC',
  HTML: 'text/html'
};

export const FILE_EXTENTION = {
  HTML_E: '.html',
  XLS_E: '.xls',
  XLSX_E: '.xlsx',
  JPG_E: '.jpg',
  JEPG_E: '.jpeg',
  PNG_E: '.png',
  GIF_E: '.gif',
  DOC_E: '.doc',
  DOCX_E: '.docx',
  TXT_E: '.txt',
  PDF_E: '.pdf',
  PPT_E: '.ppt',
  PPTX_E: '.pptx',
  HWP_E: '.hwp'
};

export const JAR_MIME_TYPE = 'application/java-archive';

export const ZIP_MIME_TYPES = [
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
  'application/octet-stream',
  'application/x-compress',
  'application/x-compressed',
  'multipart/x-zip'
];

export const UPLOADABLE_FILES_TYPE = [
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.BMP,
  MIME_TYPES.GIF,
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.PPT,
  MIME_TYPES.PPTX,
  MIME_TYPES.XLS,
  MIME_TYPES.XLSX,
  MIME_TYPES.PDF,
  MIME_TYPES.ZIP,
  MIME_TYPES.TXT,
  MIME_TYPES.JAR,
  MIME_TYPES.LOG,
  MIME_TYPES.HEIC,
  FILE_EXTENTION.XLS_E,
  FILE_EXTENTION.XLSX_E,
  FILE_EXTENTION.JPG_E,
  FILE_EXTENTION.JEPG_E,
  FILE_EXTENTION.PNG_E,
  FILE_EXTENTION.GIF_E,
  FILE_EXTENTION.DOC_E,
  FILE_EXTENTION.DOCX_E,
  FILE_EXTENTION.TXT_E,
  FILE_EXTENTION.PDF_E,
  FILE_EXTENTION.PPT_E,
  FILE_EXTENTION.PPTX_E,
  FILE_EXTENTION.HWP_E
].join(',');

export const UPLOADABLE_IMAGE_TYPE = [
  MIME_TYPES.JPEG,
  MIME_TYPES.PNG,
  MIME_TYPES.BMP,
  MIME_TYPES.GIF
].join(',');

// Lang
export const DEFAULT_LANG_TYPES = ['ko', 'ko-KR'];

// Table
export const DEFAULT_ITEM_PER_PAGE = 10;
export const DEFAULT_SORTING_DIRECTION = 'ASC';
export const SORTING_DIRECTION_BY_DSC = 'DESC';

export const DEFAULT_TABLE_VALUES = {
  direction: SORTING_DIRECTION_BY_DSC,
  pageNum: 0,
  itemPerPage: DEFAULT_ITEM_PER_PAGE,
  usePaging: ACTIVE_YN.Yes
};

export const KEY_CODES = {
  ENTER: 13,
  BACKSPACE: 8
};

export const LANGS = [
  {
    value: 'ko',
    label: '한국어',
    icon: '/static/icons/ic_flags_kr.svg'
  },
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  }
];

export const MESSAGE_TYPE = {
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS'
};

export const COLOR_VALUES: any = {
  'primary.main': '#00AB55',
  'info.main': '#1890FF',
  'success.main': '#94D82D',
  'warning.main': '#FFC107',
  'error.main': '#FF4842',
  'info.darker': '#04297A',
  'error.darker': '#7A0C2E'
};

export const BACKGROUND_COLOR_OPTIONS: Array<LabelColor> = [
  'default',
  'info',
  'success',
  'warning',
  'error',
  'none'
];

export const COLOR_OPTIONS: Array<string> = Object.keys(COLOR_VALUES);

export const USER_HOVER_DELAY = 1000;

export const COMMON_SEARCH_DEBOUNCE = 500;
export const MIN_CHARS_MENTION = 2;
export const HASHTAG_ALLOWED_CHARS_REGEX = /^\S*$/;
export const MENTION_USER_DENOTATION_CHAR = '@';
export const MENTION_HASHTAG_DENOTATION_CHAR = '#';
export const MENTION_TYPES = {
  GUIDELINE: 'guideline',
  USER_NOT_FOUND: 'userNotFound'
};

export const SELECT_ALL = 'all';

export const NA_TEXT = 'N/A';

export const TIME_GET_NUMBER_NOTIFICATION = 1000 * 60 * 5; // 5 minutes

export const FIELD_PERIOD = 'period';

export const APP_VER_SEPERATOR = ',';

export const TICKET_TYPE_REQDEPLOY = 'REQDEPLOY';
export const TICKET_TYPE = {
  Service: 'SERVICE',
  Incident: 'INCIDENT',
  Deploy: 'DEPLOY',
  Build: 'BUILD',
  Request: 'REQUEST',
  ReqDeploy: 'REQDEPLOY',
  DeloyRequest: 'DEPLOYREQ'
};

export const ENDPOINT_TYPE = {
  HTTP: 'http://',
  HTTPS: 'https://'
};

export enum SOLUTION_TYPE {
  GITLAB = 'GITLAB',
  MetaCICD = 'MetaCICD'
}

export enum DynamicContent {
  selectCorp = 'selectCorp'
}

export enum TABLE_SETTING_NAME {
  UserManagement = 'UserManagement',
  AnnouncementManagement = 'AnnouncementManagement',
  InternalSharing = 'InternalSharing',
  DocumentManagement = 'DocumentManagement',
  FAQManagement = 'FAQManagement',
  TicketManagement = 'TicketManagement',
  ApplicationManagement = 'ApplicationManagement',
  VersionManagement = 'VersionManagement',
  ContractManagement = 'ContractManagement',
  ProjectManagement = 'ProjectManagement',
  LoginLog = 'LoginLog',
  ActionLog = 'ActionLog',
  Workflow = 'Workflow',
  buildDeployCicdMgt = 'buildDeployCicdMgt'
}

export const COMMENT_TYPE = {
  ALL: 'ALL',
  PUB_CMT: 'PUB_CMT',
  INT_CMT: 'INT_CMT'
};

export const REGEX_REMOVE_HTML_TAG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

export const ROLE_CODE_PERMISSION = {
  systemAdmin: 'R0001',
  maintenanceAdmin: 'R0007',
  maintenanceTeamLeader: 'R0004',
  contractPIC: 'R0002',
  customerPIC: 'R0005',
  maintenancePIC: 'R0003'
};

export const KEY_ERROR_PROJECT_NOT_ALLOW = 'message.project.not.allow';
export const KEY_ERROR_CORP_NOT_FOUND = 'error.common.notfound.incorp.id';
export const KEY_ERROR_USER_LOGOUT = 'message.user.logout';

export const LOGOUT_TIMEOUT = 'TIMEOUT';
export const LOGOUT_DUPLICATE = 'DUPLICATE';
export const LOGOUT_IDLE = 'IDLE';
