/**
 * Ref: https://github.com/afry/quill-mention
 */
import { MENTION_USER_DENOTATION_CHAR } from 'constants/common';
import Quill from 'quill';

const Embed = Quill.import('blots/embed');

class MentionUserBlot extends Embed {
  static create(data: any) {
    const node = super.create();
    node.innerHTML = data.denotationChar + data.name;
    node.dataset.info = data.username;
    return node;
  }

  static value(domNode: any) {
    return {
      username: domNode.dataset.info,
      name: domNode.innerHTML.split(MENTION_USER_DENOTATION_CHAR)[1],
      denotationChar: MENTION_USER_DENOTATION_CHAR
    };
  }
}

MentionUserBlot.blotName = 'mentionUser';
MentionUserBlot.tagName = 'strong';
MentionUserBlot.className = 'mention';

export default MentionUserBlot;
