import SockJsClient from 'react-stomp';
import { useState, useEffect, useMemo } from 'react';

import * as auth from 'utils/authHelper';
import { BASE_API_URL } from 'constants/appConfig';
import { SOCKET_CHANNEL_URL, SOCKET_DUPLICATE_SESSION } from 'constants/apiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, authSliceName } from 'features/auth/slices';
import get from 'lodash/get';

// const SOCKET_DELAY = 3 * 60 * 1000;

type SocketServiceProps = {
  urls: string[];
  onChange: (topics: string[]) => void;
};

const SocketService = ({ urls, onChange }: SocketServiceProps) => {
  const dispatch = useDispatch();
  const currentToken =
    useSelector((store) => get(store, [authSliceName, 'token'])) || auth.getAccessToken();
  const isConnectSocket = useSelector((store) => get(store, [authSliceName, 'isConnectSocket']));

  const [messages, setMessages] = useState<any>({});

  const corp = auth.getCurrentCorpCode();

  const header = useMemo(() => {
    if (currentToken) {
      return {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json;charset=UTF-8'
      };
    }
    return null;
  }, [currentToken]);

  const topics = useMemo(
    () =>
      urls.map((url: string) => {
        if (url === SOCKET_DUPLICATE_SESSION) {
          return url;
        }
        return `${url}.${corp}`;
      }),
    [urls, corp]
  );

  const handleOnMessage = (msg: any) => {
    const topic = messages[msg.type];
    setMessages({
      ...messages,
      [msg.type]: topic ? [...topic, msg] : [msg]
    });
  };

  const handleDisconnect = () => {
    dispatch(authActions.setConnectSocket(false));
  };

  useEffect(
    () => () => {
      handleDisconnect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const topics = Object.keys(messages);
    if (topics.length) {
      onChange(topics);
      setMessages({});
    }
  }, [messages, onChange]);

  return isConnectSocket && header ? (
    <SockJsClient
      url={BASE_API_URL + SOCKET_CHANNEL_URL}
      topics={topics}
      onMessage={handleOnMessage}
      subscribeHeaders={header}
      headers={header}
      autoReconnect
      onDisconnect={handleDisconnect}
    />
  ) : null;
};

export default SocketService;
