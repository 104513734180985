import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function BmpIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
        <path
          fill="#E2E5E7"
          d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
        />
        <path fill="#B0B7BD" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
        <polygon fill="#CAD1D8" points="480,224 384,128 480,128 " />
        <path
          fill="#84BD5A"
          d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
	V416z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M99.968,384c-4.608,0-7.808-3.456-7.808-7.936v-72.656c0-4.608,3.2-7.936,7.808-7.936h35.952
		c16.768,0,25.84,11.392,25.84,24.432c0,5.744-1.664,11.392-7.024,16.128c10.096,3.968,14.576,11.76,14.576,21.232
		c-0.016,14.704-10,26.736-29.184,26.736H99.968z M135.904,311.072h-26.992v19.056h26.992c5.504,0,8.96-3.456,8.96-10.24
		C144.864,315.68,141.408,311.072,135.904,311.072z M108.912,368.384h31.216c14.848,0,14.848-22.64,0-22.64
		c-9.712,0-21.104,0-31.216,0V368.384z"
          />
          <path
            fill="#FFFFFF"
            d="M201.456,327.84v47.328c0,5.648-4.608,8.832-9.2,8.832c-4.096,0-7.68-3.184-7.68-8.832v-72.016
		c0-6.656,5.648-8.848,7.68-8.848c3.696,0,5.872,2.192,8.048,4.624l28.16,37.984l29.152-39.408c4.24-5.232,14.592-3.2,14.592,5.648
		v72.016c0,5.648-3.584,8.832-7.664,8.832c-4.608,0-8.192-3.184-8.192-8.832V327.84l-21.248,26.864
		c-4.592,5.648-10.352,5.648-14.576,0L201.456,327.84z"
          />
          <path
            fill="#FFFFFF"
            d="M290.176,303.152c0-4.224,3.328-8.848,8.704-8.848h29.552c16.64,0,31.616,11.136,31.616,32.496
		c0,20.224-14.976,31.472-31.616,31.472h-21.36v16.896c0,5.648-3.584,8.832-8.192,8.832c-4.224,0-8.704-3.184-8.704-8.832
		L290.176,303.152L290.176,303.152z M307.056,310.432v31.856h21.36c8.576,0,15.36-7.552,15.36-15.488
		c0-8.96-6.784-16.368-15.36-16.368L307.056,310.432L307.056,310.432z"
          />
        </g>
        <path
          fill="#CAD1D8"
          d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
        />
      </svg>
    </Box>
  );
}
