const system = {
  title: '시스템 관리',
  userManagement: {
    title: '사용자 관리',
    newUserHeader: '신규 사용자 등록',
    updateUserHeader: '사용자 프로필 수정',
    usernameNote: '(*) 사용자명은 로그인에 사용될 것이며 저장되면 수정 불가능합니다.',
    resetPasswordWarning: '해당 사용자의 비밀번호를 초기화하시겠습니까?',
    duplicationWarning: '중복확인버튼을 클릭하여 중복확인을 해주세요.',
    confirmDelete: '해당 사용자를 삭제하시겠습니까?',
    noteIntegration: 'MetaCICD 통합 사용중 ',
    dsbIntegration: 'MetaCICD 통합 비활성화',
    timeOut: '세션 타임아웃',
    assignedProject: '할당된 프로젝트',
    noProjectAssigned: '할당된 프로젝트가 없음',
    succeedMess: {
      create: '신규 사용자가 등록되었습니다.',
      edit: '사용자 상세 정보가 변경되었습니다.',
      resetPassword: '비밀번호가 초기화되었습니다. 사용자 전체 접속이 로그아웃됩니다.',
      deleteUser: '해당 사용자가 삭제되었습니다.',
      verifyPhone:
        '인증 메시지가 해당 사용자 <bold>{{username}}</bold> 에게 이메일/카카오톡으로 전송되었습니다.'
    },
    user: '사용자',
    userList: '사용자 목록',
    inviteUser: {
      title: '사용자 초대',
      orgHelperText: '초대할 사용자의 조직을 선택하세요.',
      roleHelperText: '초대할 사용자의 권한을 선택하세요.',
      emailHelperText: '이메일을 확인해서 Enter 키를 눌러 동시에 많은 사용자를 초대할 수 있습니다.',
      success: '해당 이메일로 사용자 등록이 요청되었습니다.'
    },
    resetPassword: {
      headerTitle: '비밀번호 초기화',
      title: '임시 비밀번호 발급',
      manually: '비밀번호 수동 발급',
      content: '<bold>{{email}}</bold>에게 비밀번호 초기화 링크를 이메일로 전송합니다.',
      dividerText: '또는',
      buttonResetPassword: '발급'
    },
    registration: {
      title: '등록 요청',
      table: {
        title: '요청 목록',
        action: '승인 여부',
        requestDate: '요청일',
        authentication: '인증 상태',
        approve: '승인',
        denied: '거절됨',
        deny: '거부'
      },
      form: {
        titleDenied: '승인 거부',
        deniedMessage: '요청자에게 승인 거부 사유를 전달합니다. ',
        denialReason: '승인 거부 사유',
        titleSingleApprove: '사용자 등록 요청 승인',
        titleMultiApprove: '사용자 등록 요청 다건 승인'
      },
      message: {
        approveMessage: '등록 요청이 성공적으로 승인되었습니다.',
        deniedMessage: '등록 요청이 거절되었습니다.',
        confirmRemoveUserApprove: '해당 등록 요청을 삭제하시겠습니까?'
      }
    },
    changePassword: {
      title: '비밀번호 변경',
      form: {
        currentPass: '현재 비밀번호',
        newPassword: '새 비밀번호',
        confirmPassword: '비밀번호 확인'
      },
      message: {
        chassSuccess: ' 비밀번호가 변경되었습니다',
        matchPassword: '비밀번호가 일치하지 않습니다.'
      }
    },
    timeout: {
      timeoutSettingMessage: '세션 타임아웃 설정:',
      timeOption: {
        default: '기본 (2시간)',
        threeHours: '3시간',
        sixHours: '6시간',
        twelve: '12시간',
        twentyFour: '24시간'
      }
    }
  },
  userInformation: {
    title: '사용자 정보(상세)'
  },
  orgManagement: {
    title: '조직 관리',
    newOrgHeader: '신규 조직 등록',
    updateOrgHeader: '조직 정보 수정',
    confirmDelete: '해당 조직을 삭제하시겠습니까?',
    validateDelete: '조직 상태를 ‘미사용’으로 변경후에 삭제해주세요.',
    form: {
      orgName: '조직명',
      orgID: '조직ID',
      type: '유형'
    },
    errorMess: {
      orgCode: '조직ID는 2~5자이어야 하며, 특수 문자를 포함하면 안됩니다'
    },
    succeedMess: {
      create: '신규 조직이 등록되었습니다.',
      edit: ' 조직 상세 정보가 변경되었습니다.',
      delete: '해당 조직이 삭제되었습니다.'
    },
    organizationList: '조직 목록'
  },
  selectCoporation: {
    title: '법인 선택'
  },
  corpMgt: {
    title: '법인 관리',
    owner: '소유 법인',
    allCorp: '전체 법인',
    titleTabOwner: '소유 법인 관리',
    titleAllCorpMgt: '전체 법인 관리',
    field: {
      select: {
        corpId: '법인 ID',
        corpName: '법인명'
      },
      input: {
        placeholder: '입력'
      }
    },
    gridHeader: {
      no: '순번',
      corpId: '법인ID',
      corpName: '법인명',
      status: '상태',
      logo: '로고',
      registrant: '등록자'
    },
    form: {
      defaultValueCreator: 'MetaDesk Admin',
      createTitle: '신규 법인 등록',
      updateTitle: '법인 정보 수정',
      corpId: '법인 ID',
      corpName: '법인명',
      status: '상태',
      active: '미사용',
      confirmDelete:
        '해당 법인을 삭제하는 경우 법인에 속한 사용자 {{numberUser}}명도 동시 삭제됩니다. 그래도 삭제하시겠습니까?'
    },
    text: {
      upload:
        '이미지 올리기를 선택 후 {{fileSize}}이하인 .png, .jpg, .bmp, .gif 이미지를 클릭해주세요.'
    },
    errorMess: {
      corpCode: '법인ID는 2~5자이어야 하며, 특수 문자를 포함하면 안됩니다.',
      warningDeleteStatusActive: '법인 상태를 ‘미사용’으로 변경후에 삭제해주세요.'
    },
    succeedMess: {
      delete: '해당 법인이 삭제되었습니다.',
      create: '신규 법인이 등록되었습니다.',
      edit: '법인 상세 정보가 변경되었습니다.'
    },
    corporationList: '법인 목록'
  },
  profile: {
    title: '사용자 정보',
    uploadImgDesc:
      '이미지 올리기를 선택 후\n{{fileSize}}이하인 .png, .jpg, .bmp, .gif\n이미지를 클릭해주세요.',
    editFormTitle: '사용자 프로필 수정',
    saveChanges: '저장',
    succeedMess: {
      edit: '사용자 정보가 업데이트되었습니다.',
      needVerify: '이메일/카카오 메시지를 통해 연락처를 인증해주세요.'
    },
    edit: '수정',
    remove: '삭제'
  },
  contractManagement: {
    title: '계약 관리',
    newContractHeader: '신규 계약 등록',
    updateContractHeader: '계약 정보 수정',
    form: {
      contractType: '계약 유형',
      picName: '담당자 성명',
      startDate: '계약 시작일',
      endDate: '계약 종료일',
      contractInfo: '계약 내용',
      contractPoint: '고객사 담당자 정보',
      primaryContact: '이름'
    },
    errorMess: {
      contractNo: '계약번호는 대문자, 소문자, 숫자, 하이픈(-)으로만 구성되어야 합니다.',
      compareDate: '시작일은 종료일 보다 작아야합니다.',
      fileSizeAndType:
        '{{fileSize}} 이하 .xlsx, .xls, .docx, .doc, .pptx, .ppt, .pdf, .png, .jpg, .jpeg, .zip, .jar, .txt 파일을 올려주세요.',
      dateType: '올바른 형식의 날짜를 입력해 주세요.'
    },
    succeedMess: {
      create: '신규 계약이 등록되었습니다.',
      edit: '계약 상세 정보가 변경되었습니다.',
      delete: '해당 계약이 삭제되었습니다.'
    },
    confirmDelete: '해당 계약을 삭제하시겠습니까?',
    contractList: '계약 목록'
  },
  projectManagement: {
    title: '프로젝트 관리',
    grid: {
      applications: '애플리케이션 수',
      projInfo: '프로젝트 내용',
      contractName: '계약명',
      assignUser: '사용자 배정'
    },
    assignedUser: {
      title: '프로젝트 사용자 배정',
      search: {
        select: {
          name: '이름',
          userID: '사용자명'
        },
        searchPlaceholder: '사용자 검색'
      },
      grid: {
        userID: '사용자명',
        name: '이름',
        org: '조직',
        title: '직급',
        remove: '삭제'
      },
      succeedMess: {
        add: '사용자가 배정되었습니다.',
        delete: '사용자가 배정 해제되었습니다.'
      },
      confirmDelete: '배정된 사용자를 삭제하시겠습니까?',
      errorMess: '중복값 이미 배정되었습니다.'
    },
    confirmDeleteProject: '해당 프로젝트를 삭제하시겠습니까?',
    form: {
      title: {
        create: '신규 프로젝트 등록',
        edit: '프로젝트 정보 수정'
      },
      field: {
        projNo: '프로젝트 번호',
        projName: '프로젝트명',
        orgID: '조직ID',
        orgName: '조직명',
        projType: '조직유형',
        projInfo: '프로젝트 내용',
        appName: '애플리케이션명',
        version: '버전',
        serverDetail: '배포 환경',
        releaseDate: '릴리즈 일자/시간',
        contractNo: '계약 번호',
        contractName: '계약명',
        contractSDate: '계약 시작일',
        contractEDate: '계약 종료일',
        serviceType: '서비스유형',
        assignServiceType: '프로젝트 서비스 대상 할당'
      },
      tab: {
        title: {
          app: '애플리케이션',
          contract: '계약'
        }
      },
      succeedMess: {
        create: '신규 프로젝트가 등록되었습니다.',
        edit: '프로젝트 정보가 변경되었습니다.',
        delete: '해당 프로젝트가 삭제되었습니다.'
      },
      errorMess: {
        validateFieldProjCode:
          '프로젝트 번호는 15자이하이어야 하며, 대문자, 소문자, 숫자, 언더바(), 하이픈(-)으로만 구성되어야 합니다.',
        duplicatedAppCode:
          '해당 애플리케이션은 이미 프로젝트에 추가되었습니다. 다시 확이해 주세요.',
        warningDeleteStatusActive: '프로젝트 상태를 ‘미사용’으로 변경후에 삭제해주세요.',
        validateApplication: '최소 1개 이상의 애플리케이션이 지정되어야 합니다.'
      }
    },
    projectList: '프로젝트 목록',
    assignService: {
      application: '어플리케이션',
      serviceClassfication: '서비스 구분',
      user: '사용자',
      serviceType: '서비스 대상'
    }
  },
  applicationManagement: {
    headerTitle: '애플리케이션 관리',
    tabs: {
      application: {
        title: '애플리케이션',
        create: '신규 애플리케이션 등록',
        edit: '애플리케이션 정보 수정',
        confirmDelete: '해당 애플리케이션을 삭제하시겠습니까?',
        succeedMess: {
          edit: '애플리케이션 상세 정보가 수정되었습니다',
          create: '신규 애플리케이션이 등록되었습니다',
          delete: '해당 애플리케이션이 삭제되었습니다'
        },
        errorMess: {
          specialCharacter: '2~5자 이하의 숫자, 영문 대문자만 입력 가능합니다.'
        },
        applicationList: '애플리케이션 목록'
      },
      version: {
        title: '버전',
        create: '신규 버전 등록',
        edit: '버전 정보 수정',
        confirmDelete: '해당 버전을 삭제하시겠습니까?',
        succeedMess: {
          create: '신규 버전이 등록되었습니다',
          edit: '버전 상세 정보가 변경되었습니다',
          delete: '해당 버전이 삭제되었습니다'
        },
        errorMess: {
          fileSizeAndType:
            '{{fileSize}} 이하 .xlsx, .xls, .docx, .doc, .pptx, .ppt, .pdf, .zip, .jar, .txt  파일을 올려주세요.'
        },
        versionList: '버전 목록'
      }
    },
    form: {
      searchInput: {
        placeholder: '애플리케이션명'
      },
      applicationId: '애플리케이션ID',
      applicationName: '애플리케이션명',
      version: '버전 수',
      versionCode: '버전 코드',
      status: '상태',
      description: '설명',
      creator: '등록자',
      versionName: '버전명',
      environment: '환경',
      platform: '플랫폼',
      server: '서버',
      serverDetail: '서버 상세',
      deployed: '배포 여부',
      releaseDate: '릴리즈 일자',
      releaseDateTime: '릴리즈 일자/시간',
      releaseTime: '릴리즈 시간',
      source: '소스 경로',
      releaseNote: '릴리즈 노트',
      creationDate: '등록일시',
      updater: '수정자',
      updateDate: '수정일시',
      remark: '비고'
    }
  },
  menuManagement: {
    title: '메뉴 관리',
    tree: {
      title: '메뉴 목록'
    },
    form: {
      title: '메뉴 상세',
      koName: '한글 메뉴명',
      enName: '영문 메뉴명',
      menuLv: '메뉴 레벨',
      parentMenu: '상위메뉴',
      menuPath: '메뉴 경로',
      order: '정렬순서',
      des: '설명',
      menuCode: '메뉴 코드',
      lv1: '레벨 1',
      lv2: '레벨 2',
      lv3: '레벨 3',
      icon: '아이콘',
      none: '없음'
    },
    changeIcon: '아이콘 변경',
    showAllBtn: '펼치기',
    showLessBtn: '접기',
    confirmDelete: '해당 메뉴를 삭제하시겠습니까?',
    succeedMess: {
      delete: '메뉴가 삭제되었습니다.',
      create: '신규 메뉴가 추가되었습니다.',
      edit: '메뉴 상세 정보가 수정되었습니다.'
    },
    validateField: {
      deleteItemActive: '메뉴 상태를 ‘미사용’으로 변경후에 삭제해주세요.',
      menuCode: '메뉴코드는 대문자로 구성되어야 하며 10자 이하여야 합니다.',
      menuName: '메뉴명은 50자 이하여야 합니다.',
      desc: '설명은 250자 이하여야 합니다.',
      order: '정렬순서는 반드시 0 보다 큰 숫자이어야 합니다.',
      required: {
        menuCode: ' 메뉴코드는 필수 항목입니다. ',
        menuNameKo: '한글 메뉴명은 필수 항목입니다.',
        menuNameEn: '영문 메뉴명은 필수 항목입니다.',
        menuLv: '메뉴 레벨은 필수 항목입니다.',
        parentMenu: '상위메뉴는 필수 항목입니다.'
      }
    },
    confirmMsg: {
      submenuUpdate: '하위 메뉴는 레벨 {{level}}으로 이동됩니다.'
    }
  },
  configurationManagement: {
    headerTitle: '시스템 설정',
    commonCodeManagement: {
      headerTitle: '공통코드 관리',
      commonCode: '공통코드',
      subCode: '상세코드',
      subDetailCode: '하위 상세코드',
      editCommonCodeName: '공통코드명 변경',
      createCommonCode: '공통코드 생성',
      workflowProcessing: '워크플로우 처리 상태 Y/N',
      form: {
        searchInput: {
          placeholder: '코드 조회...'
        },
        code: '코드',
        englishName: '영문 코드명',
        koreanName: '한글 코드명',
        order: '순서',
        status: '상태',
        backgroundColour: '배경색',
        formUpload: '양식 업로드',
        default: '기본'
      },
      message: {
        maxLengthCode:
          '코드는 {{length}}자이하여야 합니다, 대문자, 숫자, 언더바(), 하이픈(-)으로만 구성되어야 합니다.',
        minOrder: ' 0보다 더 큰 정수를 입력해주세요',
        orderRange: '순서 범위는 1 ~ 9999 입니다.'
      },
      succeedMess: {
        create: '상세코드가 등록되었습니다.',
        edit: '상세코드가 수정되었습니다.',
        delete: '상세코드가 삭제되었습니다'
      },
      confirmDelete: '해당 상세코드를 삭제하시겠습니까?',
      confirmDeleteParentCode: '해당 코드를 삭제하시겠습니까?'
    }
  },
  permissionManagement: {
    headerTitle: '메뉴 권한 관리',
    form: {
      roles: '권한',
      menuTree: '메뉴 목록',
      checkAll: '전체 선택',
      showAll: '펼치기',
      action: '버튼 권한',
      roleName: '권한명은'
    },
    succeedMess: {
      create: '신규 권한이 등록되었습니다.',
      delete: '해당 권한이 삭제되었습니다.',
      update: '변경 내용이 저장되었습니다.'
    },
    confirmDeleteRole: '해당 권한을 삭제하시겠습니까?'
  },
  workflowManagement: {
    groupTitle: '구성 관리',
    title: '워크플로우 관리',
    titleActionLogwork: '상태 변경시 추가 설정',
    messageConfigAction: '작업 이력을 선택한 경우는 반드시 작업 내용을 등록해야합니다.',
    table: {
      title: '워크플로우 목록',
      no: '순번',
      name: '워크플로우명',
      desc: '설명',
      type: '티켓 유형',
      createdBy: '등록자',
      createdDate: '등록일',
      updatedBy: '수정자',
      updatedDate: '수정일',
      incident: '장애',
      service: '서비스',
      deploy: '서비스',
      build: '짓다',
      request: '서비스 요청'
    },
    detail: {
      edit: '워크플로우 수정',
      create: '워크플로우 생성',
      label: {
        name: '워크플로우명',
        ticketType: '티켓 유형',
        desc: '설명',
        statuses: '티켓 상태',
        add: '상태 변경시 설정'
      },
      placeholder: {
        name: '이름을 입력하시오',
        desc: '설명 입력'
      },
      errorMess: {
        validateLengthStatuses: '최소 1개 이상의 상태가 지정되어야 합니다.'
      },
      instruction: {
        title: '사용법',
        addPointTitle: '상태 노드 연결하기:',
        addPointDesc:
          '노드를 마우스로 선택합니다. 선택된 노드가 색깔로 표시되게 됩니다. Shift를 누른 상태에서 다른 노드를 클릭하여 두 노드를 연결합니다. Shift를 계속 누른 상태에서 또 다른 노드를 클릭하면 그 노드에도 연결시킵니다.',
        removePointTitle: '노드 연결 제거하기:',
        removePointDesc:
          "이미 연결된 노드에 '상태 노드 연결하기'와 동일하게 작업하면 연결 제거가 됩니다."
      },
      currentStatus: '현재 상태',
      newStatus: '변경 후',
      succeedMess: {
        delete: '워크플로우가 성공적으로 삭제되었습니다.',
        create: '워크플로우가 성공적으로 생성되었습니다.',
        edit: '워크플로우가 성공적으로 업데이트되었습니다.'
      }
    },
    actions: {
      start: '시작',
      end: '종료',
      requiredAction: '필수 조치',
      logwork: '작업 내용 등록',
      PicDisplay: '담당자 표시'
    }
  },
  cicdMgt: {
    title: 'CI/CD API 관리',
    table: {
      corporationCode: '법인코드',
      commonCode: '공통코드',
      endPoint: '엔드 포인트',
      param: '파람',
      method: '방법',
      ticketType: '티켓 유형',
      codeName: '코드명'
    },
    form: {
      tittle: 'CI/CD 관리',
      token: '토큰',
      code: '코드'
    },
    message: {
      deleteSuccess: '항목이 정상적으로 삭제되었습니다',
      createSuccess: '항목이 정상적으로 등록되었습니다.',
      updateSuccess: '항목이 정상적으로 수정되었습니다.'
    }
  },
  projectCicdMgt: {
    title: '통합 솔루션 프로젝트 관리',
    table: {
      title: '통합 솔루션 프로젝트 목록',
      corporationCode: '법인코드',
      projectCode: '프로젝트 코드',
      projectCiCdName: '통합 솔루션 프로젝트명',
      projectCiCdCode: '통합 솔루션 프로젝트 코드',
      projectGitlabCode: 'Gitlab 프로젝트 코드',
      projectName: '프로젝트명',
      project: '프로젝트',
      integratedSolutionName: '통합 솔루션명',
      status: '상태'
    },
    form: {
      createTitle: '통합 솔루션 프로젝트 생성',
      updateTitle: '통합 솔루션 프로젝트 수정'
    },
    message: {
      confirmDelete: '해당 항목을 삭제하시겠습니까?',
      deleteSuccess: '항목이 정상적으로 삭제되었습니다',
      createSuccess: '항목이 정상적으로 등록되었습니다.',
      updateSuccess: '항목이 정상적으로 수정되었습니다.',
      cicd: {
        availableCode: 'CI/CD 프로젝트 코드를 사용할 수 있습니다.',
        warningCheckCode: 'CICD 프로젝트 코드 중복여부를 확인하세요.'
      },
      gitlab: {
        availableCode: 'Gitlab 프로젝트 코드를 사용할 수 있습니다.',
        warningCheckCode: 'Gitlab 프로젝트 코드 중복여부를 확인하세요.'
      }
    }
  },
  exposeApiMgt: {
    title: '노출 API 관리',
    table: {
      title: '노출 API 목록',
      corporationCode: '법인코드',
      host: '호스트',
      payload: '페이로드',
      response: '응답',
      token: '토큰',
      path: '경로',
      endPoint: '엔드 포인트'
    },
    form: {
      createTitle: '노출 API 생성',
      updateTitle: '노출 API 수정'
    },
    message: {
      addSuccess: '항목이 정상적으로 생성되었습니다.',
      deleteSuccess: '항목이 삭제되었습니다.',
      updateSuccess: '항목이 정상적으로 수정되었습니다.'
    }
  },
  integrationSolutionMgt: {
    title: '프로젝트별 통합 솔루션 연계 관리',
    table: {
      title: '통합 솔루션 목록',
      corporationCode: '법인코드',
      endpoint: '엔드 포인트',
      payload: '페이로드',
      response: '응답',
      token: '토큰',
      solutionType: '솔루션유형',
      solutionName: '솔루션명',
      active: '활성',
      statusInformation: '정보 상태',
      creator: '등록자',
      createDate: '등록일자',
      modifyBy: '수정자',
      modifyDate: '수정일자',
      projectName: '프로젝트명'
    },
    form: {
      createTitle: '통합 솔루션 생성',
      updateTitle: '솔루션 정보 수정',
      thirdParty: '카카오톡'
    },
    message: {
      addSuccess: '항목이 정상적으로 등록되었습니다.',
      deleteSuccess: '항목이 정상적으로 삭제되었습니다.',
      updateSuccess: '항목이 정상적으로 수정되었습니다.',
      deleteItem: '사용자 상태를 ‘미사용’으로 변경후에 삭제해주세요.',
      errorHTTP: '앤드 포인트가 http 또는 https로 시작해 야 합니다.'
    }
  },
  thirdPartyMgt: {
    title: '통합 솔루션 관리',
    kakaoName: '카카오톡 BizMessage - NHN 알림톡',
    cicdName: 'MetaCICD 계정 동기화',
    gitlabConnectName: 'Gitlab 통합',
    form: {
      senderKey: '발송인 키',
      alimtalkPath: '알림톡 경로',
      secretKey: '시크릿 키',
      createTitle: '제삼자 생성',
      updateTitle: '{{solution}} 변경',
      kakaoName: '카카오톡 BizMessage - NHN 알림톡'
    },
    table: {
      title: '카카오톡 목록'
    },
    warningChangeActive: '통합 사용자의 계정 동기화를 해제하시겠습니까?'
  },
  userWorkflowPermission: {
    title: '사용자 워크플로우 권한',
    userList: '사용자 목록',
    workflow: '워크플로우',
    changeSuccess: '변경 사항이 저장되었습니다.'
  },
  licenseMgt: {
    title: '라이선스 관리',
    form: {
      clientName: '클라이언트명',
      deviceName: '장비명',
      expiredDate: '만료일',
      licenseType: '라이선스 유형',
      userCount: '사용자 수',
      ticketCount: '티켓 수',
      serviceCount: '서비스 수',
      clientDescription: '클라이언트 설명',
      licenseKey: '라이선스 키',
      keyRegistrationActive: '키 생성 및 사용',
      importLicenseKey: '라이선스 키 입력'
    },
    confirm: {
      message: '새로운 라이선스를 활성화하시겠습니까?'
    }
  },
  dynamicContentManagement: {
    title: '동적 언어 관리',
    scope: '구분',
    content: '상세',
    defaultContent: '기본 내용',
    variantionContent: '변경 내용',
    enContent: '영어 내용',
    koContent: '한글 내용'
  },
  logManagement: {
    loginLog: {
      title: '로그인 로그',
      searchForm: {
        userName: '사용자명',
        loginType: '로그인 구분',
        status: '상태',
        period: '기간'
      },
      table: {
        logDate: '로그 일시',
        userName: '사용자명',
        loginType: '로그인 구분',
        ipAddress: 'IP 주소',
        status: '상태',
        remark: '비고'
      },
      status: {
        success: '성공',
        fail: '실패',
        block: '블록'
      }
    }
  },
  actionLog: {
    headTitle: '작업 로그',
    form: {
      userName: '사용자명',
      method: '방법',
      status: '상태',
      period: '기간',
      logDate: '로그 일시',
      menuName: '메뉴명',
      url: 'URI',
      exceptionNote: '제외명',
      periodTo: '기간 종료일',
      periodFrom: '기간 시작일'
    }
  },
  userGuideSetting: {
    headTitle: '사용자 매뉴얼 관리',
    form: {
      roles: '권한',
      documentInfomation: '문서 정보',
      popUpTitle: '팝업 제목',
      engName: '영문명',
      koName: '한글명',
      forKoLang: '한글용',
      forEngLang: '영문용',
      message: {
        updateSuccess: '사용자 안내서 업데이트 성공'
      }
    }
  },
  concurrentUserAndHistory: {
    headTitle: '동시 사용자 및 기록',
    table: {
      logDate: '로그 일시',
      userCount: '사용자 수',
      variance: '증감수',
      percent: '증감율(%)',
      note: '비고'
    },
    popup: {
      concurrentUser: '동시 사용자',
      user: '사용자'
    }
  }
};

export { system };
