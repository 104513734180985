import { experimentalStyled as styled } from '@mui/material/styles';

export const ConfigStatusWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1)
}));

export const CurrentStatusWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  svg: {
    color: '#a7a7a7'
  }
}));

export const FixedStyled = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  zIndex: 9
}));

export const StatusIconStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  cursor: 'pointer',
  '.selected': {
    backgroudColor: 'red'
  }
}));

export const WrapperIcon = styled('div')(({ theme }) => ({
  height: 16,
  marginRight: theme.spacing(0.5),
  svg: {
    width: 16,
    height: 16
  }
}));

export const WrapperPostionList = styled('div')(({ theme }) => ({
  background: 'white',
  border: 'solid 1px rgba(145, 158, 171, 0.08)',
  borderRadius: 8,
  boxShadow: ' 0 0 2px 0 rgba(145, 158, 171, 0.24), 0 12px 24px 0 rgba(145, 158, 171, 0.24)',
  width: '220px',
  position: 'absolute',
  top: '55px',
  right: '96px',
  transition: '0.2s',
  opacity: 0,
  zIndex: 10,
  visibility: 'hidden',

  '&.show': {
    opacity: 1,
    top: '70px',
    visibility: 'visible'
  }
}));

export const WrapperListStatus = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}));

export const ExtendStatusStyled = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  svg: {
    marginRight: theme.spacing(0.5)
  }
}));

export const WrapperAvatarUser = styled('div')(({ theme }) => ({
  position: 'relative'
}));

export const MiniIconStatusWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -3,
  right: 3,
  width: 15,
  height: 15,
  zIndex: 3,
  svg: {
    width: '100%',
    height: '100%',
    marginBottom: 5
  },

  p: {
    fontSize: 12,
    background: 'black',
    width: 'fit-content',
    padding: '2PX 4PX',
    color: '#ffffffd6',
    top: 8,
    display: 'block',
    right: 17,
    position: 'absolute',
    whiteSpace: 'nowrap',
    transition: '0.3s',
    visibility: 'hidden'
  },
  '&:hover p': {
    visibility: 'visible'
  }
}));

export const IconStyled = styled('div')(({ theme }) => ({
  svg: {
    background: '#ffffff',
    borderRadius: '50%'
  }
}));

export const ItemTimeReset = styled('div')(({ theme }) => ({
  background: '#e7e8e9',
  padding: '2px 4px',
  marginRight: theme.spacing(1)
}));
