import { useState } from 'react';

import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Popover, Grid, PopoverProps } from '@mui/material';
import { MButton, MInput } from '@material-extend';

import useLocales from 'hooks/useLocales';
import { INITIAL_TABLE_ROW_COL } from '../constants';

const ButtonBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2, 2, 2),
  '&> button:not(:first-of-type)': {
    marginLeft: theme.typography.pxToRem(16)
  }
}));

export type TablePopoverProps = PopoverProps & {
  onInsertTable?: (row: number, col: number) => void;
  onClose?: VoidFunction;
};

const TablePopover = (props: TablePopoverProps) => {
  const { t } = useLocales();
  const [tableInfo, setTableInfo] = useState<{ row: number; col: number }>(INITIAL_TABLE_ROW_COL);

  const { onClose, onInsertTable, ...others } = props;

  const handleClose = () => {
    onClose && onClose();
    setTableInfo(INITIAL_TABLE_ROW_COL);
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setTableInfo((prevState) => ({ ...prevState, [name]: parseInt(value, 10) }));
  };

  const handleInsertTable = () => {
    onInsertTable && onInsertTable(tableInfo.row, tableInfo.col);
    handleClose();
  };

  return (
    <Popover {...others} onClose={handleClose}>
      <Grid container sx={{ p: 2 }} spacing={2}>
        <Grid item xs={6}>
          <MInput
            name="row"
            type="number"
            label={t('editor.row')}
            value={tableInfo.row}
            inputProps={{ min: 1, step: 1 }}
            sx={{ width: 100 }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <MInput
            name="col"
            type="number"
            label={t('editor.col')}
            value={tableInfo.col}
            inputProps={{ min: 1, step: 1 }}
            sx={{ width: 100 }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <ButtonBoxStyle>
        <MButton variant="outlined" onClick={handleClose}>
          {t('common.button.cancel')}
        </MButton>
        <MButton type="submit" variant="contained" onClick={handleInsertTable}>
          {t('common.button.add')}
        </MButton>
      </ButtonBoxStyle>
    </Popover>
  );
};

export default TablePopover;
