import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function JarIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 303.188 303.188"
      >
        <g>
          <polygon
            fill="#E8E8E8"
            points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
          />
          <g>
            <path
              fill="#FFFFFF"
              d="M204.913,142.494H180.24c-1.916,0-3.472-1.555-3.472-3.472s1.556-3.47,3.472-3.47h24.673
			c5.574,0,10.107-4.536,10.107-10.107c0-5.575-4.533-10.109-10.107-10.109h-11.751c-1.917,0-3.471-1.556-3.471-3.471
			c0-1.918,1.554-3.471,3.471-3.471h11.751c9.402,0,17.05,7.646,17.05,17.051C221.963,134.846,214.316,142.494,204.913,142.494z"
            />
            <path
              fill="#FFFFFF"
              d="M206.946,99.905c-5.797,25.057-17.47,48.618-46.085,54.228H129.31
			c-28.61-5.609-40.287-29.171-46.084-54.228H206.946z"
            />
            <rect x="101.905" y="152.959" fill="#A4A9AD" width="86.357" height="10.628" />
          </g>
          <g>
            <path
              fill="#A4A9AD"
              d="M93.286,288.935c-2.363,0-4.605-0.23-6.728-0.689V278.17c0.7,0.131,1.444,0.279,2.231,0.443
			c0.788,0.164,1.641,0.246,2.56,0.246c2.144,0,3.675-0.646,4.594-1.936c0.919-1.292,1.378-3.501,1.378-6.63v-44.402h13.028v43.286
			c0,6.498-1.422,11.416-4.266,14.752C103.24,287.266,98.974,288.935,93.286,288.935z"
            />
            <path
              fill="#A4A9AD"
              d="M150.158,273.871l-2.362-8.992h-15.588l-2.429,8.992h-14.243l15.654-48.176h17.295l15.851,48.176
			H150.158z M145.104,254.246l-2.067-7.876c-0.481-1.751-1.066-4.015-1.756-6.794c-0.688-2.777-1.144-4.768-1.361-5.973
			c-0.197,1.116-0.586,2.954-1.166,5.514c-0.58,2.561-1.865,7.604-3.856,15.129L145.104,254.246L145.104,254.246z"
            />
            <path
              fill="#A4A9AD"
              d="M182.484,256.412v17.459h-12.963v-47.979h15.72c13.039,0,19.559,4.725,19.559,14.177
			c0,5.559-2.713,9.857-8.139,12.898l13.98,20.904H195.94l-10.174-17.459H182.484z M182.484,246.666h2.428
			c4.529,0,6.794-2.002,6.794-6.006c0-3.304-2.221-4.956-6.662-4.956h-2.56V246.666z"
            />
          </g>
          <polygon fill="#FB9200" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	" />
          <polygon fill="#D1D3D3" points="219.821,50.525 270.346,50.525 219.821,0 	" />
        </g>
      </svg>
    </Box>
  );
}
