import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class UserSearchParamsModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'searchCriteria' })
  searchCriteria: SearchCriteriaUserSearModel | undefined;
}
export class SearchCriteriaUserSearModel extends BaseModel {
  @Expose({ name: 'searchType' })
  searchType: string | undefined;

  @Expose({ name: 'searchValue' })
  searchValue: string | undefined;
}
