import { IconButton, IconButtonProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from '@mui/styles';

// For automation test
const TEST_ID = 'addBtn';

const StyledIconButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200]
  }
}))(IconButton);

const AddIconButton = (props: IconButtonProps) => (
  <StyledIconButton {...props}>
    <AddIcon test-id={TEST_ID} aria-label="add" />
  </StyledIconButton>
);

export default AddIconButton;
