import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import dayjs from 'dayjs';
import { FULL_DATE_FORMAT } from 'constants/common';
import { ProjectApplicationModel } from './ProjectApplicationModel';
import { ContractModel } from './ContractModel';

export class ProjectDataModel extends BaseModel {
  @Expose({ name: 'content' })
  @Transform((item) => item && ProjectListModel.toClass(item.value))
  projectList: ProjectListModel | undefined;

  @Expose({ name: 'totalElements' })
  totalElements: number | undefined;
}

export class ProjectListModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'orgName' })
  orgName: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'projName' })
  projName: string | undefined;

  @Expose({ name: 'projType' })
  projType: string | undefined;

  @Expose({ name: 'projTypeName' })
  projTypeName: string | undefined;

  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'activeYnName' })
  activeYnName: string | undefined;

  @Expose({ name: 'projInfo' })
  projInfo: string | undefined;

  @Expose({ name: 'contractName' })
  contractName: string | undefined;

  @Expose({ name: 'projAppCount' })
  projAppCount: number | undefined;

  @Expose({ name: 'createdName' })
  createdBy: string | undefined;

  @Expose({ name: 'createdDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  createdDate: number | undefined;

  @Expose({ name: 'updatedName' })
  updatedBy: string | undefined;

  @Expose({ name: 'updatedDate' })
  @Transform((v) => v && dayjs(v.value).format(FULL_DATE_FORMAT))
  updatedDate: number | undefined;

  @Expose({ name: 'projectApplications' })
  @Transform((item) => item && ProjectApplicationModel.toClass(item.value))
  projectApplications: any | undefined;

  @Expose({ name: 'contract' })
  @Transform((item) => item && ContractModel.toClass(item.value))
  contract: any | undefined;

  @Expose({ name: 'projectUsers' })
  projectUsers: any | undefined;
}

export class ProjectDeleteModel extends BaseModel {
  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;
}
