import { Expose } from 'class-transformer';

import BaseModel from 'models/BaseModel';

export class EmailMeWhenSettingModel extends BaseModel {
  @Expose({ name: 'ticketCreated' })
  ticketCreated: string | undefined;

  @Expose({ name: 'ticketAssigned' })
  ticketAssigned: string | undefined;

  @Expose({ name: 'ticketComment' })
  ticketComment: string | undefined;

  @Expose({ name: 'ticketUpdated' })
  ticketUpdated: string | undefined;

  @Expose({ name: 'ticketStatus' })
  ticketStatus: string | undefined;

  @Expose({ name: 'announcement' })
  announcement: string | undefined;

  @Expose({ name: 'accountRegistrationRequest' })
  accountRegistrationRequest!: string | null;
}
