import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class MenuPathModel extends BaseModel {
  @Expose({ name: 'menuCode' })
  menuCode: string | undefined;

  @Expose({ name: 'menuPath' })
  menuPath: string | undefined;
}

export class GetMenuPathModel extends BaseModel {
  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;
}
