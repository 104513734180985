import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class MenuListModel extends BaseModel {
  @Expose({ name: 'menuName' })
  menuName: string | undefined;

  @Expose({ name: 'menuCode' })
  menuCode: string | undefined;

  @Expose({ name: 'path' })
  path: string | undefined;

  @Expose({ name: 'level' })
  level: number | undefined;

  @Expose({ name: 'subMenuList' })
  subMenuList: Array<MenuListModel> | undefined;
}
