import { ButtonProps } from '@mui/material';
import useLocales from 'hooks/useLocales';
import MButton from '../MButton';

const ApplyButton = (props: ButtonProps) => {
  const { t } = useLocales();
  return (
    <MButton {...props} variant="outlined" color="primary">
      {t('common.button.apply')}
    </MButton>
  );
};

export default ApplyButton;
