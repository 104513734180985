import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import ScheduleIcon from '@mui/icons-material/Schedule';
import RefreshIcon from '@mui/icons-material/Refresh';
import useLocales from 'hooks/useLocales';
import { Divider, Typography } from '@mui/material';
import { CommonDialog } from '@material-extend';
import { STATUS_ICON_TYPE, USER_STATUS } from 'constants/userStatus';
import { FULL_DATE_FORMAT_12_HOURS } from 'constants/common';

import {
  ExtendStatusStyled,
  ItemTimeReset,
  // ItemTimeReset,
  StatusIconStyled,
  WrapperIcon,
  WrapperListStatus
} from './styled';
import StatusIcon from './StatusIcon';
import { DurationDataType, StatusIconRender, StatusSelectedType } from './type';
import DurationForm from './DurationForm';
import { fomatTimeStatus } from './helper';

type ListStatusProps = {
  statusDuration: string;
  onChangeStatus: (value: StatusSelectedType) => void;
};

const ListStatus = ({ statusDuration, onChangeStatus }: ListStatusProps) => {
  const { t } = useLocales();

  const [openDurationPopup, setOpenDuarationPopup] = useState(false);

  const handelDiscard = () => setOpenDuarationPopup(false);

  const handleChangeStatus = (value: StatusSelectedType) => {
    onChangeStatus(value);
    handelDiscard();
  };

  const onSelectStatus = (e: any, statusCode: string) => {
    e.stopPropagation();
    handleChangeStatus({
      status: statusCode,
      timestamp: 0
    });
  };

  const onOpenDuration = (e: any) => {
    e.stopPropagation();
    setOpenDuarationPopup(true);
  };

  const handelResetStatus = (e: any) => {
    e.stopPropagation();
    handleChangeStatus({
      status: USER_STATUS.AVAILABLE,
      timestamp: 0
    });
  };

  const handleSelectDuration = (value: DurationDataType) => {
    const timestamp = fomatTimeStatus(value.typeReset, value.dateReset, value.timeReset);
    handleChangeStatus({
      status: value.status,
      timestamp
    });
  };

  const timeResetFormat = useMemo(() => {
    if (statusDuration) {
      const formatDuration = dayjs(statusDuration).format(FULL_DATE_FORMAT_12_HOURS);
      const date = formatDuration.substr(0, 10);
      const time = formatDuration.substr(11, formatDuration.length);
      return {
        date,
        time
      };
    }
    return null;
  }, [statusDuration]);

  return (
    <>
      <WrapperListStatus>
        {Object.values(USER_STATUS).map((statusKey: STATUS_ICON_TYPE, index) => (
          <StatusIcon
            key={index}
            statusKey={statusKey}
            renderProps={(statusIcon: StatusIconRender) => (
              <StatusIconStyled onClick={(e) => onSelectStatus(e, statusIcon.value)}>
                <WrapperIcon>{statusIcon.icon}</WrapperIcon>
                <Typography>{statusIcon.lable}</Typography>
              </StatusIconStyled>
            )}
          />
        ))}
        <Divider sx={{ my: 1 }} />
        <ExtendStatusStyled onClick={onOpenDuration}>
          <ScheduleIcon />
          <Typography>{t('dashboard.userStatus.duration')}</Typography>
        </ExtendStatusStyled>

        {statusDuration ? (
          <>
            <ExtendStatusStyled>
              <Typography>{t('dashboard.userStatus.resetAfter')}:</Typography>
            </ExtendStatusStyled>

            <ExtendStatusStyled>
              <ItemTimeReset>{timeResetFormat?.date}</ItemTimeReset>
              <ItemTimeReset>{timeResetFormat?.time}</ItemTimeReset>
            </ExtendStatusStyled>
          </>
        ) : null}

        <Divider sx={{ my: 1 }} />

        <ExtendStatusStyled onClick={handelResetStatus}>
          <RefreshIcon />
          <Typography>{t('dashboard.userStatus.resetStatus')}</Typography>
        </ExtendStatusStyled>
      </WrapperListStatus>

      <CommonDialog isOpenDialog={openDurationPopup} maxWidth="sm">
        <DurationForm onDiscard={handelDiscard} onSubmit={handleSelectDuration} />
      </CommonDialog>
    </>
  );
};

export default ListStatus;
