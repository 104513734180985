import { useEffect, useState, useMemo, useCallback } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// hook form
import useHookForm from 'hooks/useHookForm';
import { RHFProvider, RHFInput, RHFSelect, RHFController } from 'components/hook-form';
import QuillEditorWithImage from 'containers/QuillEditorWithImage';
// material
import { Box, Typography, Theme, FormControlLabel, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  AuthDialogButtons,
  AuthEditButton,
  AuthPrivateTicket
} from 'features/auth/components/authButtons';
import { AuthVisible } from 'features/auth/components/authComponents';
import { useAuth } from 'features/auth/useAuth';
import {
  MMenuItem,
  HelpTooltip,
  MDatePicker,
  DiscardButton,
  MTooltip,
  MButton,
  MRadio,
  MAutocomplete,
  CommonDialog
} from 'components/@material-extend';
import { FormCard, FormGroup, FormGridItem, FormGridContainer } from 'components/layouts';
import UploadFile from 'components/upload/UploadFile';
import { COMMON_CODE_TYPE, SOLUTION_TYPE, TICKET_TYPE as TYPE } from 'constants/common';
import { useFormSchema } from 'hooks/useFormSchema';
import { useInjectSaga } from 'hooks/useInjector';
import useLocales from 'hooks/useLocales';
import { useAlert } from 'hooks/useAlert';

import AssigneeInput from 'containers/AssigneeInput';
import EstimationInput from 'components/inputs/EstimationInput';
import { useFetchCommonCode } from 'hooks/useFetchCommonCode';
import { commonCodeSliceName, commonCodeSaga } from 'features/commonCode/slices';
import { ACTIVE_YN } from 'constants/commonCodes';
import TicketFieldSetting from 'features/auth/components/TicketFieldSetting';

import {
  ATTACHMENT_ACCEPTED_TYPES,
  DEFAULT_PRIORITY,
  DEFAULT_ESTIMATION_VALUE,
  ESTIMATION_PLACEHOLDER,
  DEFAULT_QUILL_FORMATS,
  STATUS_ALLOW_CHANGE_PROJECT,
  SERVICE_OPERATION,
  STATUS_OPEN_DEVTEST_ASSSIGN_DEV,
  STATUS_APPROVE_WITHDRAW
} from './constants';
import ProjectAppVersionForm from './components/ProjectAppVersionForm';
import TechnicalSolutionForm from './components/TechnicalSolutionForm';
import { ticketFormSliceName, ticketFormActions, ticketFormSaga } from './slices';

import { TicketFormModel } from './models';
import {
  checkTicketNeedApprove,
  formatTechnicalSolution,
  getDefaultValueCommonCode,
  getLabelFormCommonCode,
  getTicketType,
  isDisableFromDevelopmentStatus
} from './helpers';
import { APRDEV_STATUS, OPEN_STATUS, STATUS_TICKET_NEED_APPROVE } from '../TicketDetail/constants';
import { GroupButtonConfirmSave, WrapperApprover, WrapperCicdProject } from './styles';
import { ticketDetailActions } from '../TicketDetail/slices';
import { getTicketSchema } from './schemas';
import {
  getAssigneeCutomer,
  getAssigneeList,
  getIsSubmittingForm,
  getProjCodeOfAssigneeList,
  getProjectCicdList,
  getServiceClassfication,
  getServiceClassficationDetail,
  getTicketTypeList,
  getUserInfo
} from './reducers';
import { FilerServiceTargetType, ServiceClassficationType, ServiceDetailType } from './types';
import DownloadServiceTarget from './components/DownloadServiceTarget';
import { ProjectCICDType } from '../TicketDetail/types';
import { FIELD_CONFIG } from '../TicketManagement/components/SetLayoutTicket/contants';

const { TICKET_TYPE, PRIORITY, SERVICE_CLASSFICATION } = COMMON_CODE_TYPE;

type TicketFormProps = {
  currentTicket?: TicketFormModel | null;
  projectLinked?: string; // project from register ticket link
  callbackEditTicket?: (ticketCode: string) => void;
  onDiscard: (isCheckHistory?: boolean) => void;
  onRegisterTicketLink?: (idTicket: any, title: string) => void;
};

const useStyles = makeStyles<Theme, TicketFormProps>((theme: Theme) => ({
  leftButtons: {
    float: 'left'
  },
  rightButtons: {
    float: 'right'
  },
  estimationBox: {
    display: 'flex',
    alignItems: 'center'
  },
  rightButtonsWidthDraw: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    '&> button:not(:first-child)': {
      marginLeft: theme.typography.pxToRem(16)
    }
  }
}));

const getAssignee = ({
  assigneeList,
  assignee
}: {
  assigneeList: any;
  assignee?: string | null;
}) => {
  const userHighest = assigneeList[0];
  if (userHighest && userHighest.order) {
    return userHighest.username;
  }
  const foundUser: any = assigneeList.find((user: any) => user.username === assignee);
  return foundUser ? foundUser.username : null;
};

const TicketForm = (props: TicketFormProps) => {
  const { currentLang, t } = useLocales();
  const { showAlert } = useAlert();
  const dispatch: any = useDispatch();
  const {
    currentTicket = null,
    projectLinked,
    onDiscard,
    onRegisterTicketLink,
    callbackEditTicket
  } = props;

  useInjectSaga({ key: ticketFormSliceName, saga: ticketFormSaga });
  useInjectSaga({ key: commonCodeSliceName, saga: commonCodeSaga });

  const { isCustomer } = useAuth();
  const classes = useStyles(props);

  const [shouldChangeAssignee, setChangeAssignee] = useState<boolean>(false);
  const [isAssigneeTouched, setAssigneeTouched] = useState<boolean>(false);

  const [ticketState, setTicketState] = useState<TicketFormModel | null>(currentTicket);

  const [isShowWarningTicketChanged, setShowWarningTicketChanged] = useState(false);

  const [currentValueEdit, setCurrentValueEdit] = useState({});

  const [fileServiceTargets, setFileServiceTarget] = useState<Array<FilerServiceTargetType>>([]);

  const userInfo = useSelector(getUserInfo, shallowEqual);

  const projectCicdList = useSelector(getProjectCicdList, shallowEqual);

  const isSubmittingForm = useSelector(getIsSubmittingForm);

  const assigneeList = useSelector(getAssigneeList, shallowEqual);

  const serviceClassfication = useSelector(getServiceClassfication, shallowEqual);

  const serviceClassficationDetail = useSelector(getServiceClassficationDetail, shallowEqual);

  const projCodeOfAssigneeList = useSelector(getProjCodeOfAssigneeList, shallowEqual);

  const resDataTicketType = useSelector(getTicketTypeList, shallowEqual);

  const listAssigneeCustomer = useSelector(getAssigneeCutomer, shallowEqual);

  const isEdit = Boolean(ticketState);
  const isViewOnlyForCustomer = isEdit && isCustomer;

  const { username } = userInfo;

  const isCreatorTicket = username === get(ticketState, 'createdBy');
  const isTicketApproved = Boolean(get(ticketState, ['approvalDate']));
  const isTicketWithdrawn = Boolean(get(ticketState, ['withdrawDate']));

  const integrateSolution = useMemo(() => {
    if (ticketState) return ticketState.integrateSolution;
    return get(projectCicdList, 'solution');
  }, [ticketState, projectCicdList]);

  const typeIntegrationOfProject = useMemo(() => get(projectCicdList, 'solution'), [
    projectCicdList
  ]);

  const {
    [PRIORITY]: priorities,
    [SERVICE_CLASSFICATION]: serviceTypeCommonCode,
    [TICKET_TYPE]: ticketTypeCommonCode
  } = useFetchCommonCode([PRIORITY, SERVICE_CLASSFICATION, TICKET_TYPE]);

  const labelServiceType = getLabelFormCommonCode(serviceTypeCommonCode, currentLang.value);
  const labelTicketType = getLabelFormCommonCode(ticketTypeCommonCode, currentLang.value);

  const includesProjCicdInactive = useMemo(() => {
    let result: ProjectCICDType[] = [];

    if (projectCicdList && projectCicdList.projects?.length) {
      result = projectCicdList.projects;

      if (ticketState) {
        const projectCicdsInactive = get(ticketState, 'projectCicds', []).filter(
          (proj: ProjectCICDType) => proj.activeYn === ACTIVE_YN.No
        );

        if (projectCicdsInactive.length) {
          result = result.concat(projectCicdsInactive);
        }
      }
    }
    if (result.length) {
      return result.map((proj: ProjectCICDType) => ({
        ...proj,
        inActive: proj.activeYn === ACTIVE_YN.No,
        label: `${proj.projCicdCode}-${proj.projCicdName}`
      }));
    }

    return result;
  }, [ticketState, projectCicdList]);

  const handleEditTicket = (value: any) => {
    dispatch(
      ticketFormActions.editTicket({
        value,
        callback: callbackEditTicket
      })
    );
  };

  const handleCreateTicket = (value: any) => {
    dispatch(
      ticketFormActions.createTicket({
        value,
        callback: onRegisterTicketLink
      })
    );
  };

  const handleCheckTicketHistory = (valueEditTicket: any) => {
    dispatch(
      ticketFormActions.checkTicketHistory({
        value: {
          ticketCode: ticketState?.ticketCode
        },
        callback: (historyNotChanged: boolean) => {
          if (historyNotChanged) {
            handleEditTicket(valueEditTicket);
          } else {
            setCurrentValueEdit(valueEditTicket);
            setShowWarningTicketChanged(true);
          }
        }
      })
    );
  };

  const handleSubmitForm = (formValues: any) => {
    const techSolutionObject = formatTechnicalSolution(formValues);
    const projCicdCodes = inputSetting.showCicdProjectField ? formValues.projCicdCodes : [];

    const valueSubmit = {
      ...formValues,
      ...techSolutionObject,
      projCicdCodes
    };

    if (isEdit) {
      const statusType = getTicketType(formValues, ticketState);
      const valueEdit = {
        ...valueSubmit,
        statusType
      };
      handleCheckTicketHistory(valueEdit);
    } else {
      handleCreateTicket(valueSubmit);
    }
  };

  const { schema: ticketSchema } = useFormSchema((t) =>
    getTicketSchema(t, ticketState, isCustomer, labelTicketType, labelServiceType)
  );

  const hookForm = useHookForm({
    initialValues: {
      projCode: ticketState?.projCode || projectLinked || '',
      appCode: ticketState?.appCode || '',
      appVersionId: ticketState?.appVersionId || '',
      appVersion: ticketState?.appVersion || '',
      ticketType: ticketState?.ticketType || '',
      priority: isEdit ? ticketState?.priority : DEFAULT_PRIORITY,
      serviceType: ticketState?.serviceType || '',
      serviceDetail: ticketState?.serviceDetail || '',
      targetCompDate: ticketState?.targetCompDate || dayjs().startOf('day').add(3, 'day'),
      issueDetected: isEdit ? ticketState?.issueDetected : dayjs().startOf('day'),
      summary: ticketState?.summary || '',
      assignee: ticketState?.assignee || null,
      estimation: ticketState?.estimation || '',
      affectedVersion: ticketState?.affectedVersion || '',
      description: ticketState?.description || '',
      techSolution0: ticketState?.techSolution0 || '',
      techSolution1: ticketState?.techSolution1 || '',
      techSolution2: ticketState?.techSolution2 || '',
      techSolution3: ticketState?.techSolution3 || '',
      techSolution4: ticketState?.techSolution4 || '',
      tech1Files: ticketState?.tech1Files || [],
      tech2Files: ticketState?.tech2Files || [],
      tech3Files: ticketState?.tech3Files || [],
      tech4Files: ticketState?.tech4Files || [],
      tech5Files: ticketState?.tech5Files || [],
      t1Files: [],
      t2Files: [],
      t3Files: [],
      t4Files: [],
      t5Files: [],
      files: [],
      ticketFiles: ticketState?.ticketFiles || [],
      ticketCode: ticketState?.ticketCode || '',
      projCicdCodes: ticketState?.projCicdCodes || [],
      approval: ticketState?.approval || '',
      statusType: ticketState?.statusType || '',
      approvalOpinion: ticketState?.approvalOpinion || '',
      approvalDate: ticketState?.approvalDate || '',
      privateYn: ticketState?.privateYn || ACTIVE_YN.No
    },
    validationSchema: ticketSchema,
    onSubmit: handleSubmitForm
  });

  const { dirty, values, handleSubmit, setValue, methods, watch, touched, errors } = hookForm;

  const watchedServiceType = watch('serviceType');
  const watchedServiceDetail = watch('serviceDetail');
  const watchTicketFiles = watch('ticketFiles');
  const watchProjCode = watch('projCode');
  const watchAssignee = watch('assignee');
  const watchTicketType = watch('ticketType');
  const watchTicketPrivate = watch('privateYn');
  const watchAppCode = watch('appCode');
  const isPublic = watchTicketPrivate === ACTIVE_YN.No;
  const isTicketNeedApprove = checkTicketNeedApprove(watchTicketType, watchedServiceType); // FORM DATA
  const isCurrentTicketNeedApprove = checkTicketNeedApprove(
    ticketState?.ticketType,
    ticketState?.serviceType
  );

  const listStatusNotWaitApprove = ![
    STATUS_TICKET_NEED_APPROVE.APPROVE,
    STATUS_TICKET_NEED_APPROVE.WITHDRW
  ].includes(get(ticketState, ['statusType'], ''));

  const isTicketTypeDeploy = [TYPE.Deploy, TYPE.ReqDeploy, TYPE.DeloyRequest].includes(
    watchTicketType
  );

  const inputSetting = useMemo(() => {
    const { statusType, ticketType, disableCicdFieldYn, serviceType }: any = ticketState || {};
    return {
      btnWithdraw: isCreatorTicket && STATUS_TICKET_NEED_APPROVE.APPROVE === statusType,
      infoApproved: isEdit && isTicketApproved && listStatusNotWaitApprove,
      dsbFieldApproval:
        isTicketApproved &&
        listStatusNotWaitApprove &&
        isCurrentTicketNeedApprove &&
        statusType !== STATUS_TICKET_NEED_APPROVE.REJECT,
      showAssigneeGroup:
        ((!isEdit && !isTicketNeedApprove) ||
          (isEdit &&
            ((isTicketApproved && statusType !== STATUS_TICKET_NEED_APPROVE.REJECT) ||
              !isTicketNeedApprove))) &&
        (listStatusNotWaitApprove || (!listStatusNotWaitApprove && !isTicketNeedApprove)) &&
        !isCustomer,
      cicdProjectField:
        !isEmpty(get(projectCicdList, ['projects'])) &&
        !isEmpty(watchTicketType) &&
        watchTicketType !== TYPE.Incident &&
        watchTicketType !== TYPE.Request,
      showCicdProjectField:
        !isEmpty(get(projectCicdList, ['projects'])) &&
        (typeIntegrationOfProject === SOLUTION_TYPE.GITLAB ||
          (typeIntegrationOfProject === SOLUTION_TYPE.MetaCICD && isTicketTypeDeploy)),
      showWithDrawDate:
        isEdit && isTicketWithdrawn && STATUS_TICKET_NEED_APPROVE.WITHDRW === statusType,
      showCreateDate:
        isEdit &&
        (STATUS_TICKET_NEED_APPROVE.APPROVE === statusType ||
          STATUS_TICKET_NEED_APPROVE.WITHDRW === statusType),
      dsbBtnSaveEdit:
        (STATUS_TICKET_NEED_APPROVE.REJECT === statusType ||
          STATUS_TICKET_NEED_APPROVE.WITHDRW === statusType) &&
        isTicketNeedApprove &&
        !isCreatorTicket,
      dsbServiceType:
        (isEdit &&
          isTicketNeedApprove &&
          isCurrentTicketNeedApprove &&
          ((isTicketApproved &&
            statusType !== STATUS_TICKET_NEED_APPROVE.REJECT &&
            statusType !== STATUS_TICKET_NEED_APPROVE.WITHDRW) ||
            statusType === STATUS_TICKET_NEED_APPROVE.APPROVE)) ||
        !watchProjCode,
      dsbTicketType:
        (isEdit &&
          isTicketNeedApprove &&
          isCurrentTicketNeedApprove &&
          ((isTicketApproved &&
            statusType !== STATUS_TICKET_NEED_APPROVE.REJECT &&
            statusType !== STATUS_TICKET_NEED_APPROVE.WITHDRW) ||
            statusType === STATUS_TICKET_NEED_APPROVE.APPROVE)) ||
        isDisableFromDevelopmentStatus(ticketType, statusType),
      dsbProjectCICD: disableCicdFieldYn === ACTIVE_YN.Yes,
      allowChangeProject:
        !isEdit ||
        (ticketState?.ticketType === TYPE.Deploy &&
          STATUS_ALLOW_CHANGE_PROJECT.DEPLOY.includes(statusType)) ||
        (ticketState?.ticketType === TYPE.Request &&
          (serviceType !== SERVICE_OPERATION ||
            (serviceType === SERVICE_OPERATION &&
              STATUS_ALLOW_CHANGE_PROJECT.SERVICE_REQUEST.includes(statusType)))) ||
        (ticketState?.ticketType === TYPE.ReqDeploy &&
          ((serviceType === SERVICE_OPERATION && STATUS_APPROVE_WITHDRAW.includes(statusType)) ||
            (serviceType !== SERVICE_OPERATION &&
              STATUS_OPEN_DEVTEST_ASSSIGN_DEV.includes(statusType)))) ||
        ticketState?.ticketType === TYPE.Service ||
        ticketState?.ticketType === TYPE.Incident,
      dsbCicdProject:
        isEdit &&
        statusType !== APRDEV_STATUS &&
        statusType !== OPEN_STATUS &&
        ticketState?.ticketType === TYPE.DeloyRequest
    };
  }, [
    ticketState,
    isCreatorTicket,
    isEdit,
    isTicketNeedApprove,
    isCustomer,
    isTicketApproved,
    projectCicdList,
    watchTicketType,
    isTicketWithdrawn,
    listStatusNotWaitApprove,
    isCurrentTicketNeedApprove,
    watchProjCode,
    isTicketTypeDeploy,
    typeIntegrationOfProject
  ]);

  const shouldShowAssigneeWarningMsg: boolean = useMemo(
    () => isAssigneeTouched && isEmpty(watchProjCode),
    [isAssigneeTouched, watchProjCode]
  );

  const helperTextFiledAssignee = useMemo(
    () =>
      shouldShowAssigneeWarningMsg ? t('helpDesk.ticketManagement.hint.selectProjectFirst') : '',
    [shouldShowAssigneeWarningMsg, t]
  );

  const ticketTypeList = useMemo(() => {
    const defaultList = get(resDataTicketType, [TICKET_TYPE, 'subCodeList']) || [];

    // if solution is GITLAB
    // convert the ticket type from Deploy to Service
    if (integrateSolution === SOLUTION_TYPE.GITLAB) {
      return defaultList.map((item: any) => ({
        ...item,
        value: item.value === TYPE.Deploy ? TYPE.Service : item.value
      }));
    }
    return defaultList;
  }, [resDataTicketType, integrateSolution]);

  const handleWithdrawTicket = () => {
    dispatch(
      ticketDetailActions.changeTicketStatus({
        value: {
          ticketCode: get(ticketState, ['ticketCode']),
          statusType: STATUS_TICKET_NEED_APPROVE.WITHDRW,
          withdrawYn: ACTIVE_YN.Yes
        },
        callback: onDiscard
      })
    );
  };

  const handleClearDataAndCloseForm = () => {
    onDiscard();
    dispatch(ticketFormActions.clearProjectCicdData());
  };

  const resetAssingeeWhenProjectAndServiceTypeChange = useCallback(
    (selectApproval = false) => {
      switch (true) {
        case isEdit && (isTicketNeedApprove || selectApproval) && !isTicketApproved: {
          setValue('approval', '');
          setValue('assignee', '');
          break;
        }
        case !isCustomer: {
          //  clear to SET ASSIGNEE IN LINE 528
          setValue('assignee', '');
          break;
        }
      }
    },
    [isEdit, isCustomer, isTicketApproved, isTicketNeedApprove, setValue]
  );

  const showDiscardWarningDialog = () =>
    dirty
      ? showAlert({
          message: 'common.form.discardWarning',
          onYes: () => handleClearDataAndCloseForm()
        })
      : handleClearDataAndCloseForm();

  const onChangeServiceType = (event: any) => {
    const serviceType = event.target.value;
    setValue('serviceType', serviceType);
    setValue('serviceDetail', '');
    const selectApproval = checkTicketNeedApprove(watchTicketType, serviceType);
    resetAssingeeWhenProjectAndServiceTypeChange(selectApproval);
  };

  const onChangeServiceDetail = (event: any) => {
    setValue('serviceDetail', event.target.value);
  };

  const handleRemoveTicketFile = useCallback(
    (removed: any) => {
      const filteredItems = watchTicketFiles.filter(({ id }) => id !== removed.id);
      setValue('ticketFiles', filteredItems);
    },
    [setValue, watchTicketFiles]
  );

  const getTicketTypeByProject = useCallback(
    (projCode: string) =>
      dispatch(
        ticketFormActions.fetchTicketTypeList({
          codes: [TICKET_TYPE],
          projCode
        })
      ),
    [dispatch]
  );

  const onChangeTicketType = (e: any) => {
    const { value } = e.target;
    if (isEdit) {
      showAlert({
        message: 'helpDesk.ticketManagement.waringTicketType',
        onYes: () => setValue('ticketType', value)
      });
    } else {
      setValue('ticketType', value);
    }
  };

  const handleAfterProjectChange = (projCode: string) => {
    // Turn on a flag to indicate if the assignee field should be change or not
    setChangeAssignee(!isEmpty(projCode));
    setValue('projCicdCodes', []);
    getTicketTypeByProject(projCode);
    resetAssingeeWhenProjectAndServiceTypeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleChangePrivacy = (e: any) => {
    const { value } = e.target;
    setValue('privateYn', value);
    if (!isEdit && isTicketNeedApprove && value === ACTIVE_YN.Yes) {
      setValue('approval', userInfo.username);
    }
  };

  const getProjCodeToFetchProjectCICD = (projCodeOfAssigneeList: string, watchProjCode: string) => {
    if (isEmpty(watchProjCode)) return null;
    // in case there is no assignee input (aka. customer role)
    if (watchProjCode && !projCodeOfAssigneeList) return watchProjCode;
    // prevent fetching 2 times
    if (watchProjCode && projCodeOfAssigneeList && watchProjCode !== projCodeOfAssigneeList)
      return null;
    return projCodeOfAssigneeList;
  };

  // Fech List assignee user when creator is Customer(Role:R0005)
  useEffect(() => {
    if (isCustomer && watchedServiceType && watchProjCode && watchAppCode) {
      dispatch(
        ticketFormActions.fetchAssignUserRoleCustomer({
          projCode: watchProjCode,
          serviceType: watchedServiceType,
          appCode: watchAppCode
        })
      );
    }
  }, [watchProjCode, watchedServiceType, isCustomer, dispatch, watchAppCode]);

  // fetch service classfication detail form service classfication
  useEffect(() => {
    if (watchedServiceType && serviceClassfication.length) {
      const isCodeServiceClassfication = serviceClassfication.find(
        (item: ServiceClassficationType) => item.value === watchedServiceType
      );

      if (isCodeServiceClassfication) {
        dispatch(ticketFormActions.fetchServiceClassficationDetail(watchedServiceType));
      }
    }
  }, [watchedServiceType, serviceClassfication, dispatch]);

  useEffect(() => {
    if (watchProjCode && watchAppCode) {
      dispatch(
        ticketFormActions.fetchServiceClassfication({
          projCode: watchProjCode,
          appCode: watchAppCode
        })
      );
    }
  }, [watchProjCode, watchAppCode, dispatch]);

  // SET ASSIGNEE LOGIC
  useEffect(() => {
    if (!isEmpty(assigneeList)) {
      // ALLOW FLOW APPROVE TICKET
      const foundUser: string | null = getAssignee({
        assigneeList,
        assignee: watchAssignee
      });
      if (!isEdit || (isEdit && !watchAssignee)) {
        if (isTicketNeedApprove) {
          setValue('assignee', userInfo.username);
        } else {
          setValue('assignee', foundUser);
        }
      }
    } else if (isCustomer) {
      const assigneeValue = get(listAssigneeCustomer, [0, 'order'])
        ? get(listAssigneeCustomer, [0, 'username'])
        : userInfo.username;
      setValue('assignee', assigneeValue);
    }

    // eslint-disable-next-line
  }, [assigneeList, isEdit, userInfo.username, isCustomer, listAssigneeCustomer]);

  // Fetch common code TICKET_TYPE when projectCode(projCode) is change
  useEffect(() => {
    if (watchProjCode) {
      getTicketTypeByProject(watchProjCode);
    }
  }, [watchProjCode, getTicketTypeByProject]);

  useEffect(() => {
    // Update assignee when user selects a project
    // if no touch and creator = project_user or R0001,
    // then assignee = creator/updater
    if (
      shouldChangeAssignee &&
      // Make sure that project code of the form and project code of the assignee list are the same
      projCodeOfAssigneeList === watchProjCode
    ) {
      // Check if current user belongs to the project
      const isProjectUser: boolean = assigneeList.some(
        (user: any) => user.username === userInfo.username
      );
      if (isProjectUser) {
        // must check assignee === null instead of empty string
        // if (watchAssignee === null && !touched.assignee) { <- This comment to hotfix auto assignee

        //   // setValue('assignee', userInfo.username);
        // }
        return;
      }
      setChangeAssignee(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeList, shouldChangeAssignee, touched.assignee, userInfo.username, watchAssignee]);

  useEffect(() => {
    dispatch(ticketFormActions.fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    // fetch project cicds by projCodeOfAssigneeList or watchProjCode
    const projCode = getProjCodeToFetchProjectCICD(projCodeOfAssigneeList, watchProjCode);
    if (projCode) {
      dispatch(
        ticketFormActions.getProjectCicdByProjCode({
          projCode
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projCodeOfAssigneeList, watchProjCode]);

  // fetch file service target by service detail
  useEffect(() => {
    if (watchedServiceDetail && serviceClassficationDetail.length) {
      const serviceDetail: ServiceDetailType = serviceClassficationDetail.find(
        (item: ServiceDetailType) => item.value === watchedServiceDetail
      );
      if (serviceDetail) {
        setFileServiceTarget(serviceDetail.commonFiles);
      }
    }
  }, [watchedServiceDetail, serviceClassficationDetail]);

  // SET DEFAULT TICKET TYPE
  useEffect(() => {
    if (watchProjCode && !isEdit && ticketTypeList) {
      const dfTicketTypeValue = getDefaultValueCommonCode(ticketTypeList);
      if (dfTicketTypeValue) {
        setValue('ticketType', dfTicketTypeValue);
      }
    }

    // eslint-disable-next-line
  }, [ticketTypeList, watchProjCode]);

  useEffect(
    () => () => {
      dispatch(ticketFormActions.clearDataDefault());
    },
    [dispatch]
  );

  // SET DEFAULT SERVICE TYPE
  useEffect(() => {
    if (watchProjCode && !isEdit) {
      const dfServiceTypeValue = getDefaultValueCommonCode(serviceClassfication);
      if (dfServiceTypeValue) {
        setValue('serviceType', dfServiceTypeValue);
      }
    }
    // eslint-disable-next-line
  }, [serviceClassfication, watchProjCode, isEdit]);

  // SET DEFAULT SERVICE DETAIL(SERVICE TARGET)
  useEffect(() => {
    if (watchProjCode && !isEdit) {
      const dfServiceDetailValue = getDefaultValueCommonCode(serviceClassficationDetail);
      if (dfServiceDetailValue) {
        setValue('serviceDetail', dfServiceDetailValue);
      }
    }
    // eslint-disable-next-line
  }, [serviceClassficationDetail, watchProjCode, isEdit]);

  useEffect(() => {
    if (currentTicket) {
      setTicketState(currentTicket);
    }
  }, [currentTicket, setTicketState]);

  const onSetValue = (field: string, value: any, shouldValidate?: boolean) => {
    setValue(field, value);
  };

  const onSelectProjectCicd = (value: any) => {
    const projList = value.map((item: any) => item.projCicdCode);
    setValue('projCicdCodes', projList || []);
  };

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ProjectCICDType
  ) => (
    <li {...props}>
      <span style={{ textDecoration: option.inActive ? 'line-through' : 'uset' }}>
        {option.label}
      </span>
    </li>
  );

  const handleReloadData = () => {
    if (currentTicket) {
      const { ticketCode } = currentTicket;
      dispatch(ticketDetailActions.fetchTicketDetailAndHistory({ ticketCode }));
      setShowWarningTicketChanged(false);
    }
  };

  return (
    <>
      <RHFProvider methods={methods} onSubmit={handleSubmit}>
        <FormGridContainer>
          <AuthPrivateTicket>
            <FormGridItem spacing={12}>
              <RadioGroup row onChange={handleChangePrivacy}>
                <FormControlLabel
                  value={ACTIVE_YN.No}
                  checked={isPublic}
                  control={<MRadio />}
                  label={t('helpDesk.ticketManagement.form.public') as string}
                />
                <FormControlLabel
                  value={ACTIVE_YN.Yes}
                  checked={!isPublic}
                  control={<MRadio size="small" />}
                  label={t('helpDesk.ticketManagement.form.private') as string}
                />
              </RadioGroup>
            </FormGridItem>
          </AuthPrivateTicket>

          {/* Project - Application - Version */}
          <ProjectAppVersionForm
            onAfterProjectChange={handleAfterProjectChange}
            isEdit={isEdit}
            openFromRegister={Boolean(projectLinked)} // open ticket form from register button linked ticket
            disableProject={!inputSetting.allowChangeProject || Boolean(projectLinked)}
          />
          <FormGridItem spacing={4}>
            {/* Ticket Type - 티켓 유형 */}
            <RHFSelect
              name="ticketType"
              label={labelTicketType || t('helpDesk.ticketManagement.form.ticketType')}
              options={{
                hasFirstOption: true
              }}
              onChange={onChangeTicketType}
              disabled={inputSetting.dsbTicketType}
            >
              {ticketTypeList.map((item: any) => (
                <MMenuItem key={item.value} value={item.value}>
                  {get(item.title, [currentLang.value])}
                </MMenuItem>
              ))}
            </RHFSelect>
          </FormGridItem>

          {/* Service Type - 서비스 구분 */}
          <FormGridItem spacing={4}>
            {serviceClassfication && (
              <RHFSelect
                name="serviceType"
                onChange={onChangeServiceType}
                label={labelServiceType || t('helpDesk.ticketManagement.form.serviceType')}
                options={{ hasFirstOption: true }}
                disabled={inputSetting.dsbServiceType}
              >
                {serviceClassfication.map((item: ServiceClassficationType) => (
                  <MMenuItem key={item.value} value={item.value}>
                    {get(item.title, [currentLang.value])}
                  </MMenuItem>
                ))}
              </RHFSelect>
            )}
          </FormGridItem>
          <FormGridItem spacing={4}>
            {/* Service Details - 서비스 대상 */}
            <RHFSelect
              name="serviceDetail"
              label={t('helpDesk.ticketManagement.form.serviceDetails')}
              options={{
                hasFirstOption: true
              }}
              disabled={!watchedServiceType}
              onChange={onChangeServiceDetail}
            >
              {serviceClassficationDetail &&
                serviceClassficationDetail.map((item: ServiceDetailType) => (
                  <MMenuItem key={item.value} value={item.value}>
                    {get(item.title, [currentLang.value])}
                  </MMenuItem>
                ))}
            </RHFSelect>
          </FormGridItem>

          <TicketFieldSetting fieldName={FIELD_CONFIG.priority}>
            <FormGridItem spacing={4}>
              {/* Priority - 우선순위 */}
              {priorities && (
                <RHFSelect
                  name="priority"
                  label={t('helpDesk.ticketManagement.form.priority')}
                  options={{
                    hasFirstOption: true
                  }}
                >
                  {priorities?.subCodeList.map((item: any) => (
                    <MMenuItem key={item.value} value={item.value}>
                      {get(item.title, [currentLang.value])}
                    </MMenuItem>
                  ))}
                </RHFSelect>
              )}
            </FormGridItem>
          </TicketFieldSetting>

          <TicketFieldSetting fieldName={FIELD_CONFIG.issueDetected}>
            <FormGridItem spacing={4}>
              {/* Issue Detected */}
              <RHFController name="issueDetected">
                {({ field: { ref, ...otherFieldProps }, fieldState: { error } }: any) => (
                  <MDatePicker
                    label={t('helpDesk.ticketManagement.form.issueDetected')}
                    {...otherFieldProps}
                    inputOptions={{
                      fullWidth: true
                    }}
                    onChange={setValue}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </RHFController>
            </FormGridItem>
          </TicketFieldSetting>
          {/* Target Completion Date - 목표완료일시 */}
          <TicketFieldSetting fieldName={FIELD_CONFIG.completionDate}>
            <FormGridItem spacing={4}>
              <RHFController name="targetCompDate">
                {({ field: { ref, ...otherFieldProps }, fieldState: { error } }: any) => (
                  <MDatePicker
                    label={t('helpDesk.ticketManagement.form.completionDate')}
                    {...otherFieldProps}
                    // For editing, do not allow selecting the time before created date
                    // For creating, do not allow selecting the time before today
                    minDate={ticketState ? dayjs(ticketState.createdDate) : dayjs().startOf('day')}
                    inputOptions={{
                      fullWidth: true
                    }}
                    onChange={setValue}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </RHFController>
            </FormGridItem>
          </TicketFieldSetting>
          <FormGridItem spacing={12}>
            {inputSetting.showCicdProjectField ? (
              <WrapperCicdProject>
                <RHFController name="projCicdCodes">
                  {({ field: { ref, ...otherFieldProps } }: any) => (
                    <MAutocomplete
                      {...otherFieldProps}
                      options={includesProjCicdInactive}
                      fullWidth
                      keyString="label"
                      keyValue="projCicdCode"
                      onSetValueOtherField={onSelectProjectCicd}
                      onChange={onSetValue}
                      disabled={inputSetting.dsbCicdProject}
                      multiple
                      label={t(
                        integrateSolution === SOLUTION_TYPE.MetaCICD
                          ? 'helpDesk.ticketManagement.ticketDetail.cicdProject'
                          : 'helpDesk.ticketManagement.ticketDetail.gitlabProject'
                      )}
                      noAvatar
                      renderOption={handleRenderOption}
                    />
                  )}
                </RHFController>
              </WrapperCicdProject>
            ) : (
              <></>
            )}
          </FormGridItem>

          {/* Editor section */}
          {/* Title - 제목 */}
          <FormGridItem spacing={12}>
            <RHFInput name="summary" fullWidth label={t('helpDesk.ticketManagement.form.title')} />
          </FormGridItem>

          {/* File Service Target  - 설명 */}
          <FormGridItem spacing={12}>
            <DownloadServiceTarget fileServiceTargets={fileServiceTargets} />
          </FormGridItem>

          <FormGridItem spacing={12}>
            {/* Description - 설명 */}
            <Typography fontWeight="bold">
              {t('helpDesk.ticketManagement.form.description')}
            </Typography>
          </FormGridItem>

          <FormGridItem spacing={12}>
            <FormCard>
              <FormGroup>
                <RHFController name="description">
                  {({ field, fieldState: { error } }: any) => (
                    <QuillEditorWithImage
                      type="ticketForm"
                      name={field.name}
                      value={field.value}
                      onChange={(val: any) => {
                        setValue('description', val);
                      }}
                      formats={DEFAULT_QUILL_FORMATS}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </RHFController>
                <RHFController name="files">
                  {({ field: { ref, ...otherFieldProps } }: any) => (
                    <UploadFile
                      accept={ATTACHMENT_ACCEPTED_TYPES}
                      {...otherFieldProps}
                      onChange={setValue}
                      initialFiles={watchTicketFiles}
                      onRemoveInitialFile={handleRemoveTicketFile}
                    />
                  )}
                </RHFController>
              </FormGroup>
            </FormCard>
          </FormGridItem>

          {/* Technical Solution - 조치 내역 */}
          <AuthVisible>
            <TechnicalSolutionForm />
          </AuthVisible>

          {isTicketNeedApprove ? (
            <>
              <FormGridItem spacing={4}>
                <WrapperApprover>
                  <RHFController name="approval">
                    {({ field }: any) => (
                      <AssigneeInput
                        fullWidth
                        appCode={watchAppCode}
                        label={t('helpDesk.ticketManagement.form.approveRejectPerson')}
                        keyString="username"
                        {...field}
                        onChange={setValue}
                        disabled={inputSetting.dsbFieldApproval}
                        projectCode={watchProjCode}
                        serviceType={watchedServiceType}
                        includesAdmin={false}
                        onClickClearButton={() => {
                          // trigger touched
                          setValue('approval', '', true, true);
                        }}
                        onClick={() => {
                          setAssigneeTouched(true);
                        }}
                        error={get(errors, ['approval', 'message'])}
                        helperText={get(errors, ['approval', 'message'])}
                      />
                    )}
                  </RHFController>
                </WrapperApprover>
              </FormGridItem>

              {inputSetting.showCreateDate && (
                <>
                  <FormGridItem spacing={4}>
                    <RHFInput
                      name="createdDate"
                      disabled
                      fullWidth
                      value={get(ticketState, ['createdDate'])}
                      label={t('helpDesk.ticketManagement.form.creationDate')}
                    />
                  </FormGridItem>
                </>
              )}

              {inputSetting.showWithDrawDate && (
                <FormGridItem spacing={4}>
                  <RHFInput
                    name="withdrawDate"
                    disabled
                    fullWidth
                    value={get(ticketState, ['withdrawDate'])}
                    label={t('helpDesk.ticketManagement.form.withdrawDate')}
                  />
                </FormGridItem>
              )}

              {inputSetting.infoApproved ? (
                <>
                  <FormGridItem spacing={4}>
                    <RHFInput
                      name="approvalDate"
                      disabled
                      fullWidth
                      label={t('helpDesk.ticketManagement.form.approvedRejectedDate')}
                    />
                  </FormGridItem>

                  <FormGridItem spacing={4}>
                    <MTooltip title={values.approvalOpinion}>
                      <div>
                        <RHFInput
                          name="approvalOpinion"
                          disabled
                          fullWidth
                          label={t('helpDesk.ticketManagement.form.approvalOpinion')}
                        />
                      </div>
                    </MTooltip>
                  </FormGridItem>
                </>
              ) : null}
            </>
          ) : null}

          {inputSetting.showAssigneeGroup ? (
            <FormGridItem spacing={12}>
              <FormGridContainer>
                <FormGridItem spacing={4}>
                  {/* Assignee - 담당자 */}
                  <RHFController name="assignee">
                    {({ field }: any) => (
                      <AssigneeInput
                        fullWidth
                        disabled={isViewOnlyForCustomer}
                        label={t('helpDesk.ticketManagement.form.assignee')}
                        keyString="username"
                        {...field}
                        onChange={setValue}
                        appCode={watchAppCode}
                        projectCode={watchProjCode}
                        serviceType={watchedServiceType}
                        includesAdmin={false}
                        onClickClearButton={() => {
                          // trigger touched
                          setValue('assignee', '', true, true);
                        }}
                        onClick={() => {
                          setAssigneeTouched(true);
                        }}
                        error={shouldShowAssigneeWarningMsg || get(errors, ['assignee', 'message'])}
                        helperText={helperTextFiledAssignee || get(errors, ['assignee', 'message'])}
                      />
                    )}
                  </RHFController>
                </FormGridItem>

                <TicketFieldSetting fieldName={FIELD_CONFIG.estimation}>
                  <FormGridItem spacing={4}>
                    {/* Estimation - 공수측정 */}
                    <Box className={classes.estimationBox}>
                      <RHFController name="estimation">
                        {({ field, fieldState: { error } }: any) => (
                          <EstimationInput
                            defaultValue={DEFAULT_ESTIMATION_VALUE}
                            fullWidth
                            label={t('helpDesk.ticketManagement.form.estimation')}
                            {...field}
                            error={!!error}
                            helperText={error?.message}
                            disabled={isCustomer}
                            placeholder={ESTIMATION_PLACEHOLDER}
                            onChange={setValue}
                          />
                        )}
                      </RHFController>
                      <HelpTooltip title={`${t('helpDesk.ticketManagement.hint.estimation')}`} />
                    </Box>
                  </FormGridItem>
                </TicketFieldSetting>

                <TicketFieldSetting fieldName={FIELD_CONFIG.affectedVersion}>
                  <FormGridItem spacing={4}>
                    <Box className={classes.estimationBox}>
                      <RHFInput
                        name="affectedVersion"
                        fullWidth
                        label={t('helpDesk.ticketManagement.form.affectedVersion')}
                      />
                      <HelpTooltip
                        title={`${t('helpDesk.ticketManagement.hint.affectedVersion')}`}
                      />
                    </Box>
                  </FormGridItem>
                </TicketFieldSetting>
              </FormGridContainer>
            </FormGridItem>
          ) : null}
        </FormGridContainer>

        {/* Buttons */}
        {isEdit ? (
          <Box className={classes.rightButtonsWidthDraw}>
            <DiscardButton onClick={showDiscardWarningDialog} />
            {inputSetting.btnWithdraw ? (
              <MButton onClick={handleWithdrawTicket} variant="contained">
                {t('common.button.withdraw')}
              </MButton>
            ) : null}
            <AuthEditButton
              disabled={inputSetting.dsbBtnSaveEdit}
              type="submit"
              loading={isSubmittingForm}
            />
          </Box>
        ) : (
          <AuthDialogButtons
            isEdit={isEdit}
            onDiscard={showDiscardWarningDialog}
            isSubmitting={isSubmittingForm}
            labelName={isTicketNeedApprove ? t('common.button.approveRequest') : undefined}
            formName="tikcetForm"
          />
        )}
      </RHFProvider>

      <CommonDialog isOpenDialog={isShowWarningTicketChanged} maxWidth="sm">
        <Typography sx={{ mt: -2 }} variant="subtitle2">
          {t('helpDesk.ticketManagement.errorMess.warningHistoryChange')}
        </Typography>
        <GroupButtonConfirmSave>
          <MButton onClick={() => setShowWarningTicketChanged(false)}>
            {t('common.button.cancel')}
          </MButton>
          <MButton
            variant="contained"
            color="error"
            onClick={() => handleEditTicket(currentValueEdit)}
          >
            {t('common.button.no')}
          </MButton>
          <MButton variant="contained" color="primary" onClick={handleReloadData}>
            {t('common.button.yes')}
          </MButton>
        </GroupButtonConfirmSave>
      </CommonDialog>
    </>
  );
};

export default TicketForm;
