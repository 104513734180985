import useLocales from 'hooks/useLocales';
import { ButtonAuth } from 'interfaces/ButtonAuth';

import MLoadingButton from '../MLoadingButton';

// For automation test
const TEST_ID = 'newBtn';

const NewButton = ({ labelName, ...other }: ButtonAuth) => {
  const { t } = useLocales();
  return (
    <MLoadingButton test-id={TEST_ID} {...other} variant="contained" color="primary">
      {labelName || <span>{t('common.button.create')}</span>}
    </MLoadingButton>
  );
};

export default NewButton;
