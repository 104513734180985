import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';
import * as auth from 'utils/authHelper';
import isEmpty from 'lodash/isEmpty';
import { ContractModel } from './ContractModel';

export class CreatUpdateParamModel extends BaseModel {
  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'contractCode' })
  contractCode: string | undefined;

  @Expose({ name: 'contracts' })
  @Transform((v) => (!isEmpty(v.value) ? [ContractModel.toPlain(v.value[0])] : v.value), {
    toPlainOnly: true
  })
  contracts: Array<any> | undefined;

  @Expose({ name: 'orgCode' })
  orgCode: string | undefined;

  @Expose({ name: 'projCode' })
  @Transform((v) => v.value.toUpperCase(), { toPlainOnly: true })
  projCode: string | undefined;

  @Expose({ name: 'projInfo' })
  projInfo: string | undefined;

  @Expose({ name: 'projName' })
  projName: string | undefined;

  @Expose({ name: 'projType' })
  projType: string | undefined;

  @Expose({ name: 'projectApplications' })
  @Transform(
    (v) => {
      let convertApplicationData = [];
      if (get(v, 'value[0].appCode', null)) {
        convertApplicationData = v.value.map((item: any) => ({
          projCode: v.obj.projCode.toUpperCase(),
          orgCode: v.obj.orgCode,
          corpCode: auth.getCurrentCorpCode() || '', // get corp for each item application
          appCode: item.appCode,
          appVersionId: item.version
        }));
      }
      return convertApplicationData;
    },
    { toPlainOnly: true }
  )
  applications: any | undefined;

  @Expose({ name: 'projectUsers' })
  projectUsers: any | undefined;
}
