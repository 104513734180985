import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import {
  ACCESS_TOKEN,
  CUSTOMER_ROLE_CODE,
  EXPIRED_AT,
  MAINTENANCE_AMIN_ROLE_CODE,
  SYSTEM_ADMIN_ROLE_CODE
} from 'constants/common';
import useLocalStorage from 'hooks/useLocalStorage';
import { authSliceName } from 'features/auth/slices';
import { getCurrentUser, getNeedChangePass } from 'utils/authHelper';

export function useAuth() {
  const [accessToken] = useLocalStorage(ACCESS_TOKEN, false);
  const [expiredAt] = useLocalStorage(EXPIRED_AT, null);
  const { roleCode, timeOut, username } = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'corpUserInfo']) || {}
  );

  const logPeriod = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'logPeriod']) || {}
  );

  const navigateChangePwd = useSelector((state) =>
    get(state, [authSliceName, 'userInfo', 'navigateChangePwd'], false)
  );

  return {
    isAuthenticated: Boolean(accessToken),
    isExpired: isNil(expiredAt) ? false : Number(expiredAt) <= new Date().getTime(),
    isCustomer: roleCode === CUSTOMER_ROLE_CODE,
    isSystemAdmin: roleCode === SYSTEM_ADMIN_ROLE_CODE,
    roleCode,
    isMaintenanceAdmin: roleCode === MAINTENANCE_AMIN_ROLE_CODE,
    expiredAt,
    logPeriod,
    timeOut,
    currentUser: username || getCurrentUser(),
    needChangePassword: navigateChangePwd || getNeedChangePass()
  };
}

export const useVerifyPhone = () => {
  const verifyPhone = useSelector((state) => get(state, [authSliceName, 'verifyPhone']));
  return verifyPhone;
};
