import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class TicketFileModel extends BaseModel {
  @Expose({ name: 'id' })
  id: string | undefined;

  @Expose({ name: 'fileName' })
  fileName: string | undefined;

  @Expose({ name: 'fileUrl' })
  fileUrl: string | undefined;

  @Expose({ name: 'appCode' })
  appCode: string | undefined;

  @Expose({ name: 'appVersionId' })
  appVersionId: number | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'createdBy' })
  createdBy: string | undefined;

  @Expose({ name: 'createdDate' })
  createdDate: number | undefined;

  @Expose({ name: 'projCode' })
  projCode: string | undefined;

  @Expose({ name: 'ticketCode' })
  ticketCode: string | undefined;

  @Expose({ name: 'updatedBy' })
  updatedBy: string | undefined;

  @Expose({ name: 'updatedDate' })
  updatedDate: number | undefined;
}
