/**
 * Ref: https://github.com/afry/quill-mention
 */
import { MENTION_HASHTAG_DENOTATION_CHAR, MENTION_USER_DENOTATION_CHAR } from 'constants/common';
import Quill from 'quill';

const Embed = Quill.import('blots/embed');

class MentionPostBlot extends Embed {
  static create(data: any) {
    const node = super.create();
    switch (true) {
      case Boolean(data.title): {
        node.dataset.keyword = data.title;
        node.innerHTML = data.denotationChar + data.title;
        break;
      }
      case Boolean(data.username && data.name): {
        node.dataset.info = data.username;
        node.innerHTML = data.denotationChar + data.name;
        break;
      }
    }
    return node;
  }

  static value(domNode: any) {
    switch (true) {
      case Boolean(domNode.dataset.keyword): {
        return {
          keyword: domNode.dataset.keyword,
          title: domNode.innerHTML.split(MENTION_HASHTAG_DENOTATION_CHAR)[1],
          denotationChar: MENTION_HASHTAG_DENOTATION_CHAR
        };
      }
      case Boolean(domNode.dataset.info): {
        return {
          username: domNode.dataset.info,
          name: domNode.innerHTML.split(MENTION_USER_DENOTATION_CHAR)[1],
          denotationChar: MENTION_USER_DENOTATION_CHAR
        };
      }
      default:
        return {};
    }
  }
}

MentionPostBlot.blotName = 'mentionPost';
MentionPostBlot.tagName = 'strong';
MentionPostBlot.className = 'mention';

export default MentionPostBlot;
