export const helpDesk = {
  title: 'Service Desk',
  ticketManagement: {
    title: '티켓 관리',
    createTicket: '신규 티켓 등록',
    editTicket: '티켓 수정',
    configLayout: '티켓 레이아웃 구성',
    bulkCreateSetup: '티켓 가져오기',
    table: '티켓 목록',
    kanban: 'Kanban',
    ticket: '티켓',
    approvalRequest: '승인요청',
    warningDelete: '해당 티켓을 삭제하시겠습니까?',
    deleteSuccess: '티켓이 삭제되었습니다',
    requiredItem: '필수항목',
    optionalItem: '선택항목',
    ticketDetail: {
      title: '티켓 상세',
      details: '상세',
      logWork: '작업 내용 등록',
      logWorkWarning: '작업내용을 등록하시겠어요?',
      regularValue: '정기분 그룹 정보',
      regularRange: '사용 가능한 범위 [1-9999999] 의 값이 아닙니다.',
      regularGroup: '정기분 그룹',
      integratedProject: '통합 프로젝트',
      warningCheckRegular: '중복확인버튼을 클릭하여 중복확인을 해주세요.',
      regularGroupUsed: '해당 정기분 그룹이 사용중입니다. 다른 값을 입력하세요.',
      inputRegularData: '정기분 그룹 정보를 입력하세요',
      suggestion: '추천',
      cancel: {
        default: '티켓을 취소하시겠습니까?',
        staging: '검증 빌드/배포 승인을 취소하시겠습니까?',
        production: '운영 빌드/배포 승인을 취소하시겠습니까?',
        confirmRegular: '빌드/배포 진행이 필요합니까?'
      },
      pic: '담당자',
      preview: '워크플로우',
      cicdStatus: 'CI/CD 상태',
      gitlabStatus: 'Gitlab 상태',
      cicdProject: 'CI/CD 프로젝트',
      gitlabProject: 'Gitlab 프로젝트',
      confirmRestoreIntegration: '이 티켓의 통합 프로젝트를 재사용하시겠습니까?',
      deployment: {
        title: '개발',
        tabs: {
          branch: '브랜치',
          commit: {
            creator: '수정자'
          }
        },
        branch: '브랜치명',
        commit: '커밋',
        mergeRequest: '병합 요청',
        build: '빌드',
        deploy: '배포',
        repo: 'Repo',
        form: {
          id: 'ID',
          title: '제목',
          status: '상태',
          creator: '생성자',
          assignee: '수정자',
          lastUpdated: '수정일시',
          repoName: '리포지토리명',
          fileAdded: '추가된 파일',
          fileChanged: '변경된 파일',
          fileRemoved: '삭제된 파일',
          commitTime: '커밋 시간',
          commitId: '커밋 ID',
          approver: '승인자 ',
          updater: '수정자',
          updaterId: '수정자ID',
          creatorName: '생성자명',
          creatorId: '생성자ID',
          approverName: '승인자명',
          approverID: '승인자ID',
          commitMessage: '커밋 메시지',
          detail: '상세'
        },
        status: {
          opened: '오픈',
          closed: '완료',
          updated: '수정',
          approved: '승인',
          merged: '병합',
          deleted: '삭제',
          reopened: '재오픈',
          delete: '삭제'
        }
      },
      tooltip: {
        viewStatusWorkflow: '상태 워크플로우',
        editTicketDetail: '티켓 상세 정보 수정',
        downloadWord: '워드 파일로 티켓 상세 정보 출력'
      },
      regular: '정기분',
      irregular: '수시분',
      nonSelected: '선택 안함',
      descriptionAttachement: '설명의 첨부 파일',
      history: {
        field: '필드',
        originalValue: '이전',
        newValue: '이후',
        createdIssue: '티켓등록',
        madeChanges: '변경사항'
      }
    },
    ticketDeploymentStatus: {
      projectId: 'CI/CD 프로젝트 코드',
      projectName: 'CI/CD 프로젝트명',
      ticketStatus: '티켓 상태',
      type: '유형',
      environment: '환경',
      buildTime: '배포시작일시',
      updatedName: '수정자',
      updatedDate: '수정일시',
      putCreator: '생성자',
      executor: '실행자',
      buildDuration: '전체 빌드 시간',
      buildType: '빌드 유형',
      deployDuration: '전체 배포 시간',
      deployType: '배포 유형',
      completedDateTime: '배포완료일시',
      buildStartDateTime: '빌드시작일시',
      buildCompletedDateTime: '빌드완료일시',
      cicdStartDateTime: ' 시작일시',
      cicdCompletedDateTime: '완료일시',
      branchTagInfor: '브랜치|태그 정보',
      buildNumber: '빌드 No.',
      deployNumber: '배포 No.',
      buildDeployNumber: '빌드/배포 No.',
      pipelineName: '파이프라인명',
      pipelineAssign: '할당여부',
      assignCICD: '할당',
      unAssignCICD: '미할당 ',
      buildDeployDuration: '전체 빌드/배포 시간',
      activation: '활성화 여부'
    },
    ticketComment: {
      newComment: '신규 댓글',
      comment: '댓글',
      internal: '내부',
      internalComment: '내부 댓글',
      deleteWarning: '댓글을 삭제하시겠습니까?',
      edited: '수정됨',
      noComment: '댓글이 없습니다.',
      guideline: '@사용자를 입력해보세요',
      userNotFound: '{{searchTerm}}에 맞는 사용자는 찾을 수 없습니다.',
      currentStatus: '현재 상태'
    },
    form: {
      filter: '필터',
      project: '프로젝트',
      application: '애플리케이션',
      version: '버전',
      ticketType: '티켓 유형',
      priority: '우선순위',
      creator: '등록자',
      serviceType: '서비스 구분          ',
      serviceDetails: '서비스 대상',
      completionDate: '목표 완료일',
      operationDate: '티켓 작업 시작일시',
      ticketTile: '티켓 제목',
      title: '제목',
      description: '설명',
      technicalSolution: '조치 내역',
      techAttachment: '조치 내역의 첨부 파일',
      assignee: '담당자',
      unassign: '미배정',
      estimation: '예상 소요시간',
      affectedVersion: '영향 받은 버전',
      issueDetected: '티켓 발생일',
      ticketID: '티켓 ID',
      summary: '제목',
      createdStartDate: '등록 시작일',
      createdEndDate: '등록 종료일',
      history: '변경 이력',
      estimated: '예상소요시간',
      remaining: '남은시간',
      logged: '등록된 소요시간',
      timeLeft: '완료까지 남은 시간',
      privateYn: '공개여부',
      user: '사용자',
      timeSpent: '작업시간',
      date: '작업일',
      workDescription: '작업내용',
      less: '접기',
      more: '펼치기',
      creationDate: '등록일자',
      updateDate: '수정일자',
      logWork: '작업내용 이력',
      moreTechSol: '조치 내역 추가',
      projectCicd: 'CI/CD 프로젝트',
      approvedRejectedPerson: '승인/반려자',
      approvedRejectedDate: '승인/반려 일시',
      approvalOpinion: '승인/반려 의견',
      withdrawDate: '철회일자',
      approveRejectPerson: '승인/반려자',
      integrateSolution: '통합 프로젝트',
      deploymentSchedule: '배포일정',
      other: '기타',
      linkedTicket: '연관된 티켓',
      downloadServiceTarget: '서비스 대상 양식 다운로드',
      public: '공개',
      private: '비공개',
      privateTicket: '비공개',
      regularGroupPipeline: '정기분 그룹 (파이프라인)'
    },
    succeedMess: {
      create: '신규 티켓이 추가되었습니다.',
      edit: ' 티켓 상세 정보가 변경되었습니다.',
      logWork: '티켓 작업내용이 등록되었습니다.',
      approvalTicket: '티켓이 정상적으로 승인되었습니다.',
      rejectTicket: '티켓이 반려되었습니다.',
      createApprove: '승인 요청 티켓이 성공적으로 작성되었습니다.',
      checkRegularGroup: '정기분 그룹은 이용 가능합니다.',
      successUploadFile: '신규 티켓 {{totalTicket}} 이 생성되었습니다.'
    },
    errorMess: {
      dsbStatusForBuild: '빌드/배포 진행하여 상태를 변경하십시오.',
      periodFrom: '시작일은 종료일 보다 작아야합니다 하며 최대 범위는 30일입니다.',
      periodTo: '기간은 시작 기간보다 이후여야 하며 최대 범위는 30일입니다.',
      createEndDate: '생성 종료일은 생성 시작일보다 이후여야 하며 최대 범위는 30일입니다.',
      overlapCicdProject:
        'CICD 프로젝트({{projects}})에 대한 처리 티켓이 있습니다. 새 티켓을 만들려면 이전 티켓({{tickets}})을 닫으십시오.',
      cantChangeFromCloseDone: '완료로 변경한 후에는 티켓 상태를 변경할 수 없습니다.',
      validCSVFile: '2MB를 초과하지 않는 *.CSV 파일 형식만 업로드 해주세요.',
      warningHistoryChange: '이미 수정된 정보가 있습니다. 티켓 상세정보를 재조회하시겠습니까?'
    },
    hint: {
      estimated: '예상 해결시간 (입력)',
      remaining: '예상 해결시간과 실제 작업시간 간의 차이 (자동 계산)',
      logged: '실제 작업 시간 (입력)',
      timeLeft: '목표완료일과 현재시간 간의 차이 (자동 계산)',
      estimation: '1mo, 1w, 1d, 1h와 같은 형식으로 입력하세요.',
      affectedVersion: '결함이나 이슈가 발견된 버전.',
      validateCompletionDate: '목표완료일은 생성일과 동일하거나 더 커야 합니다.',
      selectProjectFirst: '프로젝트를 선택한 다음에 배정자를 선택하세요.',
      maxsimunFileCSV: '최대 업로드 크기는 2MB입니다.',
      csvMessage: 'CSV 파일 형식으로 데이터를 제공해야 합니다.'
    },
    ticketList: '티켓 목록',
    messageDefaultSearch: `티켓 목록 상태 조회시에는, "취소", "완료"는 제외되고 default로 조회됩니다.`,
    logwork: {
      title:
        '<bold>{{createdBy}}</bold>님이 {{date}}<bold> {{timeSpent}}</bold> 작업내용을 등록하였습니다.',
      warning: '해당 기록을 삭제하시겠습니까?',
      delete: '작업내용이 삭제되었습니다.',
      update: '작업내용이 수정되었습니다',
      noWork: '등록된 작업내용이 없습니다.',
      dateValidation: '미래 날짜를 입력하면 안 됩니다.',
      changeStatus: '변경 상태'
    },
    ticketRelates: {
      title: '연관된 티켓',
      linkButton: '연결',
      registerButton: '등록',
      warning: '연관된 티켓을 삭제하시겠습니까?',
      submitWarning: '연결할 티켓을 하나 이상 선택해야 합니다.',
      linkToPost: '게시글에 티켓을 연결합니다.',
      succeedMess: {
        add: '티켓이 성공적으로 연결되었습니다.',
        delete: '연결된 티켓이 해제되었습니다.'
      },
      form: {
        searchBy: '검색',
        ticketIdTitle: ' 티켓ID/티켓 제목'
      }
    },
    approveTicket: {
      approvalRejectOpinion: '승인/반려 의견',
      approvalOpinion: '승인 의견',
      approveOpinion: '승인 의견',
      rejectOpinion: '반려 의견'
    },
    waringTicketType: '티켓 유형을 변경하면 티켓 상태가 초기화되겠습니다. 변경하시겠습니까?',
    warningChangeProject:
      '프로젝트를 변경하면 티켓 상태가 초기화됩니다. 계속해서 진행하시겠습니까?',
    createOption: {
      importFile: '티켓 가져오기',
      downloadTemplate: '템플릿 다운로드'
    }
  },
  knowledgeManagement: {
    tabs: {
      section: {
        title: '섹션',
        sectionList: '섹션 목록',
        create: '신규 섹션 등록',
        edit: '섹션 정보 수정',
        confirmDelete: '해당 섹션을 삭제하시겠습니까?',
        succeedMess: {
          create: '신규 섹션이 등록되었습니다.',
          edit: '섹션 상세 정보가 변경되었습니다.',
          delete: '해당 섹션이 삭제되었습니다.'
        }
      },
      category: {
        title: '카테고리',
        categoryList: '카테고리 목록',
        create: '신규 카테고리 등록',
        edit: '카테고리 정보 수정',
        confirmDelete: '해당 카테고리를 삭제하시겠습니까?',
        succeedMess: {
          create: '신규 카테고리가 등록되었습니다.',
          edit: '카테고리 상세 정보가 변경되었습니다.',
          delete: '해당 카테고리가 삭제되었습니다.'
        }
      },
      document: {
        title: '산출물',
        headerTitle: '산출물 관리',
        documentList: '산출물 목록',
        create: '신규 산출물 등록',
        edit: '산출물 정보 수정',
        confirmDelete: '해당 산출물을 삭제하시겠습니까?',
        succeedMess: {
          create: '신규 산출물이 등록되었습니다.',
          edit: '산출물 상세 정보가 변경되었습니다.',
          delete: '해당 산출물이 삭제되었습니다.'
        }
      },
      question: {
        title: 'FAQ',
        headerTitle: 'FAQ 관리',
        faqList: '질문 목록',
        create: '신규 질문 등록',
        edit: '질문 정보 수정',
        confirmDelete: '해당 FAQ를 삭제하시겠습니까?',
        succeedMess: {
          create: '신규 FAQ가 등록되었습니다.',
          edit: 'FAQ 상세 정보가 변경되었습니다.',
          delete: '해당 FAQ가 삭제되었습니다.'
        }
      }
    },
    form: {
      sectionID: '섹션ID',
      sectionName: '섹션명',
      order: '순서',
      project: '프로젝트',
      publish: '게시',
      language: '언어',
      primaryLanguage: '기본 언어',
      supportedLanguages: '지원된 언어',
      additionalLanguages: '언어 추가',
      categoryID: '카테고리ID',
      categoryName: '카테고리명',
      description: '설명',
      answer: '답변',
      section: '섹션',
      category: '카테고리',
      document: '산출물명',
      documentID: '산출물ID',
      documentName: '산출물명',
      questionID: '질문ID',
      questionName: '질문명',
      attachments: '첨부파일',
      author: '작성자',
      all: '전체'
    },
    addTranslation: '번역 추가',
    editTranslation: '번역 추가',
    viewMoreItems: '자세한 내용은 여기를 클릭하십시오.',
    succeedMess: {
      languageRegistered: '{{language}} 번역본이 등록되었습니다.',
      languageUpdated: '{{language}} 번역본이 수정되었습니다.',
      languageDeleted: '{{language}} 번역본이 삭제되었습니다.'
    }
  },
  viewAnnouncement: {
    title: '공지사항 조회',
    searchForm: {
      title: '필터',
      urgent: '긴급'
    },
    loading: '로딩 중...',
    viewCount: '{{ viewCount }} 조회 수'
  },
  announcementManagement: {
    title: '공지사항 관리',
    create: '신규 공지사항 등록',
    edit: '공지사항 정보 수정',
    confirmDelete: '해당  공지사항을 삭제하시겠습니까?',
    searchForm: {
      publishDate: {
        last30days: '최근 30일',
        last7days: '최근 7일',
        last3days: '최근 3일'
      }
    },
    table: {
      column: {
        announcementId: '공지사항 번호',
        title: '제목',
        project: '프로젝트',
        announcementType: '공지사항 유형',
        status: '상태',
        urgent: '긴급',
        attachments: '파일 첨부',
        publish: '게시',
        publishDate: '게시일',
        creator: '등록자',
        modifiedBy: '수정자',
        modifiedDate: '수정일시'
      }
    },
    succeedMess: {
      create: '신규 공지사항이 추가되었습니다.',
      edit: '공지사항 상세 정보가 변경되었습니다.',
      delete: '해당 공지사항이 삭제되었습니다.'
    },
    errorMess: {
      validateLengthProjects: '최소 1개 이상의 프로젝트가 지정되어야 합니다.'
    },
    announcementList: '공지사항 목록'
  },
  viewDocument: {
    title: '매뉴얼 조회',
    viewMore: '더 보기',
    banner: {
      mainText: '도움이 필요하면',
      subText: '매뉴얼을 참조하세요'
    },
    tabs: {
      all: '전체'
    },
    button: {
      back: '돌아가기'
    }
  },
  viewFAQ: {
    title: 'FAQ 조회',
    banner: {
      mainText: '어떻게 ',
      subText: '도와드릴까요?'
    }
  },
  scheduleManagement: {
    title: '일정 관리',
    createEvent: '이벤트 등록',
    editEvent: '이벤트 정보 수정',
    noEvent: '등록된 일정이 없습니다.',
    editRecurringEvents: '반복 이벤트 수정',
    deleteRecurringEvents: '반복 이벤트 삭제',
    form: {
      public: '공용',
      personal: '개인',
      project: '프로젝트',
      title: '제목',
      description: '내용',
      location: '위치',
      allDay: '하루 종일',
      startDate: '시작일',
      startTime: '시작시간',
      endDate: '종료일',
      endTime: '종료시간',
      repeat: '반복',
      every: '반복\n주기',
      for: '종료일',
      occurrences: ' 번 이후',
      recurrencePattern: '반복 주기',
      startingFrom: '{{start}}부터',
      creator: '등록자'
    },
    confirmDelete: '해당 이벤트를 삭제하시겠습니까?',
    succeedMess: {
      create: '신규 이벤트가 등록되었습니다.',
      edit: '이벤트가 수정되었습니다.',
      delete: '이벤트가 삭제되었습니다.'
    },
    errorMess: {
      compareDate: '시작일은 종료일 보다 작아야합니다.',
      compareTime: '종료시간은 시작시간 보다 크거나 같아야합니다.',
      timeType: 'HH:mm 형식으로 입력하세요.',
      overlappingOccurrences: '중복 일정을 등록할 수 없습니다. 반복 주기를 다시 확인해주십시오.'
    },
    allDay: '종일',
    today: '오늘',
    calendarViewMonth: '월간',
    week: '주',
    calendarViewAgenda: '주간',
    updatePattern: {
      title: '반복 패턴 수정',
      content: '반복 패턴을 수정하였습니다. 신규 패턴이 전체 일정에 적용될 것입니다.'
    },
    updateOriginalEvent: {
      title: '반복 시리즈 수정',
      content: '반복 시리즈의 원본 이벤트 수정 시 전체 일정에 적용될 것입니다.'
    },
    deleteOriginalEvent: {
      title: '반복 시리즈 삭제',
      content: '반복 시리즈의 원본 이벤트 수정 시 전체 일정에 적용될 것입니다.'
    }
  },
  internalSharingBoard: {
    title: '게시판',
    form: {
      title: '제목',
      project: '프로젝트',
      type: '유형',
      organization: '조직',
      attachments: '파일 첨부',
      period: '게시기간',
      periodFrom: '기간 시작일',
      periodTo: '기간 종료일',
      creator: '등록자',
      creationDate: '등록일시',
      modifiedBy: '수정자',
      modifiedDate: '수정일시',
      description: '내용'
    },
    internalSharingList: '게시글 목록',
    confirmDelete: '해당  게시글을 삭제하시겠습니까?',
    succeedMess: {
      create: '신규 게시글이 등록되었습니다.',
      edit: '게시글이 수정되었습니다.',
      delete: '해당 게시글이 삭제되었습니다.'
    },
    error: {
      requiredPeriodFrom: '올바른 형식의 기간 시작일을 입력해주세요',
      requiredPeriodTo: '올바른 형식의 기간 종료일을 입력해주세요',
      compareTime: '시작일은 종료일 보다 작아야합니다.'
    },
    create: '게시글 등록',
    edit: '게시글 수정'
  },
  reportingManagement: {
    title: '보고서',
    reportGroup: {
      ticketCreated: '티켓 등록 현황',
      ticketClosed: '티켓 처리완료 현황',
      ticketCreatedClosed: '티켓 등록 대비 처리완료 현황',
      averageFirstResponseTime: '티켓 평균 응답시간',
      averageResolutionTime: '티켓 평균 처리완료시간',
      agentActivity: '티켓 업무 처리 현황',
      FAQRegistered: 'FAQ 등록 현황',
      documentRegistered: '산출물 등록 현황'
    },
    by: '에 의해',
    total: '전체',
    created: '등록',
    resolved: '해결',
    filterBy: '필터 조건',
    categories: '카테고리 건수',
    totalDocuments: '산출물 건수',
    period: '기간',
    errorMsg: {
      periodExceed30days: '최대 30일까지 조회 가능합니다. ',
      noReportCreated: '생성된 보고서 화면이 없습니다. 시스템 관리자에게 문의하세요.'
    },
    table: {
      projectId: '프로젝트 번호',
      projectName: '프로젝트명',
      createDate: '등록일시',
      applicationId: '애플리케이션ID',
      applicationName: '애플리케이션명',
      version: '버전 수',
      priority: '우선순위',
      ticketType: '티켓 유형',
      serviceType: '서비스 구분',
      serviceDetail: '서비스 대상',
      ticket: '티켓'
    }
  },
  ticketCreated: {
    title: '티켓 등록 현황',
    downloadTooltip: '엑셀 다운로드',
    table: {
      title: '티켓 등록 현황',
      projectId: '프로젝트 번호',
      projectName: '프로젝트명'
    }
  },
  ticketClosed: {
    title: '티켓 처리완료 현황',
    downloadTooltip: '엑셀 다운로드',
    table: {
      title: '티켓 처리완료 현황',
      projectId: '프로젝트 번호',
      projectName: '프로젝트명'
    }
  },
  ticketCreatedClosed: {
    title: '티켓 등록 대비 처리완료 현황',
    downloadTooltip: '엑셀 다운로드',
    table: {
      title: '티켓 등록 대비 처리완료 현황',
      projectId: '프로젝트 번호',
      projectName: '프로젝트명',
      ticketStatus: '티켓 상태'
    }
  },
  totalTickets: '총 티켓 수',
  averageFirstResponseTime: {
    title: '티켓 평균 응답시간',
    ticketFirstResponse: '최초 응답 현황',
    firstResponseTimeByTicketType: '티켓 유형 별 평균 최초 응답 시간',
    newTicketNoResponse: '無응답 티켓 수',
    firstResponseTimeByPriority: '티켓 우선순위 별 평균 최초 응답 시간',
    downloadTooltip: 'pdf 다운로드'
  },
  averageResolutionTime: {
    title: '티켓 평균 처리완료시간',
    ARTByPriorityTitle: '티켓 우선순위 별 평균 처리완료시간',
    ARTByTicketTypeTitle: '티켓 유형 별 평균 처리완료시간',
    ticketResolvedTitle: '티켓 처리완료 현황',
    downloadTooltip: 'pdf 다운로드'
  },
  documentRegistered: {
    title: '산출물 등록 현황',
    categoriesRegistered: '섹션별 등록된 카테고리 수',
    documentRegistered: '카테고리별 등록된 산출물 수',
    downloadTooltip: 'pdf 다운로드'
  },
  FAQRegistered: {
    title: 'FAQ 등록 현황',
    categoriesRegistered: '섹션별 등록된 카테고리 수',
    downloadTooltip: 'pdf 다운로드'
  },
  agentActivity: {
    title: '티켓 업무 처리 현황',
    chartTitle: '티켓 업무처리 활동',
    downloadTooltip: 'pdf 다운로드',
    types: {
      assigned: '최다 티켓 처리활동 수',
      created: '최다 티켓 등록활동 수',
      internal: '최다 내부 댓글활동 수',
      public: '최다 외부 댓글활동 수'
    },
    table: {
      title: '티켓 처리 담당자 순위',
      username: '사용자명',
      name: '이름',
      assigned: '티켓처리 활동',
      created: '티켓등록 활동',
      internal_respond: '내부댓글 활동',
      public_respond: '외부댓글 활동',
      top10: 'Top 10',
      top20: 'Top 20'
    }
  },
  internalTechnicalPost: {
    title: '정보/기술 공유 게시판',
    postList: '게시글 목록',
    form: {
      keyword: '키워드',
      title: '제목',
      largeCategory: '대분류',
      middleCategory: '중분류',
      smallCategory: '소분류',
      attachments: '첨부파일',
      description: '내용',
      createdDate: '등록일시',
      creator: '등록자',
      updateDate: '수정일시'
    },
    confirmDelete: '해당 게시글을 삭제하시겠습니까?',
    succeedMess: {
      create: '신규 게시글이 등록되었습니다.',
      edit: '게시글이 수정되었습니다.',
      delete: '해당 게시글이 삭제되었습니다.'
    },
    create: '신규 게시글 등록',
    edit: ' 게시글 수정',
    noMatchTicketLink: '연관된 티켓 정보가 없습니다. 다시 시도해 주세요.'
  },
  internalTechnicalPostDetail: {
    sharingBoard: '작성일자',
    details: '상세',
    ticketLink: '연관된 티켓'
  }
};
