import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Typography, Stack, Box } from '@mui/material';
import { MFormControlSelect, MMenuItem } from '@material-extend';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState, useMemo } from 'react';

import LoadingScreen from 'components/LoadingScreen';
import MTree, { TreeItemStyled } from 'components/@material-extend/MTree';
import useLocales from 'hooks/useLocales';
import { ACTIVE_YN } from 'constants/commonCodes';
import LabeledCheckbox from 'components/LabeledCheckbox';
import { useAlert } from 'hooks/useAlert';

import { NotFoundData, WrapperListStatusList } from '../styles';
import { emailActions, emailConfigSliceName } from './slices';
import { OptionConfig, ProjectItemType, StatusTicetType } from './types';
import {
  checkIndeterminateNode,
  checkIsCheckAllData,
  // checkIndeterminateNode,
  checkStatusTreeData,
  findNodeCallback,
  getListEmailKakaoSubmit,
  updateSendEmailKakaoStatus
} from './helper';
import { GroupCheckBoxTicketStatus, WrapperItemTicketStatus } from './styles';
import { SEND_EMAIL } from './contants';

const ListStatusByTicketType = ({ isShow }: any, ref: React.Ref<unknown>) => {
  const { t, currentLang } = useLocales();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const treeData = useSelector((state) => get(state, [emailConfigSliceName, 'treeStatus']));
  const listProject = useSelector((state) => get(state, [emailConfigSliceName, 'listProject'], []));
  const loadingFetchStatus = useSelector((state) =>
    get(state, [emailConfigSliceName, 'loadingFetchStatus'], false)
  );

  const [listNodeExpand, setListNodeExpend] = useState<Array<string>>([]);

  const [treeStatus, setTreeStatus] = useState<StatusTicetType | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentProject, setCurrentProject] = useState('');

  const [, setCheckAll] = useState({
    sendKakaoYn: false,
    sendMailYn: false
  });

  const submitListStatus = () => {
    if (treeStatus) {
      const listStatusSubmit = getListEmailKakaoSubmit(treeStatus, currentProject);
      dispatch(emailActions.addListStatus(listStatusSubmit));
    }
  };

  useImperativeHandle(ref, () => ({
    submitListStatus
  }));

  const handelSelectProject = (value: string) => {
    if (isUpdate) {
      showAlert({
        message: 'common.form.changeWarning',
        onYes: () => {
          setCurrentProject(value);
        }
      });
    } else {
      setCurrentProject(value);
    }
  };

  const handelChangeNodeStatus = (e: any, nodeSelect: StatusTicetType, option: OptionConfig) => {
    e.stopPropagation();
    e.preventDefault();
    if (treeStatus) {
      const cloneTreeStatus = cloneDeep(treeStatus);

      findNodeCallback(cloneTreeStatus, nodeSelect, (node: StatusTicetType) => {
        const newValue = node[option] === ACTIVE_YN.No ? ACTIVE_YN.Yes : ACTIVE_YN.No;
        updateSendEmailKakaoStatus(node, newValue, option);
      });

      checkStatusTreeData(cloneTreeStatus);
      const { sendKakaoYn, sendMailYn } = checkIsCheckAllData(cloneTreeStatus);
      setCheckAll({
        sendKakaoYn,
        sendMailYn
      });
      setIsUpdate(true);
      setTreeStatus(cloneTreeStatus);
    }
  };

  const handleNodeToggle = useCallback(
    (event: React.SyntheticEvent, nodeIds: Array<string>) => {
      const code = get(treeStatus, 'code', '');
      const getKeyParent = [...nodeIds, code].find((v) => v.includes(code));
      if (getKeyParent) {
        setListNodeExpend(nodeIds);
      } else {
        setListNodeExpend([]);
      }
    },
    [setListNodeExpend, treeStatus]
  );

  useEffect(() => {
    if (treeData) {
      const { sendKakaoYn, sendMailYn } = checkIsCheckAllData(treeData);
      setCheckAll({
        sendKakaoYn,
        sendMailYn
      });
      setTreeStatus(treeData);
    }
  }, [treeData]);

  useEffect(() => {
    if (listProject.length) {
      const firstProjectCode = get(listProject, [0, 'projCode']);
      setCurrentProject(firstProjectCode);
    }
  }, [listProject]);

  useEffect(() => {
    if (currentProject) {
      dispatch(
        emailActions.fetchstatusByProject({
          projCode: currentProject
        })
      );
    }
  }, [currentProject, dispatch]);

  // render
  const renderTreeItem = (
    nodes: StatusTicetType | null,
    parentNodes?: StatusTicetType
  ): React.ReactNode => {
    if (!nodes) return null;
    const code = get(treeStatus, 'code', '');

    if (nodes.code === code && nodes?.subCodeList?.length) {
      return nodes?.subCodeList.map((node: any) => renderTreeItem(node, nodes));
    }
    const { indeterminateEmail } = checkIndeterminateNode(nodes);
    return (
      <>
        <TreeItemStyled
          nodeId={nodes.code}
          key={nodes.code}
          label={
            <WrapperItemTicketStatus>
              <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                {get(nodes.name, [currentLang.value])}
              </Typography>
              <GroupCheckBoxTicketStatus>
                <LabeledCheckbox
                  checked={nodes.sendMailYn === ACTIVE_YN.Yes}
                  style={{ marginRight: 8 }}
                  onClick={(event) => handelChangeNodeStatus(event, nodes, SEND_EMAIL)}
                  indeterminate={indeterminateEmail}
                />
              </GroupCheckBoxTicketStatus>
            </WrapperItemTicketStatus>
          }
        >
          {nodes?.subCodeList?.length
            ? nodes?.subCodeList.map((node: StatusTicetType) => renderTreeItem(node, nodes))
            : null}
        </TreeItemStyled>
      </>
    );
  };

  const isAnyItemNotCheck = useMemo(() => {
    const findItemNotSendMail = (obj: any) => {
      if (obj?.sendMailYn === 'N') {
        return true;
      }

      if (Array.isArray(obj?.subCodeList)) {
        return obj.subCodeList.some(findItemNotSendMail);
      }

      return false;
    };
    return findItemNotSendMail(treeStatus);
  }, [treeStatus]);

  const handleSelectAll = () => {
    if (treeStatus) {
      const cloneTree = cloneDeep(treeStatus);
      const sendMailYn = isAnyItemNotCheck ? ACTIVE_YN.Yes : ACTIVE_YN.No;
      const recursive = (ob: any) => {
        if (ob?.sendMailYn) ob.sendMailYn = sendMailYn;
        if (ob?.subCodeList?.length) {
          for (const val of ob.subCodeList) {
            recursive(val);
          }
        }
      };
      recursive(cloneTree);
      setIsUpdate(true);
      setTreeStatus(cloneTree);
    }
  };

  return (
    <>
      <Stack sx={{ pt: 1, pr: 1, pl: 2.5 }}>
        <Typography sx={{ mt: 1, mb: 1 }}>{t('dashboard.project')}</Typography>

        <MFormControlSelect
          name="projectMember"
          value={currentProject}
          onChange={({ target }) => handelSelectProject(target.value)}
        >
          {listProject.map((item: ProjectItemType) => (
            <MMenuItem key={item.projCode} value={item.projCode}>
              {item.projName}
            </MMenuItem>
          ))}
        </MFormControlSelect>
        <WrapperItemTicketStatus>
          <Typography sx={{ mt: 1, mb: 1 }}>
            {t('helpDesk.ticketManagement.ticketDeploymentStatus.ticketStatus')}
          </Typography>
          <Box
            sx={{ width: 'max-content' }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
              {t('dashboard.emailSetting.checkOrRemoveAll')}
            </Typography>
            <LabeledCheckbox checked={!isAnyItemNotCheck} onClick={handleSelectAll} />
          </Box>
        </WrapperItemTicketStatus>
      </Stack>
      <Stack sx={{ pr: 1, pl: 2.5 }}>
        <WrapperListStatusList>
          {loadingFetchStatus ? (
            <LoadingScreen />
          ) : (
            <>
              {!isEmpty(treeStatus) ? (
                <>
                  <MTree
                    expands={listNodeExpand}
                    renderTreeItem={renderTreeItem(treeStatus, undefined)}
                    onNodeToggle={handleNodeToggle}
                    showEndIcon={false}
                  />
                </>
              ) : (
                <NotFoundData>
                  <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                    {t('common.notFoundData')}{' '}
                    {t('helpDesk.ticketManagement.hint.selectProjectFirst')}
                  </Typography>
                </NotFoundData>
              )}
            </>
          )}
        </WrapperListStatusList>
      </Stack>
    </>
  );
};

export default forwardRef(ListStatusByTicketType);
