import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

import { isFile, isFileArray } from 'utils/helpers';

import BaseModel from './BaseModel';

// TODO: Update using @Type
class MultipartModel extends BaseModel {
  // Override `toPlain` method
  static toPlain(data: any, options = {}) {
    // Exclude any File property
    const excluded = Object.keys(data).reduce((obj: object, key: string) => {
      if (isFile(data[key]) || isFileArray(data[key])) {
        return { ...obj, [key]: data[key] };
      }
      return obj;
    }, {});

    // To Plain `included` object in normal way
    const included = omitBy(data, (item) => isFile(item) || isFileArray(item));
    const plained = super.toPlain(included, options);

    return omitBy({ ...plained, ...excluded }, isUndefined);
  }
}

export default MultipartModel;
