// material

import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function CalendarBannerIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="140"
        height="104"
        viewBox="0 0 140 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 57.3734C0 69.2347 4.35907 80.0433 11.5322 88.2121C19.7614 97.5864 31.6946 103.48 44.9962 103.5C50.8095 103.508 56.3681 102.388 61.4739 100.342C64.1139 99.2843 67.0687 99.4057 69.6255 100.659C72.6248 102.128 75.9833 102.951 79.5297 102.951C80.9947 102.951 82.4293 102.81 83.8171 102.541C87.7782 101.777 91.3829 99.9689 94.3432 97.4081C96.2036 95.7981 98.5864 94.9889 101.022 94.9917H101.063C109.11 94.9917 116.584 92.4933 122.79 88.2121C128.338 84.3896 132.868 79.1434 135.887 72.9838C138.518 67.6162 140 61.5527 140 55.1352C140 33.1245 122.566 15.2786 101.063 15.2786C98.8709 15.2786 96.7232 15.4681 94.628 15.8218C89.0801 6.62852 79.1453 0.5 67.812 0.5C63.0272 0.5 58.4909 1.59228 54.4304 3.5503C50.5689 5.40633 47.1356 8.04368 44.327 11.2578C34.7904 11.4134 25.9723 14.6024 18.755 19.9245C7.39417 28.2972 0 41.9524 0 57.3734Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <g clipPath="url(#clip0)">
          <g opacity="0.24">
            <g opacity="0.24">
              <g opacity="0.24">
                <path
                  opacity="0.24"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 57.3734C0 69.2347 4.35907 80.0433 11.5322 88.212C19.7614 97.5863 31.6946 103.48 44.9962 103.5C50.8095 103.508 56.3681 102.388 61.4739 100.342C64.1139 99.2842 67.0687 99.4057 69.6255 100.659C72.6248 102.128 75.9833 102.951 79.5297 102.951C80.9947 102.951 82.4293 102.81 83.817 102.541C87.7782 101.777 91.3829 99.9689 94.3432 97.4081C96.2036 95.7981 98.5864 94.9888 101.022 94.9917H101.063C109.11 94.9917 116.584 92.4933 122.79 88.212C128.338 84.3895 132.868 79.1434 135.887 72.9838C138.518 67.6162 140 61.5526 140 55.1351C140 33.1244 122.566 15.2786 101.063 15.2786C98.8709 15.2786 96.7232 15.4681 94.628 15.8218C89.0801 6.62852 79.1453 0.5 67.812 0.5C63.0272 0.5 58.4909 1.59228 54.4304 3.55029C50.5689 5.40633 47.1356 8.04368 44.327 11.2578C34.7904 11.4134 25.9723 14.6024 18.755 19.9245C7.39417 28.2972 0 41.9524 0 57.3734Z"
                  fill="url(#paint1_linear)"
                />
              </g>
            </g>
          </g>
          <path
            opacity="0.545118"
            d="M79.312 22.141C78.5769 22.141 77.842 21.8671 77.2827 21.3192C76.1636 20.2232 76.1636 18.4401 77.2827 17.3443C78.4012 16.2487 80.2223 16.2483 81.3412 17.3443C82.4603 18.4403 82.4603 20.2234 81.3412 21.3192C80.7819 21.8671 80.0469 22.141 79.312 22.141ZM79.312 18.4598C79.084 18.4598 78.8558 18.5447 78.682 18.7149C78.3349 19.0549 78.3349 19.6083 78.682 19.9485C79.0296 20.2885 79.5941 20.2887 79.9417 19.9485C80.2888 19.6085 80.2888 19.0551 79.9417 18.7149C79.7681 18.5449 79.5399 18.4598 79.312 18.4598Z"
            fill="#C4CDD5"
          />
          <path
            opacity="0.545118"
            d="M56.7505 87.7641C56.0156 87.7641 55.2805 87.49 54.7211 86.9422C53.6021 85.8462 53.6021 84.0631 54.7211 82.9673C55.8398 81.8716 57.6612 81.8716 58.7799 82.9673C59.8989 84.0633 59.8989 85.8464 58.7799 86.9422C58.2205 87.4902 57.4854 87.7641 56.7505 87.7641ZM56.7505 84.0829C56.5225 84.0829 56.2943 84.1678 56.1205 84.338C55.7733 84.678 55.7733 85.2314 56.1205 85.5716C56.4681 85.9118 57.0328 85.9118 57.3803 85.5716C57.7275 85.2316 57.7275 84.6782 57.3803 84.338C57.2067 84.168 56.9785 84.0829 56.7505 84.0829Z"
            fill="#C4CDD5"
          />
          <path
            d="M106.927 74.5263C106.674 74.5263 106.42 74.4317 106.227 74.2425L105.047 73.0864L103.866 74.2425C103.48 74.6209 102.853 74.6209 102.467 74.2425C102.08 73.8639 102.08 73.2502 102.467 72.8718L104.347 71.0304C104.733 70.652 105.36 70.652 105.746 71.0304L107.627 72.8718C108.013 73.2504 108.013 73.8641 107.627 74.2425C107.434 74.4315 107.18 74.5263 106.927 74.5263Z"
            fill="#DFE3E8"
          />
          <path
            d="M37.1252 70.8339C36.8721 70.8339 36.6185 70.7393 36.4256 70.5501L35.2449 69.394L34.0643 70.5501C33.6781 70.9285 33.0511 70.9285 32.6649 70.5501C32.2784 70.1715 32.2784 69.5578 32.6649 69.1794L34.5452 67.338C34.9314 66.9596 35.5584 66.9596 35.9446 67.338L37.8249 69.1794C38.2115 69.558 38.2115 70.1717 37.8249 70.5501C37.6317 70.7391 37.3784 70.8339 37.1252 70.8339Z"
            fill="#D5DBE0"
          />
          <g filter="url(#filter0_d)">
            <mask
              id="mask0"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="41"
              y="29"
              width="59"
              height="47"
            >
              <path
                d="M95.2649 29.0444H45.4551C43.2282 29.0444 41.4229 30.8154 41.4229 32.9999V71.2227C41.4229 73.4073 43.2282 75.1782 45.4551 75.1782H95.2649C97.4918 75.1782 99.2971 73.4073 99.2971 71.2227V32.9999C99.2971 30.8154 97.4918 29.0444 95.2649 29.0444Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0)">
              <path
                d="M95.2649 29.0444H45.4551C43.2282 29.0444 41.4229 30.8154 41.4229 32.9999V71.2227C41.4229 73.4073 43.2282 75.1782 45.4551 75.1782H95.2649C97.4918 75.1782 99.2971 73.4073 99.2971 71.2227V32.9999C99.2971 30.8154 97.4918 29.0444 95.2649 29.0444Z"
                fill="#DFE3E8"
                stroke="#919EAB"
                strokeOpacity="0.48"
                strokeWidth="1.72811"
              />
              <path
                opacity="0.32"
                d="M97.6016 29.0444C98.5512 29.0444 99.2971 29.6243 99.2971 30.3625V42.2255H41.4229V30.3625C41.4229 29.6243 42.1688 29.0444 43.1184 29.0444H97.6016ZM50.1713 33.9873C49.229 33.9873 48.4889 34.7122 48.4889 35.635C48.4889 36.5575 49.229 37.2826 50.1713 37.2826C51.1135 37.2826 51.8537 36.5575 51.8537 35.635C51.8537 34.7122 51.1135 33.9873 50.1713 33.9873ZM56.9009 33.9873C55.9586 33.9873 55.2185 34.7122 55.2185 35.635C55.2185 36.5575 55.9586 37.2826 56.9009 37.2826C57.8431 37.2826 58.5832 36.5575 58.5832 35.635C58.5832 34.7122 57.8431 33.9873 56.9009 33.9873ZM63.6304 33.9873C62.6882 33.9873 61.948 34.7122 61.948 35.635C61.948 36.5575 62.6882 37.2826 63.6304 37.2826C64.5727 37.2826 65.3128 36.5575 65.3128 35.635C65.3128 34.7122 64.5727 33.9873 63.6304 33.9873Z"
                fill="#919EAB"
              />
              <path
                d="M90.5483 46.8389H73.7244C72.7945 46.8389 72.042 47.5758 72.042 48.4865V68.2581C72.042 69.1688 72.7945 69.9057 73.7244 69.9057H90.5483C91.4782 69.9057 92.2307 69.1688 92.2307 68.2581V48.4865C92.2307 47.5758 91.4782 46.8389 90.5483 46.8389Z"
                fill="#919EAB"
                fillOpacity="0.48"
                stroke="#919EAB"
                strokeWidth="1.38249"
                strokeDasharray="2.3 2.3"
              />
              <path
                opacity="0.72"
                d="M50.1716 50.1341H56.9012C57.8311 50.1341 58.5836 49.3972 58.5836 48.4865C58.5836 47.5758 57.8311 46.8389 56.9012 46.8389H50.1716C49.2417 46.8389 48.4893 47.5758 48.4893 48.4865C48.4893 49.3972 49.2417 50.1341 50.1716 50.1341Z"
                stroke="#919EAB"
                strokeWidth="1.38249"
                strokeDasharray="2.3 2.3"
              />
              <path
                opacity="0.72"
                d="M66.9956 53.4292H50.1716C49.2417 53.4292 48.4893 54.1661 48.4893 55.0768C48.4893 55.9875 49.2417 56.7244 50.1716 56.7244H66.9956C67.9255 56.7244 68.6779 55.9875 68.6779 55.0768C68.6779 54.1661 67.9255 53.4292 66.9956 53.4292Z"
                stroke="#919EAB"
                strokeWidth="1.38249"
                strokeDasharray="2.3 2.3"
              />
              <path
                opacity="0.72"
                d="M66.9956 60.02H50.1716C49.2417 60.02 48.4893 60.757 48.4893 61.6677C48.4893 62.5784 49.2417 63.3153 50.1716 63.3153H66.9956C67.9255 63.3153 68.6779 62.5784 68.6779 61.6677C68.6779 60.757 67.9255 60.02 66.9956 60.02Z"
                stroke="#919EAB"
                strokeWidth="1.38249"
                strokeDasharray="2.3 2.3"
              />
              <path
                opacity="0.72"
                d="M66.9956 66.6104H50.1716C49.2417 66.6104 48.4893 67.3473 48.4893 68.258C48.4893 69.1687 49.2417 69.9056 50.1716 69.9056H66.9956C67.9255 69.9056 68.6779 69.1687 68.6779 68.258C68.6779 67.3473 67.9255 66.6104 66.9956 66.6104Z"
                stroke="#919EAB"
                strokeWidth="1.38249"
                strokeDasharray="2.3 2.3"
              />
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="27.4229"
            y="15.0444"
            width="113.874"
            height="102.134"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="14" dy="14" />
            <feGaussianBlur stdDeviation="14" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.24 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="95.9029"
            y1="121.82"
            x2="145.772"
            y2="7.24148"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#919EAB" />
            <stop offset="1" stopColor="#919EAB" stopOpacity="0.01" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="27.2943"
            y1="74.4769"
            x2="85.1784"
            y2="-0.331678"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00AB55" />
            <stop offset="1" stopColor="#00AB55" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="140" height="103" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
