import { TICKET_TYPE, TICKET_TYPE_REQDEPLOY } from 'constants/common';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import toastHelper from 'helpers/toast';
import { minuteToDuration, NORMAL_DURATION_FORMATER } from 'utils/formatDuration';
import { CommonCode } from 'features/commonCode/models';
import { ACTIVE_YN } from 'constants/commonCodes';
import { CommonCodeType } from 'features/system/CommonCodeManagement/type';
import get from 'lodash/get';

import {
  STATUS_SHOW_DEPLOYMENT_SCHEDULE,
  STATUS_TICKET_NEED_APPROVE
} from '../TicketDetail/constants';
import {
  SERVICE_OPERATION,
  STATUS_APPROVE_WITHDRAW,
  TECH_SOLUTION_FIELD_LIST,
  TECT_SOLUTION_FILES,
  TECT_SOLUTION_FILES_UPLOAD
} from './constants';
import { TicketFile } from './types';

export const calcTimeLeft = (timestamp: string): string => {
  const minuteLeft = dayjs(timestamp).diff(dayjs().startOf('day')) / 60000;
  return minuteToDuration(minuteLeft, NORMAL_DURATION_FORMATER);
};

export const formatTechnicalSolution = (ticketFormData: any): any => {
  const techSolutionResult: any = {};
  const technicalGroups: Array<
    [solution: string, file: Array<any>, uploadedFile: Array<File>]
  > = [];

  // TECT_SOLUTION_FILES - > ["tech1Files", "tech2Files", "tech3Files" ...] max 5 key
  // TECH_SOLUTION_FIELD_LIST - > ["techSolution0", "techSolution1", "techSolution2",...] max 5 key
  // TECT_SOLUTION_FILES_UPLOAD - > ["t1Files", "t2Files", "t3Files",...] max 5 key
  // Data of file attachments follow techSolution fields respectively, Ex: techSolution0 -> tech1Files -> t1Files

  let idx = 0;
  TECH_SOLUTION_FIELD_LIST.forEach((key, index) => {
    if (!isEmpty(ticketFormData[key])) {
      const fileKey = TECT_SOLUTION_FILES[index];
      const fileList =
        index === idx // if index !== idx -> accumulated
          ? ticketFormData[fileKey]
          : ticketFormData[fileKey]?.map((fileItem: TicketFile) => ({
              ...fileItem,
              id: null // reset id when setting file data
            })) || [];

      const uploadedFileKey = TECT_SOLUTION_FILES_UPLOAD[index];
      technicalGroups[idx] = [ticketFormData[key], fileList, ticketFormData[uploadedFileKey]];
      idx++;
    }
  });

  TECH_SOLUTION_FIELD_LIST.forEach((key, index) => {
    techSolutionResult[key] = technicalGroups[index]?.[0] || ''; // set data content by key
    techSolutionResult[TECT_SOLUTION_FILES[index]] = technicalGroups[index]?.[1] || []; // set data  file attachment
    techSolutionResult[TECT_SOLUTION_FILES_UPLOAD[index]] = technicalGroups[index]?.[2] || [];
  });

  return techSolutionResult;
};

export const filterTechnicalSolutions = (object: any): any => {
  const techSolutions: string[] = [];

  const sortedFields = Object.keys(object)
    .filter((key) => key.includes('techSolution'))
    .sort();

  sortedFields.forEach((key: string, index: number) => {
    if (!isEmpty(object[key])) {
      techSolutions.push(object[key]);
    }
  });

  if (techSolutions.length < sortedFields.length) {
    for (let i = techSolutions.length; i < sortedFields.length; i++) {
      techSolutions[i] = '';
    }
  }

  const techSolutionObject = techSolutions.reduce(
    (prev, cur, curIndex) => ({ ...prev, [`techSolution${curIndex}`]: cur }),
    {}
  );

  return techSolutionObject;
};

export const checkTicketNeedApprove = (ticketType?: string, serviceType?: string) =>
  (serviceType === SERVICE_OPERATION && ticketType === TICKET_TYPE.Request) ||
  (ticketType === TICKET_TYPE_REQDEPLOY && serviceType === SERVICE_OPERATION);

const TICKET_NEED_APPROVE = [STATUS_TICKET_NEED_APPROVE.REJECT, STATUS_TICKET_NEED_APPROVE.WITHDRW];

const TICKET_NEED_APPROVE_ALREADY_DONE = [
  ...TICKET_NEED_APPROVE,
  STATUS_TICKET_NEED_APPROVE.APPROVE
];

export const getTicketType = (formValue: any, currentTicket: any) => {
  const { approvalDate, statusType, ticketType, serviceType, projCode } = formValue;

  const isNeedApprove = checkTicketNeedApprove(ticketType, serviceType);
  const isCurentTicketApproved = checkTicketNeedApprove(
    currentTicket?.ticketType,
    currentTicket?.serviceType
  );

  if (projCode !== currentTicket?.projCode) {
    return getStatusWhenProjectChange(ticketType, statusType, serviceType);
  }

  switch (true) {
    case !isNeedApprove && TICKET_NEED_APPROVE_ALREADY_DONE.includes(statusType): {
      return STATUS_TICKET_NEED_APPROVE.OPEN;
    }
    case isNeedApprove && !isCurentTicketApproved:
    case isNeedApprove && (!approvalDate || TICKET_NEED_APPROVE.includes(statusType)): {
      return STATUS_TICKET_NEED_APPROVE.APPROVE;
    }
    case currentTicket?.statusType === STATUS_TICKET_NEED_APPROVE.REJECT: {
      if (isNeedApprove) {
        return STATUS_TICKET_NEED_APPROVE.APPROVE;
      }
      return STATUS_TICKET_NEED_APPROVE.OPEN;
    }
    default:
      return statusType;
  }
};

export const showOverlapProjectErrorMessage = ({
  projects,
  tickets
}: {
  projects: string[];
  tickets: string[];
}) => {
  // Render special error message that has ticket link
  toastHelper.error('helpDesk.ticketManagement.errorMess.overlapCicdProject', {
    projects: projects.join(', '),
    tickets: tickets
      .map(
        (ticketCode: string) =>
          `<a class="error" target="_blank" href="/ticket-detail?ticketCode=${ticketCode}">${ticketCode}</a>`
      )
      .join(', ')
  });
};

export const isDisableFromDevelopmentStatus = (ticketType?: string, status?: string): boolean => {
  if (ticketType && status) {
    const isStausFromDevelop = STATUS_SHOW_DEPLOYMENT_SCHEDULE.includes(status);
    const isTicketHaveDeployment =
      ticketType === TICKET_TYPE.ReqDeploy || ticketType === TICKET_TYPE.Deploy;
    return isStausFromDevelop && isTicketHaveDeployment;
  }
  return false;
};

export const getStatusWhenProjectChange = (
  ticketType: string,
  currentStatus: string,
  serviceType: string
) => {
  let status = currentStatus;
  switch (ticketType) {
    case TICKET_TYPE.Deploy: {
      status = STATUS_TICKET_NEED_APPROVE.OPEN;
      break;
    }
    case TICKET_TYPE.ReqDeploy: {
      if (serviceType === SERVICE_OPERATION && STATUS_APPROVE_WITHDRAW.includes(currentStatus)) {
        status = STATUS_TICKET_NEED_APPROVE.APPROVE;
      } else {
        status = STATUS_TICKET_NEED_APPROVE.OPEN;
      }
      break;
    }
    case TICKET_TYPE.Request: {
      if (serviceType === SERVICE_OPERATION) {
        status = STATUS_TICKET_NEED_APPROVE.APPROVE;
      } else {
        status = STATUS_TICKET_NEED_APPROVE.OPEN;
      }
      break;
    }
  }
  return status;
};

export const getDefaultValueCommonCode = (listComonCode: CommonCode[]) => {
  if (listComonCode.length) {
    const defaultItem = listComonCode.find((item) => item.defaultYn === ACTIVE_YN.Yes);

    if (defaultItem) {
      return defaultItem.value;
    }
  }
  return null;
};

export const getLabelFormCommonCode = (commonCode: CommonCodeType, currentLang: string) =>
  get(commonCode, ['title', currentLang]) || '';
