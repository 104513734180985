import BaseModel from 'models/BaseModel';
import { Expose, Transform } from 'class-transformer';
import { TokenModel } from './TokenModel';

export class AuthorizedUserModel extends BaseModel {
  @Expose({ name: 'navigateCorp' })
  navigateCorp: Array<String> | undefined;

  @Expose({ name: 'navigateChangePassword' })
  navigateChangePwd: Boolean | undefined;

  @Expose({ name: 'accessToken' })
  @Transform((v) => TokenModel.toClass(v.value))
  token: any | undefined;
}
