// Auth
export const SIGN_IN_URI = '/sign-in';
export const SELECT_CORP_URI = '/select-corporation';
export const SIGN_UP_URI = '/sign-up';
export const REGISTER_URI = '/register';
export const IMPORT_LICENSE_URI = '/import-license';

// Static page
export const NOTFOUND_URI = '404';
export const FORBIDDEN_URI = '403';
export const SERVICE_UNAVAILABLE_URI = '500';
export const TIMEOUT_URI = 'timeout';
export const MAINTENANCE_URI = '/maintenance';

// Dashboard
export const DASHBOARD_URI = '/';

// System Common
export const USER_PROFILE = '/profile';
export const USER_MANANGEMENT = '/user';
export const ORGANIZATION_MANAGEMENT = '/organization';
export const CORP_MGT = '/corporation';
export const CONTRACT_MANAGEMENT = '/contract';
export const PROJECT_MANAGEMENT = '/project';
export const APPLICATION_MANAGEMENT = '/application';
export const MENU_MANAGEMENT = '/menu';
export const COMMON_CODE_MANAGEMENT = '/common-code';
export const PERMISSION_MANAGEMENT = '/permission';
export const WORKFLOW_MANAGEMENT = '/workflow';
export const CODE_API = '/code-api';
export const PROJECT_CICD_MANAGEMENT = '/mapping-projcdcid';
export const EXPOSE_API_MANAGEMENT = '/expose-api';
export const INTEGRATION_SOLUTION_MGT = '/integrate-solution';
export const LICENSE_MGT = '/license';
export const LOGIN_LOG = '/user-logs';
export const ACTION_LOG = '/action-logs';
export const USER_GUIDE_SETTING = '/user-guide-setting';
export const VERIFY_USER = '/verify-by-user';
export const CONCURRENT_USER_HISTORY = '/concurrent-user-history';
export const CHANGE_PASSWORD_URL = '/change-password';
// Helpdesk
export const TICKET_MANAGEMENT = '/ticket';
export const TICKETS_WAITING_PROGRESS = `${TICKET_MANAGEMENT}/waiting-for-progress`;
export const TICKETS_CLOSE = `${TICKET_MANAGEMENT}/closed-tickets`;
export const TICKETS_IN_PROGRESS = `${TICKET_MANAGEMENT}/in-progress-tickets`;
export const TICKETS_CREATE = `${TICKET_MANAGEMENT}/ticket-created`;
export const TICKETS_UNASSIGN = `${TICKET_MANAGEMENT}/unassigned`;
export const TICKET_DETAIL = '/ticket-detail';
export const DOCUMENT_MANAGEMENT = '/document';
export const VIEW_ANNOUNCEMENT = '/vannouncement';
export const ANNOUNCEMENT_MANAGEMENT = '/announcement';
export const FAQ_MANAGEMENT = '/faq';
export const VIEW_DOCUMENT = '/vdocument';
export const VIEW_DETAIL_DOCUMENT = '/vdocument-detail';
export const VIEW_FAQ = '/vfaq';
export const VIEW_DETAIL_FAQ = '/vfaq-detail';
export const SCHEDULE_MANAGEMENT = '/schedule';
export const INTERNAL_SHARING_BOARD = '/internal-post';
export const REPORTING_MANAGEMENT = '/reporting';
export const DYNAMIC_CONTENT_MANAGEMENT = '/dynamic-content';
export const NOTIFICATIONS = '/notifications';
export const INTERNAL_TECHNICAL_POST = '/tech-sharing';
export const INTERNAL_TECHNICAL_POST_DETAIL = '/tech-sharing-detail';

export const NOT_PRIVATE_ROUTES = [DASHBOARD_URI, USER_PROFILE, NOTIFICATIONS];
