import { Expose } from 'class-transformer';
import BaseModel from 'models/BaseModel';

export class DynamicContentModel extends BaseModel {
  @Expose({ name: 'scopeSetting' })
  scopeSetting: String | undefined;
}

export class DynamicContentDetailModel extends BaseModel {
  @Expose({ name: 'generalSettingId' })
  generalSettingId: String | undefined;

  @Expose({ name: 'scopeSetting' })
  scopeSetting: String | undefined;

  @Expose({ name: 'defaultValueEn' })
  defaultValueEn: String | undefined;

  @Expose({ name: 'defaultValueKo' })
  defaultValueKo: String | undefined;

  @Expose({ name: 'valueEn' })
  valueEn: String | undefined;

  @Expose({ name: 'valueKo' })
  valueKo: String | undefined;
}
