import { useEffect } from 'react';
import get from 'lodash/get';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'hooks/useInjector';
import {
  commonCodeSliceName,
  commonCodeActions,
  commonCodeSaga,
  initialState
} from 'features/commonCode/slices';
import pullAll from 'lodash/pullAll';

type FlgLoadType = 'ACTIVE' | 'INACTIVE' | 'ALL';

export const useFetchCommonCode = (listCommonCode: Array<any>, flgLoad?: FlgLoadType) => {
  useInjectSaga({ key: commonCodeSliceName, saga: commonCodeSaga });
  const dispatch = useDispatch();
  const resData = useSelector(
    (state) => get(state, [commonCodeSliceName, 'data'], initialState.data),
    shallowEqual
  );

  useEffect(() => {
    const fetchedCodes = Object.keys(resData);
    const unfetchedCodes = pullAll(listCommonCode, fetchedCodes);
    if (unfetchedCodes && unfetchedCodes.length > 0) {
      dispatch(
        commonCodeActions.fetchCommonCode({
          codes: unfetchedCodes,
          flgLoad
        })
      );
    }
  }, [dispatch, listCommonCode, resData, flgLoad]);

  return resData;
};
