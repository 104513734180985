import { useRef, useState, useEffect, useCallback } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, MenuItem, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  ArrowDropUpSharp as ArrowDropUpSharpIcon,
  ArrowDropDownSharp as ArrowDropDownSharpIcon
} from '@mui/icons-material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import { MButton } from 'components/@material-extend';
import MenuPopover from 'components/MenuPopover';
import useLocales from 'hooks/useLocales';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'hooks/useInjector';

import {
  concurrentUserAndHistoryActions,
  concurrentUserAndHistoryReducer,
  concurrentUserAndHistorySliceName,
  concurrentUserAndHistorySaga,
  initialState
} from 'features/system/ConcurrentUserAndHistory/slices';
import { DEFAULT_SORT_BY, PERIODS } from 'features/system/ConcurrentUserAndHistory/constants';

import { FULL_DATE_FORMAT, DEFAULT_TABLE_VALUES } from 'constants/common';

import { CONCURRENT_USER_HISTORY } from 'constants/routes';
import { getIconByType, getIcon } from 'features/notifications/helper';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const WrapperTime = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const WrapperFooter = styled(FlexBox)(({ theme }) => ({
  padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(30)}`
}));

const WrapperHeader = styled(FlexBox)(({ theme }) => ({
  padding: `${theme.typography.pxToRem(20)}`
}));

const TypographyStyled = styled(Typography)(() => ({
  cursor: 'pointer'
}));

const TypographyTimeStyled = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.grey[500]}`
}));

const TEST_ID = 'concurrentUser';

export default function ConcurrentUser() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const ccuHistoryRef = useRef(null);
  const { t } = useLocales();
  const [open, setOpen] = useState(false);
  useInjectReducer({
    key: concurrentUserAndHistorySliceName,
    reducer: concurrentUserAndHistoryReducer
  });

  useInjectSaga({ key: concurrentUserAndHistorySliceName, saga: concurrentUserAndHistorySaga });

  const listData: any = get(
    useSelector(
      (state) =>
        get(state, [concurrentUserAndHistorySliceName, 'dataPopup'], initialState.dataPopup),
      shallowEqual
    ),
    'ccuHistory',
    []
  );

  const initDataHeader = {
    createdDate: '',
    deltaCount: 0,
    deltaRate: 0,
    userCount: 0
  };
  const headData = isEmpty(listData)
    ? initDataHeader
    : {
        createdDate: dayjs(listData[0].createdDate).format('HH:mm'),
        deltaCount: listData[0].deltaCount,
        deltaRate: listData[0].deltaRate,
        userCount: listData[0].userCount
      };

  useEffect(() => {
    dispatch(
      concurrentUserAndHistoryActions.fetchConcurrentUserAndHistoryPopup({
        ...DEFAULT_TABLE_VALUES,
        sortBy: DEFAULT_SORT_BY,
        itemPerPage: 5,
        searchCriteria: {
          period: PERIODS.ALL,
          createdStartDate: '',
          createdEndDate: ''
        }
      })
    );
  }, [dispatch]);

  const onClosePopover = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handelNavigateConcurrentUserPage = useCallback(() => {
    navigate(CONCURRENT_USER_HISTORY);
    onClosePopover();
  }, [navigate, onClosePopover]);

  return (
    <>
      <MButton
        onClick={() => setOpen(true)}
        test-type={TEST_ID}
        ref={ccuHistoryRef}
        sx={{
          textTransform: 'none',
          color: '#5a6771',
          '&:hover': {
            bgcolor: '#919eab14'
          }
        }}
      >
        {t('system.concurrentUserAndHistory.popup.concurrentUser')} ({headData.createdDate}):{' '}
        {headData.userCount} {t('system.concurrentUserAndHistory.popup.user')}
        {headData.deltaCount < 0 ? (
          <ArrowDropDownSharpIcon color="error" fontSize="large" />
        ) : (
          <ArrowDropUpSharpIcon color="success" fontSize="large" />
        )}
        {headData.deltaCount} {t('system.concurrentUserAndHistory.popup.user')} (
        {headData.deltaRate < 0 ? '' : '+'}
        {headData.deltaRate}%)
      </MButton>

      <MenuPopover
        sx={{ width: 'auto' }}
        styleProps={{
          horizontal: 'left'
        }}
        open={open}
        onClose={onClosePopover}
        anchorEl={ccuHistoryRef.current}
      >
        <Box>
          <WrapperHeader>
            <Typography variant="h6">{t('system.concurrentUserAndHistory.headTitle')}</Typography>
          </WrapperHeader>

          {listData.map((item: any, index: number) => (
            <MenuItem sx={{ mb: 0.5 }} key={index}>
              <FlexBox>
                <Box sx={{ marginTop: 1 }}>{getIconByType('ASSIGN_TO_ME')}</Box>
                <Box sx={{ ml: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                    {item.userCount} {t('system.concurrentUserAndHistory.popup.user')}
                    {item.deltaCount < 0 ? (
                      <ArrowDropDownSharpIcon color="error" fontSize="large" />
                    ) : (
                      <ArrowDropUpSharpIcon color="success" fontSize="large" />
                    )}
                    {item.deltaCount} {t('system.concurrentUserAndHistory.popup.user')} (
                    {item.deltaRate < 0 ? '' : '+'}
                    {item.deltaRate}%)
                  </Box>
                  <WrapperTime>
                    {getIcon('ic_clock', 'disabled')}
                    <TypographyTimeStyled sx={{ ml: 1 }}>
                      {dayjs(item.createdDate).format(FULL_DATE_FORMAT)}
                    </TypographyTimeStyled>
                  </WrapperTime>
                </Box>
              </FlexBox>
            </MenuItem>
          ))}

          <WrapperFooter>
            <TypographyStyled
              onClick={() => handelNavigateConcurrentUserPage()}
              color="primary"
              variant="subtitle1"
            >
              {t('dashboard.notifications.seeAll')}
            </TypographyStyled>
          </WrapperFooter>
        </Box>
      </MenuPopover>
    </>
  );
}
