// material
import { withStyles } from '@mui/styles';
import { DateTimePicker, DateTimePickerProps } from '@mui/lab';

import { MInput } from 'components/@material-extend';
import { FULL_DATE_FORMAT, FULL_DATE_MASK } from 'constants/common';
import { calendarHeaderstyles } from './MDatePicker';

const StyledDateTimePicker = withStyles(calendarHeaderstyles)(DateTimePicker);
export interface MDateTimePickerProps
  extends Omit<DateTimePickerProps, 'renderInput' | 'onChange'> {
  name: string;
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  inputOptions?: any;
  helperText?: string;
  error?: boolean;
}

const MDateTimePicker = (props: MDateTimePickerProps) => {
  const {
    name,
    value,
    onChange,
    inputOptions,
    mask = FULL_DATE_MASK,
    helperText,
    error,
    ...others
  } = props;

  const handleChange = (value: any) => {
    onChange(name, value);
  };

  return (
    <StyledDateTimePicker
      value={value}
      onChange={handleChange}
      mask={mask}
      inputFormat={FULL_DATE_FORMAT}
      {...others}
      renderInput={(params) => (
        <MInput {...params} {...inputOptions} error={error} helperText={helperText} />
      )}
    />
  );
};

export default MDateTimePicker;
