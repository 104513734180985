import BaseModel from 'models/BaseModel';
import { Expose } from 'class-transformer';

export class CorpUserInfoModel extends BaseModel {
  @Expose({ name: 'activeYn' })
  activeYn: string | undefined;

  @Expose({ name: 'avatarUrl' })
  avatarUrl: string | undefined;

  @Expose({ name: 'corpCode' })
  corpCode: string | undefined;

  @Expose({ name: 'email' })
  email: string | undefined;

  @Expose({ name: 'name' })
  name: string | undefined;

  @Expose({ name: 'orgName' })
  orgName: string | undefined;

  @Expose({ name: 'roleName' })
  roleName: string | undefined;

  @Expose({ name: 'userName' })
  userName: string | undefined;

  @Expose({ name: 'isChangedPass' })
  isChangedPass: string | undefined;
}
