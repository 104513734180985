import get from 'lodash/get';
import { shallowEqual, useSelector } from 'react-redux';
import { ReactNode } from 'react';

import { authSliceName } from 'features/auth/slices';
import { ACTIVE_YN } from 'constants/commonCodes';

type TicketSettingType = {
  fieldName: string;
  children: ReactNode;
};

const TicketFieldSetting = ({ fieldName, children }: TicketSettingType) => {
  const ticketSetting = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'ticketSetting']),
    shallowEqual
  );

  return get(ticketSetting, fieldName) === ACTIVE_YN.Yes ? <>{children}</> : null;
};

export const useTicketSetting = (fieldName: string) => {
  const ticketSetting = useSelector(
    (state) => get(state, [authSliceName, 'userInfo', 'ticketSetting']),
    shallowEqual
  );
  return get(ticketSetting, fieldName) === ACTIVE_YN.Yes;
};

export default TicketFieldSetting;
