import { Expose } from 'class-transformer';

import BaseModel from 'models/BaseModel';
import { TransformDateModel } from 'utils/transformer';
import { FULL_DATE_FORMAT } from 'constants/common';

export class TicketCommitDetailModel extends BaseModel {
  @Expose({ name: 'title' })
  title: string | undefined;

  @Expose({ name: 'gitCommitId' })
  gitCommitId: string | undefined;

  @Expose({ name: 'gitCommitUrl' })
  gitCommitUrl: string | undefined;

  @Expose({ name: 'createdBy' })
  createdBy: string | undefined;

  @Expose({ name: 'fileAdded' })
  fileAdded: number | undefined;

  @Expose({ name: 'fileChanged' })
  fileChanged: number | undefined;

  @Expose({ name: 'fileRemoved' })
  fileRemoved: number | undefined;

  @Expose({ name: 'gitCommitTime' })
  @TransformDateModel(FULL_DATE_FORMAT)
  gitCommitTime?: string | undefined;

  @Expose({ name: 'id' })
  id: number | undefined;

  @Expose({ name: 'repoName' })
  repoName: string | undefined;

  @Expose({ name: 'creatorId' })
  creatorId: string | undefined;

  @Expose({ name: 'creator' })
  creator: string | undefined;

  @Expose({ name: 'addedFiles' })
  addedFiles: string | undefined;

  @Expose({ name: 'removedFiles' })
  removedFiles: string | undefined;

  @Expose({ name: 'modifiedFiles' })
  modifiedFiles: string | undefined;

  @Expose({ name: 'checked' })
  checked: boolean | undefined;
}
