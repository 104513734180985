import { experimentalStyled as styled, Theme, alpha } from '@mui/material/styles';
import { Box } from '@mui/material';

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

export const StyleSnackbarText = styled(Box)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  wordBreak: 'break-word',
  position: 'static',
  fontStyle: 'normal',
  width: 'auto',
  lineHeight: theme.spacing(2.5),
  maxWidth: theme.spacing(54),
  [theme.breakpoints.down('sm')]: {
    minWidth: theme.typography.pxToRem(210)
  },
  [theme.breakpoints.up('md')]: {
    minWidth: theme.typography.pxToRem(300)
  },
  '& .error': {
    color: theme.palette.error.main
  }
}));

export const StyleSnackbarIcon = styled(Box)(
  ({ theme, styleProps }: { theme: Theme; styleProps: { color: ColorSchema } }) => ({
    width: theme.typography.pxToRem(40),
    height: theme.typography.pxToRem(40),
    display: 'flex',
    borderRadius: theme.typography.pxToRem(12),
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette[styleProps.color].main,
    backgroundColor: alpha(theme.palette[styleProps.color].main, 0.1),
    marginRight: theme.spacing(1)
  })
);

export const StyleToastContent = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  minWidth: theme.typography.pxToRem(125),
  padding: `${theme.spacing(1.2)} 0`
}));

export const WrapperContent = styled(Box)(({ theme }) => ({
  maxHeight: theme.spacing(33),
  overflow: 'auto'
}));
