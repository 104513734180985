export const THUMBNAIL_SIZE = {
  sm: {
    width: 48,
    height: 48
  },
  md: {
    width: 64,
    height: 64
  },
  lg: {
    width: 80,
    height: 80
  }
};
