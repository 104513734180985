import { experimentalStyled as styled } from '@mui/material/styles';

export const WrapperApprover = styled('div')(({ theme }) => ({
  '& div.Mui-disabled': {
    backgroundColor: theme.palette.grey[300]
  }
}));

export const WrapperListItemFile = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  flexWrap: 'wrap',
  marginTop: theme.spacing(1)
}));

export const WrapperItemFile = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: theme.typography.pxToRem(150),
  '& .wrapperIconFile': {
    flexDirection: 'row',
    alignItems: 'center',
    border: ' 1px solid #dae0e4',
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    a: {
      background: 'none'
    },
    'a:nth-child(2)': {
      width: theme.typography.pxToRem(92)
    }
  }
}));

export const GroupButtonConfirmSave = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  flexWrap: 'wrap',
  marginTop: theme.spacing(3),

  button: {
    marginLeft: theme.spacing(2)
  }
}));

export const WrapperCicdProject = styled('div')(({ theme }) => ({
  '& .Mui-disabled input': {
    background: 'none'
  },

  maxWidth: 'max-content',
  minWidth: 'calc(97% / 3)',

  '@media (max-width: 960px)': {
    minWidth: '100%'
  }
}));
