import NewButton from 'components/@material-extend/buttons/NewButton';
import { ButtonAuth } from 'interfaces/ButtonAuth';
import AuthButton from './AuthButton';
import { NEW_BUTTON_ID } from '../../constants';

const AuthNewButton = (props: ButtonAuth) => (
  <AuthButton btnId={NEW_BUTTON_ID}>
    <NewButton {...props} />
  </AuthButton>
);

export default AuthNewButton;
