export const signUp = {
  title: {
    signUp: '가입하기',
    haveAccount: '사용자 계정이 이미 있습니까?',
    login: '로그인',
    subTitle: '회원가입 요청을 보냅니다.'
  },
  field: {
    corporation: '법인',
    confirmPwd: '비밀번호 확인'
  },
  message: {
    successSignUp: '회원 가입이 완료되었습니다. 이메일로 받은 임시 비밀번호를 확인하십시오.',
    successRegister: '등록해 주셔서 감사합니다! 로그인하기 전에 계정 등록 승인을 받아야 합니다.',
    needVerifyPhone: '회원가입을 완료하기 위해 이메일/카카오 메시지를 통해 연락처를 인증해주세요.',
    matchPwd: '비밀번호가 일치하지 않습니다.',
    validatePwd: '8~30자의 영문 대/소문자, 숫자, 특수문자를 입력해주세요.',
    checkDuplicateUserError:
      '이미 존재하는 계정입니다. 승인 받는 후에 기존 비밀번호로 로그인 하십시오.',
    pleaseCheckDuplicate: '중복확인버튼을 클릭하여 중복확인을 해주세요.'
  }
};
